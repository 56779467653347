import React from 'react';
import {
  Background,
  CustomCSS,
  WidgetOptionHeader,
} from '../../../../../../../components';
import { NinjaCartEmptyStateGeneralPresetDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListTypeDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';
import { Box, Link, Text } from '@shopify/polaris';

type Preset_NDC_EmptyGeneralProps = {
  general: NinjaCartEmptyStateGeneralPresetDto;
  defaultGeneral: NinjaCartEmptyStateGeneralPresetDto;
  shopDefaultGeneral: NinjaCartEmptyStateGeneralPresetDto;
  setGeneral: (data: NinjaCartEmptyStateGeneralPresetDto) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const Preset_NDC_EmptyGeneral: React.FC<Preset_NDC_EmptyGeneralProps> = ({
  general,
  defaultGeneral,
  shopDefaultGeneral,
  setGeneral,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();

  return (
    <div>
      <WidgetOptionHeader
        onDiscardChanges={() => setGeneral(defaultGeneral)}
        onResetToSystem={() => setGeneral(shopDefaultGeneral)}
        name={WidgetListTypeDtoEnum.NINJA_CART}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />

      <div className='RightSideSection'>
        <Background
          background={general.background || {}}
          onSystemReset={() =>
            setGeneral({
              ...general,
              background: shopDefaultGeneral.background,
            })
          }
          hideBorder={true}
          setBackground={(data) => setGeneral({ ...general, background: data })}
        />
      </div>
      <div className='RightSideSection'>
        <CustomCSS
          css={general.customCss || ''}
          setCSS={(value) => setGeneral({ ...general, customCss: value })}
          note={i18n.translate('CSSNote')}
        />
      </div>
      <div className='RightSideSection'>
        <Box padding='500'>
          <Text tone='subdued' as='p'>
            {i18n.translate('SettingsInherited', {
              defaultState: <Link>{i18n.translate('defaultState')}</Link>,
            })}
          </Text>
        </Box>
      </div>
    </div>
  );
};

export default Preset_NDC_EmptyGeneral;
