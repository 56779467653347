import React from 'react';
import {
  Background,
  SingleCheckbox,
  WidgetOptionHeader,
} from '../../../../../../../../../components';
import { NinjaCartDefaultStateFooterGeneralPresetDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';
import { Box, Link, Text } from '@shopify/polaris';

type Preset_NDC_FooterGeneralProps = {
  general: NinjaCartDefaultStateFooterGeneralPresetDto;
  defaultGeneral: NinjaCartDefaultStateFooterGeneralPresetDto;
  shopDefaultGeneral: NinjaCartDefaultStateFooterGeneralPresetDto;
  setGeneral: (data: NinjaCartDefaultStateFooterGeneralPresetDto) => void;
  onShowHide?: () => void;
  onRemove?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const Preset_NDC_FooterGeneral: React.FC<Preset_NDC_FooterGeneralProps> = ({
  general,
  defaultGeneral,
  shopDefaultGeneral,
  setGeneral,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();

  return (
    <>
      <WidgetOptionHeader
        onDiscardChanges={() => setGeneral(defaultGeneral)}
        onResetToSystem={() => setGeneral(shopDefaultGeneral)}
        name={WidgetListOptionsDtoEnum.FOOTER}
        onShowHide={onShowHide}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <SingleCheckbox
          title={i18n.translate('Settings')}
          label={i18n.translate('EnableStickyFooter')}
          checked={!!general.isSticky}
          onChange={(value) => setGeneral({ ...general, isSticky: value })}
          onSystemReset={() =>
            setGeneral({ ...general, isSticky: shopDefaultGeneral.isSticky })
          }
        />
      </div>
      <div className='RightSideSection'>
        <Background
          background={general.background || {}}
          setBackground={(data) => setGeneral({ ...general, background: data })}
          onSystemReset={() =>
            setGeneral({
              ...general,
              background: shopDefaultGeneral.background,
            })
          }
        />
      </div>
      <div className='RightSideSection'>
        <Box padding='500'>
          <Text tone='subdued' as='p'>
            {i18n.translate('SettingsInherited', {
              defaultState: <Link>{i18n.translate('defaultState')}</Link>,
            })}
          </Text>
        </Box>
      </div>
    </>
  );
};

export default Preset_NDC_FooterGeneral;
