import React, { useCallback, useMemo } from 'react';
import {
  NinjaCartDefaultStateContentLevelPresetDtoRead,
  NinjaCartDefaultStateContentPresetDto,
  NinjaCartDefaultStateContentPresetDtoRead,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';
import { SelectedOptionPath } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightSideBarWrapper';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import Preset_NDC_ContentGeneral from './components/Preset_NDC_ContentGeneral/Preset_NDC_ContentGeneral';
import Preset_NDC_CartItem from './components/Preset_NDC_CartItem/Preset_NDC_CartItem';

type Preset_NDC_ContentProps = {
  content: NinjaCartDefaultStateContentPresetDtoRead;
  defaultContent: NinjaCartDefaultStateContentPresetDtoRead;
  shopDefaultContent: NinjaCartDefaultStateContentPresetDto;
  selectedOptionPath: SelectedOptionPath;
  defaultLanguage: string;
  setContent: (data: NinjaCartDefaultStateContentPresetDtoRead) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const Preset_NDC_Content: React.FC<Preset_NDC_ContentProps> = ({
  content,
  defaultContent,
  shopDefaultContent,
  selectedOptionPath,
  defaultLanguage,
  setContent,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();

  const handleUpdateOptions = useCallback(
    (
      field: keyof NinjaCartDefaultStateContentLevelPresetDtoRead,
      data: NinjaCartDefaultStateContentLevelPresetDtoRead[keyof NinjaCartDefaultStateContentLevelPresetDtoRead]
    ) => {
      setContent({
        ...content,
        options: {
          ...content.options,
          [field]: data,
        },
      });
    },
    [content, setContent]
  );

  const contentOptions = useMemo(() => {
    const selectedOption = getLastSelectedOption(selectedOptionPath);
    const selectedDefaultOption =
      selectedOptionPath?.selectedOption?.selectedOption?.selectedOption;
    switch (true) {
      case selectedOption?.name === WidgetListOptionsDtoEnum.CONTENT:
        return (
          <Preset_NDC_ContentGeneral
            general={content.general || {}}
            defaultGeneral={defaultContent.general || {}}
            shopDefaultGeneral={shopDefaultContent.general || {}}
            setGeneral={(data) => setContent({ ...content, general: data })}
            handleLeftMenuSelection={handleLeftMenuSelection}
            onShowHide={
              content.canBeHidden
                ? () => setContent({ ...content, isHidden: !content.isHidden })
                : undefined
            }
          />
        );
      case selectedDefaultOption?.name === WidgetListOptionsDtoEnum.CART_ITEM:
        return (
          <Preset_NDC_CartItem
            cartItem={content.options?.cartItem || {}}
            selectedOptionPath={selectedOptionPath}
            defaultCartItem={defaultContent.options?.cartItem || {}}
            shopDefaultCartItem={shopDefaultContent.options?.cartItem || {}}
            setCartItem={(data) => handleUpdateOptions('cartItem', data)}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
    }
  }, [
    selectedOptionPath,
    defaultContent,
    shopDefaultContent,
    content,
    handleLeftMenuSelection,
  ]);

  return <>{contentOptions}</>;
};

export default Preset_NDC_Content;
