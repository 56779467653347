import {
  Banner,
  BlockStack,
  Button,
  Grid,
  Icon,
  InlineStack,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import React, { useCallback } from 'react';
import { DeleteIcon, PlusIcon } from '@shopify/polaris-icons';
import { v4 as uuidv4 } from 'uuid';
import { ColorSelector } from '../../../ColorSelector/ColorSelector';

type BrandColor = {
  id: string;
  color: string;
};

type ConfigureBrandColorsProps = {
  brandColors: BrandColor[];
  bannerText?: string;
  setBrandColors: (data: BrandColor[]) => void;
};
export const ConfigureBrandColors: React.FC<ConfigureBrandColorsProps> = ({
  brandColors,
  bannerText,
  setBrandColors,
}) => {
  const [i18n] = useI18n();

  const handleColorChange = useCallback(
    (id: string, newColor: string) => {
      setBrandColors(
        brandColors?.map((color) =>
          id === color.id ? { ...color, color: newColor } : color
        )
      );
    },
    [brandColors]
  );

  const handleColorDelete = useCallback(
    (id: string) => {
      setBrandColors(brandColors.filter((color) => color.id !== id));
    },
    [brandColors]
  );

  const handleAddColor = useCallback(() => {
    setBrandColors([...brandColors, { id: uuidv4(), color: '#000000' }]);
  }, [brandColors]);

  return (
    <>
      {bannerText && <Banner tone='info'>{bannerText}</Banner>}
      {brandColors?.length ? (
        <Grid>
          {brandColors?.map((color, index) => (
            <Grid.Cell
              columnSpan={{ xs: 4, sm: 2, md: 2, lg: 4, xl: 4 }}
              key={color.id}
            >
              <InlineStack wrap={false} blockAlign='end' gap='300'>
                <ColorSelector
                  label={`${i18n.translate('Color')} ${index + 1}`}
                  color={color.color}
                  onColorChange={(newColor) =>
                    handleColorChange(color.id, newColor)
                  }
                />
                <div className='DeleteIcon'>
                  {brandColors && brandColors?.length > 2 && (
                    <Button
                      variant='tertiary'
                      icon={<Icon source={DeleteIcon} tone='base' />}
                      onClick={() => handleColorDelete(color.id)}
                    />
                  )}
                </div>
              </InlineStack>
            </Grid.Cell>
          ))}
        </Grid>
      ) : null}
      <BlockStack inlineAlign='start'>
        <Button
          disabled={(brandColors?.length || 0) >= 8}
          onClick={handleAddColor}
          icon={<Icon source={PlusIcon} tone='base' />}
          variant='tertiary'
        >
          {i18n.translate('AddColor')}
        </Button>
      </BlockStack>
    </>
  );
};
