import { OfferTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import {
  CartDownIcon,
  DiscountIcon,
  CartDiscountIcon,
  DeliveryIcon,
  GiftCardIcon,
  DiscountCodeIcon,
} from '@shopify/polaris-icons';
import { OfferTypesFromCatalogDataType } from '../../SelectedOfferTypeSettings/types/OfferTypesFromCatalogTypes';

export const OfferTemplatesList: OfferTypesFromCatalogDataType[] = [
  {
    title: 'PercentageDiscountTitle',
    subtitle: 'PercentageDiscountSubtitle',
    icon: DiscountIcon,
    type: OfferTypeDtoEnum.SALES_PERCENTAGE,
  },
  {
    title: 'FixedAmountDiscountTitle',
    subtitle: 'FixedAmountDiscountSubtitle',
    icon: DiscountIcon,
    type: OfferTypeDtoEnum.SALES_FIXED_AMOUNT,
  },
  {
    title: 'FixedPricePerUnitTitle',
    subtitle: 'FixedPricePerUnitSubtitle',
    icon: DiscountIcon,
    type: OfferTypeDtoEnum.SALES_FIXED_PRICE,
  },

  {
    title: 'OfferAFreeGiftAutomaticallyTitle',
    subtitle: 'OfferAFreeGiftAutomaticallySubtitle',
    icon: GiftCardIcon,
    type: OfferTypeDtoEnum.GIFT_AUTOMATIC,
  },
  {
    title: 'PickAGiftTitle',
    subtitle: 'PickAGiftSubtitle',
    icon: GiftCardIcon,
    type: OfferTypeDtoEnum.GIFT_MANUAL,
  },

  {
    title: 'CrossSellTitle',
    subtitle: 'CrossSellSubtitle',
    icon: CartDiscountIcon,
    example: 'CrossSellExample',
    type: OfferTypeDtoEnum.CROSS_SELL,
  },

  {
    title: 'BundleOrderDiscountTitle',
    subtitle: 'BundleOrderDiscountSubtitle',
    example: 'BundleOrderDiscountExample',
    icon: CartDiscountIcon,
    type: OfferTypeDtoEnum.BUNDLE_ORDER_DISCOUNT,
  },

  {
    title: 'BOGO1Title',
    subtitle: 'BOGO1Subtitle',
    example: 'BOGO1Example',
    icon: CartDiscountIcon,
    type: OfferTypeDtoEnum.BOGO_REGULAR,
  },
  {
    title: 'BOGO2Title',
    subtitle: 'BOGO2Subtitle',
    example: 'BOGO2Exampe',
    icon: CartDiscountIcon,
    type: OfferTypeDtoEnum.BOGO_STRICT_MATCH,
  },

  {
    title: 'MultiTierBogoTitle',
    subtitle: 'MultiTierBogoSubtitle',
    example: 'MultiTierBogoExample',
    icon: DiscountIcon,
    type: OfferTypeDtoEnum.TIERED_BOGO,
  },
  {
    title: 'SpendAmountTitle',
    subtitle: 'SpendAmountSubtitle',
    example: 'SpendAmountExample',
    icon: DiscountIcon,
    type: OfferTypeDtoEnum.TIERED_SPEND_X_GET_Y,
  },
  {
    title: 'VolumeTitle',
    subtitle: 'VolumeSubtitle',
    example: 'VolumeExample',
    icon: DiscountIcon,
    type: OfferTypeDtoEnum.VOLUME_DISCOUNT,
  },
  {
    title: 'ShippingPercentageTitle',
    subtitle: 'ShippingPercentageSubtitle',
    icon: DeliveryIcon,
    type: OfferTypeDtoEnum.SHIPPING_PERCENTAGE,
  },
  {
    title: 'ShippingFixedAmountTitle',
    subtitle: 'ShippingFixedAmountSubtitle',
    icon: DeliveryIcon,
    type: OfferTypeDtoEnum.SHIPPING_FIXED_AMOUNT,
  },
  {
    title: 'ShippingTitle',
    subtitle: 'ShippingSubtitle',
    icon: DeliveryIcon,
    type: OfferTypeDtoEnum.FREE_SHIPPING,
  },

  {
    title: 'PercentageDiscountTitle',
    subtitle: 'PercentageDiscountSubtitle',
    icon: DiscountIcon,
    type: OfferTypeDtoEnum.SALES_PERCENTAGE,
  },
  {
    title: 'FixedAmountDiscountTitle',
    subtitle: 'FixedAmountDiscountSubtitle',
    icon: DiscountIcon,
    type: OfferTypeDtoEnum.SALES_FIXED_AMOUNT,
  },
  {
    title: 'FixedPricePerUnitTitle',
    subtitle: 'FixedPricePerUnitSubtitle',
    icon: DiscountIcon,
    type: OfferTypeDtoEnum.SALES_FIXED_PRICE,
  },

  {
    title: 'ApplyAShopifyDiscountTitle',
    subtitle: 'ApplyAShopifyDiscountSubtitle',
    icon: DiscountCodeIcon,
    type: OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_REGULAR,
  },
  {
    title: 'ApplyAUniqueShopifyDiscountTitle',
    subtitle: 'ApplyAUniqueShopifyDiscountSubtitle',
    icon: DiscountCodeIcon,
    type: OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_GROUP,
  },
  {
    title: 'IntegrateWithOtherAppsTitle',
    subtitle: 'IntegrateWithOtherAppsSubtitle',
    icon: DiscountCodeIcon,
    type: OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_APP,
  },

  {
    title: 'FrequentlyBoughtTogetherTitle',
    subtitle: 'FrequentlyBoughtTogetherSubtitle',
    icon: CartDownIcon,
    type: OfferTypeDtoEnum.FREQUENTLY_BOUGHT_TOGETHER,
  },
  {
    title: 'OrderBumpTitle',
    subtitle: 'OrderBumpSubtitle',
    icon: CartDownIcon,
    type: OfferTypeDtoEnum.ORDER_BUMP,
  },
];
