import {
  NinjaCartDefaultStateLevelPresetDtoRead,
  NinjaCartDefaultStatePresetDto,
  NinjaCartDefaultStatePresetDtoRead,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { SelectedOptionPath } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightSideBarWrapper';
import {
  WidgetListOptionsDtoEnum,
  WidgetListTypeDtoEnum,
} from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import React, { useCallback, useMemo } from 'react';
import Preset_NDC_DefaultGeneral from './components/Preset_NDC_DefaultGeneral/Preset_NDC_DefaultGeneral';
import Preset_NDC_Header from './components/Preset_NDC_Header/Preset_NDC_Header';
import Preset_NDC_Content from './components/Preset_NDC_Content/Preset_NDC_Content';
import Preset_NDC_Footer from './components/Preset_NDC_Footer/Preset_NDC_Footer';
type Preset_NDC_DefaultProps = {
  defaultState: NinjaCartDefaultStatePresetDtoRead;
  defaultDefaultState: NinjaCartDefaultStatePresetDtoRead;
  selectedOptionPath: SelectedOptionPath;
  defaultLanguage: string;
  shopDefaultData: NinjaCartDefaultStatePresetDto;
  setDefaultState: (data: NinjaCartDefaultStatePresetDtoRead) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

export const Preset_NDC_Default: React.FC<Preset_NDC_DefaultProps> = ({
  defaultState,
  defaultDefaultState,
  selectedOptionPath,
  shopDefaultData,
  defaultLanguage,
  setDefaultState,
  handleLeftMenuSelection,
}) => {
  const handleUpdateOptions = useCallback(
    (
      field: keyof NinjaCartDefaultStateLevelPresetDtoRead,
      data: NinjaCartDefaultStateLevelPresetDtoRead[keyof NinjaCartDefaultStateLevelPresetDtoRead]
    ) => {
      setDefaultState({
        ...defaultState,
        options: {
          ...defaultState.options,
          [field]: data,
        },
      });
    },
    [defaultState, setDefaultState]
  );
  const defaultStateOptions = useMemo(() => {
    const selectedOption = getLastSelectedOption(selectedOptionPath);
    const selectedDefaultOption =
      selectedOptionPath?.selectedOption?.selectedOption;
    switch (true) {
      case selectedOption?.name === WidgetListTypeDtoEnum.NINJA_CART:
        return (
          <Preset_NDC_DefaultGeneral
            general={defaultState.general || {}}
            defaultGeneral={defaultDefaultState.general || {}}
            shopDefaultGeneral={shopDefaultData.general || {}}
            setGeneral={(data) =>
              setDefaultState({ ...defaultState, general: data })
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedDefaultOption?.name === WidgetListOptionsDtoEnum.HEADER:
        return (
          <Preset_NDC_Header
            defaultHeader={defaultDefaultState.options?.header || {}}
            header={defaultState.options?.header || {}}
            selectedOptionPath={selectedOptionPath}
            defaultLanguage={defaultLanguage}
            shopDefaultHeader={shopDefaultData.options?.header || {}}
            setHeader={(data) => handleUpdateOptions('header', data)}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );

      case selectedDefaultOption?.name === WidgetListOptionsDtoEnum.CONTENT:
        return (
          <Preset_NDC_Content
            defaultContent={defaultDefaultState.options?.content || {}}
            content={defaultState.options?.content || {}}
            selectedOptionPath={selectedOptionPath}
            defaultLanguage={defaultLanguage}
            shopDefaultContent={shopDefaultData.options?.content || {}}
            setContent={(data) => handleUpdateOptions('content', data)}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );

      case selectedDefaultOption?.name === WidgetListOptionsDtoEnum.FOOTER:
        return (
          <Preset_NDC_Footer
            defaultFooter={defaultDefaultState.options?.footer || {}}
            footer={defaultState.options?.footer || {}}
            selectedOptionPath={selectedOptionPath}
            defaultLanguage={defaultLanguage}
            shopDefaultFooter={shopDefaultData.options?.footer || {}}
            setFooter={(data) => handleUpdateOptions('footer', data)}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
    }
  }, [
    selectedOptionPath,
    defaultState,
    defaultDefaultState,
    shopDefaultData,
    handleUpdateOptions,
    handleLeftMenuSelection,
  ]);
  return <>{defaultStateOptions}</>;
};
