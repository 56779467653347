import { Popover, TextField } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import ColorSelectorPopover from '../ColorSelectorPopover/ColorSelectorPopover';
import './ColorSelectorBox.scss';

type ColorSelectorBoxProps = {
  color: string;
  setColor: (value: string) => void;
  disabled?: boolean;
};

const ColorSelectorBox: React.FC<ColorSelectorBoxProps> = ({
  color,
  setColor,
  disabled,
}) => {
  const storedColors = localStorage.getItem('recentColors');
  const [colorPopover, setColorPopover] = useState<boolean>(false);

  const onClosePopover = useCallback(() => {
    //Parsing colors from localstorage
    const recentColors = storedColors
      ? (JSON.parse(storedColors) as string[])
      : [];
    //Adding new colors
    if (!recentColors.includes(color)) {
      localStorage.setItem(
        'recentColors',
        JSON.stringify(
          recentColors.length > 20
            ? [color, ...recentColors.slice(0, -1)]
            : [color, ...recentColors]
        )
      );
    }
    //Close popover
    setColorPopover(false);
  }, [storedColors, color]);

  const validateHexOnBlur = useCallback(() => {
    const hexRegex = /^#([0-9A-F]{3}){1,2}$/i;
    if (!hexRegex.test(color)) {
      setColor('#fff');
    }
  }, [color, setColor]);

  return (
    <div className='ColorSelectorBox'>
      <Popover
        active={colorPopover}
        fullHeight
        activator={
          <div
            onClick={() => (disabled ? null : setColorPopover(true))}
            className='ColorBox'
            style={{ background: color }}
          />
        }
        autofocusTarget='first-node'
        onClose={onClosePopover}
      >
        <ColorSelectorPopover
          onClosePopover={onClosePopover}
          allowAlpha={false}
          color={{ hex: color, alpha: 1 }}
          onColorChange={(value) => setColor(value.hex || '')}
        />
      </Popover>

      <TextField
        onChange={(value) => {
          const newValue = value.replace(/[^a-zA-Z0-9]/g, '');
          setColor(`#${newValue}`);
        }}
        value={color.replace(/^#/, '')}
        disabled={disabled}
        autoComplete=''
        label=''
        onBlur={validateHexOnBlur}
      />
    </div>
  );
};

export default ColorSelectorBox;
