import React, { useCallback } from 'react';
import {
  Badge,
  Box,
  Icon,
  IconSource,
  InlineStack,
  Link,
  Text,
} from '@shopify/polaris';
import { ChevronRightIcon } from '@shopify/polaris-icons';
import { useNavigate } from 'react-router-dom';
import './NavigationBox.scss';
import classNames from 'classnames';
type NavigationBoxProps = {
  title: string;
  btnContent: React.ReactNode;
  icon?: IconSource;
  tag?: string;
  link?: string;
  fullClickable?: boolean;
  onClick?: () => void;
};

export const NavigationBox: React.FC<NavigationBoxProps> = ({
  title,
  btnContent,
  icon,
  tag,
  link,
  fullClickable,
  onClick,
}) => {
  const navigate = useNavigate();

  const handleNavigate = useCallback(
    () => (link ? navigate(link) : onClick?.()),
    [link, fullClickable, onClick]
  );

  return (
    <div
      onClick={() => (fullClickable ? handleNavigate() : null)}
      className={classNames('NavigationBox', {
        isHoverable: fullClickable,
      })}
    >
      <Box
        borderColor='border'
        borderWidth='025'
        borderRadius='200'
        padding={fullClickable ? '400' : '300'}
      >
        <InlineStack align='space-between' blockAlign='center'>
          <InlineStack gap='200'>
            {icon && <Icon tone='base' source={icon}></Icon>}
            <Text fontWeight='medium' as='p'>
              {title}
            </Text>
            {tag && <Badge>{tag}</Badge>}
          </InlineStack>
          <Link
            monochrome={fullClickable}
            removeUnderline
            onClick={handleNavigate}
          >
            <InlineStack gap='050' blockAlign='center'>
              {btnContent}
              <Icon source={ChevronRightIcon}></Icon>
            </InlineStack>
          </Link>
        </InlineStack>
      </Box>
    </div>
  );
};
