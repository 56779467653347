import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { BlockStack, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { DiscountNinjaPromoCodesSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  useConfigureSettings,
  SettingsFetchTypeEnum,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { SettingsSavebarRef } from 'features/settings/Settings';
import { SettingsSkeleton } from '../../../components/SettingsSkeleton/SettingsSkeleton';
import { DNPromocodesGeneral } from './components/DNPromocodesGeneral/DNPromocodesGeneral';
import { MultipleCodeSupport } from './components/MultipleCodeSupport/MultipleCodeSupport';
import { PromocodeActivation } from './components/PromocodeActivation/PromocodeActivation';

export const DNPromocodes = forwardRef<SettingsSavebarRef>((_, ref) => {
  const [i18n] = useI18n();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { dnPromocodesData, dnPromocodesIsFetching, saveDNPromocodes } =
    useConfigureSettings(SettingsFetchTypeEnum.DN_PROMO_CODES);

  const [dnPromocodesState, setDNPromocodesState] =
    useState<DiscountNinjaPromoCodesSettingsDto>();
  const [savedData, setSavedData] =
    useState<DiscountNinjaPromoCodesSettingsDto>({});

  const handleUpdateDNPromocodes = useCallback(
    (
      field: keyof DiscountNinjaPromoCodesSettingsDto,
      data: DiscountNinjaPromoCodesSettingsDto[keyof DiscountNinjaPromoCodesSettingsDto]
    ) => setDNPromocodesState((prev) => ({ ...prev, [field]: data })),
    [setDNPromocodesState]
  );

  const handleSaveChanges = useCallback(() => {
    const onSuccess = () =>
      setSavedData(dnPromocodesState as DiscountNinjaPromoCodesSettingsDto);
    return saveDNPromocodes(
      dnPromocodesState as DiscountNinjaPromoCodesSettingsDto,
      onSuccess
    );
  }, [dnPromocodesState]);

  const handleRedirectBack = useCallback(() => {
    setSearchParams((params) => {
      params.delete('subPath');
      return params;
    });
  }, [setSearchParams]);

  useImperativeHandle(ref, () => ({
    discardChanges: () => setDNPromocodesState(savedData),
    saveChanges: handleSaveChanges,
  }));

  useEffect(() => {
    dispatch(setIsUnsavedChanges(!isEqual(savedData, dnPromocodesState)));
  }, [dnPromocodesState, savedData]);

  useEffect(() => {
    if (!isEmpty(dnPromocodesData)) {
      setDNPromocodesState(dnPromocodesData);
      setSavedData(dnPromocodesData);
    }
  }, [dnPromocodesData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  return (
    <div>
      {!dnPromocodesIsFetching && dnPromocodesState ? (
        <Page
          backAction={{ onAction: handleRedirectBack }}
          title={i18n.translate('DiscountNinjaPromocodes')}
        >
          <BlockStack gap='600'>
            <DNPromocodesGeneral
              isEnabled={!!dnPromocodesState.isEnabled}
              setIsEnabled={(value) =>
                handleUpdateDNPromocodes('isEnabled', value)
              }
            />
            <MultipleCodeSupport
              allowType={dnPromocodesState.multipleSupport}
              setAllowType={(value) =>
                handleUpdateDNPromocodes('multipleSupport', value)
              }
            />
            <PromocodeActivation
              expiration={dnPromocodesState.expiration}
              rememberType={dnPromocodesState.rememberType}
              handleUpdateDNPromocodes={handleUpdateDNPromocodes}
            />
          </BlockStack>
          <div style={{ width: 20, minHeight: 200 }}></div>
        </Page>
      ) : (
        <SettingsSkeleton cardsCount={4} />
      )}
    </div>
  );
});
DNPromocodes.displayName = 'DNPromocodes';
