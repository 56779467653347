import React, { useCallback } from 'react';
import {
  Background,
  CornerRadius,
  Size,
  TextStyle,
  WidgetOptionHeader,
} from '../../../../../../../../../../../components';
import { NinjaCartCartItemCartTextPresetDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';

type Preset_NDC_CartTextProps = {
  cartText: NinjaCartCartItemCartTextPresetDto;
  defaultCartText: NinjaCartCartItemCartTextPresetDto;
  shopDefaultCartText: NinjaCartCartItemCartTextPresetDto;
  setCartText: (data: NinjaCartCartItemCartTextPresetDto) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const Preset_NDC_CartText: React.FC<Preset_NDC_CartTextProps> = ({
  cartText,
  defaultCartText,
  shopDefaultCartText,
  setCartText,
  handleLeftMenuSelection,
}) => {
  const handleUpdateText = useCallback(
    (
      field: keyof NinjaCartCartItemCartTextPresetDto,
      data: NinjaCartCartItemCartTextPresetDto[keyof NinjaCartCartItemCartTextPresetDto]
    ) => setCartText({ ...cartText, [field]: data }),
    [cartText]
  );

  return (
    <>
      <WidgetOptionHeader
        onDiscardChanges={() => setCartText(defaultCartText)}
        onResetToSystem={() => setCartText(shopDefaultCartText)}
        name={WidgetListOptionsDtoEnum.CART_TEXT}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <TextStyle
          textStyle={cartText.textStyle || {}}
          setTextStyle={(data) => handleUpdateText('textStyle', data)}
          onSystemReset={() =>
            handleUpdateText('textStyle', shopDefaultCartText.textStyle)
          }
        />
      </div>
      <div className='RightSideSection'>
        <Size
          size={cartText.size || {}}
          disableWidth
          onSystemReset={() =>
            handleUpdateText('size', shopDefaultCartText.size)
          }
          setSize={(data) => handleUpdateText('size', data)}
        />
      </div>
      <div className='RightSideSection'>
        <Background
          background={cartText.background || {}}
          onSystemReset={() =>
            handleUpdateText('background', shopDefaultCartText.background)
          }
          setBackground={(data) => handleUpdateText('background', data)}
        />
      </div>
      <div className='RightSideSection'>
        <CornerRadius
          cornerRadius={cartText.cornerRadius || {}}
          setCornerRadius={(data) => handleUpdateText('cornerRadius', data)}
          onSystemReset={() =>
            handleUpdateText('cornerRadius', shopDefaultCartText.cornerRadius)
          }
        />
      </div>
    </>
  );
};

export default Preset_NDC_CartText;
