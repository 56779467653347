import React, { useCallback, useEffect, useState } from 'react';
import './ColorSelectorPopover.scss';
import {
  HSBAColor,
  hsbToRgb,
  rgbToHex,
  ColorPicker,
  rgbToHsb,
  Button,
  Text,
  TextField,
  BlockStack,
  InlineStack,
  Box,
} from '@shopify/polaris';
import { hexToRgb } from 'core/utils/hexToRgb';
import { XIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import { hexToHsb } from 'core/utils/hexToHsb';
import { useAppSelector } from 'core/hooks';

export type ColorDto = {
  hex?: string | undefined;
  alpha?: number | undefined;
};
type ColorSelectorPopoverProps = {
  color?: ColorDto | null;
  onColorChange: (value: ColorDto) => void;
  onClosePopover: () => void;
  allowAlpha?: boolean;
};

const ColorSelectorPopover: React.FC<ColorSelectorPopoverProps> = ({
  onColorChange,
  color,
  onClosePopover,
  allowAlpha,
}) => {
  const [i18n] = useI18n();
  const { brandColors } = useAppSelector((state) => state.settings);
  const storedColors = localStorage.getItem('recentColors');
  const recentColors = storedColors
    ? (JSON.parse(storedColors) as string[])
    : [];

  const [shadowColor, setShadowColor] = useState({
    color: { hue: 0, saturation: 0, brightness: 0, alpha: color?.alpha || 0 },
    hex: color?.hex || '#000000',
  });

  const handleColorChange = useCallback((value: HSBAColor) => {
    setShadowColor((prevState) => ({
      ...prevState,
      color: value,
      hex: rgbToHex(hsbToRgb(value)),
    }));
  }, []);

  const pickBrandColor = useCallback((hex: string) => {
    setShadowColor((prevState) => ({
      ...prevState,
      color: {
        ...rgbToHsb(hexToRgb(hex)),
        alpha: 1,
      },
      hex: hex,
    }));
  }, []);

  useEffect(() => {
    onColorChange({ hex: shadowColor.hex, alpha: shadowColor.color.alpha });
  }, [shadowColor]);

  useEffect(() => {
    if (color?.hex) {
      setShadowColor({ ...shadowColor, color: hexToHsb(color as ColorDto) });
    }
  }, []);

  return (
    <div className='ColorSelectorPopover'>
      <Box
        paddingInline='300'
        paddingBlock='200'
        borderBlockEndWidth='025'
        borderColor='border'
      >
        <InlineStack align='space-between' blockAlign='center'>
          <Text as='h6' fontWeight='semibold'>
            {i18n.translate('Color')}
          </Text>
          <Button onClick={onClosePopover} icon={XIcon} variant='plain' />
        </InlineStack>
      </Box>
      <Box padding='300'>
        <BlockStack gap='300'>
          <ColorPicker
            fullWidth
            onChange={(value) => handleColorChange(value)}
            color={shadowColor.color}
            allowAlpha={allowAlpha}
          />
          <TextField
            label=''
            autoComplete=''
            value={shadowColor.hex.slice(1)}
          />
          <BlockStack>
            <Text as='p' tone='subdued'>
              {i18n.translate('BrandColors')}
            </Text>
            <div className='SavedColorsBoxes'>
              {brandColors.brandColors?.map((item, idx) => (
                <div
                  key={idx}
                  onClick={() => pickBrandColor(item)}
                  style={{ background: item }}
                  className='Color'
                />
              ))}
            </div>
          </BlockStack>
          {!!recentColors.length && (
            <BlockStack>
              <Text as='p' tone='subdued'>
                {i18n.translate('RecentlyUsed')}
              </Text>
              <div className='SavedColorsBoxes'>
                {recentColors?.map((item, idx) => (
                  <div
                    key={idx}
                    onClick={() => pickBrandColor(item)}
                    style={{ background: item }}
                    className='Color'
                  />
                ))}
              </div>
            </BlockStack>
          )}
        </BlockStack>
      </Box>
    </div>
  );
};

export default ColorSelectorPopover;
