import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './Onboarding.scss';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  InlineStack,
  Page,
} from '@shopify/polaris';
import {
  BrandColorsStep,
  EmailStep,
  FinalStep,
  GoalStep,
  WelcomeStep,
  EnableDNStep,
  WidgetsIntroStep,
  EnableNinjaCartStep,
} from './components';
import { useI18n } from '@shopify/react-i18n';
import {
  CompleteOnboardingRequestDto,
  PromotionEngineAndPlanCheckResponseDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  OnboardingFetchTypeEnum,
  useConfigureOnboarding,
} from './hooks/useConfigureOnboarding';
import { isEmpty } from 'lodash';
import { CardsSkeleton } from 'core/components';
import { ThemeTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';

export type EngineStatusesDto = {
  test?: PromotionEngineAndPlanCheckResponseDto;
  current?: PromotionEngineAndPlanCheckResponseDto;
};

export type SelectedThemesType = {
  currentId: string;
  testId: string;
};

type OnboardingProps = {
  finishOnboarding: () => void;
};
export const Onboarding: React.FC<OnboardingProps> = ({ finishOnboarding }) => {
  const [i18n] = useI18n();
  const { onboardingInitialData } = useConfigureOnboarding(
    OnboardingFetchTypeEnum.ONBOARDING_PAGE
  );

  const [onboardingSetup, setOnboardingSetup] =
    useState<CompleteOnboardingRequestDto>({});

  const [step, setStep] = useState<number>(1);
  const [disableNext, setDisableNext] = useState<boolean>(false);

  const [engineStatuses, setEngineStatuses] = useState<EngineStatusesDto>();
  const [themeType, setThemeType] = useState<ThemeTypeDtoEnum>(
    ThemeTypeDtoEnum.CURRENT
  );
  const [selectedThemes, setSelectedThemes] = useState<SelectedThemesType>({
    currentId: '',
    testId: '',
  });

  const showStepButtons = useMemo(() => step !== 1 && step !== 8, [step]);

  const isEmailValid = useMemo(() => {
    const regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
    return step === 3 ? regex.test(onboardingSetup.email as string) : true;
  }, [onboardingSetup.email, step]);

  const isGoalValid = useMemo(
    () => (step === 2 ? !!onboardingSetup.goal?.goals?.length : true),
    [onboardingSetup.goal?.goals, step]
  );

  const onNextStep = useCallback(() => {
    if (isEmailValid) {
      setStep((prev) => prev + 1);
    } else {
      setDisableNext(true);
    }
  }, [setStep, isEmailValid]);

  const onPrevStep = useCallback(() => {
    setDisableNext(false);
    setStep(step - 1);
  }, [step]);

  const hadnleUpdateSetup = useCallback(
    (
      field: keyof CompleteOnboardingRequestDto,
      data: CompleteOnboardingRequestDto[keyof CompleteOnboardingRequestDto]
    ) => {
      setDisableNext(false);
      setOnboardingSetup({ ...onboardingSetup, [field]: data });
    },
    [onboardingSetup]
  );

  const currentStep = useMemo(() => {
    switch (step) {
      case 1:
        return <WelcomeStep onNextStep={onNextStep} />;
      case 2:
        return (
          <GoalStep
            goal={onboardingSetup.goal}
            setGoal={(data) => hadnleUpdateSetup('goal', data)}
          />
        );
      case 3:
        return (
          <EmailStep
            email={onboardingSetup.email}
            emailValid={!disableNext}
            subscribe={onboardingSetup.subscribeToNewsletter}
            setSubscribe={(value) =>
              hadnleUpdateSetup('subscribeToNewsletter', value)
            }
            setEmail={(value) => hadnleUpdateSetup('email', value)}
          />
        );
      case 4:
        return (
          <BrandColorsStep
            brandColors={onboardingSetup.brandColors}
            setBrandColors={(value) => hadnleUpdateSetup('brandColors', value)}
          />
        );
      case 5:
        return <WidgetsIntroStep />;
      case 6:
        return (
          <EnableDNStep
            engineStatuses={engineStatuses}
            themeType={themeType}
            selectedThemes={selectedThemes}
            setEngineStatuses={setEngineStatuses}
            setThemeType={setThemeType}
            setSelectedThemes={setSelectedThemes}
          />
        );
      case 7:
        return (
          <EnableNinjaCartStep
            onNextStep={onNextStep}
            setDisableNext={setDisableNext}
          />
        );
      case 8:
        return (
          <FinalStep
            onboardingSetup={onboardingSetup}
            isTestThemeEnabled={
              !!engineStatuses?.test?.promotionEngine?.enabled
            }
            finishOnboarding={finishOnboarding}
          />
        );
    }
  }, [
    step,
    onboardingSetup,
    disableNext,
    engineStatuses,
    themeType,
    selectedThemes,
    engineStatuses?.test?.promotionEngine?.enabled,
    onNextStep,
  ]);

  useEffect(() => {
    if (onboardingInitialData) {
      setOnboardingSetup(onboardingInitialData);
    }
  }, [onboardingInitialData]);

  return (
    <div className='Onboarding'>
      <Page>
        {!isEmpty(onboardingSetup) ? (
          <Card padding='600'>
            <InlineStack align='center'>
              <Box width='70%'>
                {currentStep}
                {showStepButtons && (
                  <Box paddingBlock='400'>
                    <InlineStack align='end'>
                      <ButtonGroup>
                        <Button onClick={onPrevStep}>
                          {i18n.translate('Back')}
                        </Button>
                        <Button
                          disabled={disableNext || !isGoalValid}
                          onClick={onNextStep}
                          variant='primary'
                        >
                          {i18n.translate(
                            step === 7 ? 'FinishConfiguration' : 'Next'
                          )}
                        </Button>
                      </ButtonGroup>
                    </InlineStack>
                  </Box>
                )}
              </Box>
            </InlineStack>
          </Card>
        ) : (
          <CardsSkeleton cardsCount={1} size='large' />
        )}
      </Page>
    </div>
  );
};
