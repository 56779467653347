import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { BlockStack, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { ShopifyDiscountCodesSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  useConfigureSettings,
  SettingsFetchTypeEnum,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { SettingsSavebarRef } from 'features/settings/Settings';
import { SettingsSkeleton } from '../../../components/SettingsSkeleton/SettingsSkeleton';
import { SDCFilter } from './components/SDCFilter/SDCFilter';
import { SDCGeneral } from './components/SDCGeneral/SDCGeneral';

export const ShopifyDiscountCodes = forwardRef<SettingsSavebarRef>((_, ref) => {
  const [i18n] = useI18n();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    shopifyDiscountCodesData,
    shopifyDiscountCodesIsFetching,
    saveShopifyDiscountCodes,
  } = useConfigureSettings(SettingsFetchTypeEnum.SHOPIFY_DISCOUNT_CODES);

  const [sdcState, setSDCState] = useState<ShopifyDiscountCodesSettingsDto>();
  const [savedData, setSavedData] = useState<ShopifyDiscountCodesSettingsDto>(
    {}
  );

  const handleUpdateSDC = useCallback(
    (
      field: keyof ShopifyDiscountCodesSettingsDto,
      data: ShopifyDiscountCodesSettingsDto[keyof ShopifyDiscountCodesSettingsDto]
    ) => setSDCState((prev) => ({ ...prev, [field]: data })),
    [setSDCState]
  );

  const handleSaveChanges = useCallback(() => {
    const onSuccess = () =>
      setSavedData(sdcState as ShopifyDiscountCodesSettingsDto);
    return saveShopifyDiscountCodes(
      sdcState as ShopifyDiscountCodesSettingsDto,
      onSuccess
    );
  }, [sdcState]);

  const handleRedirectBack = useCallback(() => {
    setSearchParams((params) => {
      params.delete('subPath');
      return params;
    });
  }, [setSearchParams]);

  useImperativeHandle(ref, () => ({
    discardChanges: () => setSDCState(savedData),
    saveChanges: handleSaveChanges,
  }));

  useEffect(() => {
    dispatch(setIsUnsavedChanges(!isEqual(savedData, sdcState)));
  }, [sdcState, savedData]);

  useEffect(() => {
    if (!isEmpty(shopifyDiscountCodesData)) {
      setSDCState(shopifyDiscountCodesData);
      setSavedData(shopifyDiscountCodesData);
    }
  }, [shopifyDiscountCodesData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  return (
    <div>
      {!shopifyDiscountCodesIsFetching && sdcState ? (
        <Page
          backAction={{ onAction: handleRedirectBack }}
          title={i18n.translate('ShopifyDiscountCodes')}
        >
          <BlockStack gap='600'>
            <SDCGeneral
              isEnabled={!!sdcState.isEnabled}
              setIsEnabled={(value) => handleUpdateSDC('isEnabled', value)}
            />
            <SDCFilter
              filters={sdcState.limitationsOverrideSettings}
              setFilters={(data) =>
                handleUpdateSDC('limitationsOverrideSettings', data)
              }
            />
          </BlockStack>
          <div style={{ width: 20, minHeight: 200 }}></div>
        </Page>
      ) : (
        <SettingsSkeleton cardsCount={4} />
      )}
    </div>
  );
});
ShopifyDiscountCodes.displayName = 'ShopifyDiscountCodes';
