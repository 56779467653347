export enum EditPresetTypeEnum {
  PRESET_EDITOR = 'PRESET_EDITOR',
  PROMOTION_EDITOR = 'PROMOTION_EDITOR',
}
export enum WidgetListOptionsDtoEnum {
  GENERAL = 'general',
  FONT = 'font',
  BAR_PRIMARY = 'barPrimary',
  BAR_SECONDARY = 'barSecondary',
  IMAGE = 'image',
  CHEVRONS = 'chevrons',
  CLOSING_ICON = 'closingIcon',
  INDICATORS = 'indicators',
  BACKGROUND = 'background',
  ICON = 'icon',
  MESSAGE = 'message',
  BADGE = 'badge',
  HEADER = 'header',
  FOOTER = 'footer',
  OFFER = 'offer',
  PRIMARY_MULTI_SLOT = 'primaryBanner',
  SECONDARY_THREE_SLOT = 'secondaryBanner',
  CODE_INPUT = 'codeInput',
  CODE_VALIDATION = 'codeValidation',
  TOTAL = 'total',
  SUBTOTAL = 'subtotal',
  SHIPPING = 'shipping',
  TAXES_AND_DUTIES = 'taxesAndDuties',
  DISCOUNT = 'discount',
  DISCLAIMER = 'disclaimer',
  OFFER_RULE = 'offerRule',
  CTA_BUTTON = 'callToActionButton',
  HEADLINE_TEXT = 'headlineText',
  RULE_TEXT = 'ruleText',
  DISCOUNTED_PRICE = 'discountedPrice',
  NOT_DISCOUNTED_PRICE = 'notDiscountedPrice',
  PRICE = 'price',
  HEADER_TEXT = 'headerText',
  FOOTER_TEXT = 'footerText',
  BODY_ROW_1 = 'bodyRow1',
  BODY_ROW_2 = 'bodyRow2',
  BODY_ROW_3 = 'bodyRow3',
  ENTITLED = 'entitled',
  PREREQUISITE = 'prerequisite',
  EMPTY_STATE = 'emptyState',
  DEFAULT_STATE = 'defaultState',
  TEXT = 'text',
  HEADING = 'heading',
  ITEM_COUNT = 'itemCount',
  CONTENT = 'content',
  CART_ITEM = 'cartItem',
  CART_TEXT = 'cartText',
  PRODUCT_BADGE = 'productBadge',
  CTA_BUTTONS = 'ctaButtons',
  CTA_BUTTON_1 = 'ctaButton1',
  CTA_BUTTON_2 = 'ctaButton2',
  PAYMENT_OPTIONS = 'paymentOptions',
  TERMS_AND_CONDITIONS = 'termsAndConditions',
  CONTINUE_SHOPPING_BUTTON = 'continueShoppingButton',
}

export enum WidgetListTypeDtoEnum {
  ANNOUNCEMENT_BAR = 'announcementBar',
  NOTIFICATION = 'notification',
  PRODUCT_BANNER = 'productBanner',
  PROMOTIONAL_BADGE = 'promotionalBadge',
  OFFER_RULE_POPUP = 'offerRulePopup',
  DRAWER_CART_PROMOTION_SUMMARY = 'drawerCartPromotionSummary',
  CART_PROMOTION_SUMMARY = 'cartPromotionSummary',
  DRAWER_CART_PROMOTION_CODE_FIELD = 'drawerCartPromotionCodeField',
  CART_PROMOTION_CODE_FIELD = 'cartPromotionCodeField',
  COUNTDOWN_CLOCK = 'countdownClock',
  PRODUCT_PAGE_PRICE = 'productPagePrice',
  COLLECTION_PAGE_PRICE = 'collectionPagePrice',
  DRAWER_CART_PRODUCT_PRICE = 'drawerCartProductPrice',
  DRAWER_CART_LINE_PRICE = 'drawerCartLinePrice',
  DRAWER_CART_TOTAL_PRICE = 'drawerCartTotalPrice',
  DRAWER_CART_BREAKDOWN_PRICE = 'drawerCartBreakdownPrice',
  CART_PRODUCT_PRICE = 'cartProductPrice',
  CART_LINE_PRICE = 'cartLinePrice',
  CART_TOTAL_PRICE = 'cartTotalPrice',
  CART_BREAKDOWN_PRICE = 'cartBreakdownPrice',
  CART_PAGE_CART_TEXT = 'cartPageCartText',
  DRAWER_CART_PAGE_CART_TEXT = 'drawerCartPageCartText',
  DISCOUNT_LABEL = 'discountLabel',
  NINJA_CART = 'ninjaCart',
  NINJA_CART_PROMOTION_SUMMARY = 'promotionSummary',
  POP_UP = '',
  STRIKETHROUGH_PRICING = '',
  CHECKOUT_MESSAGE = '',
  VOLUME_DISCOUNT = '',
  NINJA_CART_CART_TEXT = '',
  NINJA_CART_PRODUCT_BADGE = '',
}
