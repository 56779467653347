import React from 'react';
import { Modal, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';

type TestThemeAppModalProps = {
  isOpen: boolean;
  onClose: (value: boolean | null) => void;
};

export const TestThemeAppModal: React.FC<TestThemeAppModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [i18n] = useI18n();
  return (
    <Modal
      open={isOpen}
      title={i18n.translate('Title')}
      onClose={() => onClose(false)}
      primaryAction={{
        content: i18n.translate('Continue'),
        onAction: () => onClose(null),
      }}
      secondaryActions={[
        {
          content: i18n.translate('Cancel'),
          onAction: () => onClose(false),
        },
      ]}
    >
      <Modal.Section>
        <Text as='p'>{i18n.translate('Description')}</Text>
      </Modal.Section>
    </Modal>
  );
};
