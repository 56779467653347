import React, { useCallback, useMemo } from 'react';
import {
  Banner,
  BlockStack,
  Box,
  Checkbox,
  Icon,
  InlineStack,
  List,
  Text,
  TextField,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { PromotionCodeComplexityDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { GeneratePromotionCodesAutomaticallyRequestDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { SelectIcon } from '@shopify/polaris-icons';
type AutomaticCodesGenerateProps = {
  automatic: GeneratePromotionCodesAutomaticallyRequestDto;
  handleUpdateAutomatic: (
    field: keyof GeneratePromotionCodesAutomaticallyRequestDto,
    data: GeneratePromotionCodesAutomaticallyRequestDto[keyof GeneratePromotionCodesAutomaticallyRequestDto]
  ) => void;
};

const AutomaticCodesGenerate: React.FC<AutomaticCodesGenerateProps> = ({
  automatic,
  handleUpdateAutomatic,
}) => {
  const [i18n] = useI18n();

  const alphanumericRegex = /^[a-zA-Z0-9]*$/;

  const complexityOptions = useMemo(
    () =>
      Object.values(PromotionCodeComplexityDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    []
  );
  const limitOptions = useMemo(
    () =>
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => ({
        label: `${value}`,
        value: `${value}`,
      })),
    []
  );

  const validateAmount = useCallback(() => {
    const value = automatic.amount || 1;
    handleUpdateAutomatic(
      'amount',
      value > 1000 ? 1000 : value < 1 ? 1 : value
    );
  }, [automatic.amount, handleUpdateAutomatic]);

  const codeExamples = useMemo(() => {
    const suffix = automatic?.suffix ? `-${automatic?.suffix}` : '';
    const prefix = automatic.prefix ? `${automatic?.prefix}-` : '';
    return [
      ['TWS8', 'GPEG'],
      ['JDUY', 'EFKY'],
      ['OSHZ', 'MBLS'],
    ].map((example, index) => (
      <Text key={index} as='p' tone='subdued'>
        {automatic.complexity === PromotionCodeComplexityDtoEnum.SIMPLE &&
          `${prefix}${example[0]}${suffix}`}
        {automatic.complexity === PromotionCodeComplexityDtoEnum.MEDIUM &&
          `${prefix}${example
            .map((code) => code.slice(0, -1))
            .join('-')}${suffix}`}
        {automatic.complexity === PromotionCodeComplexityDtoEnum.ADVANCED &&
          `${prefix}${example.join('-')}${suffix}`}
      </Text>
    ));
  }, [automatic]);

  return (
    <BlockStack gap='400'>
      <Banner tone='info' title={i18n.translate('BestPractices')}>
        <List gap='extraTight' type='bullet'>
          <List.Item>{i18n.translate('BestPractices1')}</List.Item>
          <List.Item>{i18n.translate('BestPractices2')}</List.Item>
          <List.Item>{i18n.translate('BestPractices3')}</List.Item>
        </List>
      </Banner>
      <Box
        borderRadius='200'
        padding='400'
        borderWidth='025'
        borderColor='border-brand'
      >
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='semibold'>
              {i18n.translate('DefineUnique')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('CodesSensitive')}
            </Text>
          </BlockStack>
          <InlineStack gap='200'>
            <Box width='210px'>
              <TextField
                autoComplete=''
                placeholder={i18n.translate('PrefixExample')}
                label={i18n.translate('Prefix')}
                value={automatic?.prefix}
                maxLength={10}
                onChange={(value) =>
                  alphanumericRegex.test(value) &&
                  handleUpdateAutomatic('prefix', value)
                }
              />
            </Box>
            <Box width='120px'>
              <SelectOptions
                label={i18n.translate('Complexity')}
                options={complexityOptions}
                selectedOption={automatic?.complexity as string}
                onOptionSelect={(value) =>
                  handleUpdateAutomatic('complexity', value)
                }
              />
            </Box>
            <TextField
              autoComplete=''
              label={i18n.translate('Suffix')}
              placeholder={i18n.translate('SuffixExample')}
              value={automatic?.suffix}
              maxLength={10}
              onChange={(value) =>
                alphanumericRegex.test(value) &&
                handleUpdateAutomatic('suffix', value)
              }
            />
          </InlineStack>
          <Box borderRadius='200' padding='300' background='bg-fill-secondary'>
            <InlineStack gap='800'>
              <Text tone='subdued' fontWeight='semibold' as='p'>
                {i18n.translate('Examples')}
              </Text>
              <BlockStack>{codeExamples}</BlockStack>
            </InlineStack>
          </Box>
        </BlockStack>
      </Box>
      <Box
        borderRadius='200'
        padding='400'
        borderWidth='025'
        borderColor='border-brand'
      >
        <InlineStack align='space-between' blockAlign='center'>
          <BlockStack gap='100'>
            <Text as='p'>{i18n.translate('NumberOfCodes')}</Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('Max1000')}
            </Text>
          </BlockStack>
          <Box width='180px'>
            <TextField
              label
              autoComplete=''
              value={automatic.amount?.toString()}
              type='number'
              onChange={(value) => handleUpdateAutomatic('amount', value)}
              onBlur={validateAmount}
            />
          </Box>
        </InlineStack>
      </Box>
      <Box
        borderRadius='200'
        padding='400'
        borderWidth='025'
        borderColor='border-brand'
      >
        <InlineStack align='space-between' blockAlign='center'>
          <Checkbox
            checked={automatic.maximumUses !== 0}
            label={i18n.translate('MaximumUses')}
            helpText={i18n.translate('MaximumUsesSubtitle')}
            onChange={(value) =>
              handleUpdateAutomatic('maximumUses', value ? 1 : 0)
            }
          />
          <Box width='180px'>
            {automatic.maximumUses !== 0 ? (
              <SelectOptions
                options={limitOptions}
                label=''
                selectedOption={automatic.maximumUses?.toString()}
                allowManual
                min={1}
                max={10}
                onOptionSelect={(value) =>
                  handleUpdateAutomatic('maximumUses', +value)
                }
              />
            ) : (
              <TextField
                autoComplete=''
                value={i18n.translate('Unlimited')}
                label=''
                disabled
                suffix={<Icon source={SelectIcon} tone='base' />}
              />
            )}
          </Box>
        </InlineStack>
      </Box>
    </BlockStack>
  );
};

export default AutomaticCodesGenerate;
