import React, { useCallback } from 'react';
import {
  Background,
  CornerRadius,
  Offset,
  Position,
  ShopSettingsWrapper,
  Size,
  Stacking,
  Stitch,
  TextStyle,
  WidgetOptionHeader,
} from '../../../../../../../../../../../components';
import { NinjaCartCartItemProductBadgePresetDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';

type Preset_NDC_ProductBadgeProps = {
  productBadge: NinjaCartCartItemProductBadgePresetDto;
  defaultProductBadge: NinjaCartCartItemProductBadgePresetDto;
  shopDefaultProductBadge: NinjaCartCartItemProductBadgePresetDto;
  setProductBadge: (data: NinjaCartCartItemProductBadgePresetDto) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const Preset_NDC_ProductBadge: React.FC<Preset_NDC_ProductBadgeProps> = ({
  productBadge,
  defaultProductBadge,
  shopDefaultProductBadge,
  setProductBadge,
  handleLeftMenuSelection,
}) => {
  const handleUpdateBadge = useCallback(
    (
      field: keyof NinjaCartCartItemProductBadgePresetDto,
      data: NinjaCartCartItemProductBadgePresetDto[keyof NinjaCartCartItemProductBadgePresetDto]
    ) => setProductBadge({ ...productBadge, [field]: data }),
    [productBadge, setProductBadge]
  );

  return (
    <>
      <WidgetOptionHeader
        onDiscardChanges={() => setProductBadge(defaultProductBadge)}
        onResetToSystem={() => setProductBadge(shopDefaultProductBadge)}
        name={WidgetListOptionsDtoEnum.PRODUCT_BADGE}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <TextStyle
          textStyle={productBadge.textStyle || {}}
          setTextStyle={(data) => handleUpdateBadge('textStyle', data)}
          onSystemReset={() =>
            handleUpdateBadge('textStyle', shopDefaultProductBadge.textStyle)
          }
        />
      </div>
      <div className='RightSideSection'>
        <Size
          size={productBadge.style || {}}
          setSize={(data) => handleUpdateBadge('style', data)}
          disableWidth
          onSystemReset={() =>
            handleUpdateBadge('style', shopDefaultProductBadge.style)
          }
        />
      </div>
      <div className='RightSideSection'>
        <Background
          background={productBadge.background || {}}
          onSystemReset={() =>
            handleUpdateBadge('background', shopDefaultProductBadge.background)
          }
          setBackground={(data) => handleUpdateBadge('background', data)}
        />
      </div>
      <div className='RightSideSection'>
        <CornerRadius
          cornerRadius={productBadge.cornerRadius || {}}
          setCornerRadius={(data) => handleUpdateBadge('cornerRadius', data)}
          onSystemReset={() =>
            handleUpdateBadge(
              'cornerRadius',
              shopDefaultProductBadge.cornerRadius
            )
          }
        />
      </div>
      <div className='RightSideSection'>
        <Stitch
          stitch={productBadge.stitch || {}}
          setStitch={(data) => handleUpdateBadge('stitch', data)}
          onSystemReset={() =>
            handleUpdateBadge('stitch', shopDefaultProductBadge.stitch)
          }
        />
      </div>
      <ShopSettingsWrapper>
        <div className='RightSideSection'>
          <Position
            position={{ position: productBadge.shopSettings?.position }}
            disabled
          />
        </div>
        <div className='RightSideSection'>
          <Stacking
            stacking={{ value: productBadge.shopSettings?.stacking }}
            allowDirection
            disabled
          />
        </div>
        <div className='RightSideSection'>
          <Offset
            offset={{ value: productBadge.shopSettings?.offset || {} }}
            disabled
          />
        </div>
      </ShopSettingsWrapper>
    </>
  );
};

export default Preset_NDC_ProductBadge;
