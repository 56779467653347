import React from 'react';
import { BlockStack, Box, Checkbox, Text, TextField } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
type EmailStepProps = {
  email?: string;
  emailValid: boolean;
  subscribe?: boolean;
  setSubscribe: (value: boolean) => void;
  setEmail: (data: string) => void;
};
export const EmailStep: React.FC<EmailStepProps> = ({
  email,
  emailValid,
  subscribe,
  setSubscribe,
  setEmail,
}) => {
  const [i18n] = useI18n();

  return (
    <Box paddingBlock='400'>
      <BlockStack gap='400'>
        <BlockStack gap='200'>
          <Text as='h1' variant='headingLg' fontWeight='semibold'>
            {i18n.translate('EmailTitle')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('EmailSubtitle')}
          </Text>
        </BlockStack>
        <TextField
          label={i18n.translate('Email')}
          autoComplete='email'
          name='email'
          value={email}
          placeholder={i18n.translate('EmailPlaceholder')}
          error={!emailValid ? i18n.translate('EmailError') : ''}
          onChange={setEmail}
          type='email'
        />
        <Checkbox
          checked={subscribe}
          onChange={setSubscribe}
          label={i18n.translate('EmailCheckbox')}
        />
      </BlockStack>
    </Box>
  );
};
