import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Badge,
  BlockStack,
  Box,
  Button,
  InlineStack,
  RadioButton,
  Text,
} from '@shopify/polaris';
import { ThemeTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { useI18n } from '@shopify/react-i18n';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import {
  EngineStatusesDto,
  SelectedThemesType,
} from 'features/onboarding/Onboarding';

type ThemeBoxProps = {
  type: ThemeTypeDtoEnum;
  checked?: boolean;
  selectedThemes: SelectedThemesType;
  engineStatuses: EngineStatusesDto | undefined;
  engineStatusIsFetching: boolean;
  refetchThemeEngines: (themeId: string) => void;
  setSelectedThemes: React.Dispatch<React.SetStateAction<SelectedThemesType>>;
  onChange: (type: ThemeTypeDtoEnum) => void;
};

export const ThemeBox: React.FC<ThemeBoxProps> = ({
  type,
  checked,
  selectedThemes,
  engineStatuses,
  engineStatusIsFetching,
  refetchThemeEngines,
  setSelectedThemes,
  onChange,
}) => {
  const [i18n] = useI18n();
  const { themesData, themesIsFetching } = useConfigureSettings(
    SettingsFetchTypeEnum.THEMES_LOOKUP,
    true
  );
  const [openThemeEditor, setOpenThemeEditor] = useState<boolean>(false);

  const selectedTheme = useMemo(
    () =>
      type === ThemeTypeDtoEnum.CURRENT
        ? selectedThemes.currentId
        : selectedThemes.testId,
    [selectedThemes, type]
  );

  const engineStatus = useMemo(
    () =>
      type === ThemeTypeDtoEnum.CURRENT
        ? engineStatuses?.current
        : engineStatuses?.test,
    [engineStatuses, type]
  );

  const isEnabled = useMemo(
    () => engineStatus?.promotionEngine?.enabled,
    [engineStatus?.promotionEngine?.enabled]
  );

  const isLoading = useMemo(
    () => !themesData || themesIsFetching || engineStatusIsFetching,
    [themesData, themesIsFetching, engineStatusIsFetching]
  );

  const currentTheme = useMemo(
    () => themesData?.find((theme) => theme.isLiveTheme),
    [themesData]
  );

  const themeOptions = useMemo(
    () =>
      themesData
        ?.filter((theme) =>
          type === ThemeTypeDtoEnum.TEST ? !theme.isLiveTheme : theme
        )
        ?.map((theme) => ({
          label: `${theme.name}`,
          value: `${theme.id}`,
        })) || [],
    [themesData]
  );

  const isDisabled = useMemo(
    () =>
      type === ThemeTypeDtoEnum.CURRENT &&
      engineStatuses?.test?.promotionEngine?.enabled,
    [type, engineStatuses?.test?.promotionEngine?.enabled]
  );

  const onOpenThemeEditor = useCallback(() => {
    const themeLink = themesData?.find(
      (theme) => String(theme.id) === selectedTheme
    )?.promotionEngineInstallationDeepLink;
    window.open(`${themeLink}`, '_blank');
    setOpenThemeEditor(true);
  }, [themesData, selectedTheme]);

  useEffect(() => {
    if (type === ThemeTypeDtoEnum.CURRENT && currentTheme) {
      setSelectedThemes({
        ...selectedThemes,
        currentId: String(currentTheme?.id),
      });
    }
  }, [currentTheme]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden && openThemeEditor) {
        refetchThemeEngines(
          type === ThemeTypeDtoEnum.TEST && selectedTheme ? selectedTheme : ''
        );
        setOpenThemeEditor(false);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [openThemeEditor, selectedTheme]);

  return (
    <Box
      borderColor={checked && !isEnabled ? 'border-inverse' : 'border'}
      background={checked && !isEnabled ? 'bg-surface-secondary' : 'bg-surface'}
      borderWidth='025'
      borderRadius='200'
      padding='400'
    >
      <BlockStack gap='400'>
        <RadioButton
          disabled={isDisabled}
          checked={checked}
          onChange={() => onChange(type)}
          label={
            <InlineStack gap='200'>
              <Text as='p'>{i18n.translate(`${type}_Theme`)}</Text>
              {isEnabled && checked && (
                <Badge tone='success'>{i18n.translate('Enabled')}</Badge>
              )}
              {type === ThemeTypeDtoEnum.CURRENT &&
                !isEnabled &&
                !engineStatuses?.test?.promotionEngine?.enabled && (
                  <Badge tone='info'>{i18n.translate('Recommended')}</Badge>
                )}
              {engineStatuses?.test?.promotionEngine?.enabled &&
                type === ThemeTypeDtoEnum.CURRENT && (
                  <Badge tone='attention'>{i18n.translate('NotEnabled')}</Badge>
                )}
            </InlineStack>
          }
          helpText={
            <BlockStack gap='400'>
              <Text as='p'>{i18n.translate(`${type}_ThemeHelp`)}</Text>
              {!isDisabled && checked && (
                <SelectOptions
                  isLoading={isLoading && !!selectedTheme}
                  label=''
                  options={themeOptions}
                  selectedOption={selectedTheme}
                  placeholder={i18n.translate(
                    type === ThemeTypeDtoEnum.TEST
                      ? 'SelectTheme'
                      : 'CurrentTheme'
                  )}
                  readOnly={isEnabled || type === ThemeTypeDtoEnum.CURRENT}
                  onOptionSelect={(value) =>
                    setSelectedThemes({ ...selectedThemes, testId: value })
                  }
                  connectedRight={
                    checked &&
                    !isEnabled && (
                      <Box paddingInlineStart='200'>
                        <Button
                          disabled={isLoading || !selectedTheme}
                          onClick={onOpenThemeEditor}
                          variant='primary'
                        >
                          {i18n.translate('OpenThemeEditor')}
                        </Button>
                      </Box>
                    )
                  }
                />
              )}
            </BlockStack>
          }
        />
      </BlockStack>
    </Box>
  );
};
