import React, { useCallback, useMemo } from 'react';
import { SelectedOptionPath } from '../../../../RightSideBarWrapper';
import {
  NinjaCartPresetDtoRead,
  NinjaCartStatesPresetDtoRead,
  NinjaCartSystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { Preset_NDC_Empty } from './components/Preset_NDC_Empty/Preset_NDC_Empty';
import { Preset_NDC_Default } from './components/Preset_NDC_Default/Preset_NDC_Default';
import { NinjaCartStatesPresetEntryTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';

type PresetNinjaDrawerCartProps = {
  selectedOptionPath: SelectedOptionPath;
  ninjaCart: NinjaCartPresetDtoRead;
  defaultPresetData: NinjaCartPresetDtoRead;
  shopDefaultData: NinjaCartSystemDefaultsDto;
  defaultLanguage: string;
  updateNinjaCart: (data: NinjaCartPresetDtoRead) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const PresetNinjaDrawerCart: React.FC<PresetNinjaDrawerCartProps> = ({
  selectedOptionPath,
  ninjaCart,
  defaultPresetData,
  shopDefaultData,
  defaultLanguage,
  updateNinjaCart,
  handleLeftMenuSelection,
}) => {
  const handleUpdateState = useCallback(
    (
      field: keyof NinjaCartStatesPresetDtoRead,
      data: NinjaCartStatesPresetDtoRead[keyof NinjaCartStatesPresetDtoRead]
    ) => {
      updateNinjaCart({
        ...ninjaCart,
        options: {
          ...ninjaCart.options,
          [field]: data,
        },
      });
    },
    [ninjaCart, updateNinjaCart]
  );

  const ninjaCartOptions = useMemo(() => {
    switch (true) {
      case ninjaCart.currentEntries?.includes(
        NinjaCartStatesPresetEntryTypeDtoEnum.EMPTY_STATE
      ):
        return (
          <Preset_NDC_Empty
            emptyState={ninjaCart.options?.emptyState || {}}
            selectedOptionPath={selectedOptionPath}
            shopDefaultData={shopDefaultData.emptyState || {}}
            defaultEmptyState={defaultPresetData.options?.emptyState || {}}
            defaultLanguage={defaultLanguage}
            setEmptyState={(data) => handleUpdateState('emptyState', data)}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case ninjaCart.currentEntries?.includes(
        NinjaCartStatesPresetEntryTypeDtoEnum.DEFAULT_STATE
      ):
        return (
          <Preset_NDC_Default
            defaultState={ninjaCart.options?.defaultState || {}}
            selectedOptionPath={selectedOptionPath}
            shopDefaultData={shopDefaultData.defaultState || {}}
            defaultDefaultState={defaultPresetData.options?.defaultState || {}}
            defaultLanguage={defaultLanguage}
            setDefaultState={(data) => handleUpdateState('defaultState', data)}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
    }
  }, [
    selectedOptionPath,
    shopDefaultData,
    ninjaCart,
    defaultPresetData,
    updateNinjaCart,
    handleLeftMenuSelection,
  ]);
  return <div>{ninjaCartOptions}</div>;
};

export default PresetNinjaDrawerCart;
