import React, { useCallback, useMemo, useState } from 'react';
import {
  Banner,
  BlockStack,
  Box,
  Button,
  InlineStack,
  MediaCard,
  Text,
  VideoThumbnail,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { RefreshIcon } from '@shopify/polaris-icons';
import { ThemeBox } from './components/ThemeBox/ThemeBox';
import { ThemeTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import useFetchEngineAndPlanCheck from 'features/promotions/components/PromotionOverview/utils/useFetchEngineAndPlanCheck/useFetchEngineAndPlanCheck';
import { TestThemeAppModal } from './components/TestThemeAppModal/TestThemeAppModal';
import {
  EngineStatusesDto,
  SelectedThemesType,
} from 'features/onboarding/Onboarding';

type EnableDNStepProps = {
  engineStatuses: EngineStatusesDto | undefined;
  themeType: ThemeTypeDtoEnum;
  selectedThemes: SelectedThemesType;
  setEngineStatuses: React.Dispatch<
    React.SetStateAction<EngineStatusesDto | undefined>
  >;
  setThemeType: React.Dispatch<React.SetStateAction<ThemeTypeDtoEnum>>;
  setSelectedThemes: React.Dispatch<React.SetStateAction<SelectedThemesType>>;
};
export const EnableDNStep: React.FC<EnableDNStepProps> = ({
  engineStatuses,
  selectedThemes,
  themeType,
  setEngineStatuses,
  setThemeType,
  setSelectedThemes,
}) => {
  const [i18n] = useI18n();
  const { engineAndPlanCheckIsFetching, fetchEngineAndPlanCheck } =
    useFetchEngineAndPlanCheck();

  // Modal to confirm using Test theme. null = skip modal.
  const [testThemeModal, setTestThemeModal] = useState<boolean | null>(false);

  const successBannerMessage = useMemo(() => {
    if (engineStatuses?.current?.promotionEngine?.enabled)
      return i18n.translate('CurrentThemeSuccess');
    if (engineStatuses?.test?.promotionEngine?.enabled)
      return i18n.translate('TestThemeSuccess');
    return null;
  }, [engineStatuses]);

  const refetchThemeEngines = useCallback(
    (themeId?: string) => {
      fetchEngineAndPlanCheck({
        ...(themeId ? { testThemeId: +themeId } : {}),
        themeType: themeType,
      }).then((data) =>
        setEngineStatuses(() => ({
          ...(themeId ? { test: data } : { current: data }),
        }))
      );
    },
    [setEngineStatuses, themeType]
  );

  const handleThemeType = useCallback(
    (type: ThemeTypeDtoEnum) => {
      if (type === ThemeTypeDtoEnum.TEST && testThemeModal !== null) {
        setTestThemeModal(true);
        return;
      }
      setThemeType(type);
    },
    [testThemeModal]
  );

  const handleCloseThemeModal = useCallback((value: boolean | null) => {
    value === null && setThemeType(ThemeTypeDtoEnum.TEST);
    setTestThemeModal(value);
  }, []);

  return (
    <Box paddingBlock='400'>
      <BlockStack gap='400'>
        <InlineStack align='space-between' blockAlign='start'>
          <BlockStack gap='200'>
            <Text as='h1' variant='headingXl' fontWeight='semibold'>
              {i18n.translate('Title')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('Subtitle')}
            </Text>
          </BlockStack>
        </InlineStack>
        <MediaCard
          title={i18n.translate('VideoTitle')}
          primaryAction={{
            content: i18n.translate('LearnMore'),
          }}
          description={i18n.translate('VideoSubitle')}
          size='small'
        >
          <VideoThumbnail
            videoLength={80}
            thumbnailUrl='https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850'
            onClick={() =>
              window.open(
                'https://www.youtube.com/watch?v=RVmG_d3HKBA',
                '_blank'
              )
            }
          />
        </MediaCard>
        <BlockStack gap='300'>
          <InlineStack align='space-between'>
            <Text as='p'>{i18n.translate('SelectTheme')}</Text>
            <Button
              onClick={() =>
                refetchThemeEngines(
                  themeType === ThemeTypeDtoEnum.TEST
                    ? selectedThemes.testId
                    : ''
                )
              }
              disabled={
                engineAndPlanCheckIsFetching ||
                (themeType === ThemeTypeDtoEnum.TEST && !selectedThemes.testId)
              }
              variant='plain'
              icon={RefreshIcon}
            >
              {i18n.translate('Refresh')}
            </Button>
          </InlineStack>
        </BlockStack>
        {Object.values(ThemeTypeDtoEnum).map((type) => {
          const hideTheme =
            type === ThemeTypeDtoEnum.TEST &&
            engineStatuses?.current?.promotionEngine?.enabled;
          return (
            !hideTheme && (
              <ThemeBox
                key={type}
                type={type}
                checked={themeType === type}
                engineStatuses={engineStatuses}
                engineStatusIsFetching={engineAndPlanCheckIsFetching}
                selectedThemes={selectedThemes}
                setSelectedThemes={setSelectedThemes}
                refetchThemeEngines={refetchThemeEngines}
                onChange={handleThemeType}
              />
            )
          );
        })}
        {successBannerMessage && (
          <Banner tone='success'>{successBannerMessage}</Banner>
        )}
      </BlockStack>
      <TestThemeAppModal
        isOpen={!!testThemeModal}
        onClose={handleCloseThemeModal}
      />
    </Box>
  );
};
