import React, { useMemo } from 'react';
import {
  GetSystemDefaultsResponseDto,
  PresetDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import {
  LeftMenuWidget,
  isLeftMenuOptionSelected,
} from 'features/settings/components/EditPresetSkeleton/utils/leftMenuGenerators';
import { SelectedOptionPath } from '../../RightSideBarWrapper';
import PresetProductBanner from './components/PresetProductBanner/PresetProductBanner';
import PresetPromotionalBadge from './components/PresetPromotionalBadge/PresetPromotionalBadge';
import PresetNotification from './components/PresetNotification/PresetNotification';
import PresetAnnouncementBar from './components/PresetAnnouncementBar/PresetAnnouncementBar';
import PresetPromotionCode from './components/PresetPromotionCode/PresetPromotionCode';
import PresetPromotionSummary from './components/PresetPromotionSummary/PresetPromotionSummary';
import PresetOfferRulePopup from './components/PresetOfferRulePopup/PresetOfferRulePopup';
import PresetPrice from './components/PresetPrice/PresetPrice';
import { Box, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { WidgetListTypeDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import PresetCartText from './components/PresetCartText/PresetCartText';
import { WidgetOptionHeader } from '../components';
import PresetNinjaDrawerCart from './components/PresetNinjaDrawerCart/PresetNinjaDrawerCart';

type EditPresetRightBarProps = {
  currentWidget?: LeftMenuWidget;
  selectedOptionPath: SelectedOptionPath;
  editPresetState: PresetDto;
  defaultPresetData: PresetDto;
  shopDefaultData: GetSystemDefaultsResponseDto;
  smallScreen?: boolean;
  updateEditPresetState: (
    field: keyof PresetDto,
    data: PresetDto[keyof PresetDto]
  ) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

export enum PriceWidgetTypesDtoEnum {
  PRODUCT_PAGE_PRICE = 'productPagePrice',
  COLLECTION_PAGE_PRICE = 'collectionPagePrice',
  DRAWER_CART_PRODUCT_PRICE = 'drawerCartProductPrice',
  DRAWER_CART_LINE_PRICE = 'drawerCartLinePrice',
  DRAWER_CART_TOTAL_PRICE = 'drawerCartTotalPrice',
  DRAWER_CART_BREAKDOWN_PRICE = 'drawerCartBreakdownPrice',
  CART_PRODUCT_PRICE = 'cartProductPrice',
  CART_LINE_PRICE = 'cartLinePrice',
  CART_TOTAL_PRICE = 'cartTotalPrice',
  CART_BREAKDOWN_PRICE = 'cartBreakdownPrice',
}

const EditPresetRightBar: React.FC<EditPresetRightBarProps> = ({
  currentWidget,
  editPresetState,
  defaultPresetData,
  shopDefaultData,
  selectedOptionPath,
  smallScreen,
  updateEditPresetState,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();
  const rightSideOptions = useMemo(() => {
    const widgetName =
      isLeftMenuOptionSelected([currentWidget as LeftMenuWidget]) &&
      currentWidget?.name;
    const renderPriceWidget = (type: PriceWidgetTypesDtoEnum) => {
      return (
        <PresetPrice
          selectedOptionPath={selectedOptionPath}
          price={editPresetState?.[type] || {}}
          priceType={type}
          shopDefaultData={shopDefaultData?.[type] || {}}
          defaultPresetData={defaultPresetData?.[type] || {}}
          updatePrice={(data) => updateEditPresetState(type, data)}
          handleLeftMenuSelection={handleLeftMenuSelection}
          defaultLanguage={editPresetState.defaultLanguage || ''}
        />
      );
    };

    switch (true) {
      case widgetName === WidgetListTypeDtoEnum.PRODUCT_BANNER:
        return (
          <PresetProductBanner
            selectedOptionPath={selectedOptionPath}
            productBanner={editPresetState.productBanner || {}}
            shopDefaultData={shopDefaultData.productBanner || {}}
            defaultPresetData={defaultPresetData.productBanner || {}}
            updateProductBanner={(data) =>
              updateEditPresetState('productBanner', data)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case widgetName === WidgetListTypeDtoEnum.PROMOTIONAL_BADGE:
        return (
          <PresetPromotionalBadge
            selectedOptionPath={selectedOptionPath}
            promotionalBadge={editPresetState.promotionalBadge || {}}
            shopDefaultData={shopDefaultData.promotionalBadge || {}}
            defaultPresetData={defaultPresetData.promotionalBadge || {}}
            updatePromotionalBadge={(data) =>
              updateEditPresetState('promotionalBadge', data)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case widgetName === WidgetListTypeDtoEnum.NOTIFICATION:
        return (
          <PresetNotification
            selectedOptionPath={selectedOptionPath}
            notification={editPresetState.notification || {}}
            shopDefaultData={shopDefaultData.notification || {}}
            defaultPresetData={defaultPresetData.notification || {}}
            defaultLanguage={editPresetState.defaultLanguage || ''}
            updateNotification={(data) =>
              updateEditPresetState('notification', data)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case widgetName === WidgetListTypeDtoEnum.ANNOUNCEMENT_BAR:
        return (
          <PresetAnnouncementBar
            selectedOptionPath={selectedOptionPath}
            announcementBar={editPresetState.announcementBar || {}}
            shopDefaultData={shopDefaultData.announcementBar || {}}
            defaultPresetData={defaultPresetData.announcementBar || {}}
            updateAnnouncementBar={(data) =>
              updateEditPresetState('announcementBar', data)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case widgetName === WidgetListTypeDtoEnum.CART_PROMOTION_CODE_FIELD:
        return (
          <PresetPromotionCode
            selectedOptionPath={selectedOptionPath}
            promotionCodeField={editPresetState.cartPromotionCodeField || {}}
            shopDefaultData={shopDefaultData.cartPromotionCodeField || {}}
            defaultPresetData={defaultPresetData.cartPromotionCodeField || {}}
            defaultLanguage={editPresetState.defaultLanguage || ''}
            updatePromotionCodeField={(data) =>
              updateEditPresetState('cartPromotionCodeField', data)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case widgetName ===
        WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_CODE_FIELD:
        return (
          <PresetPromotionCode
            selectedOptionPath={selectedOptionPath}
            promotionCodeField={
              editPresetState.drawerCartPromotionCodeField || {}
            }
            shopDefaultData={shopDefaultData.drawerCartPromotionCodeField || {}}
            defaultPresetData={
              defaultPresetData.drawerCartPromotionCodeField || {}
            }
            defaultLanguage={editPresetState.defaultLanguage || ''}
            updatePromotionCodeField={(data) =>
              updateEditPresetState('drawerCartPromotionCodeField', data)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case widgetName === WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_SUMMARY:
        return (
          <PresetPromotionSummary
            selectedOptionPath={selectedOptionPath}
            promotionSummary={editPresetState.drawerCartPromotionSummary || {}}
            shopDefaultData={shopDefaultData.drawerCartPromotionSummary || {}}
            pageType={WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_SUMMARY}
            defaultPresetData={
              defaultPresetData.drawerCartPromotionSummary || {}
            }
            defaultLanguage={editPresetState.defaultLanguage || ''}
            updatePromotionSummary={(data) =>
              updateEditPresetState('drawerCartPromotionSummary', data)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case widgetName === WidgetListTypeDtoEnum.CART_PROMOTION_SUMMARY:
        return (
          <PresetPromotionSummary
            selectedOptionPath={selectedOptionPath}
            promotionSummary={editPresetState.cartPromotionSummary || {}}
            shopDefaultData={shopDefaultData.cartPromotionSummary || {}}
            pageType={WidgetListTypeDtoEnum.CART_PROMOTION_SUMMARY}
            defaultPresetData={defaultPresetData.cartPromotionSummary || {}}
            defaultLanguage={editPresetState.defaultLanguage || ''}
            updatePromotionSummary={(data) =>
              updateEditPresetState('cartPromotionSummary', data)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case widgetName === WidgetListTypeDtoEnum.OFFER_RULE_POPUP:
        return (
          <PresetOfferRulePopup
            selectedOptionPath={selectedOptionPath}
            offerRulePopup={editPresetState.offerRulePopup || {}}
            shopDefaultData={shopDefaultData.offerRulePopup || {}}
            defaultLanguage={editPresetState.defaultLanguage || ''}
            defaultPresetData={defaultPresetData.offerRulePopup || {}}
            updateOfferRulePopup={(data) =>
              updateEditPresetState('offerRulePopup', data)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case widgetName === WidgetListTypeDtoEnum.CART_PAGE_CART_TEXT:
        return (
          <PresetCartText
            selectedOptionPath={selectedOptionPath}
            cartText={editPresetState.cartPageCartText || {}}
            shopDefaultData={shopDefaultData.cartPageCartText || {}}
            defaultPresetData={defaultPresetData.cartPageCartText || {}}
            updateCartText={(data) =>
              updateEditPresetState('cartPageCartText', data)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case widgetName === WidgetListTypeDtoEnum.DRAWER_CART_PAGE_CART_TEXT:
        return (
          <PresetCartText
            selectedOptionPath={selectedOptionPath}
            cartText={editPresetState.drawerCartPageCartText || {}}
            shopDefaultData={shopDefaultData.drawerCartPageCartText || {}}
            defaultPresetData={defaultPresetData.drawerCartPageCartText || {}}
            updateCartText={(data) =>
              updateEditPresetState('drawerCartPageCartText', data)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case widgetName === WidgetListTypeDtoEnum.NINJA_CART:
        return (
          <PresetNinjaDrawerCart
            selectedOptionPath={selectedOptionPath}
            ninjaCart={editPresetState.ninjaCart || {}}
            shopDefaultData={shopDefaultData.ninjaCart || {}}
            defaultPresetData={defaultPresetData.ninjaCart || {}}
            updateNinjaCart={(data) => updateEditPresetState('ninjaCart', data)}
            defaultLanguage={editPresetState.defaultLanguage || ''}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );

      case widgetName &&
        widgetName ===
          Object.values(PriceWidgetTypesDtoEnum).find(
            (widget) => widget === currentWidget?.name
          ):
        return renderPriceWidget(
          currentWidget?.name as PriceWidgetTypesDtoEnum
        );

      default:
        return (
          <>
            {smallScreen && (
              <WidgetOptionHeader
                name={i18n.translate('Back')}
                handleLeftMenuSelection={handleLeftMenuSelection}
              />
            )}
            <Box
              borderColor='border-brand'
              borderBlockEndWidth='025'
              padding='500'
            >
              <Text tone='subdued' as='p'>
                {i18n.translate(
                  editPresetState.isShopLevel
                    ? 'ShopLevelEmptyState'
                    : 'PromotionLevelEmptyState'
                )}
              </Text>
            </Box>
          </>
        );
    }
  }, [
    currentWidget,
    selectedOptionPath,
    shopDefaultData,
    editPresetState,
    defaultPresetData,
    updateEditPresetState,
  ]);

  return <>{rightSideOptions}</>;
};

export default EditPresetRightBar;
