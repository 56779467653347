import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './ManageThemesModal.scss';
import { ModalCustom } from 'core/components/ModalCustom/ModalCustom';
import { useI18n } from '@shopify/react-i18n';
import { BlockStack, Divider, InlineStack, Text } from '@shopify/polaris';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { ComboboxOption, Loader } from 'core/components';
import { ThemeSlotDto } from 'core/api/adminSettings/adminSettingsApi';
import { ThemeTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
type ManageThemesModalProps = {
  isOpen: boolean;
  themeSlotsData: ThemeSlotDto[];
  themeSlotsDataIsFetching: boolean;
  isSaving: boolean;
  onClose: () => void;
  onSave: (slots: ThemeSlotDto[]) => void;
};
const ManageThemesModal: React.FC<ManageThemesModalProps> = ({
  isOpen,
  isSaving,
  themeSlotsData,
  themeSlotsDataIsFetching,
  onSave,
  onClose,
}) => {
  const [i18n] = useI18n();
  const { themesData, themesIsFetching } = useConfigureSettings(
    SettingsFetchTypeEnum.THEMES_LOOKUP
  );

  const [slots, setSlots] = useState<ThemeSlotDto[]>([]);

  const themesOptions = useMemo(
    () =>
      themesData?.map((theme) => ({
        value: theme.id?.toString(),
        label: theme.name || '',
      })) as ComboboxOption[],
    [themesData]
  );
  const isThemesLoading =
    themesIsFetching || themeSlotsDataIsFetching || !slots.length;

  const getThemeSlotId = useCallback(
    (type: ThemeTypeDtoEnum) => {
      return (
        slots?.find((slot) => slot.type === type)?.themeId?.toString() || ''
      );
    },
    [slots]
  );
  const updateThemeSlot = useCallback(
    (type: ThemeTypeDtoEnum, id: string) => {
      setSlots(
        slots?.map((slot) => {
          if (slot.type === type) {
            const slotName = themesData?.find((item) => item.id === +id)?.name;
            return { ...slot, themeId: +id, themeName: slotName };
          }
          return slot;
        })
      );
    },
    [slots, setSlots, themesData]
  );

  useEffect(() => {
    if (themeSlotsData && !slots?.length) {
      setSlots(themeSlotsData);
    }
  }, [themeSlotsData]);

  return (
    <ModalCustom
      title={i18n.translate(`ManageThemes`)}
      isOpen={isOpen}
      buttons={[
        {
          content: i18n.translate(`Cancel`),
          primary: false,
          action: onClose,
        },
        {
          content: i18n.translate(`Save`),
          primary: true,
          disabled: isThemesLoading || isSaving,
          action: () => onSave(slots),
        },
      ]}
      onClose={onClose}
    >
      <div className='ManageThemesModalContent'>
        {isThemesLoading ? (
          <Loader fullWidth />
        ) : (
          <BlockStack gap='400'>
            <InlineStack gap={'300'} wrap={false}>
              <BlockStack gap='100'>
                <Text fontWeight='medium' as='p'>
                  {i18n.translate('CurrentTheme')}
                </Text>
                <Text tone='subdued' as='p'>
                  {i18n.translate('ThisIsCurrentTheme')}
                </Text>
              </BlockStack>
              <div className='SelectThemeInput'>
                <SelectOptions
                  options={themesOptions}
                  onOptionSelect={(value) =>
                    updateThemeSlot(ThemeTypeDtoEnum.CURRENT, value)
                  }
                  selectedOption={getThemeSlotId(ThemeTypeDtoEnum.CURRENT)}
                  label=''
                />
              </div>
            </InlineStack>
            <Divider />
            <InlineStack gap={'300'} wrap={false}>
              <BlockStack gap='100'>
                <Text fontWeight='medium' as='p'>
                  {i18n.translate('TestTheme')}
                </Text>
                <Text tone='subdued' as='p'>
                  {i18n.translate('AllowYouSmth')}
                </Text>
              </BlockStack>
              <div className='SelectThemeInput'>
                <SelectOptions
                  options={themesOptions}
                  onOptionSelect={(value) =>
                    updateThemeSlot(ThemeTypeDtoEnum.TEST, value)
                  }
                  selectedOption={getThemeSlotId(ThemeTypeDtoEnum.TEST)}
                  label=''
                />
              </div>
            </InlineStack>
            <Divider />
          </BlockStack>
        )}
      </div>
    </ModalCustom>
  );
};

export default ManageThemesModal;
