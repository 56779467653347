import React, { useCallback, useMemo } from 'react';
import {
  NinjaCartDefaultStateFooterLevelPresetDtoRead,
  NinjaCartDefaultStateFooterPresetDto,
  NinjaCartDefaultStateFooterPresetDtoRead,
  PromotionSummarySystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import {
  WidgetListOptionsDtoEnum,
  WidgetListTypeDtoEnum,
} from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { SelectedOptionPath } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightSideBarWrapper';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import Preset_NDC_FooterGeneral from './components/Preset_NDC_FooterGeneral/Preset_NDC_FooterGeneral';
import PresetPromotionSummary from '../../../../../PresetPromotionSummary/PresetPromotionSummary';
import Preset_NDC_CTA from './components/Preset_NDC_CTA/Preset_NDC_CTA';
import Preset_NDC_PaymentMethods from './components/Preset_NDC_PaymentMethods/Preset_NDC_PaymentMethods';
import Preset_NDC_TermsAndConditions from './components/Preset_NDC_TermsAndConditions/Preset_NDC_TermsAndConditions';
import Preset_NDC_ContinueShoppingBtn from './components/Preset_NDC_ContinueShoppingBtn/Preset_NDC_ContinueShoppingBtn';

type Preset_NDC_FooterProps = {
  footer: NinjaCartDefaultStateFooterPresetDtoRead;
  defaultFooter: NinjaCartDefaultStateFooterPresetDtoRead;
  shopDefaultFooter: NinjaCartDefaultStateFooterPresetDto;
  selectedOptionPath: SelectedOptionPath;
  defaultLanguage: string;
  setFooter: (data: NinjaCartDefaultStateFooterPresetDtoRead) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const Preset_NDC_Footer: React.FC<Preset_NDC_FooterProps> = ({
  footer,
  defaultFooter,
  shopDefaultFooter,
  selectedOptionPath,
  defaultLanguage,
  setFooter,
  handleLeftMenuSelection,
}) => {
  const handleUpdateOptions = useCallback(
    (
      field: keyof NinjaCartDefaultStateFooterLevelPresetDtoRead,
      data: NinjaCartDefaultStateFooterLevelPresetDtoRead[keyof NinjaCartDefaultStateFooterLevelPresetDtoRead]
    ) => {
      setFooter({
        ...footer,
        options: {
          ...footer.options,
          [field]: data,
        },
      });
    },
    [footer, setFooter]
  );

  const promotionSummaryShopDefault: PromotionSummarySystemDefaultsDto =
    useMemo(
      () =>
        ({
          ...shopDefaultFooter.options?.promotionSummary?.options,
          general: shopDefaultFooter.options?.promotionSummary?.general,
        } as PromotionSummarySystemDefaultsDto),
      [shopDefaultFooter.options?.promotionSummary]
    );

  const footerOptions = useMemo(() => {
    const selectedOption = getLastSelectedOption(selectedOptionPath);
    const selectedDefaultOption =
      selectedOptionPath?.selectedOption?.selectedOption?.selectedOption;
    switch (true) {
      case selectedOption?.name === WidgetListOptionsDtoEnum.FOOTER:
        return (
          <Preset_NDC_FooterGeneral
            general={footer.general || {}}
            defaultGeneral={defaultFooter.general || {}}
            shopDefaultGeneral={shopDefaultFooter.general || {}}
            setGeneral={(data) => setFooter({ ...footer, general: data })}
            handleLeftMenuSelection={handleLeftMenuSelection}
            onShowHide={
              footer.canBeHidden
                ? () => setFooter({ ...footer, isHidden: !footer.isHidden })
                : undefined
            }
          />
        );
      case selectedDefaultOption?.name ===
        WidgetListTypeDtoEnum.NINJA_CART_PROMOTION_SUMMARY:
        return (
          <PresetPromotionSummary
            promotionSummary={footer.options?.promotionSummary || {}}
            selectedOptionPath={selectedOptionPath}
            defaultPresetData={defaultFooter.options?.promotionSummary || {}}
            shopDefaultData={promotionSummaryShopDefault}
            defaultLanguage={defaultLanguage}
            pageType={WidgetListTypeDtoEnum.NINJA_CART_PROMOTION_SUMMARY}
            updatePromotionSummary={(data) =>
              handleUpdateOptions('promotionSummary', data)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedDefaultOption?.name === WidgetListOptionsDtoEnum.CTA_BUTTONS:
        return (
          <Preset_NDC_CTA
            ctaButtons={footer.options?.ctaButtons || {}}
            selectedOptionPath={selectedOptionPath}
            defaultLanguage={defaultLanguage}
            defaultCTAButtons={defaultFooter.options?.ctaButtons || {}}
            shopDefaultCTAButtons={shopDefaultFooter.options?.ctaButtons || {}}
            setCTAButtons={(data) => handleUpdateOptions('ctaButtons', data)}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedDefaultOption?.name ===
        WidgetListOptionsDtoEnum.PAYMENT_OPTIONS:
        return (
          <Preset_NDC_PaymentMethods
            paymentOptions={footer.options?.paymentOptions || {}}
            defaultPaymentOptions={defaultFooter.options?.paymentOptions || {}}
            shopDefaultPaymentOptions={
              shopDefaultFooter.options?.paymentOptions || {}
            }
            setPaymentOptions={(data) =>
              handleUpdateOptions('paymentOptions', data)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedDefaultOption?.name ===
        WidgetListOptionsDtoEnum.TERMS_AND_CONDITIONS:
        return (
          <Preset_NDC_TermsAndConditions
            termsAndConditions={footer.options?.termsAndConditions || {}}
            defaultTermsAndConditions={
              defaultFooter.options?.termsAndConditions || {}
            }
            shopDefaultTermsAndConditions={
              shopDefaultFooter.options?.termsAndConditions || {}
            }
            defaultLanguage={defaultLanguage}
            setTermsAndConditions={(data) =>
              handleUpdateOptions('termsAndConditions', data)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedDefaultOption?.name ===
        WidgetListOptionsDtoEnum.CONTINUE_SHOPPING_BUTTON:
        return (
          <Preset_NDC_ContinueShoppingBtn
            continueShoppingBtn={footer.options?.continueShoppingButton || {}}
            defaultContinueShoppingBtn={
              defaultFooter.options?.continueShoppingButton || {}
            }
            shopDefaultContinueShoppingBtn={
              shopDefaultFooter.options?.continueShoppingButton || {}
            }
            defaultLanguage={defaultLanguage}
            setContinueShoppingBtn={(data) =>
              handleUpdateOptions('continueShoppingButton', data)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
    }
  }, [
    selectedOptionPath,
    defaultFooter,
    defaultLanguage,
    shopDefaultFooter,
    footer,
    handleLeftMenuSelection,
  ]);

  return <>{footerOptions}</>;
};

export default Preset_NDC_Footer;
