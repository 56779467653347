import React, { useEffect, useState } from 'react';
import { BlockStack, Box, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { v4 as uuidv4 } from 'uuid';
import { ConfigureBrandColors } from 'features/settings/components/GeneralSettings/Style/components/BrandColors/components/ConfigureBrandColors/ConfigureBrandColors';

type BrandColor = {
  id: string;
  color: string;
};

type BrandColorsStepProps = {
  brandColors?: string[];
  setBrandColors: (data: string[]) => void;
};

export const BrandColorsStep: React.FC<BrandColorsStepProps> = ({
  brandColors,
  setBrandColors,
}) => {
  const [i18n] = useI18n();
  const [colors, setColors] = useState<BrandColor[]>([]);

  useEffect(() => {
    if (brandColors?.length && !colors.length) {
      setColors(brandColors.map((color) => ({ id: uuidv4(), color: color })));
    }
  }, [brandColors]);

  useEffect(() => {
    setBrandColors(colors.map((color) => color.color));
  }, [colors]);

  return (
    <Box paddingBlock='400'>
      <BlockStack gap='400'>
        <BlockStack gap='200'>
          <Text as='h1' variant='headingLg' fontWeight='semibold'>
            {i18n.translate('BrandColorTitle')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('BrandColorSubtitle')}
          </Text>
        </BlockStack>
        <Box
          padding='400'
          borderColor='border'
          borderWidth='025'
          borderRadius='200'
        >
          <BlockStack gap='500'>
            <ConfigureBrandColors
              brandColors={colors || []}
              setBrandColors={setColors}
            />
          </BlockStack>
        </Box>
      </BlockStack>
    </Box>
  );
};
