import React, { useCallback, useMemo } from 'react';
import {
  Background,
  CornerRadius,
  PaymentOptions,
  SingleInputSelection,
  WidgetOptionHeader,
} from '../../../../../../../../../components';
import { NinjaCartDefaultStateFooterPaymentOptionsPresetDtoRead } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { SizeTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { useI18n } from '@shopify/react-i18n';

type Preset_NDC_PaymentMethodsProps = {
  paymentOptions: NinjaCartDefaultStateFooterPaymentOptionsPresetDtoRead;
  defaultPaymentOptions: NinjaCartDefaultStateFooterPaymentOptionsPresetDtoRead;
  shopDefaultPaymentOptions: NinjaCartDefaultStateFooterPaymentOptionsPresetDtoRead;
  setPaymentOptions: (
    data: NinjaCartDefaultStateFooterPaymentOptionsPresetDtoRead
  ) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const Preset_NDC_PaymentMethods: React.FC<Preset_NDC_PaymentMethodsProps> = ({
  paymentOptions,
  defaultPaymentOptions,
  shopDefaultPaymentOptions,
  setPaymentOptions,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();

  const sizeOptions = useMemo(
    () =>
      [SizeTypeDtoEnum.SMALL, SizeTypeDtoEnum.LARGE].map((value) => ({
        label: i18n.translate(value),
        value,
      })),
    [i18n]
  );

  const handleUpdateOptions = useCallback(
    (
      field: keyof NinjaCartDefaultStateFooterPaymentOptionsPresetDtoRead,
      data: NinjaCartDefaultStateFooterPaymentOptionsPresetDtoRead[keyof NinjaCartDefaultStateFooterPaymentOptionsPresetDtoRead]
    ) => setPaymentOptions({ ...paymentOptions, [field]: data }),
    [paymentOptions]
  );

  return (
    <>
      <WidgetOptionHeader
        onDiscardChanges={() => setPaymentOptions(defaultPaymentOptions)}
        onResetToSystem={() => setPaymentOptions(shopDefaultPaymentOptions)}
        name={WidgetListOptionsDtoEnum.PAYMENT_OPTIONS}
        onShowHide={
          paymentOptions.canBeHidden
            ? () => handleUpdateOptions('isHidden', !paymentOptions.isHidden)
            : undefined
        }
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <PaymentOptions
          paymentOptions={paymentOptions?.paymentOptionsTypes || []}
          setPaymentOptions={(data) =>
            handleUpdateOptions('paymentOptionsTypes', data)
          }
          onSystemReset={() =>
            handleUpdateOptions(
              'paymentOptionsTypes',
              shopDefaultPaymentOptions?.paymentOptionsTypes
            )
          }
        />
      </div>
      <div className='RightSideSection'>
        <SingleInputSelection
          options={sizeOptions}
          label={i18n.translate('Size')}
          selectedOption={paymentOptions?.size || ''}
          setOption={(data) =>
            handleUpdateOptions('size', data as SizeTypeDtoEnum)
          }
          onSystemReset={() =>
            handleUpdateOptions('size', shopDefaultPaymentOptions?.size)
          }
        />
      </div>
      <div className='RightSideSection'>
        <CornerRadius
          cornerRadius={paymentOptions?.cornerRadius || {}}
          setCornerRadius={(data) => handleUpdateOptions('cornerRadius', data)}
          onSystemReset={() =>
            handleUpdateOptions(
              'cornerRadius',
              shopDefaultPaymentOptions?.cornerRadius
            )
          }
        />
      </div>
      <div className='RightSideSection'>
        <Background
          background={paymentOptions?.background || {}}
          setBackground={(data) => handleUpdateOptions('background', data)}
          onSystemReset={() =>
            handleUpdateOptions(
              'background',
              shopDefaultPaymentOptions?.background
            )
          }
        />
      </div>
    </>
  );
};

export default Preset_NDC_PaymentMethods;
