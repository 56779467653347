import React, { useCallback, useMemo } from 'react';
import {
  Background,
  CornerRadius,
  SingleInputSelection,
  TextStyle,
  WidgetOptionHeader,
} from '../../../../../../../../../components';
import { NinjaCartDefaultStateHeaderItemCountPresetDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';
import { NinjaCartHeaderItemCountStyleDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';

type Preset_NDC_ItemCountProps = {
  itemCount: NinjaCartDefaultStateHeaderItemCountPresetDto;
  defaultItemCount: NinjaCartDefaultStateHeaderItemCountPresetDto;
  shopDefaultItemCount: NinjaCartDefaultStateHeaderItemCountPresetDto;
  setItemCount: (data: NinjaCartDefaultStateHeaderItemCountPresetDto) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const Preset_NDC_ItemCount: React.FC<Preset_NDC_ItemCountProps> = ({
  itemCount,
  defaultItemCount,
  shopDefaultItemCount,
  setItemCount,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();

  const styleOptions = useMemo(
    () =>
      Object.values(NinjaCartHeaderItemCountStyleDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    [i18n]
  );

  const handleUpdateItem = useCallback(
    (
      field: keyof NinjaCartDefaultStateHeaderItemCountPresetDto,
      data: NinjaCartDefaultStateHeaderItemCountPresetDto[keyof NinjaCartDefaultStateHeaderItemCountPresetDto]
    ) => setItemCount({ ...itemCount, [field]: data }),
    [itemCount, setItemCount]
  );

  return (
    <>
      <WidgetOptionHeader
        onDiscardChanges={() => setItemCount(defaultItemCount)}
        onResetToSystem={() => setItemCount(shopDefaultItemCount)}
        name={WidgetListOptionsDtoEnum.ITEM_COUNT}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <SingleInputSelection
          selectedOption={itemCount.style as string}
          options={styleOptions}
          label={i18n.translate('Style')}
          setOption={(value) =>
            handleUpdateItem(
              'style',
              value as NinjaCartHeaderItemCountStyleDtoEnum
            )
          }
          onSystemReset={() =>
            handleUpdateItem('style', shopDefaultItemCount.style)
          }
        />
      </div>
      <div className='RightSideSection'>
        <TextStyle
          textStyle={itemCount.textStyle || {}}
          onSystemReset={() =>
            handleUpdateItem('textStyle', shopDefaultItemCount.textStyle)
          }
          setTextStyle={(data) => handleUpdateItem('textStyle', data)}
        />
      </div>
      {itemCount.style === NinjaCartHeaderItemCountStyleDtoEnum.STYLE_1 && (
        <>
          <div className='RightSideSection'>
            <CornerRadius
              cornerRadius={itemCount.cornerRadius || {}}
              setCornerRadius={(data) => handleUpdateItem('cornerRadius', data)}
              onSystemReset={() =>
                handleUpdateItem(
                  'cornerRadius',
                  shopDefaultItemCount.cornerRadius
                )
              }
            />
          </div>
          <div className='RightSideSection'>
            <Background
              background={itemCount.background || {}}
              setBackground={(data) => handleUpdateItem('background', data)}
              onSystemReset={() =>
                handleUpdateItem('background', shopDefaultItemCount.background)
              }
            />
          </div>
        </>
      )}
    </>
  );
};

export default Preset_NDC_ItemCount;
