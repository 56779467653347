import React from 'react';
import { Banner, BlockStack, Card, Link, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { PromotionTypeDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { PromotionTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';

export type ShopifyCombinationsProps = {
  promotionType?: PromotionTypeDto;
};

export const ShopifyCombinations: React.FC<ShopifyCombinationsProps> = (
  props
) => {
  const { promotionType } = props;
  const [i18n] = useI18n();
  return promotionType &&
    [
      PromotionTypeDtoEnum.MARKDOWN,
      PromotionTypeDtoEnum.SPECIAL_OFFER,
    ].includes(promotionType as PromotionTypeDtoEnum) ? (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('Title')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('Subtitle', {
              type: i18n.translate(promotionType),
            })}
          </Text>
        </BlockStack>
        <Banner
          action={{
            content: i18n.translate('Btn'),
            onAction: () => window.open('shopify://admin/discounts', '_blank'),
          }}
          tone='info'
        >
          <Text as='p'>
            {i18n.translate('BannerInfo', {
              learnMore: (
                <Link url='http://www.google.com' target='_blank'>
                  {i18n.translate('LearnMore')}
                </Link>
              ),
            })}
          </Text>
        </Banner>
      </BlockStack>
    </Card>
  ) : null;
};
