import React, { useMemo } from 'react';
import { NinjaCartDefaultStateFooterCtaButtonsGeneralPresetDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';
import {
  SingleInputSelection,
  Stacking,
  WidgetOptionHeader,
} from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components';
import { SizeTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';

type Preset_NDC_CTAGeneralProps = {
  general: NinjaCartDefaultStateFooterCtaButtonsGeneralPresetDto;
  defaultGeneral: NinjaCartDefaultStateFooterCtaButtonsGeneralPresetDto;
  shopDefaultGeneral: NinjaCartDefaultStateFooterCtaButtonsGeneralPresetDto;
  setGeneral: (
    data: NinjaCartDefaultStateFooterCtaButtonsGeneralPresetDto
  ) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const Preset_NDC_CTAGeneral: React.FC<Preset_NDC_CTAGeneralProps> = ({
  general,
  defaultGeneral,
  shopDefaultGeneral,
  setGeneral,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();
  const buttonSizeOptions = useMemo(
    () =>
      Object.values(SizeTypeDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    []
  );

  return (
    <>
      <WidgetOptionHeader
        onDiscardChanges={() => setGeneral(defaultGeneral)}
        onResetToSystem={() => setGeneral(shopDefaultGeneral)}
        name={WidgetListOptionsDtoEnum.CTA_BUTTONS}
        onShowHide={onShowHide}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <SingleInputSelection
          options={buttonSizeOptions}
          label={i18n.translate('ButtonSize')}
          selectedOption={general.buttonSize?.size as string}
          onSystemReset={() =>
            setGeneral({
              ...general,
              buttonSize: shopDefaultGeneral.buttonSize,
            })
          }
          setOption={(value) =>
            setGeneral({
              ...general,
              buttonSize: {
                ...general.buttonSize,
                size: value as SizeTypeDtoEnum,
              },
            })
          }
        />
      </div>
      <div className='RightSideSection'>
        <Stacking
          stacking={{ value: general.stacking }}
          setStacking={(data) =>
            setGeneral({ ...general, stacking: data.value })
          }
          onSystemReset={() =>
            setGeneral({ ...general, stacking: shopDefaultGeneral.stacking })
          }
        />
      </div>
    </>
  );
};

export default Preset_NDC_CTAGeneral;
