import { SettingsModalPathsEnums } from 'pages/enums/PagesEnums';
import {
  AccountIcon,
  StyleIcon,
  TranslationsIcon,
  WidgetsIcon,
  StrikethroughPricingIcon,
  CheckoutOptionsIcon,
  PlanIcon,
  IntegrationsIcon,
  CartBehaviorIcon,
  TagsIcon,
  ShippingRateIcon,
  DiscountLinksIcon,
  ImportExportIcon,
  GiftsIcon,
  NotificationsIcon,
  DiscountCodesIcon,
} from '../../../assets/SettingsNavbarIcons';

export const settingNavbarPathes = [
  SettingsModalPathsEnums.Account,
  SettingsModalPathsEnums.Plan,
  SettingsModalPathsEnums.Style,
  SettingsModalPathsEnums.Translations,
  SettingsModalPathsEnums.CartBehavior,
  SettingsModalPathsEnums.Gifts,
  SettingsModalPathsEnums.Notifications,
  SettingsModalPathsEnums.CheckoutOptions,
  SettingsModalPathsEnums.CustomShippingRates,
  SettingsModalPathsEnums.DiscountCodes,
  SettingsModalPathsEnums.DiscountLinks,
  SettingsModalPathsEnums.ImportExport,
  SettingsModalPathsEnums.Integrations,
  SettingsModalPathsEnums.TagsMetadata,
  SettingsModalPathsEnums.StrikethroughPricing,
  SettingsModalPathsEnums.ManageWidgets,
];

const iconMap: Partial<
  Record<SettingsModalPathsEnums, (filled: boolean) => JSX.Element>
> = {
  [SettingsModalPathsEnums.Account]: AccountIcon,
  [SettingsModalPathsEnums.Plan]: PlanIcon,
  [SettingsModalPathsEnums.Style]: StyleIcon,
  [SettingsModalPathsEnums.Translations]: TranslationsIcon,
  [SettingsModalPathsEnums.CartBehavior]: CartBehaviorIcon,
  [SettingsModalPathsEnums.Gifts]: GiftsIcon,
  [SettingsModalPathsEnums.Notifications]: NotificationsIcon,
  [SettingsModalPathsEnums.CheckoutOptions]: CheckoutOptionsIcon,
  [SettingsModalPathsEnums.CustomShippingRates]: ShippingRateIcon,
  [SettingsModalPathsEnums.DiscountCodes]: DiscountCodesIcon,
  [SettingsModalPathsEnums.ManageWidgets]: WidgetsIcon,
  [SettingsModalPathsEnums.StrikethroughPricing]: StrikethroughPricingIcon,
  [SettingsModalPathsEnums.Integrations]: IntegrationsIcon,
  [SettingsModalPathsEnums.TagsMetadata]: TagsIcon,
  [SettingsModalPathsEnums.DiscountLinks]: DiscountLinksIcon,
  [SettingsModalPathsEnums.ImportExport]: ImportExportIcon,
};

export const getSettingsNavbarIcon = (
  path: SettingsModalPathsEnums,
  filled: boolean
) => {
  const IconComponent = iconMap[path] || AccountIcon;
  return IconComponent(filled);
};
