import React from 'react';
import {
  Badge,
  BlockStack,
  Button,
  Card,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';

type DNPromocodesGeneralProps = {
  isEnabled: boolean;
  setIsEnabled: (value: boolean) => void;
};
export const DNPromocodesGeneral: React.FC<DNPromocodesGeneralProps> = ({
  isEnabled,
  setIsEnabled,
}) => {
  const [i18n] = useI18n();
  return (
    <Card>
      <BlockStack gap='200'>
        <InlineStack align='space-between' blockAlign='center'>
          <InlineStack gap='100'>
            <Text fontWeight='semibold' as='p'>
              {i18n.translate('DiscountNinjaPromocodes')}
            </Text>
            <Badge tone={isEnabled ? 'success' : 'new'}>
              {i18n.translate(isEnabled ? 'On' : 'Off')}
            </Badge>
          </InlineStack>
          <Button onClick={() => setIsEnabled(!isEnabled)}>
            {i18n.translate(isEnabled ? 'TurnOff' : 'TurnOn')}
          </Button>
        </InlineStack>
        <Text tone='subdued' as='p'>
          {i18n.translate('DiscountNinjaPromocodesSubtitle')}
        </Text>
      </BlockStack>
    </Card>
  );
};
