import { yupResolver } from '@hookform/resolvers/yup';
import {
  ButtonGroup,
  Button,
  Icon,
  Tooltip,
  InlineStack,
  Text,
  Banner,
} from '@shopify/polaris';
import {
  QuestionCircleIcon,
  CaretDownIcon,
  CaretUpIcon,
} from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import * as yup from 'yup';
import {
  Controller,
  ControllerRenderProps,
  useController,
  useForm,
} from 'react-hook-form';
import React, { useCallback, useMemo, useEffect, useState } from 'react';
import './GrayBoxFirstLineContent.scss';
import '../../GrayBox.scss';
import { OfferRuleValueTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import {
  OfferCartRuleDto,
  OfferRuleValueTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import { getCurrencySymbol, parseCurrencyString } from 'core/utils';

type FormFields = {
  numberOfProducts: number | null;
  numberOfProductsMax: number | null;
  numberOfProductsExactly: number | null;
  productsMaxValidation: OfferRuleValueTypeDto;
};

export type GrayBoxFirstLineContentProps = {
  visitorsAlsoAdd?: boolean;
  isMoney?: boolean;
  isCrossSell?: boolean;
  activeRuleOfferType: OfferRuleValueTypeDto;
  data: OfferCartRuleDto;
  updateValue: (data: OfferCartRuleDto) => void;
  onFormValidityChange(isFormInvalid: boolean): void;
};

export const GrayBoxFirstLineContent: React.FC<GrayBoxFirstLineContentProps> = (
  props
) => {
  const {
    isCrossSell,
    activeRuleOfferType,
    updateValue,
    onFormValidityChange,
    isMoney,
    visitorsAlsoAdd,
    data,
  } = props;

  const [i18n] = useI18n();

  const form = yup.object({
    productsMaxValidation: yup.string(),
    numberOfProducts: yup
      .number()
      .transform((value, originalValue) => parseCurrencyString(originalValue))
      .nullable()
      .when('productsMaxValidation', {
        is: (value: OfferRuleValueTypeDto) =>
          value !== OfferRuleValueTypeDtoEnum.AT_MOST &&
          value !== OfferRuleValueTypeDtoEnum.EXACTLY,
        then: yup.number().min(1).required(),
      }),
    numberOfProductsExactly: yup
      .number()
      .transform((value, originalValue) => parseCurrencyString(originalValue))
      .nullable()
      .when('productsMaxValidation', {
        is: (value: OfferRuleValueTypeDto) =>
          value === OfferRuleValueTypeDtoEnum.EXACTLY,
        then: yup.number().min(1).required(),
      }),
    numberOfProductsMax: yup
      .number()
      .transform((value, originalValue) => parseCurrencyString(originalValue))
      .nullable()
      .when('productsMaxValidation', {
        is: (value: OfferRuleValueTypeDto) =>
          value === OfferRuleValueTypeDtoEnum.BETWEEN,
        then: yup
          .number()
          .min(1)
          .moreThan(yup.ref('numberOfProducts'))
          .required(),
      })
      .when('productsMaxValidation', {
        is: (value: OfferRuleValueTypeDto) =>
          value === OfferRuleValueTypeDtoEnum.AT_MOST,
        then: yup.number().min(1).required(),
      }),
  });

  const numberOfProductsMaxDefault: number | null = useMemo(
    () =>
      data.value?.to
        ? data.value?.to
        : data.value?.from
        ? data.value?.from + 1
        : null,
    [data.value?.to, data.value?.from]
  );

  const [currentValues, setCurrentValues] = useState<FormFields>({
    numberOfProducts: data.value?.from || 1,
    numberOfProductsMax: numberOfProductsMaxDefault,
    productsMaxValidation: activeRuleOfferType,
    numberOfProductsExactly: data.value?.exactly || 1,
  });

  const { control, formState, watch, trigger, resetField, getValues } =
    useForm<FormFields>({
      defaultValues: {
        numberOfProducts: currentValues.numberOfProducts,
        numberOfProductsMax: currentValues.numberOfProductsMax,
        productsMaxValidation: currentValues.productsMaxValidation,
        numberOfProductsExactly: currentValues.numberOfProductsExactly,
      },
      mode: 'onChange',
      resolver: yupResolver(form),
    });

  const getProductAmountEnumKey = useCallback(
    (value: OfferRuleValueTypeDtoEnum) => {
      return Object.keys(OfferRuleValueTypeDtoEnum)[
        Object.values(OfferRuleValueTypeDtoEnum).indexOf(value)
      ];
    },
    []
  );

  const conditionalInputName = useMemo(
    () =>
      currentValues.productsMaxValidation ===
      getProductAmountEnumKey(OfferRuleValueTypeDtoEnum.AT_MOST)
        ? 'numberOfProductsMax'
        : currentValues.productsMaxValidation ===
          getProductAmountEnumKey(OfferRuleValueTypeDtoEnum.EXACTLY)
        ? 'numberOfProductsExactly'
        : 'numberOfProducts',
    [currentValues.productsMaxValidation, getProductAmountEnumKey]
  );

  useEffect(() => {
    resetField('numberOfProducts', {
      defaultValue: currentValues.numberOfProducts,
    });
    resetField('numberOfProductsMax', {
      defaultValue:
        currentValues.numberOfProductsMax || numberOfProductsMaxDefault,
    });
    resetField('numberOfProductsExactly', {
      defaultValue: currentValues.numberOfProductsExactly,
    });
    resetField('productsMaxValidation', {
      defaultValue: currentValues.productsMaxValidation,
    });
  }, [
    currentValues.productsMaxValidation,
    numberOfProductsMaxDefault,
    data.type,
  ]);

  useEffect(() => {
    onFormValidityChange(formState.isValid);
  }, [formState.isValid, onFormValidityChange]);

  useEffect(() => {
    trigger('numberOfProductsMax');
    trigger('numberOfProductsExactly');
    const subscription = watch((value, { type }) => {
      if (type === 'change') {
        updateValue({
          ...data,
          value: {
            type: value.productsMaxValidation,
            from:
              value.productsMaxValidation ===
                OfferRuleValueTypeDtoEnum.AT_LEAST ||
              value.productsMaxValidation === OfferRuleValueTypeDtoEnum.BETWEEN
                ? parseCurrencyString(value.numberOfProducts)
                : null,
            to:
              value.productsMaxValidation ===
                OfferRuleValueTypeDtoEnum.AT_MOST ||
              value.productsMaxValidation === OfferRuleValueTypeDtoEnum.BETWEEN
                ? parseCurrencyString(value.numberOfProductsMax)
                : null,
            exactly: parseCurrencyString(value.numberOfProductsExactly),
          },
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, updateValue, trigger, data]);

  useEffect(() => {
    trigger('numberOfProductsMax');
    trigger('numberOfProducts');
    trigger('numberOfProductsExactly');
  }, []);

  const changeValue = useCallback(
    (field: ControllerRenderProps<FormFields>, value: string) => {
      if (field.value !== parseFloat(value)) {
        setCurrentValues((prevState) => ({
          ...prevState,
          [field.name]: parseFloat(value),
        }));
        field.onChange(parseFloat(value));
      }
    },
    [setCurrentValues]
  );

  const changeRuleOfferType = useCallback(
    (field: ControllerRenderProps<FormFields>, value: string) => {
      if (field.value !== value) {
        setCurrentValues((prevState) => ({
          ...prevState,
          [field.name]: value,
        }));
        field.onChange(value);
        trigger('numberOfProductsMax');
        trigger('numberOfProducts');
        trigger('numberOfProductsExactly');
      }
    },
    [setCurrentValues, trigger]
  );

  const validationMessage = useMemo(() => {
    const {
      numberOfProducts: numberOfProductsErrors,
      numberOfProductsMax: numberOfProductsMaxErrors,
      numberOfProductsExactly: numberOfProductsExactlyErrors,
    } = formState.errors;

    let label = { translationKey: '', textContent: '' };
    if (numberOfProductsErrors) {
      switch (numberOfProductsErrors.type) {
        case 'min':
          !isMoney
            ? (label = {
                translationKey: 'FiltersWarning.WarningProduct1',
                textContent:
                  'Value for “product(s)” must be equal or higher than 1',
              })
            : (label = {
                translationKey: 'FiltersWarning.WarningAmount1',
                textContent:
                  'Value for “amount(s)” must be equal or higher than 1',
              });
          break;
        case 'typeError':
          !isMoney
            ? (label = {
                translationKey: 'FiltersWarning.WarningProduct3',
                textContent: 'Value for “product(s)” is required',
              })
            : (label = {
                translationKey: 'FiltersWarning.WarningAmount3',
                textContent: 'Value for “amounts(s)” is required',
              });
          break;
      }
    }
    if (numberOfProductsMaxErrors) {
      switch (numberOfProductsMaxErrors.type) {
        case 'min':
          !isMoney
            ? (label = {
                translationKey: 'FiltersWarning.WarningProduct2',
                textContent:
                  'Second value in range for “product(s)” must be higher than first value in range',
              })
            : (label = {
                translationKey: 'FiltersWarning.WarningAmount2',
                textContent:
                  'Second value in range for “amount(s)” must be higher than first value in range',
              });
          break;
        case 'typeError':
          !isMoney
            ? (label = {
                translationKey: 'FiltersWarning.WarningProduct3',
                textContent: 'Value for “product(s)” is required',
              })
            : (label = {
                translationKey: 'FiltersWarning.WarningAmount3',
                textContent: 'Value for “amounts(s)” is required',
              });
          break;
      }
    }
    if (numberOfProductsExactlyErrors) {
      switch (numberOfProductsExactlyErrors?.type) {
        case 'min':
          !isMoney
            ? (label = {
                translationKey: 'FiltersWarning.WarningProduct1',
                textContent:
                  'Value for “product(s)” must be equal or higher than 1',
              })
            : (label = {
                translationKey: 'FiltersWarning.WarningAmount1',
                textContent:
                  'Value for “amount(s)” must be equal or higher than 1',
              });
          break;
        case 'required':
          !isMoney
            ? (label = {
                translationKey: 'FiltersWarning.WarningProduct3',
                textContent: 'Value for “product(s)” is required',
              })
            : (label = {
                translationKey: 'FiltersWarning.WarningAmount3',
                textContent: 'Value for “amounts(s)” is required',
              });
          break;
        default:
          break;
      }
    }
    if (label.textContent && label.translationKey) {
      return (
        <Banner tone='critical'>
          {i18n.translate(`GrayBoxFirstLineContent.${label.translationKey}`) ||
            label.textContent}
        </Banner>
      );
    } else {
      return null;
    }
  }, [formState, isMoney, i18n]);

  const incrementDecrementValue = useCallback(
    (
      field: ControllerRenderProps<FormFields>,
      type: 'increment' | 'decrement'
    ) => {
      const currentValue =
        typeof getValues(field.name) === 'number' ? getValues(field.name) : 1;
      if (type === 'increment' && typeof currentValue === 'number') {
        changeValue(field, `${currentValue + 1}`);
      } else if (type === 'decrement' && typeof currentValue === 'number') {
        changeValue(field, `${currentValue - 1}`);
      }
    },
    [getValues, changeValue]
  );

  const { field: conditionalInputNameField } = useController({
    name: conditionalInputName,
    control,
  });

  const { field: numberOfProductsMaxField } = useController({
    name: 'numberOfProductsMax',
    control,
  });

  const incrementDecrementButtons = useCallback(
    (field: ControllerRenderProps<FormFields>) => {
      return (
        <div className='Polaris-TextField__Spinner' aria-hidden='true'>
          <div
            role='button'
            className='Polaris-TextField__Segment'
            onClick={() => {
              incrementDecrementValue(field, 'increment');
            }}
          >
            <div className='Polaris-TextField__SpinnerIcon'>
              <span className='Polaris-Icon'>
                <Icon source={CaretUpIcon} />
              </span>
            </div>
          </div>
          <div
            role='button'
            className='Polaris-TextField__Segment'
            onClick={() => {
              incrementDecrementValue(field, 'decrement');
            }}
          >
            <div className='Polaris-TextField__SpinnerIcon'>
              <span className='Polaris-Icon'>
                <Icon source={CaretDownIcon} />
              </span>
            </div>
          </div>
        </div>
      );
    },
    [incrementDecrementValue]
  );

  const firstLineContent = useMemo(() => {
    if (isCrossSell) {
      return (
        <>
          {validationMessage}
          <InlineStack gap='200' blockAlign='center'>
            <Text as='p'>
              {i18n.translate(
                `GrayBoxFirstLineContent.CrossSellOrBundleOrder.${
                  isMoney ? 'Amount' : 'Quantity'
                }.Apply`
              )}
            </Text>
            <div
              className={classNames(
                'Polaris-TextField Polaris-TextField--hasValue',
                {
                  'Polaris-TextField--error': validationMessage,
                }
              )}
              style={{ maxWidth: '100px' }}
            >
              {isMoney && (
                <div className={'Polaris-TextField__Prefix'}>
                  {getCurrencySymbol()}
                </div>
              )}

              <Controller<FormFields>
                name={conditionalInputName}
                control={control}
                render={({ field }) => (
                  <NumberFormat
                    {...field}
                    value={field.value as number}
                    className={`Polaris-TextField__Input ${conditionalInputName}`}
                    decimalScale={isMoney ? 2 : undefined}
                    decimalSeparator={
                      isMoney ? i18n.numberSymbols().decimalSymbol : undefined
                    }
                    thousandSeparator={
                      isMoney ? i18n.numberSymbols().thousandSymbol : undefined
                    }
                    allowNegative={false}
                    autoComplete='off'
                    onValueChange={({ floatValue, formattedValue }) => {
                      // Prevent decimal input if isMoney is false
                      if (
                        !isMoney &&
                        formattedValue.includes(
                          i18n.numberSymbols().decimalSymbol
                        )
                      ) {
                        return;
                      }
                      changeValue(field, `${floatValue || ''}`);
                    }}
                  />
                )}
              />
              {incrementDecrementButtons(conditionalInputNameField)}
              <div className='Polaris-TextField__Backdrop'></div>
            </div>
            {!isMoney && (
              <>
                <Text as='p'>
                  {i18n.translate(
                    'GrayBoxFirstLineContent.CrossSellOrBundleOrder.Quantity.ToCart'
                  )}
                </Text>
                <Tooltip
                  preferredPosition='mostSpace'
                  content={i18n.translate(
                    'GrayBoxFirstLineContent.HoverProducts'
                  )}
                >
                  <span>
                    <Icon source={QuestionCircleIcon} />
                  </span>
                </Tooltip>
              </>
            )}
          </InlineStack>
        </>
      );
    } else {
      return (
        <>
          {validationMessage}
          <InlineStack gap='200' blockAlign='center'>
            {!isMoney ? (
              <Text as='p'>
                {visitorsAlsoAdd
                  ? i18n.translate(`GrayBoxFirstLineContent.VisitorsAlsoAdd`) ||
                    'And visitors also add'
                  : i18n.translate(`GrayBoxFirstLineContent.ApplyTheOffer`) ||
                    'Apply the offer when visitors add'}
              </Text>
            ) : (
              <Text as='p'>
                {i18n.translate(`GrayBoxFirstLineContent.ApplyTheOfferSpend`) ||
                  'Apply the offer when visitors spend'}
              </Text>
            )}
            <Controller<FormFields>
              name='productsMaxValidation'
              control={control}
              render={({ field }) => (
                <ButtonGroup variant='segmented'>
                  {Object.keys(OfferRuleValueTypeDtoEnum).map((key: string) => (
                    <Button
                      id={`${key}_OfferRuleType`}
                      key={key}
                      pressed={field.value === key}
                      onClick={() => changeRuleOfferType(field, key)}
                    >
                      {i18n.translate(`GrayBoxFirstLineContent.${key}`) || key}
                    </Button>
                  ))}
                </ButtonGroup>
              )}
            />
            <div
              className={classNames(
                'Polaris-TextField Polaris-TextField--hasValue',
                {
                  'Polaris-TextField--error': validationMessage,
                }
              )}
              style={{ maxWidth: '100px' }}
            >
              {isMoney && (
                <div className={'Polaris-TextField__Prefix'}>
                  {getCurrencySymbol()}
                </div>
              )}

              <Controller<FormFields>
                name={conditionalInputName}
                control={control}
                render={({ field }) => (
                  <NumberFormat
                    {...field}
                    value={field.value as number}
                    className={`Polaris-TextField__Input ${conditionalInputName}`}
                    decimalScale={isMoney ? 2 : undefined}
                    decimalSeparator={
                      isMoney ? i18n.numberSymbols().decimalSymbol : undefined
                    }
                    thousandSeparator={
                      isMoney ? i18n.numberSymbols().thousandSymbol : undefined
                    }
                    allowNegative={false}
                    autoComplete='off'
                    onValueChange={({ floatValue, formattedValue }) => {
                      // Prevent decimal input if isMoney is false
                      if (
                        !isMoney &&
                        formattedValue.includes(
                          i18n.numberSymbols().decimalSymbol
                        )
                      ) {
                        return;
                      }
                      changeValue(field, `${floatValue || ''}`);
                    }}
                  />
                )}
              />
              {incrementDecrementButtons(conditionalInputNameField)}
              <div className='Polaris-TextField__Backdrop'></div>
            </div>
            {currentValues.productsMaxValidation ===
              getProductAmountEnumKey(OfferRuleValueTypeDtoEnum.BETWEEN) && (
              <>
                <Text as='p'>
                  {i18n.translate(`GrayBoxFirstLineContent.TextAnd`) || 'and'}
                </Text>

                <div
                  className={classNames(
                    'Polaris-TextField Polaris-TextField--hasValue',
                    {
                      'Polaris-TextField--error': validationMessage,
                    }
                  )}
                  style={{ maxWidth: '100px' }}
                >
                  {isMoney && (
                    <div className={'Polaris-TextField__Prefix'}>
                      {getCurrencySymbol()}
                    </div>
                  )}

                  <Controller<FormFields>
                    name={'numberOfProductsMax'}
                    control={control}
                    render={({ field }) => (
                      <NumberFormat
                        {...field}
                        value={field.value as number}
                        className='Polaris-TextField__Input numberOfProductsMax'
                        decimalScale={isMoney ? 2 : undefined}
                        decimalSeparator={
                          isMoney
                            ? i18n.numberSymbols().decimalSymbol
                            : undefined
                        }
                        thousandSeparator={
                          isMoney
                            ? i18n.numberSymbols().thousandSymbol
                            : undefined
                        }
                        allowNegative={false}
                        autoComplete='off'
                        onValueChange={({ floatValue, formattedValue }) => {
                          // Prevent decimal input if isMoney is false
                          if (
                            !isMoney &&
                            formattedValue.includes(
                              i18n.numberSymbols().decimalSymbol
                            )
                          ) {
                            return;
                          }
                          changeValue(field, `${floatValue || ''}`);
                        }}
                      />
                    )}
                  />
                  {incrementDecrementButtons(numberOfProductsMaxField)}
                  <div className='Polaris-TextField__Backdrop'></div>
                </div>
              </>
            )}
            {!isMoney && (
              <InlineStack gap='100' blockAlign='center'>
                <Text as='p'>
                  {i18n.translate('GrayBoxFirstLineContent.Products') ||
                    'product(s)'}
                </Text>
                <Text as='p'>
                  {i18n.translate('GrayBoxFirstLineContent.Text2') ||
                    'to the cart'}
                </Text>
                <Tooltip
                  preferredPosition='mostSpace'
                  content={i18n.translate(
                    'GrayBoxFirstLineContent.HoverProducts'
                  )}
                >
                  <span>
                    <Icon source={QuestionCircleIcon} />
                  </span>
                </Tooltip>
              </InlineStack>
            )}
          </InlineStack>
        </>
      );
    }
  }, [
    isMoney,
    currentValues.productsMaxValidation,
    control,
    getProductAmountEnumKey,
    i18n,
    isCrossSell,
    visitorsAlsoAdd,
    changeRuleOfferType,
    formState,
    changeValue,
    validationMessage,
    incrementDecrementButtons,
    conditionalInputNameField,
    numberOfProductsMaxField,
    incrementDecrementValue,
  ]);

  return firstLineContent;
};
