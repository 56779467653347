import React from 'react';
import {
  SimplifiedTextEntries,
  TextStyle,
  WidgetOptionHeader,
} from '../../../../../../../../../components';
import { NinjaCartDefaultStateHeaderHeadingPresetDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';

type Preset_NDC_HeadingProps = {
  heading: NinjaCartDefaultStateHeaderHeadingPresetDto;
  defaultHeading: NinjaCartDefaultStateHeaderHeadingPresetDto;
  shopDefaultHeading: NinjaCartDefaultStateHeaderHeadingPresetDto;
  defaultLanguage: string;
  setHeading: (data: NinjaCartDefaultStateHeaderHeadingPresetDto) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const Preset_NDC_Heading: React.FC<Preset_NDC_HeadingProps> = ({
  heading,
  defaultLanguage,
  defaultHeading,
  shopDefaultHeading,
  setHeading,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();
  return (
    <div>
      <WidgetOptionHeader
        onDiscardChanges={() => setHeading(defaultHeading)}
        onResetToSystem={() => setHeading(shopDefaultHeading)}
        name={WidgetListOptionsDtoEnum.HEADING}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <SimplifiedTextEntries
          message={heading.text?.message || {}}
          defaultLanguage={defaultLanguage}
          title={i18n.translate('Text')}
          setMessage={(data) =>
            setHeading({
              text: {
                ...heading.text,
                message: data,
              },
            })
          }
          onSystemReset={() =>
            setHeading({
              text: shopDefaultHeading.text,
            })
          }
        />
        <TextStyle
          textStyle={heading.text?.style || {}}
          setTextStyle={(data) =>
            setHeading({
              ...heading,
              text: {
                ...heading.text,
                style: data,
              },
            })
          }
          hideHeader
        />
      </div>
    </div>
  );
};

export default Preset_NDC_Heading;
