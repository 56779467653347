import React, { useMemo } from 'react';
import {
  CTAButtonAction,
  SimplifiedTextEntries,
  SingleInputSelection,
  WidgetOptionHeader,
} from '../../../../../../../components';
import { NinjaCartEmptyStateCallToActionButtonPresetDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';
import {
  NinjaCartButtonStyleSetKeyDtoEnum,
  SizeTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';

type Preset_NDC_EmptyCTAProps = {
  cta: NinjaCartEmptyStateCallToActionButtonPresetDto;
  defaultCTA: NinjaCartEmptyStateCallToActionButtonPresetDto;
  shopDefaultCTA: NinjaCartEmptyStateCallToActionButtonPresetDto;
  defaultLanguage: string;
  setCTA: (data: NinjaCartEmptyStateCallToActionButtonPresetDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const Preset_NDC_EmptyCTA: React.FC<Preset_NDC_EmptyCTAProps> = ({
  cta,
  defaultCTA,
  shopDefaultCTA,
  defaultLanguage,
  setCTA,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();

  const buttonSizeOptions = useMemo(
    () =>
      Object.values(SizeTypeDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    []
  );
  const buttonStyleOptions = useMemo(
    () =>
      Object.values(NinjaCartButtonStyleSetKeyDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    []
  );
  return (
    <div>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setCTA(defaultCTA)}
        onResetToSystem={() => setCTA(shopDefaultCTA)}
        name={WidgetListOptionsDtoEnum.CTA_BUTTON}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <SingleInputSelection
          options={buttonSizeOptions}
          label={i18n.translate('ButtonSize')}
          selectedOption={cta.buttonSize as string}
          setOption={(value) =>
            setCTA({ ...cta, buttonSize: value as SizeTypeDtoEnum })
          }
          onSystemReset={() =>
            setCTA({ ...cta, buttonSize: shopDefaultCTA.buttonSize })
          }
        />
      </div>
      <div className='RightSideSection'>
        <SingleInputSelection
          options={buttonStyleOptions}
          label={i18n.translate('ButtonStyle')}
          selectedOption={cta.buttonStyleSet as string}
          setOption={(value) =>
            setCTA({
              ...cta,
              buttonStyleSet: value as NinjaCartButtonStyleSetKeyDtoEnum,
            })
          }
          onSystemReset={() =>
            setCTA({ ...cta, buttonStyleSet: shopDefaultCTA.buttonStyleSet })
          }
        />
      </div>
      <div className='RightSideSection'>
        <CTAButtonAction
          action={cta.action || {}}
          onSystemReset={() =>
            setCTA({ ...cta, action: shopDefaultCTA.action })
          }
          simplified
          setAction={(data) => setCTA({ ...cta, action: data })}
        />
      </div>
      <div className='RightSideSection'>
        <SimplifiedTextEntries
          message={cta.label || {}}
          title={i18n.translate('Label')}
          defaultLanguage={defaultLanguage}
          setMessage={(data) =>
            setCTA({
              ...cta,
              label: data,
            })
          }
          onSystemReset={() => setCTA({ ...cta, label: shopDefaultCTA.label })}
        />
      </div>
    </div>
  );
};

export default Preset_NDC_EmptyCTA;
