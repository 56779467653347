import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './RuleAdvancedSection.scss';
import { useI18n } from '@shopify/react-i18n';
import { ChevronDownIcon } from '@shopify/polaris-icons';
import { OfferSuggestedAdvancedSetupDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { usePrevious } from 'core/hooks/usePrevious';
import { isEqual } from 'lodash';
import {
  LegacyCard,
  Collapsible,
  Icon,
  RadioButton,
  Checkbox,
  Banner,
  IconSource,
  Tooltip,
} from '@shopify/polaris';
import classNames from 'classnames';
import {
  OfferSuggestedProductsDisplayOrderDtoEnum,
  OfferTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';

type RuleAdvancedSectionProps = {
  advancedSetup?: OfferSuggestedAdvancedSetupDto;
  updateAdvancedSetup: (obj: OfferSuggestedAdvancedSetupDto) => void;
  offerType: OfferTypeDtoEnum;
};

export const RuleAdvancedSection: React.FC<RuleAdvancedSectionProps> = (
  props
) => {
  const { advancedSetup, updateAdvancedSetup, offerType } = props;

  const [currentAdvancedSetup, setCurrentAdvancedSetup] =
    useState<OfferSuggestedAdvancedSetupDto>(advancedSetup || {});
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const prevAdvancedSetup = usePrevious(currentAdvancedSetup);

  const [i18n] = useI18n();

  const radioBtns = useMemo(
    () => [
      {
        label: i18n.translate('RuleAdvanced.Custom'),
        tooltip: i18n.translate('RuleAdvanced.CustomTooltip'),
        value: OfferSuggestedProductsDisplayOrderDtoEnum.CUSTOM,
      },
      {
        label: i18n.translate('RuleAdvanced.Price'),
        tooltip: i18n.translate('RuleAdvanced.PriceTooltip'),
        value: OfferSuggestedProductsDisplayOrderDtoEnum.PRICE,
      },
      {
        label: i18n.translate('RuleAdvanced.Inventory'),
        tooltip: i18n.translate('RuleAdvanced.InventoryTooltip'),
        value: OfferSuggestedProductsDisplayOrderDtoEnum.INVENTORY,
      },
    ],
    [i18n]
  );

  const handleIsExpanded = useCallback(
    () => setIsExpanded((active: boolean) => !active),
    [setIsExpanded]
  );

  const updateCurrentAdvancedSetup = useCallback(
    (
      field: keyof OfferSuggestedAdvancedSetupDto,
      data: OfferSuggestedAdvancedSetupDto[keyof OfferSuggestedAdvancedSetupDto]
    ) => {
      setCurrentAdvancedSetup((prevState: OfferSuggestedAdvancedSetupDto) => ({
        ...prevState,
        [field]: data,
      }));
    },
    [setCurrentAdvancedSetup]
  );

  useEffect(() => {
    if (
      prevAdvancedSetup &&
      currentAdvancedSetup &&
      !isEqual(prevAdvancedSetup, currentAdvancedSetup)
    ) {
      updateAdvancedSetup(currentAdvancedSetup);
    }
  }, [prevAdvancedSetup, currentAdvancedSetup, updateAdvancedSetup]);

  return (
    <LegacyCard>
      <LegacyCard.Section>
        <div
          className={classNames({
            RuleAdvancedTitle: true,
            RuleAdvancedOpen: isExpanded,
          })}
          onClick={handleIsExpanded}
        >
          <div className='TitleTextContainer'>
            <h4>{i18n.translate('RuleAdvanced.Title')}</h4>
            <span className='TileDescription'>
              {i18n.translate('RuleAdvanced.TitleSecondary')}
            </span>
          </div>
          <Icon source={ChevronDownIcon as IconSource} tone='base' />
        </div>
      </LegacyCard.Section>
      <Collapsible id='rule-advanced-section-collapsible' open={isExpanded}>
        {offerType === OfferTypeDtoEnum.FREQUENTLY_BOUGHT_TOGETHER && (
          <div className='block'>
            <div className='title'>
              {i18n.translate('RuleAdvanced.DefineOrder')}
            </div>
            <div className='radioBtns'>
              {radioBtns.map((btn) => (
                <RadioButton
                  key={btn.value}
                  label={
                    <div className='label'>
                      <span className='text'>{btn.label}</span>
                      <Tooltip
                        preferredPosition='mostSpace'
                        content={btn.tooltip}
                      >
                        {/*FIX FONTAWESOME */}
                        {/* <span className='tooltip'>
                          <FontAwesomeIcon
                            icon={findIconDefinition(questionMarkIconLookup)}
                            color='#5C5F62'
                          />
                        </span> */}
                      </Tooltip>
                    </div>
                  }
                  checked={currentAdvancedSetup.displayOrder === btn.value}
                  id={btn.label}
                  name={btn.label}
                  onChange={() =>
                    updateCurrentAdvancedSetup('displayOrder', btn.value)
                  }
                />
              ))}
            </div>
          </div>
        )}
        <div className='block'>
          <div className='title'>
            {i18n.translate('RuleAdvanced.GeneralSettings')}
          </div>
          <div className='checkbox'>
            <Checkbox
              label={i18n.translate('RuleAdvanced.CheckBoxLabel1')}
              checked={currentAdvancedSetup.disableInCartProductsSuggestions}
              onChange={(value) =>
                updateCurrentAdvancedSetup(
                  'disableInCartProductsSuggestions',
                  value
                )
              }
            />
          </div>
          {offerType === OfferTypeDtoEnum.ORDER_BUMP && (
            <div className='checkbox'>
              <Checkbox
                label={i18n.translate('RuleAdvanced.CheckBoxLabel2')}
                helpText={i18n.translate('RuleAdvanced.HelpText')}
                checked={!!currentAdvancedSetup.hideProductInCart}
                onChange={(value) =>
                  updateCurrentAdvancedSetup('hideProductInCart', value)
                }
              />
            </div>
          )}
          <Banner tone='info'>
            {offerType === OfferTypeDtoEnum.FREQUENTLY_BOUGHT_TOGETHER
              ? i18n.translate('RuleAdvanced.FBTInfo')
              : i18n.translate('RuleAdvanced.OBInfo')}
          </Banner>
        </div>
      </Collapsible>
    </LegacyCard>
  );
};
