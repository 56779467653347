import { OfferTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { OfferTypesFromCatalogDataModel } from '../types/OfferTypesFromCatalogTypes';

export const OfferTypesFromCatalogData: OfferTypesFromCatalogDataModel = {
  Sales: [
    {
      title: 'PercentageDiscountTitle',
      subtitle: 'PercentageDiscountSubtitle',
      type: OfferTypeDtoEnum.SALES_PERCENTAGE,
    },
    {
      title: 'FixedAmountDiscountTitle',
      subtitle: 'FixedAmountDiscountSubtitle',
      type: OfferTypeDtoEnum.SALES_FIXED_AMOUNT,
    },
    {
      title: 'FixedPricePerUnitTitle',
      subtitle: 'FixedPricePerUnitSubtitle',
      type: OfferTypeDtoEnum.SALES_FIXED_PRICE,
    },
  ],
  FreeGift: [
    {
      title: 'OfferAFreeGiftAutomaticallyTitle',
      subtitle: 'OfferAFreeGiftAutomaticallySubtitle',
      type: OfferTypeDtoEnum.GIFT_AUTOMATIC,
    },
    {
      title: 'PickAGiftTitle',
      subtitle: 'PickAGiftSubtitle',
      type: OfferTypeDtoEnum.GIFT_MANUAL,
    },
    {
      title: 'OrderBumpTitle',
      subtitle: 'OrderBumpSubtitle',
      type: OfferTypeDtoEnum.BUNDLE_ORDER_DISCOUNT,
    },
  ],
  BuyOneGetOne: [
    {
      title: 'BOGOTitle',
      subtitle: 'BOGOSubtitle',
      type: OfferTypeDtoEnum.BOGO_REGULAR,
    },
    {
      title: 'BOGOTitle',
      subtitle: 'BOGOSubtitle',
      type: OfferTypeDtoEnum.BOGO_STRICT_MATCH,
    },
  ],
  IncreaseAverageOrderValue: [
    {
      title: 'CrossSellTitle',
      subtitle: 'CrossSellSubtitle',
      type: OfferTypeDtoEnum.CROSS_SELL,
    },
  ],
  TieredDiscounts: [
    {
      title: 'MultiTierBOGOTitle',
      subtitle: 'MultiTierBOGOSubtitle',
      type: OfferTypeDtoEnum.TIERED_BOGO,
    },
    {
      title: 'SpendAmountGet$OffTitle',
      subtitle: 'SpendAmountGet$OffSubtitle',
      type: OfferTypeDtoEnum.TIERED_SPEND_X_GET_Y,
    },
  ],
  VolumeDiscount: [
    {
      title: 'VolumeTitle',
      subtitle: 'VolumeSubtitle',
      type: OfferTypeDtoEnum.VOLUME_DISCOUNT,
    },
  ],
  RelatedProducts: [
    {
      title: 'FrequentlyBoughtTogetherTitle',
      subtitle: 'FrequentlyBoughtTogetherSubtitle',
      type: OfferTypeDtoEnum.FREQUENTLY_BOUGHT_TOGETHER,
    },
    {
      title: 'OrderBumpTitle',
      subtitle: 'OrderBumpSubtitle',
      type: OfferTypeDtoEnum.ORDER_BUMP,
    },
  ],
  FreeShipping: [
    {
      title: 'FreeShippingToAnyCountryTitle',
      subtitle: 'FreeShippingToAnyCountrySubtitle',
      type: OfferTypeDtoEnum.FREE_SHIPPING,
    },
    {
      title: 'FreeShippingForZonesTitle',
      subtitle: 'FreeShippingForZonesSubtitle',
      type: OfferTypeDtoEnum.SHIPPING_FIXED_AMOUNT,
    },
    {
      title: 'PerProductShippingFeeTitle',
      subtitle: 'PerProductShippingFeeSubtitle',
      type: OfferTypeDtoEnum.SHIPPING_PERCENTAGE,
    },
  ],
  ShopifyDiscountCodeWrapper: [
    {
      title: 'ApplyAShopifyDiscountTitle',
      subtitle: 'ApplyAShopifyDiscountSubtitle',
      type: OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_REGULAR,
    },
    {
      title: 'ApplyAUniqueShopifyDiscountTitle',
      subtitle: 'ApplyAUniqueShopifyDiscountSubtitle',
      type: OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_GROUP,
    },
    {
      title: 'IntegrateWithOtherAppsTitle',
      subtitle: 'IntegrateWithOtherAppsSubtitle',
      type: OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_APP,
    },
  ],
};
