import React from 'react';
import {
  BlockStack,
  Box,
  Button,
  ButtonProps,
  Collapsible,
  InlineStack,
  SkeletonBodyText,
  Text,
} from '@shopify/polaris';
import { CheckCircle } from './assets/icons';
import EmptyImage from './assets/EmptyImage.png';
import './SetupStepBox.scss';
import classNames from 'classnames';

type SetupStepBoxProps = {
  checked: boolean;
  button: ButtonProps;
  label: string;
  helpText?: string;
  image?: React.ReactNode;
  badge?: React.ReactNode;
  collapsed: boolean;
  isLoading: boolean;
  onCollapse?: () => void;
};
export const SetupStepBox: React.FC<SetupStepBoxProps> = ({
  checked,
  button,
  label,
  helpText,
  image,
  badge = null,
  collapsed,
  isLoading,
  onCollapse,
}) => {
  return (
    <div
      className={classNames('SetupStepBox', {
        Uncollapsed: !collapsed,
      })}
      onClick={onCollapse}
    >
      <Box paddingBlock='300' paddingInline='200'>
        <InlineStack
          align='space-between'
          wrap={false}
          gap='600'
          blockAlign='center'
        >
          {!isLoading ? (
            <InlineStack gap='200' wrap={false} blockAlign='start'>
              <div style={{ maxHeight: 20 }}>
                <CheckCircle checked={checked} />
              </div>
              <BlockStack gap='100'>
                <InlineStack align='start' gap='200'>
                  <Text fontWeight='medium' as='p'>
                    {label}
                  </Text>
                  {badge}
                </InlineStack>
                <Collapsible id='CollapsedStepBox' open={!collapsed}>
                  <BlockStack inlineAlign='start' gap='400'>
                    {helpText && (
                      <Text tone='subdued' as='p'>
                        {helpText}
                      </Text>
                    )}
                    {button && !checked && (
                      <Button {...button}>{button.children}</Button>
                    )}
                  </BlockStack>
                </Collapsible>
              </BlockStack>
            </InlineStack>
          ) : (
            <SkeletonBodyText lines={4} />
          )}
          {!collapsed &&
            (image ? (
              image
            ) : (
              <img
                style={{ paddingInline: 'var(--p-space-600)' }}
                src={EmptyImage}
              ></img>
            ))}
        </InlineStack>
      </Box>
    </div>
  );
};
