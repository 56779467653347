import {
  GetSettingsV6EngineAndPlanCheckApiArg,
  PromotionEngineAndPlanCheckResponseDto,
} from 'core/api/adminSettings/adminSettingsApi';
import resolveEnvVar from 'env-var-resolver';
import { useState, useCallback } from 'react';

const useFetchEngineAndPlanCheck = () => {
  const token = localStorage.getItem('token');
  const settingsApiURL = `${resolveEnvVar(
    'REACT_APP_SETTINGS_ADMIN_API_URL'
  )}/settings/v6`;
  const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';

  const [engineAndPlanCheckData, setEngineAndPlanCheckData] =
    useState<PromotionEngineAndPlanCheckResponseDto>();
  const [engineAndPlanCheckIsFetching, setEngineAndPlanCheckIsFetching] =
    useState(false);

  const fetchEngineAndPlanCheck = useCallback(
    async (
      body?: Pick<
        GetSettingsV6EngineAndPlanCheckApiArg,
        'testThemeId' | 'themeType'
      >
    ) => {
      setEngineAndPlanCheckIsFetching(true);
      try {
        const response = await fetch(
          `${settingsApiURL}/engineAndPlanCheck${
            body?.themeType ? `?themeType=${body?.themeType}` : ''
          }${body?.testThemeId ? `&testThemeId=${body?.testThemeId}` : ''}`,
          {
            method: 'GET',
            headers: {
              'X-LimoniApps-AppName': APP_NAME,
              'X-LimoniApps-AppSecret': APP_PASSWORD,
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEngineAndPlanCheckIsFetching(false);
        const data: PromotionEngineAndPlanCheckResponseDto =
          await response.json();
        setEngineAndPlanCheckData(data);
        return data;
      } catch (error) {
        setEngineAndPlanCheckIsFetching(false);
      }
    },
    []
  );

  return {
    fetchEngineAndPlanCheck,
    engineAndPlanCheckIsFetching,
    engineAndPlanCheckData,
  };
};

export default useFetchEngineAndPlanCheck;
