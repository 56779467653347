import React, { useEffect, useMemo } from 'react';
import { Button, LegacyCard, Banner, Icon, IconSource } from '@shopify/polaris';
import { AlertCircleIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import { OfferSuggestedRuleDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { RuleComponent } from './components/RuleComponent/RuleComponent';
import { Element } from 'react-scroll';
import './RulesSection.scss';
import { OfferTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';

type RulesSectionProps = {
  rules?: OfferSuggestedRuleDto[];
  aiSuggestionsEnabled?: boolean;
  aiSuggestionsRuleUpdate(value: boolean): void;
  aiSuggestionsId?: string;
  addRuleAction: () => void;
  deleteRuleAction(id: string): void;
  duplicateRuleAction(id: string): void;
  updateRuleAction(id: string, body: OfferSuggestedRuleDto): void;
  onFormValidityChange(formIsValid: boolean): void;
  offerType: OfferTypeDtoEnum;
};

export const RulesSection: React.FC<RulesSectionProps> = (props) => {
  const {
    offerType,
    rules,
    aiSuggestionsEnabled,
    aiSuggestionsRuleUpdate,
    onFormValidityChange,
    addRuleAction,
    deleteRuleAction,
    duplicateRuleAction,
    updateRuleAction,
    aiSuggestionsId,
  } = props;

  const [i18n] = useI18n();
  // const icon = useIcon(plusIconLookup);

  const isAtLeastOneEnabled: boolean = useMemo(
    () => rules?.some((rule) => rule.enabled) || !!aiSuggestionsEnabled,
    [aiSuggestionsEnabled, rules]
  );

  useEffect(() => {
    onFormValidityChange(isAtLeastOneEnabled);
  }, [isAtLeastOneEnabled]);

  return (
    <LegacyCard>
      <div className='Rules'>
        <LegacyCard.Section>
          <h4 className='TileTitle'>{i18n.translate('Rules.Title')}</h4>
          <span className='TileDescription'>
            {i18n.translate('Rules.Subtitle')}
          </span>
        </LegacyCard.Section>
        <LegacyCard.Section>
          <div className='rulesWrapper'>
            {aiSuggestionsId && (
              <RuleComponent
                aiSuggestionsId={aiSuggestionsId}
                aiSuggestionsEnabled={aiSuggestionsEnabled}
                aiSuggestionsRuleUpdate={aiSuggestionsRuleUpdate}
                onFormValidityChange={onFormValidityChange}
              />
            )}
            {rules?.map((rule, idx, arr) => (
              <Element
                key={rule.id}
                name={rule.id as string}
                className='RuleComponent'
              >
                <RuleComponent
                  limitExceeded={arr.length >= 10}
                  offerType={offerType}
                  idx={idx}
                  rule={rule}
                  onlyOneRule={
                    offerType === OfferTypeDtoEnum.ORDER_BUMP &&
                    arr.length === 1
                  }
                  deleteRuleAction={deleteRuleAction}
                  duplicateRuleAction={duplicateRuleAction}
                  updateRuleAction={updateRuleAction}
                  onFormValidityChange={onFormValidityChange}
                />
              </Element>
            ))}
          </div>
          {!isAtLeastOneEnabled && (
            <div className='errorBoxWrapper'>
              <p className='errorBox'>
                <Icon source={AlertCircleIcon as IconSource} tone='critical' />
                {i18n.translate('Rules.EnableAtLeastOne')}
              </p>
            </div>
          )}
          {rules && rules.length >= 10 ? (
            <Banner tone='info'>
              <p className='header'>{i18n.translate('Rules.MaxRules')}</p>
              <p className='body'>
                {i18n.translate('Rules.MaxRulesSuggestion')}
              </p>
            </Banner>
          ) : (
            <LegacyCard.Subsection>
              <Button
                //FIX FONTAWESOME
                // icon={icon}
                onClick={addRuleAction}
              >
                {i18n.translate('Rules.AddRuleButton')}
              </Button>
            </LegacyCard.Subsection>
          )}
        </LegacyCard.Section>
      </div>
    </LegacyCard>
  );
};
