import React, { useCallback, useMemo } from 'react';
import {
  BlockStack,
  Box,
  InlineStack,
  Link,
  Modal,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  PromotionFetchTypeEnum,
  useConfigurePromotions,
} from 'features/promotions/hooks/useConfigurePromotion';
import { ClipboardIcon } from '@shopify/polaris-icons';
import { CopyIcon } from 'core/components';
import { useAppDispatch } from 'core/hooks';
import { setToastMessages } from 'core/store/offersWizardSlice';
import { ThemeTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';

type PreviewPromotionModalProps = {
  type: ThemeTypeDtoEnum;
  onClose: () => void;
};
export const PreviewPromotionModal: React.FC<PreviewPromotionModalProps> = ({
  type,
  onClose,
}) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();

  const {
    promotionTestLinkData,
    promotionTestLinkIsFetching,
    promotionTestLinkError,
    refetchpromotionTestLink,
  } = useConfigurePromotions(PromotionFetchTypeEnum.PROMOTION_TESTING);

  const onCopyLink = useCallback((value?: string) => {
    navigator.clipboard.writeText(`${value}`);
    dispatch(
      setToastMessages({
        error: false,
        message: 'CopiedToClipboard',
      })
    );
  }, []);

  const secondaryActionVariants = useMemo(() => {
    switch (true) {
      case !promotionTestLinkError:
        return {
          content: i18n.translate('CopyLink'),
          disabled: promotionTestLinkIsFetching,
          onAction: () => onCopyLink(promotionTestLinkData?.link),
        };
      default:
        return {
          content: i18n.translate('Cancel'),
          onAction: onClose,
        };
    }
  }, [promotionTestLinkIsFetching]);

  const primaryActionVariants = useMemo(() => {
    switch (true) {
      case promotionTestLinkData && !promotionTestLinkError:
        return {
          content: i18n.translate('PreviewPromotion'),
          disabled: promotionTestLinkIsFetching,
          onAction: () => {
            window.open(promotionTestLinkData?.link);
            onClose();
          },
        };
      case !!promotionTestLinkError:
        return {
          content: i18n.translate('CreatePreviewLink'),
          disabled: promotionTestLinkIsFetching,
          onAction: () => refetchpromotionTestLink(),
        };
    }
  }, [promotionTestLinkIsFetching]);

  const testContentVariants = useMemo(() => {
    switch (true) {
      case promotionTestLinkIsFetching:
        return <Text as='p'>{i18n.translate('BuildingLink')}</Text>;
      case !!promotionTestLinkError:
        return <Text as='p'>{i18n.translate('TryAgainLater')}</Text>;
      case promotionTestLinkData && !promotionTestLinkIsFetching:
        return (
          <BlockStack gap='400'>
            <Text as='p'>{i18n.translate('YourLinkReady')}</Text>
            <Box
              background='bg-surface-secondary'
              borderColor='border-brand'
              borderWidth='025'
              borderRadius='200'
              padding='400'
            >
              <InlineStack
                wrap={false}
                gap='300'
                align='space-between'
                blockAlign='center'
              >
                <Text as='p' truncate>
                  {promotionTestLinkData?.link}
                </Text>
                <CopyIcon
                  icon={ClipboardIcon}
                  copyText={promotionTestLinkData?.link}
                  tooltip={i18n.translate('CopyLink')}
                />
              </InlineStack>
            </Box>
            <Text as='p'>
              {i18n.translate('LinkIsActive', {
                thisArticle: <Link>{i18n.translate('ThisArticle')}</Link>,
                halfHour: (
                  <Text as='span' fontWeight='medium'>
                    {i18n.translate('HalfHour')}
                  </Text>
                ),
              })}
            </Text>
          </BlockStack>
        );
    }
  }, [
    promotionTestLinkError,
    promotionTestLinkData,
    promotionTestLinkIsFetching,
  ]);

  return (
    <Modal
      title={i18n.translate('PreviewPromotion')}
      open={!!type}
      primaryAction={primaryActionVariants}
      secondaryActions={[secondaryActionVariants]}
      onClose={onClose}
    >
      <Modal.Section>{testContentVariants}</Modal.Section>
    </Modal>
  );
};
