import React, { useCallback, useMemo, useState } from 'react';
import './SettingsNavbar.scss';
import {
  Bleed,
  BlockStack,
  Box,
  Card,
  InlineStack,
  Text,
} from '@shopify/polaris';
import Logo from './assets/Avatar.svg';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useI18n } from '@shopify/react-i18n';
import { useAppDispatch, useAppSelector, useIsDebugOrLocal } from 'core/hooks';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import { SettingsConfirmationModal } from '../SettingsConfirmationModal/SettingsConfirmationModal';
import { NavPaths } from 'core/enums/NavPathsEnum';
import { getSettingsNavbarIcon, settingNavbarPathes } from './utils/utils';
import classNames from 'classnames';

export const SettingsNavbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [i18n] = useI18n();
  const { isUnsavedChanges } = useAppSelector((state) => state.settings);
  const isDebugOrLocal = useIsDebugOrLocal();
  const [pendingTabChange, setPendingTabChange] = useState<{
    tabPath: string;
    firstSubTabPath?: string;
  } | null>(null);
  const [modalActive, setModalActive] = useState<boolean>(false);

  const handleTabChange = useCallback(
    (tabPath: string, firstSubTabPath?: string) => {
      dispatch(setIsUnsavedChanges(false));
      searchParams.set('path', tabPath);
      if (firstSubTabPath) {
        searchParams.set('subPath', firstSubTabPath);
      } else {
        searchParams.delete('subPath');
      }
      setSearchParams(searchParams);
    },
    [searchParams, dispatch, setSearchParams]
  );

  const handleTabClick = useCallback(
    async (tabPath: string, firstSubTabPath?: string) => {
      if (!isDebugOrLocal && isUnsavedChanges) {
        if (location.pathname.includes(`/${NavPaths.Settings}`)) {
          await shopify.saveBar.leaveConfirmation();
          handleTabChange(tabPath, firstSubTabPath);
        } else {
          setPendingTabChange({ tabPath, firstSubTabPath });
          setModalActive(true);
        }
      } else {
        handleTabChange(tabPath, firstSubTabPath);
      }
    },
    [isUnsavedChanges, isDebugOrLocal, location]
  );

  const handleModalClose = useCallback(() => {
    setModalActive(false);
    setPendingTabChange(null);
  }, []);

  const handleLeavePage = useCallback(() => {
    if (pendingTabChange) {
      handleTabChange(
        pendingTabChange.tabPath,
        pendingTabChange.firstSubTabPath
      );
    }
    setModalActive(false);
    setPendingTabChange(null);
  }, [pendingTabChange]);

  const shopSettingsTabs = useMemo(() => {
    const currentPath = searchParams.get('path');
    return settingNavbarPathes.map((path) => ({
      path,
      label: i18n.translate(path),
      accessibilityLabel: path,
      icon: getSettingsNavbarIcon(path, currentPath !== path),
      onClick: () => handleTabClick(path),
      selected: currentPath === path,
    }));
  }, [searchParams, handleTabClick, i18n]);

  return (
    <>
      <div className='SettingsNavBar'>
        <div className='NavigationCard'>
          <Card>
            <BlockStack gap='300'>
              <Bleed marginBlockStart='400' marginInline='400'>
                <Box
                  background='bg-surface-secondary'
                  paddingInline='300'
                  paddingBlock='400'
                  borderColor='border'
                  borderBlockEndWidth='025'
                >
                  <InlineStack blockAlign='center' gap='200'>
                    <img src={Logo} alt='discount-ninja logo' />
                    <Text variant='headingMd' as='h6'>
                      {i18n.translate(`LogoTitle`)}
                    </Text>
                  </InlineStack>
                </Box>
              </Bleed>
              <BlockStack gap='100'>
                {shopSettingsTabs.map((tab) => (
                  <div
                    key={tab.path}
                    className={classNames('NavigationTab', {
                      isActive: tab.selected,
                    })}
                    onClick={() => tab.onClick()}
                  >
                    <InlineStack gap='200' wrap={false}>
                      <div style={{ maxHeight: 20, maxWidth: 20 }}>
                        {tab.icon}
                      </div>
                      <Text as='p' fontWeight='medium'>
                        {tab.label}
                      </Text>
                    </InlineStack>
                  </div>
                ))}
              </BlockStack>
            </BlockStack>
          </Card>
        </div>
      </div>
      <SettingsConfirmationModal
        modalActive={modalActive}
        handleModalClose={handleModalClose}
        handleLeavePage={handleLeavePage}
      />
    </>
  );
};
