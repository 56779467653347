import React from 'react';
import {
  SimplifiedTextEntries,
  TextStyle,
  WidgetOptionHeader,
} from '../../../../../../../components';
import { NinjaCartEmptyStateTextPresetDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';

type Preset_NDC_EmptyTextProps = {
  text: NinjaCartEmptyStateTextPresetDto;
  defaultText: NinjaCartEmptyStateTextPresetDto;
  shopDefaultText: NinjaCartEmptyStateTextPresetDto;
  defaultLanguage: string;
  setText: (data: NinjaCartEmptyStateTextPresetDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const Preset_NDC_EmptyText: React.FC<Preset_NDC_EmptyTextProps> = ({
  text,
  defaultText,
  shopDefaultText,
  defaultLanguage,
  setText,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();
  return (
    <div>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setText(defaultText)}
        onResetToSystem={() => setText(shopDefaultText)}
        name={WidgetListOptionsDtoEnum.TEXT}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <SimplifiedTextEntries
          message={text.headline?.message || {}}
          title={i18n.translate('Headline')}
          defaultLanguage={defaultLanguage}
          setMessage={(data) =>
            setText({
              ...text,
              headline: {
                ...text.headline,
                message: data,
              },
            })
          }
          onSystemReset={() =>
            setText({ ...text, headline: shopDefaultText.headline })
          }
        />
        <TextStyle
          textStyle={text.headline?.style || {}}
          setTextStyle={(data) =>
            setText({
              ...text,
              headline: {
                ...text.headline,
                style: data,
              },
            })
          }
          hideHeader
        />
      </div>
      <div className='RightSideSection'>
        <SimplifiedTextEntries
          message={text.paragraph?.message || {}}
          title={i18n.translate('Paragraph')}
          defaultLanguage={defaultLanguage}
          setMessage={(data) =>
            setText({
              ...text,
              paragraph: {
                ...text.paragraph,
                message: data,
              },
            })
          }
          onSystemReset={() =>
            setText({ ...text, paragraph: shopDefaultText.paragraph })
          }
        />
        <TextStyle
          textStyle={text.paragraph?.style || {}}
          setTextStyle={(data) =>
            setText({
              ...text,
              paragraph: {
                ...text.paragraph,
                style: data,
              },
            })
          }
          hideHeader
        />
      </div>
    </div>
  );
};

export default Preset_NDC_EmptyText;
