import React, { useCallback, useMemo, useState } from 'react';
import {
  ActionList,
  Box,
  Button,
  Card,
  Icon,
  InlineStack,
  Popover,
  Text,
  Bleed,
  Pagination,
  BlockStack,
  Link,
} from '@shopify/polaris';
import { Element } from 'react-scroll';
import {
  MenuHorizontalIcon,
  DuplicateIcon,
  EditIcon,
  DeleteIcon,
  PlusIcon,
  AlertTriangleIcon,
} from '@shopify/polaris-icons';
import {
  GetPromotionOverviewOffersResponseDto,
  OfferTypeDto,
  PromotionTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { OfferTemplatesList } from 'features/promotions/components/CreatePromotionModal/consts/OfferTemplatesList';
import { useI18n } from '@shopify/react-i18n';
import { PromotionTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { useNavigate } from 'react-router-dom';
import './OverviewOfferList.scss';
import { useConfigureOffers } from 'features/promotions/hooks/useConfigureOffers';
import { Loader } from 'core/components';
import {
  SelectOfferModalTypeEnum,
  SelectOfferPage,
} from 'pages/SelectOfferPage';
import classNames from 'classnames';
import { PromotionPageSubPathsEnums } from 'pages/enums/PagesEnums';
import { NavPaths } from 'core/enums/NavPathsEnum';
import { isEmpty } from 'lodash';
import { OverviewOfferListSkeleton } from './components/OverviewOfferListSkeleton';

type OverviewOfferListProps = {
  offerList: GetPromotionOverviewOffersResponseDto;
  promotionType?: PromotionTypeDto;
  offerPage: number;
  offerListIsFetching: boolean;
  isPromotionEditable?: boolean;
  shopifyDiscountCodeMissing?: boolean;
  promotionId: string;
  setOfferPage: (value: number) => void;
  refetchOfferList: () => void;
  refetchPromotionOverview?: () => void;
};

export const OverviewOfferList: React.FC<OverviewOfferListProps> = ({
  offerList,
  promotionType,
  offerPage,
  offerListIsFetching,
  isPromotionEditable,
  shopifyDiscountCodeMissing,
  promotionId,
  setOfferPage,
  refetchOfferList,
  refetchPromotionOverview,
}) => {
  const [i18n] = useI18n();
  const navigate = useNavigate();

  const {
    deleteOfferIsLoading,
    duplicateOfferIsLoading,
    deleteOffer,
    duplicateOffer,
  } = useConfigureOffers();

  const [activePopover, setActivePopover] = useState<string>('');
  const [addOfferModal, setAddOfferModal] = useState<boolean>(false);

  const isShopifyDiscountCode = useMemo(
    () => promotionType === PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE,
    [promotionType]
  );

  const isDataLoading = useMemo(
    () =>
      deleteOfferIsLoading || duplicateOfferIsLoading || offerListIsFetching,
    [deleteOfferIsLoading, duplicateOfferIsLoading, offerListIsFetching]
  );

  const isAddOfferDisabled = useMemo(
    () =>
      (isShopifyDiscountCode && !!offerList.items?.length) ||
      !isPromotionEditable,
    [isShopifyDiscountCode, offerList.items, isPromotionEditable]
  );

  const hasNextPage = useMemo(
    () =>
      offerList?.totalItems && offerPage
        ? offerList.totalItems / offerPage > 5
        : false,
    [offerList?.totalItems, offerPage]
  );
  const showSkeleton = useMemo(
    () => (offerListIsFetching || isEmpty(offerList)) && !promotionType,
    [promotionType, offerList, offerListIsFetching]
  );

  const getOfferIcon = useCallback((offerType: OfferTypeDto) => {
    return OfferTemplatesList.find((offer) => offer.type === offerType)?.icon;
  }, []);

  const toggleSelectOfferModal = useCallback(() => {
    setAddOfferModal(!addOfferModal);
  }, [addOfferModal]);

  const onEditOffer = useCallback(
    (offerId: string) => {
      navigate(
        `/${NavPaths.Promotions}/${promotionId}/${PromotionPageSubPathsEnums.CreateOfferTemplate}/${offerId}`
      );
    },
    [promotionId]
  );

  const onDuplicateOffer = useCallback(
    (offerId: string) => {
      if (promotionId) {
        setActivePopover('');
        duplicateOffer(promotionId, offerId, () => {
          refetchOfferList();
          refetchPromotionOverview?.();
        });
      }
    },
    [promotionId]
  );
  const onDeleteOffer = useCallback(
    (offerId: string) => {
      if (promotionId) {
        setActivePopover('');
        deleteOffer(promotionId, offerId, () => {
          if (offerList.items?.length === 1 && offerPage !== 1) {
            setOfferPage(offerPage - 1);
          }
          refetchOfferList();
          refetchPromotionOverview?.();
        });
      }
    },
    [promotionId, offerList.items?.length, offerPage]
  );
  const onOpenPopover = useCallback((e: any, offerId: string) => {
    e.stopPropagation();
    setActivePopover(offerId);
  }, []);

  const onNextPage = useCallback(() => {
    setOfferPage(offerPage + 1);
  }, [offerPage]);

  const onPrevPage = useCallback(() => {
    setOfferPage(offerPage === 1 ? 1 : offerPage - 1);
  }, [offerPage]);

  return (
    <div onClick={(e) => e.stopPropagation()} className='OverviewOfferList'>
      <Card>
        <Bleed marginBlockEnd='400' marginInline='400'>
          <Box
            paddingBlockEnd='400'
            paddingInline='400'
            borderBlockEndWidth='025'
            borderColor='border-brand'
          >
            <InlineStack wrap={false} align='space-between' blockAlign='center'>
              <Text as='h2' fontWeight='semibold'>
                {i18n.translate('Offers')}
              </Text>
              <InlineStack gap='200' blockAlign='center'>
                {(deleteOfferIsLoading || duplicateOfferIsLoading) && (
                  <div style={{ maxHeight: 20, maxWidth: 20 }}>
                    <Loader size='small' />
                  </div>
                )}
                {!isShopifyDiscountCode && promotionType && (
                  <Button
                    onClick={toggleSelectOfferModal}
                    disabled={isAddOfferDisabled}
                    variant='plain'
                    id='AddOfferPlainButton'
                  >
                    {i18n.translate('AddOffer')}
                  </Button>
                )}
              </InlineStack>
            </InlineStack>
          </Box>
          {showSkeleton ? (
            <OverviewOfferListSkeleton />
          ) : (
            <Element name='OfferList'>
              {offerList.totalItems ? (
                offerList?.items?.map((offer) => (
                  <div
                    key={offer.id}
                    onClick={() =>
                      isDataLoading ? null : onEditOffer(offer.id as string)
                    }
                    className={classNames('OfferListItem', {
                      IsWarning: shopifyDiscountCodeMissing,
                    })}
                  >
                    <Box
                      padding='400'
                      borderBlockEndWidth='025'
                      borderColor='border-brand'
                    >
                      <InlineStack
                        wrap={false}
                        align='space-between'
                        blockAlign='center'
                        gap='500'
                      >
                        <Box width='75%'>
                          <InlineStack align='start' wrap={false} gap='200'>
                            <Box>
                              <Icon
                                tone='base'
                                source={
                                  shopifyDiscountCodeMissing
                                    ? AlertTriangleIcon
                                    : getOfferIcon(offer.type as OfferTypeDto)
                                }
                              />
                            </Box>
                            <Text as='p'>{offer.title}</Text>
                          </InlineStack>
                        </Box>
                        <InlineStack wrap={false} gap='300' blockAlign='center'>
                          <Box minWidth='56px'>
                            <Text as='p' tone='subdued' alignment='start'>
                              {offer.token}
                            </Text>
                          </Box>
                          <Box minWidth='115px'>
                            <Text as='p' tone='subdued' alignment='start'>
                              {i18n.translate(`${offer.combinationType}`)}
                            </Text>
                          </Box>
                          {promotionType && (
                            <Popover
                              active={offer.id === activePopover}
                              activator={
                                <div
                                  onClick={(e) =>
                                    onOpenPopover(e, offer.id || '')
                                  }
                                  style={{ height: 20 }}
                                >
                                  <Button
                                    icon={MenuHorizontalIcon}
                                    disabled={!isPromotionEditable}
                                    variant='tertiary'
                                  />
                                </div>
                              }
                              autofocusTarget='first-node'
                              onClose={() => setActivePopover('')}
                            >
                              <div onClick={(e) => e.stopPropagation()}>
                                <ActionList
                                  actionRole='menuitem'
                                  items={[
                                    {
                                      external: true,
                                      content: i18n.translate(`Edit`),
                                      id: 'PromotionOverviewEditOfferButton',
                                      icon: EditIcon,
                                      disabled:
                                        isDataLoading || !isPromotionEditable,
                                      onAction: () =>
                                        onEditOffer(offer.id as string),
                                    },
                                    {
                                      content: i18n.translate(`Duplicate`),
                                      id: 'PromotionOverviewDuplicateOfferButton',
                                      icon: DuplicateIcon,
                                      disabled:
                                        isDataLoading || !isPromotionEditable,
                                      onAction: () =>
                                        onDuplicateOffer(offer.id as string),
                                    },
                                    {
                                      content: i18n.translate(`Delete`),
                                      icon: DeleteIcon,
                                      id: 'PromotionOverviewDeleteOfferButton',
                                      disabled:
                                        isDataLoading || !isPromotionEditable,
                                      destructive: true,
                                      onAction: () =>
                                        onDeleteOffer(offer.id as string),
                                    },
                                  ].filter((button) =>
                                    isShopifyDiscountCode
                                      ? button.id !==
                                        'PromotionOverviewDuplicateOfferButton'
                                      : button
                                  )}
                                />
                              </div>
                            </Popover>
                          )}
                        </InlineStack>
                      </InlineStack>
                    </Box>
                  </div>
                ))
              ) : (
                <Box paddingInline='300' paddingBlock='800'>
                  <BlockStack inlineAlign='center'>
                    <Box width='70%'>
                      <BlockStack gap='400' inlineAlign='center'>
                        <BlockStack gap='200'>
                          <Text
                            alignment='center'
                            fontWeight='semibold'
                            variant='bodyLg'
                            as='p'
                          >
                            {i18n.translate(
                              `EmptyStateTitle${
                                isShopifyDiscountCode
                                  ? '_ShopifyDiscountCode'
                                  : ''
                              }`
                            )}
                          </Text>
                          <Text alignment='center' tone='subdued' as='p'>
                            {i18n.translate(
                              `EmptyStateSubtitle${
                                isShopifyDiscountCode
                                  ? '_ShopifyDiscountCode'
                                  : ''
                              }`,
                              {
                                learnMore: (
                                  <Link monochrome>
                                    {i18n.translate('LearnMore')}
                                  </Link>
                                ),
                              }
                            )}
                          </Text>
                        </BlockStack>
                        <Button
                          variant='primary'
                          onClick={toggleSelectOfferModal}
                          icon={PlusIcon}
                          disabled={isAddOfferDisabled}
                        >
                          {i18n.translate(
                            isShopifyDiscountCode ? 'AddOffer' : 'AddFirstOffer'
                          )}
                        </Button>
                      </BlockStack>
                    </Box>
                  </BlockStack>
                </Box>
              )}
            </Element>
          )}

          {(offerList.totalItems as number) > 5 && (
            <Box
              background='bg-surface-secondary'
              paddingInline='300'
              paddingBlock='150'
            >
              <InlineStack align='space-between' blockAlign='center'>
                <Text as='p' tone='subdued'>
                  {i18n.translate('ShowXOfY', {
                    from: (offerPage - 1) * 5 + 1,
                    to: Math.min(offerPage * 5, offerList.totalItems as number),
                    total: offerList.totalItems,
                  })}
                </Text>
                <Pagination
                  onPrevious={onPrevPage}
                  onNext={onNextPage}
                  hasNext={hasNextPage}
                  hasPrevious={(offerPage as number) > 1}
                />
              </InlineStack>
            </Box>
          )}
        </Bleed>
      </Card>
      {addOfferModal && (
        <SelectOfferPage
          isOpen={addOfferModal}
          onClose={toggleSelectOfferModal}
          modalType={SelectOfferModalTypeEnum.CREATE_NEW}
        />
      )}
    </div>
  );
};
