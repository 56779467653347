import React, { useMemo } from 'react';
import { BlockStack, Card, ChoiceList, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { DiscountNinjaPromoCodesMultipleSupportTypeDto } from 'core/api/adminSettings/adminSettingsApi';
import { DiscountNinjaPromoCodesMultipleSupportTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
type MultipleCodeSupportProps = {
  allowType?: DiscountNinjaPromoCodesMultipleSupportTypeDto | null;
  setAllowType: (type: DiscountNinjaPromoCodesMultipleSupportTypeDto) => void;
};
export const MultipleCodeSupport: React.FC<MultipleCodeSupportProps> = ({
  allowType,
  setAllowType,
}) => {
  const [i18n] = useI18n();
  const codeOptions = useMemo(
    () =>
      Object.values(DiscountNinjaPromoCodesMultipleSupportTypeDtoEnum).map(
        (value) => ({
          label: i18n.translate(value),
          value,
        })
      ),
    [i18n]
  );

  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='p' fontWeight='semibold'>
            {i18n.translate('Title')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('Subtitle')}
          </Text>
        </BlockStack>
        <ChoiceList
          title=''
          choices={codeOptions}
          selected={[allowType as string]}
          onChange={(types) =>
            setAllowType(
              types[0] as DiscountNinjaPromoCodesMultipleSupportTypeDtoEnum
            )
          }
        />
      </BlockStack>
    </Card>
  );
};
