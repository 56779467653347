import React, { useCallback, useMemo } from 'react';
import {
  NinjaCartDefaultStateFooterCtaButtonsLevelPresetDto,
  NinjaCartDefaultStateFooterCtaButtonsPresetDto,
  NinjaCartDefaultStateFooterCtaButtonsPresetDtoRead,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';
import { SelectedOptionPath } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightSideBarWrapper';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import Preset_NDC_CTAGeneral from './components/Preset_NDC_CTAGeneral/Preset_NDC_CTAGeneral';
import Preset_NDC_CTAButton from './components/Preset_NDC_CTAButton/Preset_NDC_CTAButton';

type Preset_NDC_CTAProps = {
  ctaButtons: NinjaCartDefaultStateFooterCtaButtonsPresetDtoRead;
  defaultCTAButtons: NinjaCartDefaultStateFooterCtaButtonsPresetDtoRead;
  shopDefaultCTAButtons: NinjaCartDefaultStateFooterCtaButtonsPresetDto;
  selectedOptionPath: SelectedOptionPath;
  defaultLanguage: string;
  setCTAButtons: (
    data: NinjaCartDefaultStateFooterCtaButtonsPresetDtoRead
  ) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const Preset_NDC_CTA: React.FC<Preset_NDC_CTAProps> = ({
  ctaButtons,
  defaultCTAButtons,
  shopDefaultCTAButtons,
  selectedOptionPath,
  defaultLanguage,
  setCTAButtons,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();

  const handleUpdateOptions = useCallback(
    (
      field: keyof NinjaCartDefaultStateFooterCtaButtonsLevelPresetDto,
      data: NinjaCartDefaultStateFooterCtaButtonsLevelPresetDto[keyof NinjaCartDefaultStateFooterCtaButtonsLevelPresetDto]
    ) => {
      setCTAButtons({
        ...ctaButtons,
        options: {
          ...ctaButtons.options,
          [field]: data,
        },
      });
    },
    [ctaButtons, setCTAButtons]
  );

  const ctaButtonsOptions = useMemo(() => {
    const selectedOption = getLastSelectedOption(selectedOptionPath);
    const buttonType =
      selectedOption?.name === WidgetListOptionsDtoEnum.CTA_BUTTON_1
        ? WidgetListOptionsDtoEnum.CTA_BUTTON_1
        : WidgetListOptionsDtoEnum.CTA_BUTTON_2;
    switch (true) {
      case selectedOption?.name === WidgetListOptionsDtoEnum.CTA_BUTTONS:
        return (
          <Preset_NDC_CTAGeneral
            general={ctaButtons.general || {}}
            defaultGeneral={defaultCTAButtons.general || {}}
            shopDefaultGeneral={shopDefaultCTAButtons.general || {}}
            setGeneral={(data) =>
              setCTAButtons({ ...ctaButtons, general: data })
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
            onShowHide={
              ctaButtons.canBeHidden
                ? () =>
                    setCTAButtons({
                      ...ctaButtons,
                      isHidden: !ctaButtons.isHidden,
                    })
                : undefined
            }
          />
        );
      case selectedOption?.name === buttonType:
        return (
          <Preset_NDC_CTAButton
            ctaButton={ctaButtons.options?.[buttonType] || {}}
            defaultCTAButton={defaultCTAButtons.options?.[buttonType] || {}}
            shopDefaultCTAButton={
              shopDefaultCTAButtons.options?.[buttonType] || {}
            }
            defaultLanguage={defaultLanguage}
            type={buttonType}
            setCTAButton={(data) => handleUpdateOptions(buttonType, data)}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
    }
  }, [
    selectedOptionPath,
    defaultCTAButtons,
    shopDefaultCTAButtons,
    ctaButtons,
    handleLeftMenuSelection,
  ]);

  return <>{ctaButtonsOptions}</>;
};

export default Preset_NDC_CTA;
