import React, { useMemo } from 'react';
import {
  Badge,
  BlockStack,
  Card,
  ChoiceList,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  DiscountNinjaPromoCodesExpirationTypeDto,
  DiscountNinjaPromoCodesRememberTypeDto,
  DiscountNinjaPromoCodesSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  DiscountNinjaPromoCodesExpirationTypeDtoEnum,
  DiscountNinjaPromoCodesRememberTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';

type PromocodeActivationProps = {
  rememberType?: DiscountNinjaPromoCodesRememberTypeDto | null;
  expiration?: DiscountNinjaPromoCodesExpirationTypeDto | null;
  handleUpdateDNPromocodes: (
    field: keyof DiscountNinjaPromoCodesSettingsDto,
    data: DiscountNinjaPromoCodesSettingsDto[keyof DiscountNinjaPromoCodesSettingsDto]
  ) => void;
};
export const PromocodeActivation: React.FC<PromocodeActivationProps> = ({
  rememberType,
  expiration,
  handleUpdateDNPromocodes,
}) => {
  const [i18n] = useI18n();

  const rememberTypeOptions = useMemo(
    () =>
      Object.values(DiscountNinjaPromoCodesRememberTypeDtoEnum).map(
        (value) => ({
          label: (
            <InlineStack gap='200'>
              <Text as='p'>{i18n.translate(value)}</Text>
              {value === DiscountNinjaPromoCodesRememberTypeDtoEnum.ANY_TAB && (
                <Badge>{i18n.translate('Recommended')}</Badge>
              )}
            </InlineStack>
          ),
          helpText: i18n.translate(`${value}_HELP`),
          value,
        })
      ),
    [i18n]
  );

  const expirationOptions = useMemo(
    () =>
      Object.values(DiscountNinjaPromoCodesExpirationTypeDtoEnum).map(
        (value) => ({
          label: i18n.translate(value),
          value,
        })
      ),
    [i18n]
  );

  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='p' fontWeight='semibold'>
            {i18n.translate('Title')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('Subtitle')}
          </Text>
        </BlockStack>
        <ChoiceList
          choices={rememberTypeOptions}
          selected={[rememberType as string]}
          title=''
          onChange={(value) =>
            handleUpdateDNPromocodes(
              'rememberType',
              value[0] as DiscountNinjaPromoCodesRememberTypeDtoEnum
            )
          }
        />
        {rememberType ===
          DiscountNinjaPromoCodesRememberTypeDtoEnum.ANY_TAB && (
          <ChoiceList
            choices={expirationOptions}
            selected={[expiration as string]}
            title={i18n.translate('PromocodeExpiration')}
            onChange={(value) =>
              handleUpdateDNPromocodes(
                'expiration',
                value[0] as DiscountNinjaPromoCodesExpirationTypeDtoEnum
              )
            }
          />
        )}
      </BlockStack>
    </Card>
  );
};
