import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  OfferBogoDto,
  OfferBogoSpecificationDto,
  OfferCombinationsDto,
  OfferDiscountLimitDto,
  OfferPrerequisiteEntitledAdvancedSettingsDto,
  OfferPrerequisiteEntitledAppliesToDto,
  OfferPrerequisiteEntitledNameFiltersDto,
  OfferProductLimitDto,
  OptionDtoOfferMinimumSpendDto,
  OptionDtoString,
  ShopifyObjectDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { useOfferFormValidity } from 'features/promotions/hooks/useOfferFormValidity';
import { RegularBogoOfferTabsEnum } from '../../../enums/OfferTypeTabs';
import { isEqual } from 'lodash';
import {
  OfferBogoTypeDtoEnum,
  OfferTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { Combinations } from '../../Combinations/Combinations';
import { MinimumSpend } from '../../MinimumSpend/MinimumSpend';
import { DiscountLimits } from '../../DiscountLimits/DiscountLimits';
import { BlockStack, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { useNavigate } from 'react-router-dom';
import { LearnMoreModal } from '../../LearnMoreModal/LearnMoreModal';
import { OfferTemplate } from '../../OfferTemplate/OfferTemplate';
import { BogoOfferSection } from '../../BogoOfferSection/BogoOfferSection';
import { useAppSelector, useIsDebugOrLocal } from 'core/hooks';
import { OfferParentComponentProps } from '../../../types/OfferTypesFromCatalogTypes';
import { Products } from '../../ProductsNew/Products';
import { OfferTypeSpecialCases } from 'core/enums/GrayBoxEnum';
import { ShopifyCombinations } from '../../ShopifyCombinations/ShopifyCombinations';

export const BogoOffer: React.FC<OfferParentComponentProps> = (props) => {
  const { offerTemplate, handleOfferDataUpdate, savedData, offerType } = props;

  const { validityChangeHandler } = useOfferFormValidity();

  const { currentCombination } = useAppSelector((state) => state.offersWizard);

  const [offerData, setOfferData] = useState<OfferBogoDto | null>(
    offerTemplate?.template as OfferBogoDto
  );

  const [i18n] = useI18n();
  const [configureBogo, setConfigureBogo] = useState<boolean>(false);
  const [configureProducts, setConfigureProducts] = useState<boolean>(false);
  const [showHowModal, setShowHowModal] = useState<boolean>(false);

  const isDebugOrLocal = useIsDebugOrLocal();
  const navigate = useNavigate();

  const toggleShowHowModal = useCallback(() => {
    setShowHowModal((prevState: boolean) => !prevState);
  }, [setShowHowModal]);

  const maxQuantity = useMemo(
    () =>
      offerData?.specification?.type === OfferBogoTypeDtoEnum.CUSTOM
        ? offerData?.specification.customRule?.amountOfProducts || 1
        : 1,
    [offerData?.specification]
  );

  const handleSpecificationDataUpdate = useCallback(
    (specification: OfferBogoSpecificationDto) => {
      setOfferData((prevState: OfferBogoDto | null) => ({
        ...prevState,
        specification: specification,
      }));
    },
    [setOfferData]
  );

  const combinationsUpdate = useCallback(
    (data: OfferCombinationsDto) => {
      setOfferData((prevState: OfferBogoDto | null) => ({
        ...prevState,
        combinations: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const minimumSpendUpdate = useCallback(
    (data: OptionDtoOfferMinimumSpendDto) => {
      setOfferData((prevState: OfferBogoDto | null) => ({
        ...prevState,
        minimumSpend: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const limitUpdateData = useCallback(
    (data: OfferDiscountLimitDto) => {
      setOfferData((prevState: OfferBogoDto | null) => ({
        ...prevState,
        discountLimit: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const appliesToDataUpdate = useCallback(
    (data: OfferPrerequisiteEntitledAppliesToDto) => {
      setOfferData((prevState: OfferBogoDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          appliesTo: data,
        },
      }));
    },
    [setOfferData]
  );

  const exclusionsUpdate = useCallback(
    (data: boolean) => {
      setOfferData((prevState: OfferBogoDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          exclusions: {
            ...prevState?.products?.exclusions,
            collections: {
              ...prevState?.products?.exclusions?.collections,
              enabled: data,
            },
          },
        },
      }));
    },
    [setOfferData]
  );

  const excludedResourcesUpdate = useCallback(
    (data: ShopifyObjectDto[]) => {
      setOfferData((prevState: OfferBogoDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          exclusions: {
            ...prevState?.products?.exclusions,
            collections: {
              ...prevState?.products?.exclusions?.collections,
              value: data,
            },
          },
        },
      }));
    },
    [setOfferData]
  );

  const limitUpdate = useCallback(
    (data: OfferProductLimitDto) => {
      setOfferData((prevState: OfferBogoDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          limit: data,
        },
      }));
    },
    [setOfferData]
  );

  const nameFiltersUpdate = useCallback(
    (
      filterName: keyof OfferPrerequisiteEntitledNameFiltersDto,
      field: keyof OptionDtoString,
      value: string | boolean | null
    ) => {
      setOfferData((prevState: OfferBogoDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          nameFilters: {
            ...prevState?.products?.nameFilters,
            [filterName]: {
              ...prevState?.products?.nameFilters?.[filterName],
              [field]: value,
            },
          },
        },
      }));
    },
    [setOfferData]
  );

  const advancedSettingsUpdate = useCallback(
    (
      field: keyof OfferPrerequisiteEntitledAdvancedSettingsDto,
      value: string
    ) => {
      setOfferData((prevState: OfferBogoDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          advancedSettings: {
            ...prevState?.products?.advancedSettings,
            [field]: value,
          },
        },
      }));
    },
    [setOfferData]
  );

  useEffect(() => {
    if (
      offerTemplate?.template &&
      offerData &&
      !isEqual(offerData, offerTemplate?.template)
    ) {
      handleOfferDataUpdate(offerData);
    }
  }, [offerData, offerTemplate]);

  useEffect(() => {
    currentCombination.currentCombinationType &&
      setOfferData((prevState: OfferBogoDto | null) => ({
        ...prevState,
        combinationType: currentCombination.currentCombinationType,
      }));
  }, [currentCombination.currentCombinationType]);

  return (
    <>
      {configureProducts && (
        <Products
          products={(offerTemplate?.template as OfferBogoDto)?.products}
          specialOfferType={
            offerType === OfferTypeDtoEnum.BOGO_REGULAR
              ? OfferTypeSpecialCases.BogoMixMatch
              : OfferTypeSpecialCases.BogoStrictMatch
          }
          onAppliesToUpdate={appliesToDataUpdate}
          onExclusionsUpdate={exclusionsUpdate}
          onExcludedResourcesUpdate={excludedResourcesUpdate}
          onLimitUpdate={limitUpdate}
          onFormValidityChange={validityChangeHandler(
            RegularBogoOfferTabsEnum.Products
          )}
          onNameFiltersUpdate={nameFiltersUpdate}
          onAdvancedSettingsUpdate={advancedSettingsUpdate}
          savedData={(savedData?.template as OfferBogoDto)?.products}
          setConfigureComponent={setConfigureProducts}
          configureComponent={configureProducts}
        />
      )}
      {configureBogo && (
        <BogoOfferSection
          specification={
            (offerTemplate?.template as OfferBogoDto)?.specification || {}
          }
          onFormValidityChange={validityChangeHandler(
            RegularBogoOfferTabsEnum.Offer
          )}
          onSpecificationUpdate={handleSpecificationDataUpdate}
          savedData={(savedData?.template as OfferBogoDto)?.specification}
          setConfigureComponent={setConfigureBogo}
          configureComponent={configureBogo}
        />
      )}
      {!configureBogo && !configureProducts && (
        <Page
          title={i18n.translate(`ConfigureOffer`)}
          backAction={{
            content: i18n.translate('CreateOffer'),
            onAction: async () => {
              !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
              navigate('../..', { relative: 'path' });
            },
          }}
        >
          <BlockStack gap='600'>
            <OfferTemplate
              type={offerType}
              toggleShowHowModal={toggleShowHowModal}
              discountType={
                (offerTemplate?.template as OfferBogoDto).combinationType
              }
            />
            <BogoOfferSection
              specification={
                (offerTemplate?.template as OfferBogoDto)?.specification || {}
              }
              onFormValidityChange={validityChangeHandler(
                RegularBogoOfferTabsEnum.Offer
              )}
              onSpecificationUpdate={handleSpecificationDataUpdate}
              savedData={(savedData?.template as OfferBogoDto)?.specification}
              setConfigureComponent={setConfigureBogo}
              configureComponent={configureBogo}
            />
            {(offerTemplate?.template as OfferBogoDto)?.products !==
              undefined && (
              <Products
                products={(offerTemplate?.template as OfferBogoDto)?.products}
                specialOfferType={
                  offerType === OfferTypeDtoEnum.BOGO_REGULAR
                    ? OfferTypeSpecialCases.BogoMixMatch
                    : OfferTypeSpecialCases.BogoStrictMatch
                }
                onAppliesToUpdate={appliesToDataUpdate}
                onExclusionsUpdate={exclusionsUpdate}
                onExcludedResourcesUpdate={excludedResourcesUpdate}
                onLimitUpdate={limitUpdate}
                onFormValidityChange={validityChangeHandler(
                  RegularBogoOfferTabsEnum.Products
                )}
                onNameFiltersUpdate={nameFiltersUpdate}
                onAdvancedSettingsUpdate={advancedSettingsUpdate}
                savedData={(savedData?.template as OfferBogoDto)?.products}
                setConfigureComponent={setConfigureProducts}
                configureComponent={configureProducts}
              />
            )}
            <DiscountLimits
              type='Discount'
              isSetBasedDiscount
              discountLimit={
                (offerTemplate?.template as OfferBogoDto)?.discountLimit
              }
              maxQuantity={maxQuantity}
              discountLimitUpdate={limitUpdateData}
              onFormValidityChange={validityChangeHandler(
                RegularBogoOfferTabsEnum.Limit
              )}
            />
            <Combinations onCombinationsChange={combinationsUpdate} />
            <ShopifyCombinations promotionType={offerTemplate.promotionType} />
            {offerTemplate?.minimumSpendSupported && (
              <MinimumSpend
                minimumSpend={
                  (offerTemplate?.template as OfferBogoDto)?.minimumSpend || {}
                }
                minimumSpendUpdate={minimumSpendUpdate}
              />
            )}
            {showHowModal && (
              <LearnMoreModal
                onModalClose={toggleShowHowModal}
                // url='https://youtu.be/9htdypfdlBM'
                title={i18n.translate('Tutorial')}
              />
            )}
          </BlockStack>
        </Page>
      )}
    </>
  );
};
