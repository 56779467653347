import React, { useState } from 'react';
import { NinjaCartPages } from '../../EnableNinjaCartStep';
import {
  Banner,
  BlockStack,
  Checkbox,
  Link,
  Modal,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
type EnableNinjaPageModalProps = {
  page: NinjaCartPages | null;
  isLoading: boolean;
  onClose: () => void;
  onEnable: (page: NinjaCartPages) => void;
};
export const EnableNinjaPageModal: React.FC<EnableNinjaPageModalProps> = ({
  page,
  isLoading,
  onClose,
  onEnable,
}) => {
  const [i18n] = useI18n();
  const [confirmed, setConfirmed] = useState<boolean>(false);
  return (
    <Modal
      open={!!page}
      title={i18n.translate('Title')}
      onClose={onClose}
      primaryAction={{
        content: i18n.translate('Enable'),
        onAction: () => onEnable(page as NinjaCartPages),
        disabled: !confirmed,
        loading: isLoading,
      }}
      secondaryActions={[
        {
          content: i18n.translate('Cancel'),
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <BlockStack gap='400'>
          <Text as='p'>
            {i18n.translate('EnableNinjaCartOn', {
              page: (
                <Text fontWeight='semibold' as='span'>
                  {i18n.translate(`${page}`)}
                </Text>
              ),
            })}
          </Text>
          <Banner tone='warning'>
            {i18n.translate(`${page}_BANNER`, {
              learnMore: <Link>{i18n.translate('LearnMore')}</Link>,
            })}
          </Banner>
          <Checkbox
            label={i18n.translate(`${page}_CHECKBOX`)}
            checked={confirmed}
            onChange={() => setConfirmed(!confirmed)}
            disabled={isLoading}
          />
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
};
