import React from 'react';
import {
  Badge,
  BlockStack,
  Button,
  Card,
  InlineStack,
  MediaCard,
  Text,
  VideoThumbnail,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';

type SDCGeneralProps = {
  isEnabled: boolean;
  setIsEnabled: (value: boolean) => void;
};
export const SDCGeneral: React.FC<SDCGeneralProps> = ({
  isEnabled,
  setIsEnabled,
}) => {
  const [i18n] = useI18n();
  return (
    <BlockStack gap='600'>
      <Card>
        <BlockStack gap='200'>
          <InlineStack align='space-between' blockAlign='center'>
            <InlineStack gap='100'>
              <Text fontWeight='semibold' as='p'>
                {i18n.translate('ShopifyDiscountCodes')}
              </Text>
              <Badge tone={isEnabled ? 'success' : 'new'}>
                {i18n.translate(isEnabled ? 'On' : 'Off')}
              </Badge>
            </InlineStack>
            <Button onClick={() => setIsEnabled(!isEnabled)}>
              {i18n.translate(isEnabled ? 'TurnOff' : 'TurnOn')}
            </Button>
          </InlineStack>
          <Text tone='subdued' as='p'>
            {i18n.translate('ShopifyDiscountCodesSubtitle')}
          </Text>
        </BlockStack>
      </Card>
      <MediaCard
        title={i18n.translate('LearnMoreAbout')}
        primaryAction={{
          content: i18n.translate('LearnMore'),
          onAction: () => null,
        }}
        description={i18n.translate(`LearnMoreAboutDescription`)}
        size='small'
      >
        <VideoThumbnail
          videoLength={80}
          thumbnailUrl='https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850'
          onClick={() =>
            window.open(
              'https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley',
              '_blank'
            )
          }
        />
      </MediaCard>
    </BlockStack>
  );
};
