import React from 'react';
import {
  Badge,
  BlockStack,
  Card,
  Checkbox,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { LimitationsOverrideShopifyDiscountCodesSettingsDto } from 'core/api/adminSettings/adminSettingsApi';

type SDCFilterProps = {
  filters?: LimitationsOverrideShopifyDiscountCodesSettingsDto;
  setFilters: (
    data: LimitationsOverrideShopifyDiscountCodesSettingsDto
  ) => void;
};
export const SDCFilter: React.FC<SDCFilterProps> = ({
  filters,
  setFilters,
}) => {
  const [i18n] = useI18n();
  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='p' fontWeight='semibold'>
            {i18n.translate('Title')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('Subtitle')}
          </Text>
        </BlockStack>

        <Checkbox
          label={
            <InlineStack gap='200'>
              <Text as='p'>{i18n.translate('OverrideCountries')}</Text>
              <Badge tone='warning'>{i18n.translate('NotRecommended')}</Badge>
            </InlineStack>
          }
          helpText={i18n.translate('OverrideCountriesHelp')}
          checked={filters?.allowWithSelectedCountriesFilter}
          onChange={(value) =>
            setFilters({ ...filters, allowWithSelectedCountriesFilter: value })
          }
        />
      </BlockStack>
    </Card>
  );
};
