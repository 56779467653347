import React from 'react';
import { BlockStack, Box, Modal, Text, TextField } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  ClipboardIcon,
  LinkIcon,
  DiscountCodeIcon,
} from '@shopify/polaris-icons';
import {
  PromotionOverviewPromotionCodesDto,
  TriggerDiscountLinkPreviewDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { CopyIcon, NavigationBox } from 'core/components';

type SharePromotionModalProps = {
  isOpen: boolean;
  discountLink?: TriggerDiscountLinkPreviewDto;
  promotionCodes?: PromotionOverviewPromotionCodesDto;
  onClose: () => void;
};
export const SharePromotionModal: React.FC<SharePromotionModalProps> = ({
  isOpen,
  discountLink,
  promotionCodes,
  onClose,
}) => {
  const [i18n] = useI18n();

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={i18n.translate(`SharePromotion`)}
      secondaryActions={[
        {
          content: i18n.translate('Close'),
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <BlockStack gap='400'>
          <Text as='p'>{i18n.translate('Congratulations')}</Text>
          <TextField
            value={discountLink?.display}
            label={i18n.translate('DefaultLink')}
            autoComplete=''
            readOnly
            connectedRight={
              <Box paddingInlineStart='100'>
                <CopyIcon
                  icon={ClipboardIcon}
                  variant='tertiary'
                  copyText={discountLink?.copy}
                  tooltip={i18n.translate('CopyLink')}
                />
              </Box>
            }
          />
          {promotionCodes?.singleCode && (
            <TextField
              value={promotionCodes?.singleCode}
              label={i18n.translate('Promocode')}
              autoComplete=''
              readOnly
              connectedRight={
                <Box paddingInlineStart='100'>
                  <CopyIcon
                    icon={ClipboardIcon}
                    variant='tertiary'
                    copyText={promotionCodes?.singleCode}
                    tooltip={i18n.translate('CopyCode')}
                  />
                </Box>
              }
            />
          )}
          {!!discountLink?.count && discountLink?.count !== 1 && (
            <NavigationBox
              btnContent={i18n.translate('ManageLinks')}
              link={'settings/discount-links'}
              title={i18n.translate('DiscountLinks')}
              icon={LinkIcon}
              tag={i18n.translate('LinksCount', {
                count: discountLink?.count || 1,
              })}
            />
          )}
          {!!promotionCodes?.count && promotionCodes?.count !== 1 && (
            <NavigationBox
              btnContent={i18n.translate('ManagePromocodes')}
              link={'settings/promotion-codes'}
              title={i18n.translate('Promocodes')}
              icon={DiscountCodeIcon}
              tag={i18n.translate('CodesCount', {
                count: promotionCodes?.count || 1,
              })}
            />
          )}
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
};
