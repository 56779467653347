import {
  PageTypeDto,
  TextEntryDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { isEqual } from 'lodash';
import { SelectedOptionPath } from '../components/RightSideBarWrapper/RightSideBarWrapper';
import {
  AnnouncementBarOfferPositionDtoEnum,
  PageTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { SmartTagValueTypeEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import {
  OfferSmartTags,
  SmartTag,
} from 'core/api/adminPromotions/adminPromotionsApi';

import {
  CartIcon,
  StoreIcon,
  BlogIcon,
  CollectionIcon,
  ProductIcon,
  HomeIcon,
  SearchIcon,
  PaintBrushFlatIcon,
  CartSaleIcon,
  CartDiscountIcon,
} from '@shopify/polaris-icons';
import { SmartTagValue } from 'core/store/offersWizardSlice';

export const parseSmartTagCode = (
  inputString: string,
  data?: Record<string, SmartTagValue>
): string => {
  if (!data) return inputString;

  // Regular expressions for {{DATA}} and ##DATA##
  const curlyBracesRegex = /\{{\s*([^}]+)\s*}}/g;
  const hashRegex = /##\s*([^#]+)\s*##/g;

  // Function to replace {{DATA}} with actual data
  const replaceCurlyBraces = (match: string, key: string) => {
    const smartTagValue = data[key]; // trim to handle extra spaces
    return smartTagValue
      ? smartTagValue?.currencyCode
        ? `${(+smartTagValue.keyValue || 0) * 100}`
        : smartTagValue.keyValue
      : match;
  };

  // Function to replace ##DATA## with currencyCode
  const replaceHash = (match: string, key: string) => {
    const smartTagValue = data[key]; // trim to handle extra spaces
    return smartTagValue?.currencyCode || match;
  };

  // Replace placeholders in the input string
  let result = inputString;
  result = result.replace(curlyBracesRegex, replaceCurlyBraces);
  result = result.replace(hashRegex, replaceHash);

  return result;
};

export const getWidgetPageGroupIcon = (page: PageTypeDto) => {
  switch (page) {
    case PageTypeDtoEnum.ALL:
      return StoreIcon;
    case PageTypeDtoEnum.COLLECTION:
      return CollectionIcon;
    case PageTypeDtoEnum.PRODUCT:
      return ProductIcon;
    case PageTypeDtoEnum.DRAWER_CART:
      return CartIcon;
    case PageTypeDtoEnum.CART:
      return CartIcon;
    case PageTypeDtoEnum.CHECKOUT:
      return CartSaleIcon;
    case PageTypeDtoEnum.BLOG_POST:
      return BlogIcon;
    case PageTypeDtoEnum.HOME:
      return HomeIcon;
    case PageTypeDtoEnum.SEARCH:
      return SearchIcon;
    case PageTypeDtoEnum.CUSTOM:
      return PaintBrushFlatIcon;
    case PageTypeDtoEnum.NINJA_CART:
      return CartDiscountIcon;
    default:
      return CartIcon;
  }
};

export const getCurrentMessage = (
  entries?: TextEntryDto[] | null,
  defaultLanguage = 'en',
  smartTagsCodes?: Record<string, SmartTagValue>
) => {
  if (!entries?.length) return '';
  const currentEntry =
    entries.find(
      (item) => item.goal === 'BEFORE' && item.device === 'DESKTOP'
    ) ||
    entries.find((item) => item.goal === 'BEFORE' && item.device === null) ||
    entries.find((item) => item.goal === null && item.device === 'DESKTOP') ||
    entries.find((item) => item.goal === null && item.device === null);
  const message = currentEntry?.text?.find(
    (text) => text.language === defaultLanguage
  )?.text;
  return parseSmartTagCode(message || '', smartTagsCodes);
};

const isObject = (value: any) => {
  return value !== null && typeof value === 'object' && !Array.isArray(value);
};
export const findPresetChanges = (
  current: any,
  original: any,
  isAnnouncementBarSave = true, // Add a new argument with a default value
  isPresetEditor?: boolean
) => {
  if (isEqual(current, original)) {
    return undefined;
  }

  if (
    Array.isArray(current) &&
    Array.isArray(original) &&
    original[0].offerId &&
    current[0].offerId
  ) {
    const changes: any[] = original.map((origItem) => {
      const currItem = current.find(
        (item: any) => item.offerId === origItem.offerId
      );
      const nestedChanges = findPresetChanges(
        currItem,
        origItem,
        isAnnouncementBarSave,
        isPresetEditor
      );
      const isBar =
        isAnnouncementBarSave &&
        Object.keys(AnnouncementBarOfferPositionDtoEnum).includes(
          currItem?.parentKey
        );

      return (
        (nestedChanges || isBar) &&
        currItem && {
          ...nestedChanges,
          parentKey: currItem.parentKey,
          offerId: currItem.offerId,
        }
      );
    });
    return changes.some((value) => value)
      ? changes.filter((item) => item)
      : undefined;
  }

  if (isObject(current) && isObject(original)) {
    const changes: any = {};
    for (const key in current) {
      if (
        key === 'canBeDragged' ||
        key === 'canBeHidden' ||
        key === 'canBeRemoved' ||
        key === 'supportsModules' ||
        key === 'isOpen' ||
        key === 'isHidden' ||
        (isPresetEditor && key === 'slot1') ||
        (isPresetEditor && key === 'slot2') ||
        (isPresetEditor && key === 'slot3')
      ) {
        continue;
      }
      const nestedChanges = findPresetChanges(
        current[key],
        original[key],
        isAnnouncementBarSave,
        isPresetEditor
      );
      if (key === 'enabled' && Object.keys(current).includes('value')) {
        changes.enabled = current.enabled;
      }
      if (nestedChanges !== undefined) {
        changes[key] = nestedChanges;
      }
    }
    return Object.keys(changes).length > 0 ? changes : undefined;
  }
  return current;
};

export const getLastSelectedOption = (
  path: SelectedOptionPath
): SelectedOptionPath => {
  if (!path || !path.selectedOption) {
    return path;
  }
  return getLastSelectedOption(path.selectedOption);
};

export const getKeyByValue = (
  enumObject: any,
  value: string
): string | undefined => {
  return Object.keys(enumObject).find((key) => enumObject[key] === value);
};
export const stringToUpperSnakeCase = (input: string) => {
  let output = '';
  for (let i = 0; i < input.length; i++) {
    if (input[i] === input[i].toUpperCase() && i > 0) {
      output += '_';
    }
    output += input[i].toUpperCase();
  }
  return output;
};

export const findSelectedOptionPath = (
  menu: any,
  parentName?: string
): SelectedOptionPath => {
  for (const item of menu) {
    if (item.isSelected) {
      return {
        name: item.name,
        offerId: item.offerId,
        currentEntries: item.currentEntries,
        isHidden: item.isHidden,
        selectedOption: null,
        parentName,
      };
    } else if (item.options) {
      const selectedPath = findSelectedOptionPath(item.options, item.name);
      if (selectedPath) {
        return {
          name: item.name,
          offerId: item.offerId,
          currentEntries: item.currentEntries,
          isHidden: item.isHidden,
          selectedOption: selectedPath,
          parentName,
        };
      }
    } else if (item.option && item.option.length > 0) {
      const selectedOptionPath = findSelectedOptionPath(item.option, item.name);
      if (selectedOptionPath) {
        return {
          name: item.name,
          offerId: item.offerId,
          currentEntries: item.currentEntries,
          isHidden: item.isHidden,
          selectedOption: selectedOptionPath,
          parentName,
        };
      }
    }
  }
  return null;
};

export const getSelectedOffer = (obj: any): any => {
  if (obj?.offerId) {
    return obj.offerId;
  }
  if (obj?.selectedOption) {
    return getSelectedOffer(obj.selectedOption);
  }
};

const getTagValue = (tagDetails?: SmartTag) => {
  if (!tagDetails) return { keyValue: '' };

  const { valueType, ...values } = tagDetails;

  switch (valueType) {
    case SmartTagValueTypeEnum.TEXT:
      return { keyValue: values.textValue?.text || '' };
    // case SmartTagValueTypeEnum.DATE:
    //   return { keyValue: values.dateValue?.date?.toString() || '' };
    case SmartTagValueTypeEnum.MONEY:
      return {
        keyValue: values.moneyValue?.amount?.toString() || '',
        currencyCode: values.moneyValue?.currencyCode || 'USD',
      };
    case SmartTagValueTypeEnum.NUMBER:
      return { keyValue: values.numberValue?.number?.toString() || '' };
    case SmartTagValueTypeEnum.PERCENTAGE:
      return { keyValue: `${values.percentageValue?.percentage}%` || '' };
    case SmartTagValueTypeEnum.URL:
      return { keyValue: values.urlValue?.path || '' };
    default:
      return { keyValue: '' };
  }
};

export const getFormattedData = (data: SmartTag[]) => {
  return data.reduce((acc, tagDetails) => {
    const value = getTagValue(tagDetails);
    if (tagDetails?.id) {
      acc[tagDetails.id] = value;
    }
    return acc;
  }, {} as Record<string, SmartTagValue>);
};

const isOfferSmartTags = (
  tag: OfferSmartTags | SmartTag
): tag is OfferSmartTags => {
  return (tag as OfferSmartTags)?.offerId !== undefined;
};

export const getCurrentSmartTagsList = (
  smartTagsList: OfferSmartTags[] | SmartTag[],
  offerId?: string
) => {
  if (isOfferSmartTags(smartTagsList[0])) {
    if (offerId) {
      const offerSmartTags = smartTagsList as OfferSmartTags[];
      const offerTag = offerSmartTags.find(
        (tag): tag is OfferSmartTags =>
          isOfferSmartTags(tag) && tag?.offerId === offerId
      );
      return offerTag ? offerTag.smartTags : [];
    } else {
      return (smartTagsList[0] as OfferSmartTags).smartTags;
    }
  } else {
    return smartTagsList as SmartTag[];
  }
};
