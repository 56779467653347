import React, { useCallback } from 'react';
import { Badge, BlockStack, Card, Page, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  useConfigureSettings,
  SettingsFetchTypeEnum,
} from 'features/settings/hooks/useConfigureSettings';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';
import { NavigationBox } from 'core/components';
import { SettingsModalSubPathsEnums } from 'pages/enums/PagesEnums';
import { useSearchParams } from 'react-router-dom';

export const DiscountCodes: React.FC = () => {
  const [i18n] = useI18n();
  const [, setSearchParams] = useSearchParams();

  const { dnPromocodesData, shopifyDiscountCodesData } = useConfigureSettings(
    SettingsFetchTypeEnum.DISCOUNT_CODES
  );

  const handleNavigate = useCallback(
    (
      subPath:
        | SettingsModalSubPathsEnums.ShopifyDiscountCodes
        | SettingsModalSubPathsEnums.DNPromocodes
    ) =>
      setSearchParams((params) => {
        params.set('subPath', subPath);
        return params;
      }),
    [setSearchParams]
  );

  return (
    <div>
      {dnPromocodesData && shopifyDiscountCodesData ? (
        <Page title={i18n.translate('DiscountCodes')}>
          <Card>
            <BlockStack gap='400'>
              <BlockStack gap='100'>
                <Text as='p' fontWeight='semibold'>
                  {i18n.translate('DiscountCodes')}
                </Text>
                <Text as='p' tone='subdued'>
                  {i18n.translate('DiscountCodesSubtitle')}
                </Text>
              </BlockStack>

              <BlockStack gap='200'>
                <NavigationBox
                  title={i18n.translate('ShopifyDiscountCodes')}
                  fullClickable
                  btnContent={
                    <Badge
                      tone={
                        shopifyDiscountCodesData.isEnabled ? 'success' : 'new'
                      }
                    >
                      {i18n.translate(
                        shopifyDiscountCodesData.isEnabled ? 'On' : 'Off'
                      )}
                    </Badge>
                  }
                  onClick={() =>
                    handleNavigate(
                      SettingsModalSubPathsEnums.ShopifyDiscountCodes
                    )
                  }
                />
                <NavigationBox
                  title={i18n.translate('DNPromocodes')}
                  fullClickable
                  btnContent={
                    <Badge
                      tone={dnPromocodesData.isEnabled ? 'success' : 'new'}
                    >
                      {i18n.translate(
                        dnPromocodesData.isEnabled ? 'On' : 'Off'
                      )}
                    </Badge>
                  }
                  onClick={() =>
                    handleNavigate(SettingsModalSubPathsEnums.DNPromocodes)
                  }
                />
              </BlockStack>
            </BlockStack>
          </Card>
        </Page>
      ) : (
        <SettingsSkeleton cardsCount={2} />
      )}
    </div>
  );
};
