import React, { useCallback } from 'react';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import { useI18n } from '@shopify/react-i18n';
import { PaymentOptionsTypeEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { Checkbox } from '@shopify/polaris';
import { PaymentOptionsType } from 'core/api/adminWidgets/adminWidgetsApi';

type PaymentOptionsProps = {
  paymentOptions: PaymentOptionsType[];
  setPaymentOptions: (value: PaymentOptionsType[]) => void;
  onSystemReset?: () => void;
};
export const PaymentOptions: React.FC<PaymentOptionsProps> = ({
  paymentOptions,
  setPaymentOptions,
  onSystemReset,
}) => {
  const [i18n] = useI18n();

  const handlePaymentOptions = useCallback(
    (value: PaymentOptionsType) => {
      setPaymentOptions(
        paymentOptions.includes(value)
          ? paymentOptions.filter((item) => item !== value)
          : [...paymentOptions, value]
      );
    },
    [paymentOptions]
  );

  return (
    <StyleComponentHeader
      buttons={[
        {
          external: true,
          content: i18n.translate('ResetToSystemDefault'),
          onAction: () => onSystemReset?.(),
        },
      ]}
      label={i18n.translate('PaymentOptions')}
    >
      {Object.values(PaymentOptionsTypeEnum).map((value) => (
        <Checkbox
          key={value}
          label={i18n.translate(value)}
          checked={paymentOptions.includes(value)}
          onChange={() => handlePaymentOptions(value)}
        />
      ))}
    </StyleComponentHeader>
  );
};
