import React, { useCallback, useMemo } from 'react';
import {
  BorderStyle,
  ButtonStyle,
  CardSize,
  CornerRadius,
  CustomCSS,
  InputControls,
  Offset,
  OverlaySettings,
  WidgetOptionHeader,
} from '../../../../../../../components';
import { NinjaCartDefaultStateGeneralPresetDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListTypeDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';
import {
  AnimationDtoEnum,
  BorderSideDtoEnum,
  NinjaCartButtonStyleSetKeyDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';

type Preset_NDC_DefaultGeneralProps = {
  general: NinjaCartDefaultStateGeneralPresetDto;
  defaultGeneral: NinjaCartDefaultStateGeneralPresetDto;
  shopDefaultGeneral: NinjaCartDefaultStateGeneralPresetDto;
  setGeneral: (data: NinjaCartDefaultStateGeneralPresetDto) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const Preset_NDC_DefaultGeneral: React.FC<Preset_NDC_DefaultGeneralProps> = ({
  general,
  defaultGeneral,
  shopDefaultGeneral,
  setGeneral,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();

  const handleUpdateGeneral = useCallback(
    (
      field: keyof NinjaCartDefaultStateGeneralPresetDto,
      data: NinjaCartDefaultStateGeneralPresetDto[keyof NinjaCartDefaultStateGeneralPresetDto]
    ) => setGeneral({ ...general, [field]: data }),
    [general, setGeneral]
  );

  const getOverlayOptions = useCallback(
    (opening?: boolean) => [
      AnimationDtoEnum.INSTANT,
      AnimationDtoEnum.SLIDE_IN,
      opening ? AnimationDtoEnum.MOVE_IN : AnimationDtoEnum.MOVE_OUT,
    ],
    []
  );

  const buttonStyleSet = useMemo(
    () =>
      general.buttonsStyle?.selectedStyleSet ===
      NinjaCartButtonStyleSetKeyDtoEnum.PRIMARY
        ? 'primaryStyle'
        : 'secondaryStyle',
    [general.buttonsStyle?.selectedStyleSet]
  );
  return (
    <>
      <WidgetOptionHeader
        onDiscardChanges={() => setGeneral(defaultGeneral)}
        onResetToSystem={() => setGeneral(shopDefaultGeneral)}
        name={WidgetListTypeDtoEnum.NINJA_CART}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />

      <div className='RightSideSection'>
        <CardSize
          size={general.size || {}}
          setSize={(data) => handleUpdateGeneral('size', data)}
          onSystemReset={() =>
            handleUpdateGeneral('size', shopDefaultGeneral.size)
          }
        />
      </div>
      <div className='RightSideSection'>
        <OverlaySettings
          overlay={general.overlay || {}}
          setOverlay={(data) => handleUpdateGeneral('overlay', data)}
          closeOptions={getOverlayOptions(false)}
          openOptions={getOverlayOptions(true)}
          onSystemReset={() =>
            handleUpdateGeneral('overlay', shopDefaultGeneral.overlay)
          }
        />
      </div>
      <div className='RightSideSection'>
        <Offset
          offset={general.offset || {}}
          onSystemReset={() =>
            handleUpdateGeneral('offset', shopDefaultGeneral.offset)
          }
          note={i18n.translate('OffsetNote')}
          setOffset={(data) => handleUpdateGeneral('offset', data)}
        />
      </div>
      <div className='RightSideSection'>
        <CornerRadius
          cornerRadius={general.cornerRadius || {}}
          setCornerRadius={(data) => handleUpdateGeneral('cornerRadius', data)}
          onSystemReset={() =>
            handleUpdateGeneral('cornerRadius', shopDefaultGeneral.cornerRadius)
          }
        />
      </div>
      <div className='RightSideSection'>
        <BorderStyle
          border={general.border || {}}
          onSystemReset={() =>
            handleUpdateGeneral('border', shopDefaultGeneral.border)
          }
          disableStyle
          customSides={[BorderSideDtoEnum.ALL, BorderSideDtoEnum.LEFT]}
          setBorder={(data) => handleUpdateGeneral('border', data)}
        />
      </div>
      <div className='RightSideSection'>
        <ButtonStyle
          selectedStyleSet={
            general.buttonsStyle
              ?.selectedStyleSet as NinjaCartButtonStyleSetKeyDtoEnum
          }
          onSystemReset={() =>
            handleUpdateGeneral('buttonsStyle', shopDefaultGeneral.buttonsStyle)
          }
          setSelectedStyleSet={(value) =>
            handleUpdateGeneral('buttonsStyle', {
              ...general.buttonsStyle,
              selectedStyleSet: value,
            })
          }
          setButtonStyle={(data) =>
            handleUpdateGeneral('buttonsStyle', {
              ...general.buttonsStyle,
              [buttonStyleSet]: data,
            })
          }
          buttonStyle={general.buttonsStyle?.[buttonStyleSet] || {}}
        />
      </div>
      <div className='RightSideSection'>
        <InputControls
          inputControls={general.inputControls || {}}
          setInputControls={(data) =>
            handleUpdateGeneral('inputControls', data)
          }
          onSystemReset={() =>
            handleUpdateGeneral(
              'inputControls',
              shopDefaultGeneral.inputControls
            )
          }
        />
      </div>
      <div className='RightSideSection'>
        <CustomCSS
          css={general.customCss || ''}
          setCSS={(value) => handleUpdateGeneral('customCss', value)}
          note={i18n.translate('CSSNote')}
        />
      </div>
    </>
  );
};

export default Preset_NDC_DefaultGeneral;
