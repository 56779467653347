import React, { useCallback } from 'react';
import {
  ContinueShoppingAction,
  SimplifiedTextEntries,
  TextStyle,
  WidgetOptionHeader,
} from '../../../../../../../../../components';
import { NinjaCartDefaultStateFooterContinueShoppingButtonPresetDtoRead } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';

type Preset_NDC_ContinueShoppingBtnProps = {
  continueShoppingBtn: NinjaCartDefaultStateFooterContinueShoppingButtonPresetDtoRead;
  defaultContinueShoppingBtn: NinjaCartDefaultStateFooterContinueShoppingButtonPresetDtoRead;
  shopDefaultContinueShoppingBtn: NinjaCartDefaultStateFooterContinueShoppingButtonPresetDtoRead;
  defaultLanguage: string;
  setContinueShoppingBtn: (
    data: NinjaCartDefaultStateFooterContinueShoppingButtonPresetDtoRead
  ) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const Preset_NDC_ContinueShoppingBtn: React.FC<
  Preset_NDC_ContinueShoppingBtnProps
> = ({
  continueShoppingBtn,
  defaultContinueShoppingBtn,
  shopDefaultContinueShoppingBtn,
  defaultLanguage,
  setContinueShoppingBtn,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();

  const handleUpdateOptions = useCallback(
    (
      field: keyof NinjaCartDefaultStateFooterContinueShoppingButtonPresetDtoRead,
      data: NinjaCartDefaultStateFooterContinueShoppingButtonPresetDtoRead[keyof NinjaCartDefaultStateFooterContinueShoppingButtonPresetDtoRead]
    ) => setContinueShoppingBtn({ ...continueShoppingBtn, [field]: data }),
    [continueShoppingBtn]
  );

  return (
    <>
      <WidgetOptionHeader
        onDiscardChanges={() =>
          setContinueShoppingBtn(defaultContinueShoppingBtn)
        }
        onResetToSystem={() =>
          setContinueShoppingBtn(shopDefaultContinueShoppingBtn)
        }
        name={WidgetListOptionsDtoEnum.CONTINUE_SHOPPING_BUTTON}
        onShowHide={
          continueShoppingBtn.canBeHidden
            ? () =>
                handleUpdateOptions('isHidden', !continueShoppingBtn.isHidden)
            : undefined
        }
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <ContinueShoppingAction
          action={continueShoppingBtn.action || {}}
          setAction={(data) => handleUpdateOptions('action', data)}
          onSystemReset={() =>
            handleUpdateOptions('action', shopDefaultContinueShoppingBtn.action)
          }
        />
      </div>
      <div className='RightSideSection'>
        <SimplifiedTextEntries
          message={continueShoppingBtn.label?.message || {}}
          defaultLanguage={defaultLanguage}
          title={i18n.translate('Label')}
          setMessage={(data) =>
            handleUpdateOptions('label', {
              ...continueShoppingBtn.label,
              message: data,
            })
          }
          onSystemReset={() =>
            handleUpdateOptions('label', shopDefaultContinueShoppingBtn.label)
          }
        />
        <TextStyle
          textStyle={continueShoppingBtn.label?.style || {}}
          setTextStyle={(data) =>
            handleUpdateOptions('label', {
              ...continueShoppingBtn.label,
              style: data,
            })
          }
          hideHeader
        />
      </div>
    </>
  );
};

export default Preset_NDC_ContinueShoppingBtn;
