import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BlockStack, Box, Icon, InlineStack, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import DiscountNinja from '../../assets/DiscountNinja.svg';
import { CompleteOnboardingRequestDto } from 'core/api/adminSettings/adminSettingsApi';
import { useConfigureOnboarding } from 'features/onboarding/hooks/useConfigureOnboarding';
import { Loader } from 'core/components';
import { CheckCircleIcon } from '@shopify/polaris-icons';
import { debounce } from 'lodash';

type FinalStepProps = {
  onboardingSetup: CompleteOnboardingRequestDto;
  isTestThemeEnabled: boolean;
  finishOnboarding: () => void;
};
export const FinalStep: React.FC<FinalStepProps> = ({
  onboardingSetup,
  isTestThemeEnabled,
  finishOnboarding,
}) => {
  const [i18n] = useI18n();
  const { completeOnboarding, completeOnboardingSuccess } =
    useConfigureOnboarding();

  const [completedStatuses, setCompletedStatuses] = useState<number[]>([0]);

  const availableSteps = useMemo(
    () => Array.from({ length: isTestThemeEnabled ? 4 : 3 }, (_, i) => i + 1),
    [isTestThemeEnabled]
  );

  const currentSteps = useMemo(() => {
    return availableSteps.map((value) => {
      const isCompleted = completedStatuses.includes(value);
      return (
        <InlineStack key={value} gap='200'>
          {isCompleted ? (
            <Icon source={CheckCircleIcon} />
          ) : (
            <div style={{ width: 20, height: 20 }}>
              <Loader size='small' />
            </div>
          )}
          <Text as='p'>{i18n.translate(`STEP_${value}`)}</Text>
        </InlineStack>
      );
    });
  }, [completedStatuses, availableSteps]);

  const handleDebaunceStatuses = useCallback(
    debounce(
      () =>
        setCompletedStatuses((prev) => [...prev, prev[prev.length - 1] + 1]),
      1500
    ),
    []
  );

  useEffect(() => {
    if (completeOnboardingSuccess) {
      if (completedStatuses.includes(availableSteps.length + 1)) {
        finishOnboarding();
      } else {
        handleDebaunceStatuses();
      }
    }
  }, [completedStatuses, completeOnboardingSuccess]);

  useEffect(() => {
    completeOnboarding(onboardingSetup);
  }, [onboardingSetup]);

  return (
    <BlockStack gap='800' inlineAlign='center'>
      <BlockStack gap='600' inlineAlign='center'>
        <img src={DiscountNinja} alt='DiscountNinja' />
        <Box>
          <Text
            alignment='center'
            as='h1'
            variant='headingXl'
            fontWeight='semibold'
          >
            {i18n.translate('Thanks')}
          </Text>
          <Text
            alignment='center'
            as='h1'
            variant='headingXl'
            fontWeight='semibold'
          >
            {i18n.translate('GettingReady')}
          </Text>
        </Box>
      </BlockStack>
      <BlockStack gap='300' inlineAlign='start'>
        {currentSteps}
      </BlockStack>
    </BlockStack>
  );
};
