import React, { useCallback, useMemo } from 'react';
import { NinjaCartDefaultStateFooterCtaButton1PresetDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';
import {
  CTAButtonAction,
  CustomJS,
  Icon,
  SimplifiedTextEntries,
  SingleCheckbox,
  SingleInputSelection,
  WidgetOptionHeader,
} from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components';
import {
  NinjaCartButtonStyleSetKeyDtoEnum,
  NinjaCartCallToActionButtonActionTypeDtoEnum,
  ResourceGroupKeyDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';

type Preset_NDC_CTAButtonProps = {
  ctaButton: NinjaCartDefaultStateFooterCtaButton1PresetDto;
  defaultCTAButton: NinjaCartDefaultStateFooterCtaButton1PresetDto;
  shopDefaultCTAButton: NinjaCartDefaultStateFooterCtaButton1PresetDto;
  defaultLanguage: string;
  type:
    | WidgetListOptionsDtoEnum.CTA_BUTTON_1
    | WidgetListOptionsDtoEnum.CTA_BUTTON_2;
  setCTAButton: (data: NinjaCartDefaultStateFooterCtaButton1PresetDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const Preset_NDC_CTAButton: React.FC<Preset_NDC_CTAButtonProps> = ({
  ctaButton,
  type,
  defaultCTAButton,
  shopDefaultCTAButton,
  defaultLanguage,
  setCTAButton,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();

  const buttonStyleOptions = useMemo(
    () =>
      Object.values(NinjaCartButtonStyleSetKeyDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    []
  );

  const handleUpdateCTA = useCallback(
    (
      field: keyof NinjaCartDefaultStateFooterCtaButton1PresetDto,
      data: NinjaCartDefaultStateFooterCtaButton1PresetDto[keyof NinjaCartDefaultStateFooterCtaButton1PresetDto]
    ) => setCTAButton({ ...ctaButton, [field]: data }),
    [ctaButton, setCTAButton]
  );

  return (
    <>
      <WidgetOptionHeader
        onDiscardChanges={() => setCTAButton(defaultCTAButton)}
        onResetToSystem={() => setCTAButton(shopDefaultCTAButton)}
        name={type}
        onShowHide={onShowHide}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <CTAButtonAction
          action={ctaButton.action || {}}
          setAction={(data) => handleUpdateCTA('action', data)}
          onSystemReset={() =>
            handleUpdateCTA('action', shopDefaultCTAButton.action)
          }
        />
      </div>
      {ctaButton.action?.type ===
        NinjaCartCallToActionButtonActionTypeDtoEnum.EVALUATE_JS && (
        <div className='RightSideSection'>
          <CustomJS
            code={ctaButton.action?.evaluateJs as string}
            setCode={(value) =>
              handleUpdateCTA('action', {
                ...ctaButton.action,
                evaluateJs: value,
              })
            }
            onSystemReset={() =>
              handleUpdateCTA('action', {
                ...ctaButton.action,
                evaluateJs: shopDefaultCTAButton.action?.evaluateJs,
              })
            }
          />
        </div>
      )}

      <div className='RightSideSection'>
        <SimplifiedTextEntries
          message={ctaButton.label || {}}
          title={i18n.translate('Label')}
          defaultLanguage={defaultLanguage}
          setMessage={(data) => handleUpdateCTA('label', data)}
          onSystemReset={() =>
            handleUpdateCTA('label', shopDefaultCTAButton.label)
          }
        />
      </div>
      <div className='RightSideSection'>
        <SingleInputSelection
          options={buttonStyleOptions}
          label={i18n.translate('ButtonStyle')}
          selectedOption={ctaButton.buttonStyleSet as string}
          setOption={(value) =>
            handleUpdateCTA(
              'buttonStyleSet',
              value as NinjaCartButtonStyleSetKeyDtoEnum
            )
          }
          onSystemReset={() =>
            handleUpdateCTA(
              'buttonStyleSet',
              shopDefaultCTAButton.buttonStyleSet
            )
          }
        />
      </div>

      <div className='RightSideSection'>
        <Icon
          groupKey={ResourceGroupKeyDtoEnum.OFFER}
          icon={ctaButton.prefix?.value || {}}
          enabled={ctaButton.prefix?.enabled}
          enabledText={i18n.translate('EnableLock')}
          showIcon
          title={i18n.translate('Prefix')}
          setIcon={(data) =>
            handleUpdateCTA('prefix', { ...ctaButton.prefix, value: data })
          }
          onSystemReset={() =>
            handleUpdateCTA('prefix', shopDefaultCTAButton.prefix)
          }
          setEnabled={(value) =>
            handleUpdateCTA('prefix', {
              ...ctaButton.prefix,
              enabled: value,
            })
          }
        />
      </div>
      <div className='RightSideSection'>
        <SingleCheckbox
          label={i18n.translate('EnableTotal')}
          checked={!!ctaButton.suffixEnabled}
          title={i18n.translate('Suffix')}
          onChange={(value) => handleUpdateCTA('suffixEnabled', value)}
          onSystemReset={() =>
            handleUpdateCTA('suffixEnabled', shopDefaultCTAButton.suffixEnabled)
          }
        />
      </div>
    </>
  );
};

export default Preset_NDC_CTAButton;
