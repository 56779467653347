import React, { useEffect, useState } from 'react';
import { AppProvider, Banner, Frame, Text, Toast } from '@shopify/polaris';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  DashboardPage,
  AlertCenterPage,
  PromotionsPage,
  AnalyticsPage,
} from './pages';
import { I18nContext, I18nManager, useI18n } from '@shopify/react-i18n';
import { Loader, Navbar, PasswordDevLogin } from 'core/components';
import { useAuthentication } from 'core/hooks/useAuthentication';
import enTranslations from '@shopify/polaris/locales/en.json';
import '@shopify/polaris/build/esm/styles.css';
import {
  useAppDispatch,
  useAppSelector,
  usePasswordDevLogin,
} from 'core/hooks';
import { SettingsPage } from 'pages/SettingsPage';
import { deleteToastMessage } from 'core/store/offersWizardSlice';
import './App.scss';
import { setHideAppLeftMenu } from 'core/store/mainNavSlice';
import { WidgetEditor } from 'pages/WidgetEditor';
import { EditPresetTypeEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { Onboarding } from 'features/onboarding/Onboarding';
import {
  OnboardingFetchTypeEnum,
  useConfigureOnboarding,
} from 'features/onboarding/hooks/useConfigureOnboarding';
import { NavPaths } from 'core/enums/NavPathsEnum';
import { SystemCheckResponseDto } from 'core/api/adminSettings/adminSettingsApi';

(window as any).discountNinjaContext = {
  Locale: 'en',
};

type ContentProps = {
  systemCheckData?: SystemCheckResponseDto;
  isAuthenticated: boolean;
  isLoading: boolean;
  isError: boolean;
};

const App = () => {
  const { isAuthenticated, isLoading, isError } = useAuthentication();
  const { systemCheckData } = useConfigureOnboarding(
    isAuthenticated ? OnboardingFetchTypeEnum.SYSTEM_CHECK : undefined
  );

  const [i18nManager, setI18nManager] = useState<I18nManager | null>(null);

  useEffect(() => {
    if (systemCheckData) {
      const browserLocale = navigator.language || 'en';
      const currency = systemCheckData.currencyCode || 'USD';

      // Save currency in local storage for frame
      localStorage.setItem('currencyCode', currency);

      const manager = new I18nManager({
        locale: browserLocale,
        currency: currency,
        fallbackLocale: 'en',
        onError(error) {
          console.log(error);
        },
      });

      setI18nManager(manager);
    }
  }, [systemCheckData]);

  if (!i18nManager) {
    // Show a loading spinner or placeholder while the i18nManager is initializing
    return (
      <div className='wrapper wrapper--loading'>
        <Loader fullWidth={true} />
      </div>
    );
  }

  return (
    <I18nContext.Provider value={i18nManager}>
      <AppProvider i18n={enTranslations}>
        <Frame>
          <Content
            systemCheckData={systemCheckData}
            isAuthenticated={isAuthenticated}
            isError={isError}
            isLoading={isLoading}
          />
        </Frame>
      </AppProvider>
    </I18nContext.Provider>
  );
};

const Content: React.FC<ContentProps> = ({
  systemCheckData,
  isAuthenticated,
  isError,
  isLoading,
}) => {
  const { showLoginDevModal } = usePasswordDevLogin();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { toastMessages } = useAppSelector((state) => state.offersWizard);
  const dispatch = useAppDispatch();
  const [i18n] = useI18n();
  const [isOnboardingRequired, setIsOnboardingRequired] = useState<
    boolean | null
  >(null);

  useEffect(() => {
    const handleCustomEvent = () => {
      if (localStorage.getItem('redirectToDashboard') === 'true') {
        navigate('/');
        localStorage.removeItem('redirectToDashboard');
      }
    };

    window.addEventListener('redirectToDashboardChange', handleCustomEvent);

    return () => {
      window.removeEventListener(
        'redirectToDashboardChange',
        handleCustomEvent
      );
    };
  }, [navigate]);

  useEffect(() => {
    if (systemCheckData) {
      setIsOnboardingRequired(!!systemCheckData.isOnboardingRequired);
    }
  }, [systemCheckData]);

  useEffect(() => {
    if (
      location.pathname.includes(NavPaths.ConfigureWidgets) &&
      location.pathname.includes(NavPaths.Promotions)
    ) {
      dispatch(setHideAppLeftMenu(true));
    } else {
      dispatch(setHideAppLeftMenu(false));
    }
  }, [location]);

  return (
    <>
      {(isLoading || isOnboardingRequired === null) && (
        <div className='wrapper wrapper--loading'>
          <Loader fullWidth={true} />
        </div>
      )}
      {isError && (
        <div className='wrapper wrapper--loading'>
          <Banner title='Ooops!' tone='critical'>
            <Text as='h1'>{i18n.translate('CantLogin')}</Text>
            <Text as='p'>{i18n.translate('СheckСredentials')}</Text>
          </Banner>
        </div>
      )}
      {isAuthenticated && (
        <>
          {isOnboardingRequired === false && (
            <div className='wrapper wrapper--default'>
              <Navbar />
              <Pages />
              {searchParams.get('modal') === NavPaths.SettingsModal && (
                <SettingsPage />
              )}
              {showLoginDevModal && <PasswordDevLogin />}
              {toastMessages.map(
                (toast, index) =>
                  toast.message && (
                    <Toast
                      key={index}
                      content={
                        i18n.translate(`ToastMessages.${toast.message}`) ||
                        i18n.translate('ToastMessages.GenericMessage')
                      }
                      error={toast.error}
                      onDismiss={() =>
                        dispatch(deleteToastMessage(toast.message))
                      }
                    />
                  )
              )}
            </div>
          )}
          {isOnboardingRequired === true && (
            <Onboarding
              finishOnboarding={() => setIsOnboardingRequired(false)}
            />
          )}
        </>
      )}
    </>
  );
};

function Pages() {
  return (
    <Routes>
      <Route path='*' element={<DashboardPage />} />
      <Route path={`${NavPaths.Promotions}/*`} element={<PromotionsPage />} />
      <Route path={`${NavPaths.Analytics}/*`} element={<AnalyticsPage />} />
      <Route path={`${NavPaths.AlertCenter}/*`} element={<AlertCenterPage />} />
      <Route
        path={`${NavPaths.ConfigureWidgets}/:presetId`}
        element={
          <WidgetEditor skeletonType={EditPresetTypeEnum.PRESET_EDITOR} />
        }
      />
    </Routes>
  );
}

export default App;
