import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BlockStack,
  Box,
  Button,
  ButtonGroup,
  Collapsible,
  InlineStack,
  Modal,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { CheckFilledIcon, EmptyCheckIcon } from './assets/icons';
import { RefreshIcon } from '@shopify/polaris-icons';
import { useSearchParams } from 'react-router-dom';
import { PromotionEngineAndPlanCheckResponseDto } from 'core/api/adminSettings/adminSettingsApi';
import { NavPaths } from 'core/enums/NavPathsEnum';
import {
  SettingsModalPathsEnums,
  SettingsModalSubPathsEnums,
} from 'pages/enums/PagesEnums';

enum ConfigurationStepsEnum {
  TOGGLE_ENGINE = 'TOGGLE_ENGINE',
  SELECT_PLAN = 'SELECT_PLAN',
}

type FinishConfigurationModalProps = {
  isOpen: boolean;
  engineAndPlanCheckData?: PromotionEngineAndPlanCheckResponseDto;
  engineAndPlanCheckIsFetching: boolean;
  refetchEngineAndPlanCheck: () => void;
  setCheckConfiguration: (value: boolean) => void;
  onClose: () => void;
  onPublishPromiton: () => void;
};
export const FinishConfigurationModal: React.FC<
  FinishConfigurationModalProps
> = ({
  isOpen,
  engineAndPlanCheckData,
  engineAndPlanCheckIsFetching,
  refetchEngineAndPlanCheck,
  onPublishPromiton,
  onClose,
  setCheckConfiguration,
}) => {
  const [i18n] = useI18n();
  const [, setSearchParams] = useSearchParams();
  const [expandedCard, setExpandedCard] =
    useState<ConfigurationStepsEnum | null>(null);
  const isPromotionEngineEnabled = useMemo(
    () => !!engineAndPlanCheckData?.promotionEngine?.enabled,
    [engineAndPlanCheckData?.promotionEngine?.enabled]
  );
  const isActiveSubscriptionInPlace = useMemo(
    () => !!engineAndPlanCheckData?.isActiveSubscriptionInPlace,
    [engineAndPlanCheckData?.isActiveSubscriptionInPlace]
  );

  const completedSteps = useMemo(() => {
    return (
      Number(isPromotionEngineEnabled) + Number(isActiveSubscriptionInPlace)
    );
  }, [isPromotionEngineEnabled, isActiveSubscriptionInPlace]);

  const handleOpenThemeEditor = useCallback(() => {
    setCheckConfiguration(true);
    window.open(
      engineAndPlanCheckData?.promotionEngine?.installationDeepLink || '',
      '_blank'
    );
  }, [engineAndPlanCheckData?.promotionEngine?.installationDeepLink]);

  const handleOpenPlans = useCallback(() => {
    setSearchParams({
      modal: NavPaths.SettingsModal,
      path: SettingsModalPathsEnums.Plan,
      subPath: SettingsModalSubPathsEnums.PickYourPlan,
    });
  }, []);

  useEffect(() => {
    const isEngineDisabled = !engineAndPlanCheckData?.promotionEngine?.enabled;
    const isNoSubscription =
      !engineAndPlanCheckData?.isActiveSubscriptionInPlace;
    setExpandedCard(
      isEngineDisabled
        ? ConfigurationStepsEnum.TOGGLE_ENGINE
        : isNoSubscription
        ? ConfigurationStepsEnum.SELECT_PLAN
        : null
    );
  }, [engineAndPlanCheckData]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={i18n.translate('CompleteTheSetup')}
      primaryAction={{
        content: i18n.translate('PublishPromotion'),
        onAction: () => onPublishPromiton(),
        loading: engineAndPlanCheckIsFetching,
        disabled: !isActiveSubscriptionInPlace || !isPromotionEngineEnabled,
      }}
      secondaryActions={[
        { content: i18n.translate('Cancel'), onAction: onClose },
      ]}
    >
      <Modal.Section>
        <BlockStack gap='400'>
          <BlockStack inlineAlign='start' gap='300'>
            <Text as='p'>{i18n.translate('ToPublishYouNeed')}</Text>
            <Box
              borderColor='border'
              borderWidth='025'
              borderRadius='200'
              paddingInline='200'
            >
              <Text as='p'>
                {i18n.translate('CompletedCount', { count: completedSteps })}
              </Text>
            </Box>
          </BlockStack>
          <div
            onClick={() =>
              !isPromotionEngineEnabled &&
              setExpandedCard(ConfigurationStepsEnum.TOGGLE_ENGINE)
            }
          >
            <Box
              borderColor='border'
              borderWidth='025'
              borderRadius='200'
              paddingInline='200'
              paddingBlock='300'
              background={
                expandedCard === ConfigurationStepsEnum.TOGGLE_ENGINE
                  ? 'bg-surface-active'
                  : 'bg-surface'
              }
            >
              <InlineStack align='start' gap='200' wrap={false}>
                <div style={{ maxHeight: 20 }}>
                  {isPromotionEngineEnabled ? (
                    <CheckFilledIcon />
                  ) : (
                    <EmptyCheckIcon />
                  )}
                </div>

                <BlockStack gap='100' inlineAlign='start'>
                  <Text as='p' fontWeight='medium'>
                    {i18n.translate('ToggleEngine')}
                  </Text>
                  {!isPromotionEngineEnabled && (
                    <Collapsible
                      id={ConfigurationStepsEnum.TOGGLE_ENGINE}
                      open={
                        expandedCard === ConfigurationStepsEnum.TOGGLE_ENGINE
                      }
                    >
                      <BlockStack gap='400'>
                        {!isPromotionEngineEnabled && (
                          <Text tone='subdued' as='p'>
                            {i18n.translate('ToggleEngineHelp')}
                          </Text>
                        )}
                        <ButtonGroup>
                          <Button
                            onClick={handleOpenThemeEditor}
                            variant='primary'
                          >
                            {i18n.translate('OpenThemeEditor')}
                          </Button>
                          <Button
                            icon={RefreshIcon}
                            onClick={refetchEngineAndPlanCheck}
                            disabled={engineAndPlanCheckIsFetching}
                          >
                            {i18n.translate('Reload')}
                          </Button>
                        </ButtonGroup>
                      </BlockStack>
                    </Collapsible>
                  )}
                </BlockStack>
              </InlineStack>
            </Box>
          </div>
          <div
            onClick={() =>
              !isActiveSubscriptionInPlace &&
              setExpandedCard(ConfigurationStepsEnum.SELECT_PLAN)
            }
          >
            <Box
              borderColor='border'
              borderWidth='025'
              borderRadius='200'
              paddingInline='200'
              paddingBlock='300'
              background={
                expandedCard === ConfigurationStepsEnum.SELECT_PLAN
                  ? 'bg-surface-active'
                  : 'bg-surface'
              }
            >
              <InlineStack align='start' gap='200' wrap={false}>
                <div style={{ maxHeight: 20 }}>
                  {isActiveSubscriptionInPlace ? (
                    <CheckFilledIcon />
                  ) : (
                    <EmptyCheckIcon />
                  )}
                </div>

                <BlockStack gap='100' inlineAlign='start'>
                  <Text as='p' fontWeight='medium'>
                    {i18n.translate('SelectPlan')}
                  </Text>
                  {!isActiveSubscriptionInPlace && (
                    <Collapsible
                      id={ConfigurationStepsEnum.SELECT_PLAN}
                      open={expandedCard === ConfigurationStepsEnum.SELECT_PLAN}
                    >
                      <BlockStack inlineAlign='start' gap='400'>
                        <Text tone='subdued' as='p'>
                          {i18n.translate('SelectPlanHelp')}
                        </Text>
                        <Button onBlur={handleOpenPlans} variant='primary'>
                          {i18n.translate('ExplorePlans')}
                        </Button>
                      </BlockStack>
                    </Collapsible>
                  )}
                </BlockStack>
              </InlineStack>
            </Box>
          </div>
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
};
