import React, { useCallback, useEffect, useMemo } from 'react';
import './AlertCenter.scss';
import { useI18n } from '@shopify/react-i18n';
import { useAlertCenter } from 'core/hooks/useAlertCenter';
import {
  BlockStack,
  Box,
  InlineGrid,
  MediaCard,
  Page,
  Image,
  Icon,
  SkeletonPage,
  Layout,
  Card,
  SkeletonDisplayText,
  SkeletonBodyText,
  SkeletonThumbnail,
} from '@shopify/polaris';
import { ImagesIcon } from '@shopify/polaris-icons';
import { CardsSkeleton } from 'core/components';
import { SummarizeBanner } from './components/SummarizeBanner/SummarizeBanner';
import { AlertNotifications } from './components/AlertNotifications/AlertNotifications';
import { useScreenResolution } from 'core/hooks/useScreenResolution';

export const AlertCenter = () => {
  const [i18n] = useI18n();

  const { alertsData, alertsDataIsLoading, getAlertsPageData } =
    useAlertCenter();

  const { width } = useScreenResolution();

  const versionCard = useMemo(
    () => ({
      title: i18n.translate('LatestUpdates.title'),
      description: i18n.translate('LatestUpdates.description', {
        date: alertsData?.appVersion?.name,
      }),
      btn: i18n.translate('LatestUpdates.btn'),
      url: alertsData?.appVersion?.releaseNotesUrl,
      imageUrl: alertsData?.appVersion?.imageUrl,
    }),
    [alertsData?.appVersion, i18n]
  );

  const itemsArray = useCallback(
    (number: number) => Array.from({ length: number }, (_, index) => index + 1),
    []
  );

  useEffect(() => {
    getAlertsPageData();
  }, []);

  return (
    <Box width='100%' paddingBlockEnd='2000'>
      {!alertsDataIsLoading ? (
        <Page
          title={i18n.translate('PageTitle')}
          secondaryActions={[
            {
              loading: alertsDataIsLoading,
              content: i18n.translate('Refresh'),
              onAction: getAlertsPageData,
            },
          ]}
        >
          {alertsData ? (
            <div className='alertCenterContent'>
              <InlineGrid columns={['twoThirds', 'oneThird']} gap='400'>
                <BlockStack gap='400'>
                  <SummarizeBanner alerts={alertsData.alerts} />
                  <AlertNotifications alerts={alertsData.alerts} />
                </BlockStack>
                <div
                  style={{
                    height: 'fit-content',
                  }}
                >
                  <MediaCard
                    portrait={width > 1040}
                    title={versionCard.title}
                    primaryAction={{
                      content: versionCard.btn,
                      url: versionCard.url,
                      external: true,
                    }}
                    description={`${versionCard?.description}`}
                  >
                    {versionCard.imageUrl ? (
                      <Image
                        className='imageCard'
                        source={versionCard.imageUrl || ''}
                        alt=''
                      />
                    ) : (
                      <div className='placeholder'>
                        <Icon source={ImagesIcon} tone='subdued' />
                      </div>
                    )}
                  </MediaCard>
                </div>
              </InlineGrid>
            </div>
          ) : (
            <CardsSkeleton size='large' cardsCount={3} />
          )}
        </Page>
      ) : (
        <SkeletonPage primaryAction>
          <Layout>
            <Layout.Section>
              <BlockStack gap='400'>
                <Card>
                  <InlineGrid columns={3}>
                    {itemsArray(3).map((item) => (
                      <SkeletonDisplayText key={item} size='extraLarge' />
                    ))}
                  </InlineGrid>
                </Card>
                <Card>
                  <BlockStack gap='400'>
                    <SkeletonDisplayText size='extraLarge' maxWidth='45%' />
                    <BlockStack gap='200'>
                      {itemsArray(5).map((item) => (
                        <Card key={item}>
                          <SkeletonBodyText
                            lines={Math.floor(Math.random() * 3) + 1}
                          />
                        </Card>
                      ))}
                    </BlockStack>
                  </BlockStack>
                </Card>
              </BlockStack>
            </Layout.Section>
            <Layout.Section variant='oneThird'>
              <Card>
                <BlockStack gap='400'>
                  <SkeletonThumbnail size='large' />
                  <SkeletonBodyText lines={6} />
                </BlockStack>
              </Card>
            </Layout.Section>
          </Layout>
        </SkeletonPage>
      )}
    </Box>
  );
};
