import React, { useCallback, useMemo } from 'react';
import {
  NinjaCartDefaultStateHeaderLevelPresetDto,
  NinjaCartDefaultStateHeaderPresetDto,
  NinjaCartDefaultStateHeaderPresetDtoRead,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { SelectedOptionPath } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightSideBarWrapper';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import Preset_NDC_HeaderGeneral from './components/Preset_NDC_HeaderGeneral/Preset_NDC_HeaderGeneral';
import Preset_NDC_Heading from './components/Preset_NDC_Heading/Preset_NDC_Heading';
import Preset_NDC_ItemCount from './components/Preset_NDC_ItemCount/Preset_NDC_ItemCount';

type Preset_NDC_HeaderProps = {
  header: NinjaCartDefaultStateHeaderPresetDtoRead;
  defaultHeader: NinjaCartDefaultStateHeaderPresetDtoRead;
  shopDefaultHeader: NinjaCartDefaultStateHeaderPresetDto;
  selectedOptionPath: SelectedOptionPath;
  defaultLanguage: string;
  setHeader: (data: NinjaCartDefaultStateHeaderPresetDtoRead) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const Preset_NDC_Header: React.FC<Preset_NDC_HeaderProps> = ({
  header,
  defaultHeader,
  shopDefaultHeader,
  selectedOptionPath,
  defaultLanguage,
  setHeader,
  handleLeftMenuSelection,
}) => {
  const handleUpdateOptions = useCallback(
    (
      field: keyof NinjaCartDefaultStateHeaderLevelPresetDto,
      data: NinjaCartDefaultStateHeaderLevelPresetDto[keyof NinjaCartDefaultStateHeaderLevelPresetDto]
    ) => {
      setHeader({
        ...header,
        options: {
          ...header.options,
          [field]: data,
        },
      });
    },
    [header, setHeader]
  );
  const headerOptions = useMemo(() => {
    const selectedOption = getLastSelectedOption(selectedOptionPath);
    switch (true) {
      case selectedOption?.name === WidgetListOptionsDtoEnum.HEADER:
        return (
          <Preset_NDC_HeaderGeneral
            general={header.general || {}}
            defaultGeneral={defaultHeader.general || {}}
            shopDefaultGeneral={shopDefaultHeader.general || {}}
            setGeneral={(data) => setHeader({ ...header, general: data })}
            handleLeftMenuSelection={handleLeftMenuSelection}
            onShowHide={
              header.canBeHidden
                ? () => setHeader({ ...header, isHidden: !header.isHidden })
                : undefined
            }
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.HEADING:
        return (
          <Preset_NDC_Heading
            heading={header.options?.heading || {}}
            defaultLanguage={defaultLanguage}
            defaultHeading={defaultHeader.options?.heading || {}}
            shopDefaultHeading={shopDefaultHeader.options?.heading || {}}
            setHeading={(data) => handleUpdateOptions('heading', data)}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.ITEM_COUNT:
        return (
          <Preset_NDC_ItemCount
            itemCount={header.options?.itemCount || {}}
            defaultItemCount={defaultHeader.options?.itemCount || {}}
            shopDefaultItemCount={shopDefaultHeader.options?.itemCount || {}}
            setItemCount={(data) => handleUpdateOptions('itemCount', data)}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
    }
  }, [selectedOptionPath, defaultHeader, header, handleLeftMenuSelection]);

  return <>{headerOptions}</>;
};

export default Preset_NDC_Header;
