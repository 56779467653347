import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Banner,
  BlockStack,
  Box,
  Button,
  Icon,
  InlineStack,
  Link,
  MediaCard,
  Text,
  VideoThumbnail,
} from '@shopify/polaris';
import { ChevronRightIcon, RefreshIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import { SetupStepBox } from 'core/components';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import {
  PageTypeDtoEnum,
  WidgetStatusDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import { EnableNinjaPageModal } from './components/EnableNinjaPageModal/EnableNinjaPageModal';

export type NinjaCartPages = PageTypeDtoEnum.DRAWER_CART | PageTypeDtoEnum.CART;

type EnableNinjaCartStepProps = {
  onNextStep: () => void;
  setDisableNext: React.Dispatch<React.SetStateAction<boolean>>;
};
export const EnableNinjaCartStep: React.FC<EnableNinjaCartStepProps> = ({
  onNextStep,
  setDisableNext,
}) => {
  const [i18n] = useI18n();
  const {
    widgetInstallationData,
    widgetInstallationDataIsFetching,
    refetchWidgetInstallationData,
    turnWidgetOnManuallyIsLoading,
    turnWidgetOnManually,
  } = useConfigureSettings(SettingsFetchTypeEnum.MANAGE_WIDGETS, true);
  const [uncollapsedStep, setUncollapsedStep] = useState<NinjaCartPages>(
    PageTypeDtoEnum.DRAWER_CART
  );
  const [enablePageModal, setEnablePageModal] = useState<NinjaCartPages | null>(
    null
  );

  const checkNinjaPageEnabled = useCallback(
    (page: NinjaCartPages) =>
      widgetInstallationData?.widgets
        ?.find((widget) => widget.type === WidgetTypeDtoEnum.NINJA_CART)
        ?.pages?.includes(page),
    [widgetInstallationData?.widgets]
  );

  const toggleNinjaCartPages = useCallback((page: NinjaCartPages) => {
    const onSuccess = () => {
      setEnablePageModal(null);
      refetchWidgetInstallationData();
    };
    turnWidgetOnManually(
      {
        type: WidgetTypeDtoEnum.NINJA_CART,
        status: WidgetStatusDtoEnum.ENABLED,
        customProperties: {
          pageType: page,
        },
      },
      'CURRENT',
      onSuccess
    );
  }, []);

  useEffect(() => {
    if (
      checkNinjaPageEnabled(PageTypeDtoEnum.DRAWER_CART) ||
      checkNinjaPageEnabled(PageTypeDtoEnum.CART)
    ) {
      setDisableNext(false);
    } else {
      setDisableNext(true);
    }
  }, [widgetInstallationData?.widgets]);

  return (
    <Box paddingBlock='400'>
      <BlockStack gap='400'>
        <BlockStack gap='200'>
          <InlineStack align='space-between' blockAlign='start'>
            <Text as='h1' variant='headingXl' fontWeight='semibold'>
              {i18n.translate('Title')}
            </Text>
            <Box paddingBlockStart='150'>
              <Link removeUnderline onClick={onNextStep}>
                <InlineStack blockAlign='center'>
                  {i18n.translate('SkipAndFinish')}
                  <Icon source={ChevronRightIcon} />
                </InlineStack>
              </Link>
            </Box>
          </InlineStack>
          <Text as='p' tone='subdued'>
            {i18n.translate('Subtitle')}
          </Text>
        </BlockStack>

        <MediaCard
          title={i18n.translate('VideoTitle')}
          primaryAction={{
            content: i18n.translate('LearnMore'),
          }}
          description={i18n.translate('VideoSubitle')}
          size='small'
        >
          <VideoThumbnail
            videoLength={80}
            thumbnailUrl='https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850'
            onClick={() =>
              window.open(
                'https://www.youtube.com/watch?v=RVmG_d3HKBA',
                '_blank'
              )
            }
          />
        </MediaCard>
        <BlockStack gap='300'>
          <InlineStack align='space-between'>
            <Text as='p'>{i18n.translate('EnableNinjaCartOn')}</Text>
            <Button icon={RefreshIcon} variant='plain'>
              {i18n.translate('Refresh')}
            </Button>
          </InlineStack>

          <Box
            borderColor='border'
            borderRadius='200'
            borderWidth='025'
            padding='200'
          >
            <BlockStack gap='100'>
              {(
                [PageTypeDtoEnum.DRAWER_CART, PageTypeDtoEnum.CART] as const
              ).map((page) => {
                const isEnabled = !!checkNinjaPageEnabled(page);
                return (
                  <SetupStepBox
                    key={page}
                    label={i18n.translate(`${page}_TITLE`)}
                    helpText={i18n.translate(`${page}_HELP`)}
                    isLoading={
                      widgetInstallationDataIsFetching ||
                      !widgetInstallationData
                    }
                    button={{
                      children: i18n.translate('EnableNinjaCart'),
                      onClick: () => setEnablePageModal(page),
                      variant:
                        page === PageTypeDtoEnum.DRAWER_CART ||
                        !checkNinjaPageEnabled(PageTypeDtoEnum.DRAWER_CART)
                          ? 'primary'
                          : 'secondary',
                    }}
                    checked={isEnabled}
                    badge={
                      <Badge tone={isEnabled ? 'success' : 'new'}>
                        {i18n.translate(isEnabled ? 'Enabled' : 'Disabled')}
                      </Badge>
                    }
                    collapsed={uncollapsedStep !== page}
                    onCollapse={() => setUncollapsedStep(page)}
                  />
                );
              })}
            </BlockStack>
          </Box>
          <BlockStack>
            {checkNinjaPageEnabled(PageTypeDtoEnum.DRAWER_CART) && (
              <Banner tone='success'>
                {i18n.translate('DrawerCartSuccess')}
              </Banner>
            )}
            {checkNinjaPageEnabled(PageTypeDtoEnum.CART) && (
              <Banner tone='success'>
                {i18n.translate('CartPageSuccess')}
              </Banner>
            )}
          </BlockStack>
        </BlockStack>
      </BlockStack>
      {enablePageModal && (
        <EnableNinjaPageModal
          page={enablePageModal}
          isLoading={turnWidgetOnManuallyIsLoading}
          onClose={() => setEnablePageModal(null)}
          onEnable={toggleNinjaCartPages}
        />
      )}
    </Box>
  );
};
