import { emptySplitAdminSettingsApi as api } from './emptyAdminSettingsApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postSettingsV6Alerts: build.mutation<
      PostSettingsV6AlertsApiResponse,
      PostSettingsV6AlertsApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/alerts`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6Dashboard: build.query<
      GetSettingsV6DashboardApiResponse,
      GetSettingsV6DashboardApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/dashboard`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6DiscountCodesShopifyDiscountCodes: build.query<
      GetSettingsV6DiscountCodesShopifyDiscountCodesApiResponse,
      GetSettingsV6DiscountCodesShopifyDiscountCodesApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/discountCodes/shopifyDiscountCodes`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6DiscountCodesShopifyDiscountCodes: build.mutation<
      PutSettingsV6DiscountCodesShopifyDiscountCodesApiResponse,
      PutSettingsV6DiscountCodesShopifyDiscountCodesApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/discountCodes/shopifyDiscountCodes`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6DiscountCodesDiscountNinjaPromoCodes: build.query<
      GetSettingsV6DiscountCodesDiscountNinjaPromoCodesApiResponse,
      GetSettingsV6DiscountCodesDiscountNinjaPromoCodesApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/discountCodes/discountNinjaPromoCodes`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6DiscountCodesDiscountNinjaPromoCodes: build.mutation<
      PutSettingsV6DiscountCodesDiscountNinjaPromoCodesApiResponse,
      PutSettingsV6DiscountCodesDiscountNinjaPromoCodesApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/discountCodes/discountNinjaPromoCodes`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6LookupTimezones: build.query<
      GetSettingsV6LookupTimezonesApiResponse,
      GetSettingsV6LookupTimezonesApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/lookup/timezones`,
        headers: { 'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'] },
      }),
    }),
    getSettingsV6LookupThemes: build.query<
      GetSettingsV6LookupThemesApiResponse,
      GetSettingsV6LookupThemesApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/lookup/themes`,
        headers: {
          'Cache-Control': queryArg['Cache-Control'],
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6LookupLocales: build.query<
      GetSettingsV6LookupLocalesApiResponse,
      GetSettingsV6LookupLocalesApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/lookup/locales`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6LookupReleaseVersions: build.query<
      GetSettingsV6LookupReleaseVersionsApiResponse,
      GetSettingsV6LookupReleaseVersionsApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/lookup/releaseVersions`,
        headers: { 'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'] },
      }),
    }),
    getSettingsV6LookupPageTemplatesByThemeTypeAndWidgetType: build.query<
      GetSettingsV6LookupPageTemplatesByThemeTypeAndWidgetTypeApiResponse,
      GetSettingsV6LookupPageTemplatesByThemeTypeAndWidgetTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/lookup/pageTemplates/${queryArg.themeType}/${queryArg.widgetType}`,
        headers: { 'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'] },
      }),
    }),
    getSettingsV6OnboardingInitialData: build.query<
      GetSettingsV6OnboardingInitialDataApiResponse,
      GetSettingsV6OnboardingInitialDataApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/onboarding/initialData`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postSettingsV6OnboardingComplete: build.mutation<
      PostSettingsV6OnboardingCompleteApiResponse,
      PostSettingsV6OnboardingCompleteApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/onboarding/complete`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6PlansCurrent: build.query<
      GetSettingsV6PlansCurrentApiResponse,
      GetSettingsV6PlansCurrentApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/plans/current`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postSettingsV6PlansBills: build.mutation<
      PostSettingsV6PlansBillsApiResponse,
      PostSettingsV6PlansBillsApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/plans/bills`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6Plans: build.query<
      GetSettingsV6PlansApiResponse,
      GetSettingsV6PlansApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/plans`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: { thresholdType: queryArg.thresholdType },
      }),
    }),
    postSettingsV6PlansPlanSupportingFeature: build.mutation<
      PostSettingsV6PlansPlanSupportingFeatureApiResponse,
      PostSettingsV6PlansPlanSupportingFeatureApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/plans/planSupportingFeature`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postSettingsV6PlansSelectSubscription: build.mutation<
      PostSettingsV6PlansSelectSubscriptionApiResponse,
      PostSettingsV6PlansSelectSubscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/plans/selectSubscription`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
          'X-DiscountNinja-EncodedHost':
            queryArg['X-DiscountNinja-EncodedHost'],
        },
      }),
    }),
    postSettingsV6PlansRequestDevelopmentPlan: build.mutation<
      PostSettingsV6PlansRequestDevelopmentPlanApiResponse,
      PostSettingsV6PlansRequestDevelopmentPlanApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/plans/requestDevelopmentPlan`,
        method: 'POST',
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postSettingsV6PlansCancelSubscription: build.mutation<
      PostSettingsV6PlansCancelSubscriptionApiResponse,
      PostSettingsV6PlansCancelSubscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/plans/cancelSubscription`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postSettingsV6PlansResumeSubscription: build.mutation<
      PostSettingsV6PlansResumeSubscriptionApiResponse,
      PostSettingsV6PlansResumeSubscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/plans/resumeSubscription`,
        method: 'POST',
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6Account: build.query<
      GetSettingsV6AccountApiResponse,
      GetSettingsV6AccountApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/account`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6Account: build.mutation<
      PutSettingsV6AccountApiResponse,
      PutSettingsV6AccountApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/account`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postSettingsV6GeneralAppSecretCheck: build.mutation<
      PostSettingsV6GeneralAppSecretCheckApiResponse,
      PostSettingsV6GeneralAppSecretCheckApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/general/appSecret/check`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'] },
      }),
    }),
    getSettingsV6AccountAuditLogs: build.query<
      GetSettingsV6AccountAuditLogsApiResponse,
      GetSettingsV6AccountAuditLogsApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/account/auditLogs`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: {
          page: queryArg.page,
          itemsPerPage: queryArg.itemsPerPage,
          sortDirection: queryArg.sortDirection,
          search: queryArg.search,
        },
      }),
    }),
    getSettingsV6TranslationsMain: build.query<
      GetSettingsV6TranslationsMainApiResponse,
      GetSettingsV6TranslationsMainApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/translations/main`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6TranslationsByLocale: build.query<
      GetSettingsV6TranslationsByLocaleApiResponse,
      GetSettingsV6TranslationsByLocaleApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/translations/${queryArg.locale}`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6TranslationsByLocale: build.mutation<
      PutSettingsV6TranslationsByLocaleApiResponse,
      PutSettingsV6TranslationsByLocaleApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/translations/${queryArg.locale}`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6Style: build.query<
      GetSettingsV6StyleApiResponse,
      GetSettingsV6StyleApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/style`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6Style: build.mutation<
      PutSettingsV6StyleApiResponse,
      PutSettingsV6StyleApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/style`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6Integrations: build.query<
      GetSettingsV6IntegrationsApiResponse,
      GetSettingsV6IntegrationsApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/integrations`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6Integrations: build.mutation<
      PutSettingsV6IntegrationsApiResponse,
      PutSettingsV6IntegrationsApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/integrations`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6TagsMetadata: build.query<
      GetSettingsV6TagsMetadataApiResponse,
      GetSettingsV6TagsMetadataApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/tagsMetadata`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6TagsMetadata: build.mutation<
      PutSettingsV6TagsMetadataApiResponse,
      PutSettingsV6TagsMetadataApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/tagsMetadata`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6CartBehavior: build.query<
      GetSettingsV6CartBehaviorApiResponse,
      GetSettingsV6CartBehaviorApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/cartBehavior`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6CartBehavior: build.mutation<
      PutSettingsV6CartBehaviorApiResponse,
      PutSettingsV6CartBehaviorApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/cartBehavior`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6Testing: build.query<
      GetSettingsV6TestingApiResponse,
      GetSettingsV6TestingApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/testing`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6Testing: build.mutation<
      PutSettingsV6TestingApiResponse,
      PutSettingsV6TestingApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/testing`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6DiscountLinks: build.query<
      GetSettingsV6DiscountLinksApiResponse,
      GetSettingsV6DiscountLinksApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/discountLinks`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6DiscountLinks: build.mutation<
      PutSettingsV6DiscountLinksApiResponse,
      PutSettingsV6DiscountLinksApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/discountLinks`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6Gifts: build.query<
      GetSettingsV6GiftsApiResponse,
      GetSettingsV6GiftsApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/gifts`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6Gifts: build.mutation<
      PutSettingsV6GiftsApiResponse,
      PutSettingsV6GiftsApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/gifts`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postSettingsV6Import: build.mutation<
      PostSettingsV6ImportApiResponse,
      PostSettingsV6ImportApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/import`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6Export: build.query<
      GetSettingsV6ExportApiResponse,
      GetSettingsV6ExportApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/export`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6Checkout: build.query<
      GetSettingsV6CheckoutApiResponse,
      GetSettingsV6CheckoutApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/checkout`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6Checkout: build.mutation<
      PutSettingsV6CheckoutApiResponse,
      PutSettingsV6CheckoutApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/checkout`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6SystemCheck: build.query<
      GetSettingsV6SystemCheckApiResponse,
      GetSettingsV6SystemCheckApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/systemCheck`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6EngineAndPlanCheck: build.query<
      GetSettingsV6EngineAndPlanCheckApiResponse,
      GetSettingsV6EngineAndPlanCheckApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/engineAndPlanCheck`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: {
          themeType: queryArg.themeType,
          testThemeId: queryArg.testThemeId,
        },
      }),
    }),
    postSettingsV6UninstallApp: build.mutation<
      PostSettingsV6UninstallAppApiResponse,
      PostSettingsV6UninstallAppApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/uninstallApp`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6ShippingRates: build.query<
      GetSettingsV6ShippingRatesApiResponse,
      GetSettingsV6ShippingRatesApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/shippingRates`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postSettingsV6ShippingRates: build.mutation<
      PostSettingsV6ShippingRatesApiResponse,
      PostSettingsV6ShippingRatesApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/shippingRates`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6ShippingRatesById: build.mutation<
      PutSettingsV6ShippingRatesByIdApiResponse,
      PutSettingsV6ShippingRatesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/shippingRates/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    deleteSettingsV6ShippingRatesById: build.mutation<
      DeleteSettingsV6ShippingRatesByIdApiResponse,
      DeleteSettingsV6ShippingRatesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/shippingRates/${queryArg.id}`,
        method: 'DELETE',
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6Notifications: build.query<
      GetSettingsV6NotificationsApiResponse,
      GetSettingsV6NotificationsApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/notifications`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6Notifications: build.mutation<
      PutSettingsV6NotificationsApiResponse,
      PutSettingsV6NotificationsApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/notifications`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6Themes: build.query<
      GetSettingsV6ThemesApiResponse,
      GetSettingsV6ThemesApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6Themes: build.mutation<
      PutSettingsV6ThemesApiResponse,
      PutSettingsV6ThemesApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6ThemesStatus: build.query<
      GetSettingsV6ThemesStatusApiResponse,
      GetSettingsV6ThemesStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/status`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6Version: build.query<
      GetSettingsV6VersionApiResponse,
      GetSettingsV6VersionApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/version`,
        headers: { 'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'] },
      }),
    }),
    getSettingsV6ThemesByThemeTypeWidgetInstallation: build.query<
      GetSettingsV6ThemesByThemeTypeWidgetInstallationApiResponse,
      GetSettingsV6ThemesByThemeTypeWidgetInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/${queryArg.themeType}/widgetInstallation`,
        headers: {
          'Cache-Control': queryArg['Cache-Control'],
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: { testThemeId: queryArg.testThemeId },
      }),
    }),
    putSettingsV6ThemesByThemeTypeWidgetInstallationInstall: build.mutation<
      PutSettingsV6ThemesByThemeTypeWidgetInstallationInstallApiResponse,
      PutSettingsV6ThemesByThemeTypeWidgetInstallationInstallApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/${queryArg.themeType}/widgetInstallation/install`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6WidgetInstallationSupportRequest: build.query<
      GetSettingsV6WidgetInstallationSupportRequestApiResponse,
      GetSettingsV6WidgetInstallationSupportRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/widgetInstallation/supportRequest`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postSettingsV6WidgetInstallationSupportRequest: build.mutation<
      PostSettingsV6WidgetInstallationSupportRequestApiResponse,
      PostSettingsV6WidgetInstallationSupportRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/widgetInstallation/supportRequest`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6WidgetInstallationSupportRequest: build.mutation<
      PutSettingsV6WidgetInstallationSupportRequestApiResponse,
      PutSettingsV6WidgetInstallationSupportRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/widgetInstallation/supportRequest`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    deleteSettingsV6WidgetInstallationSupportRequest: build.mutation<
      DeleteSettingsV6WidgetInstallationSupportRequestApiResponse,
      DeleteSettingsV6WidgetInstallationSupportRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/widgetInstallation/supportRequest`,
        method: 'DELETE',
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6ThemesWidgetSettingsAnnouncementBar: build.query<
      GetSettingsV6ThemesWidgetSettingsAnnouncementBarApiResponse,
      GetSettingsV6ThemesWidgetSettingsAnnouncementBarApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/announcementBar`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6ThemesWidgetSettingsAnnouncementBar: build.mutation<
      PutSettingsV6ThemesWidgetSettingsAnnouncementBarApiResponse,
      PutSettingsV6ThemesWidgetSettingsAnnouncementBarApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/announcementBar`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6ThemesWidgetSettingsProductBanner: build.query<
      GetSettingsV6ThemesWidgetSettingsProductBannerApiResponse,
      GetSettingsV6ThemesWidgetSettingsProductBannerApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/productBanner`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6ThemesWidgetSettingsProductBanner: build.mutation<
      PutSettingsV6ThemesWidgetSettingsProductBannerApiResponse,
      PutSettingsV6ThemesWidgetSettingsProductBannerApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/productBanner`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6ThemesWidgetSettingsPromotionalBadge: build.query<
      GetSettingsV6ThemesWidgetSettingsPromotionalBadgeApiResponse,
      GetSettingsV6ThemesWidgetSettingsPromotionalBadgeApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/promotionalBadge`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6ThemesWidgetSettingsPromotionalBadge: build.mutation<
      PutSettingsV6ThemesWidgetSettingsPromotionalBadgeApiResponse,
      PutSettingsV6ThemesWidgetSettingsPromotionalBadgeApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/promotionalBadge`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6ThemesWidgetSettingsNotification: build.query<
      GetSettingsV6ThemesWidgetSettingsNotificationApiResponse,
      GetSettingsV6ThemesWidgetSettingsNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/notification`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6ThemesWidgetSettingsNotification: build.mutation<
      PutSettingsV6ThemesWidgetSettingsNotificationApiResponse,
      PutSettingsV6ThemesWidgetSettingsNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/notification`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6ThemesWidgetSettingsOfferRulePopup: build.query<
      GetSettingsV6ThemesWidgetSettingsOfferRulePopupApiResponse,
      GetSettingsV6ThemesWidgetSettingsOfferRulePopupApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/offerRulePopup`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6ThemesWidgetSettingsOfferRulePopup: build.mutation<
      PutSettingsV6ThemesWidgetSettingsOfferRulePopupApiResponse,
      PutSettingsV6ThemesWidgetSettingsOfferRulePopupApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/offerRulePopup`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6ThemesWidgetSettingsPopup: build.query<
      GetSettingsV6ThemesWidgetSettingsPopupApiResponse,
      GetSettingsV6ThemesWidgetSettingsPopupApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/popup`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6ThemesWidgetSettingsPopup: build.mutation<
      PutSettingsV6ThemesWidgetSettingsPopupApiResponse,
      PutSettingsV6ThemesWidgetSettingsPopupApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/popup`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6ThemesWidgetSettingsPromotionCodeField: build.query<
      GetSettingsV6ThemesWidgetSettingsPromotionCodeFieldApiResponse,
      GetSettingsV6ThemesWidgetSettingsPromotionCodeFieldApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/promotionCodeField`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6ThemesWidgetSettingsPromotionCodeField: build.mutation<
      PutSettingsV6ThemesWidgetSettingsPromotionCodeFieldApiResponse,
      PutSettingsV6ThemesWidgetSettingsPromotionCodeFieldApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/promotionCodeField`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6ThemesWidgetSettingsPromotionSummary: build.query<
      GetSettingsV6ThemesWidgetSettingsPromotionSummaryApiResponse,
      GetSettingsV6ThemesWidgetSettingsPromotionSummaryApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/promotionSummary`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6ThemesWidgetSettingsPromotionSummary: build.mutation<
      PutSettingsV6ThemesWidgetSettingsPromotionSummaryApiResponse,
      PutSettingsV6ThemesWidgetSettingsPromotionSummaryApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/promotionSummary`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6ThemesWidgetSettingsCountdownClock: build.query<
      GetSettingsV6ThemesWidgetSettingsCountdownClockApiResponse,
      GetSettingsV6ThemesWidgetSettingsCountdownClockApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/countdownClock`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6ThemesWidgetSettingsCountdownClock: build.mutation<
      PutSettingsV6ThemesWidgetSettingsCountdownClockApiResponse,
      PutSettingsV6ThemesWidgetSettingsCountdownClockApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/countdownClock`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6ThemesWidgetSettingsStrikethroughPricing: build.query<
      GetSettingsV6ThemesWidgetSettingsStrikethroughPricingApiResponse,
      GetSettingsV6ThemesWidgetSettingsStrikethroughPricingApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/strikethroughPricing`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6ThemesWidgetSettingsStrikethroughPricing: build.mutation<
      PutSettingsV6ThemesWidgetSettingsStrikethroughPricingApiResponse,
      PutSettingsV6ThemesWidgetSettingsStrikethroughPricingApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/strikethroughPricing`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6ThemesWidgetSettingsStrikethroughPricingProduct: build.query<
      GetSettingsV6ThemesWidgetSettingsStrikethroughPricingProductApiResponse,
      GetSettingsV6ThemesWidgetSettingsStrikethroughPricingProductApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/strikethroughPricing/product`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6ThemesWidgetSettingsStrikethroughPricingProduct:
      build.mutation<
        PutSettingsV6ThemesWidgetSettingsStrikethroughPricingProductApiResponse,
        PutSettingsV6ThemesWidgetSettingsStrikethroughPricingProductApiArg
      >({
        query: (queryArg) => ({
          url: `/settings/v6/themes/widgetSettings/strikethroughPricing/product`,
          method: 'PUT',
          body: queryArg.body,
          headers: {
            'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
            'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
          },
        }),
      }),
    getSettingsV6ThemesWidgetSettingsCheckoutLoadingPopup: build.query<
      GetSettingsV6ThemesWidgetSettingsCheckoutLoadingPopupApiResponse,
      GetSettingsV6ThemesWidgetSettingsCheckoutLoadingPopupApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/checkoutLoadingPopup`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6ThemesWidgetSettingsCheckoutLoadingPopup: build.mutation<
      PutSettingsV6ThemesWidgetSettingsCheckoutLoadingPopupApiResponse,
      PutSettingsV6ThemesWidgetSettingsCheckoutLoadingPopupApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/checkoutLoadingPopup`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getSettingsV6ThemesWidgetSettingsFrequentBoughtTogether: build.query<
      GetSettingsV6ThemesWidgetSettingsFrequentBoughtTogetherApiResponse,
      GetSettingsV6ThemesWidgetSettingsFrequentBoughtTogetherApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/frequentBoughtTogether`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putSettingsV6ThemesWidgetSettingsFrequentBoughtTogether: build.mutation<
      PutSettingsV6ThemesWidgetSettingsFrequentBoughtTogetherApiResponse,
      PutSettingsV6ThemesWidgetSettingsFrequentBoughtTogetherApiArg
    >({
      query: (queryArg) => ({
        url: `/settings/v6/themes/widgetSettings/frequentBoughtTogether`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as adminSettingsApi };
export type PostSettingsV6AlertsApiResponse =
  /** status 200 Success */ GetAlertsResponseDto;
export type PostSettingsV6AlertsApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: GetAlertsRequestDto;
};
export type GetSettingsV6DashboardApiResponse =
  /** status 200 Success */ GetDashboardDataResponseDtoRead;
export type GetSettingsV6DashboardApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetSettingsV6DiscountCodesShopifyDiscountCodesApiResponse =
  /** status 200 Success */ ShopifyDiscountCodesSettingsDto;
export type GetSettingsV6DiscountCodesShopifyDiscountCodesApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6DiscountCodesShopifyDiscountCodesApiResponse =
  /** status 204 No Content */ void;
export type PutSettingsV6DiscountCodesShopifyDiscountCodesApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: ShopifyDiscountCodesSettingsDto;
};
export type GetSettingsV6DiscountCodesDiscountNinjaPromoCodesApiResponse =
  /** status 200 Success */ DiscountNinjaPromoCodesSettingsDto;
export type GetSettingsV6DiscountCodesDiscountNinjaPromoCodesApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6DiscountCodesDiscountNinjaPromoCodesApiResponse =
  /** status 204 No Content */ void;
export type PutSettingsV6DiscountCodesDiscountNinjaPromoCodesApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: DiscountNinjaPromoCodesSettingsDto;
};
export type GetSettingsV6LookupTimezonesApiResponse =
  /** status 200 Success */ TimezoneDto[];
export type GetSettingsV6LookupTimezonesApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
};
export type GetSettingsV6LookupThemesApiResponse =
  /** status 200 Success */ ThemeDto[];
export type GetSettingsV6LookupThemesApiArg = {
  /** Header for controlling the cache. In order to break server cache, 'no-cache' value should be provided. */
  'Cache-Control'?: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetSettingsV6LookupLocalesApiResponse =
  /** status 200 Success */ LocaleDto[];
export type GetSettingsV6LookupLocalesApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetSettingsV6LookupReleaseVersionsApiResponse =
  /** status 200 Success */ ReleaseVersionDto[];
export type GetSettingsV6LookupReleaseVersionsApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
};
export type GetSettingsV6LookupPageTemplatesByThemeTypeAndWidgetTypeApiResponse =
  /** status 200 Success */ ShopifyPageTemplateDto[];
export type GetSettingsV6LookupPageTemplatesByThemeTypeAndWidgetTypeApiArg = {
  themeType: ThemeTypeDto | null;
  widgetType: WidgetTypeDto | null;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
};
export type GetSettingsV6OnboardingInitialDataApiResponse =
  /** status 200 Success */ OnboardingInitialDataResponseDto;
export type GetSettingsV6OnboardingInitialDataApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostSettingsV6OnboardingCompleteApiResponse =
  /** status 204 No Content */ void;
export type PostSettingsV6OnboardingCompleteApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: CompleteOnboardingRequestDto;
};
export type GetSettingsV6PlansCurrentApiResponse =
  /** status 200 Success */ GetCurrentPlanResponseDtoRead;
export type GetSettingsV6PlansCurrentApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostSettingsV6PlansBillsApiResponse =
  /** status 200 Success */ PagedResponseDtoBillingEntryDto;
export type PostSettingsV6PlansBillsApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: GetBillingsRequestDto;
};
export type GetSettingsV6PlansApiResponse =
  /** status 200 Success */ GetPlansResponseDtoRead;
export type GetSettingsV6PlansApiArg = {
  thresholdType?: PlanThresholdTypeDto | null;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostSettingsV6PlansPlanSupportingFeatureApiResponse =
  /** status 200 Success */ PlanNameDto;
export type PostSettingsV6PlansPlanSupportingFeatureApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: GetPlanSupportingFeatureRequestDto;
};
export type PostSettingsV6PlansSelectSubscriptionApiResponse =
  /** status 200 Success */ SelectSubscriptionPlanResponseDto;
export type PostSettingsV6PlansSelectSubscriptionApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  /** Encoded host value coming from Shopify */
  'X-DiscountNinja-EncodedHost': string;
  body: SelectSubscriptionPlanRequestDto;
};
export type PostSettingsV6PlansRequestDevelopmentPlanApiResponse = unknown;
export type PostSettingsV6PlansRequestDevelopmentPlanApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostSettingsV6PlansCancelSubscriptionApiResponse =
  /** status 204 No Content */ void;
export type PostSettingsV6PlansCancelSubscriptionApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: CancelSubscriptionRequestDto;
};
export type PostSettingsV6PlansResumeSubscriptionApiResponse =
  /** status 204 No Content */ void;
export type PostSettingsV6PlansResumeSubscriptionApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetSettingsV6AccountApiResponse =
  /** status 200 Success */ AccountSettingsDto;
export type GetSettingsV6AccountApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6AccountApiResponse = /** status 204 No Content */ void;
export type PutSettingsV6AccountApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: AccountSettingsDto;
};
export type PostSettingsV6GeneralAppSecretCheckApiResponse =
  /** status 204 No Content */ void;
export type PostSettingsV6GeneralAppSecretCheckApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  body: CheckAppSecretRequestDto;
};
export type GetSettingsV6AccountAuditLogsApiResponse =
  /** status 200 Success */ PagedResponseDtoAuditLogEntryDto;
export type GetSettingsV6AccountAuditLogsApiArg = {
  page?: number;
  itemsPerPage?: number;
  sortDirection?: SortDirectionDto | null;
  search?: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetSettingsV6TranslationsMainApiResponse =
  /** status 200 Success */ TranslationsMainSettingsDto;
export type GetSettingsV6TranslationsMainApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetSettingsV6TranslationsByLocaleApiResponse =
  /** status 200 Success */ TranslationsSettingsDto;
export type GetSettingsV6TranslationsByLocaleApiArg = {
  /** Language code */
  locale: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6TranslationsByLocaleApiResponse =
  /** status 204 No Content */ void;
export type PutSettingsV6TranslationsByLocaleApiArg = {
  /** Language code */
  locale: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: TranslationsSettingsDto;
};
export type GetSettingsV6StyleApiResponse =
  /** status 200 Success */ StyleSettingsDto;
export type GetSettingsV6StyleApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6StyleApiResponse = /** status 204 No Content */ void;
export type PutSettingsV6StyleApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: StyleSettingsDto;
};
export type GetSettingsV6IntegrationsApiResponse =
  /** status 200 Success */ IntegrationsSettingsDtoRead;
export type GetSettingsV6IntegrationsApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6IntegrationsApiResponse =
  /** status 204 No Content */ void;
export type PutSettingsV6IntegrationsApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: IntegrationsSettingsDto;
};
export type GetSettingsV6TagsMetadataApiResponse =
  /** status 200 Success */ TagsMetadataSettingsDto;
export type GetSettingsV6TagsMetadataApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6TagsMetadataApiResponse =
  /** status 204 No Content */ void;
export type PutSettingsV6TagsMetadataApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: TagsMetadataSettingsDto;
};
export type GetSettingsV6CartBehaviorApiResponse =
  /** status 200 Success */ CartBehaviorSettingsDto;
export type GetSettingsV6CartBehaviorApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6CartBehaviorApiResponse =
  /** status 204 No Content */ void;
export type PutSettingsV6CartBehaviorApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: CartBehaviorSettingsDto;
};
export type GetSettingsV6TestingApiResponse =
  /** status 200 Success */ TestingSettingsDto;
export type GetSettingsV6TestingApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6TestingApiResponse = /** status 204 No Content */ void;
export type PutSettingsV6TestingApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: TestingSettingsDto;
};
export type GetSettingsV6DiscountLinksApiResponse =
  /** status 200 Success */ DiscountLinksSettingsDto;
export type GetSettingsV6DiscountLinksApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6DiscountLinksApiResponse =
  /** status 204 No Content */ void;
export type PutSettingsV6DiscountLinksApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: DiscountLinksSettingsDto;
};
export type GetSettingsV6GiftsApiResponse =
  /** status 200 Success */ GiftsSettingsDto;
export type GetSettingsV6GiftsApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6GiftsApiResponse = /** status 204 No Content */ void;
export type PutSettingsV6GiftsApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: GiftsSettingsDto;
};
export type PostSettingsV6ImportApiResponse = /** status 204 No Content */ void;
export type PostSettingsV6ImportApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: {
    file?: Blob;
  };
};
export type GetSettingsV6ExportApiResponse = /** status 200 Success */ Blob;
export type GetSettingsV6ExportApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetSettingsV6CheckoutApiResponse =
  /** status 200 Success */ CheckoutSettingsDto;
export type GetSettingsV6CheckoutApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6CheckoutApiResponse =
  /** status 204 No Content */ void;
export type PutSettingsV6CheckoutApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: CheckoutSettingsDto;
};
export type GetSettingsV6SystemCheckApiResponse =
  /** status 200 Success */ SystemCheckResponseDto;
export type GetSettingsV6SystemCheckApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetSettingsV6EngineAndPlanCheckApiResponse =
  /** status 200 Success */ PromotionEngineAndPlanCheckResponseDto;
export type GetSettingsV6EngineAndPlanCheckApiArg = {
  themeType?: ThemeType | null;
  testThemeId?: number;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostSettingsV6UninstallAppApiResponse =
  /** status 204 No Content */ void;
export type PostSettingsV6UninstallAppApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: UninstallAppRequestDto;
};
export type GetSettingsV6ShippingRatesApiResponse =
  /** status 200 Success */ CustomShippingRateSettingsDto;
export type GetSettingsV6ShippingRatesApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostSettingsV6ShippingRatesApiResponse =
  /** status 201 Created */ CustomShippingRateDto;
export type PostSettingsV6ShippingRatesApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: CustomShippingRateDto;
};
export type PutSettingsV6ShippingRatesByIdApiResponse =
  /** status 201 Created */ CustomShippingRateDto;
export type PutSettingsV6ShippingRatesByIdApiArg = {
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: CustomShippingRateDto;
};
export type DeleteSettingsV6ShippingRatesByIdApiResponse =
  /** status 204 No Content */ void;
export type DeleteSettingsV6ShippingRatesByIdApiArg = {
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetSettingsV6NotificationsApiResponse =
  /** status 200 Success */ NotificationsSettingsDto;
export type GetSettingsV6NotificationsApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6NotificationsApiResponse =
  /** status 204 No Content */ void;
export type PutSettingsV6NotificationsApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: NotificationsSettingsDto;
};
export type GetSettingsV6ThemesApiResponse =
  /** status 200 Success */ ThemeSlotDto[];
export type GetSettingsV6ThemesApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6ThemesApiResponse =
  /** status 200 Success */ ThemeSlotDto[];
export type PutSettingsV6ThemesApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: SaveThemeSlotsRequestDto;
};
export type GetSettingsV6ThemesStatusApiResponse =
  /** status 200 Success */ ThemeStatusesDto[];
export type GetSettingsV6ThemesStatusApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetSettingsV6VersionApiResponse = unknown;
export type GetSettingsV6VersionApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
};
export type GetSettingsV6ThemesByThemeTypeWidgetInstallationApiResponse =
  /** status 200 Success */ WidgetsInstallationStatusSettingsDto;
export type GetSettingsV6ThemesByThemeTypeWidgetInstallationApiArg = {
  themeType: ThemeTypeDto | null;
  /** Identifier of a test theme */
  testThemeId?: number;
  /** Header for controlling the cache. In order to break server cache, 'no-cache' value should be provided. */
  'Cache-Control'?: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6ThemesByThemeTypeWidgetInstallationInstallApiResponse =
  /** status 204 No Content */ void;
export type PutSettingsV6ThemesByThemeTypeWidgetInstallationInstallApiArg = {
  themeType: ThemeTypeDto | null;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: InstallWidgetRequestDto;
};
export type GetSettingsV6WidgetInstallationSupportRequestApiResponse =
  /** status 200 Success */ GetSupportRequestResponseDto;
export type GetSettingsV6WidgetInstallationSupportRequestApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostSettingsV6WidgetInstallationSupportRequestApiResponse =
  /** status 201 Created */ CreateSupportRequestResponseDto;
export type PostSettingsV6WidgetInstallationSupportRequestApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: InstallationSupportRequestDto;
};
export type PutSettingsV6WidgetInstallationSupportRequestApiResponse =
  /** status 204 No Content */ void;
export type PutSettingsV6WidgetInstallationSupportRequestApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: InstallationSupportRequestDto;
};
export type DeleteSettingsV6WidgetInstallationSupportRequestApiResponse =
  /** status 204 No Content */ void;
export type DeleteSettingsV6WidgetInstallationSupportRequestApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetSettingsV6ThemesWidgetSettingsAnnouncementBarApiResponse =
  /** status 200 Success */ WidgetSettingsDtoAnnouncementBarShopSettingsDto;
export type GetSettingsV6ThemesWidgetSettingsAnnouncementBarApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6ThemesWidgetSettingsAnnouncementBarApiResponse =
  /** status 204 No Content */ void;
export type PutSettingsV6ThemesWidgetSettingsAnnouncementBarApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: AnnouncementBarShopSettingsDto;
};
export type GetSettingsV6ThemesWidgetSettingsProductBannerApiResponse =
  /** status 200 Success */ WidgetSettingsDtoProductBannerShopSettingsDto;
export type GetSettingsV6ThemesWidgetSettingsProductBannerApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6ThemesWidgetSettingsProductBannerApiResponse =
  /** status 204 No Content */ void;
export type PutSettingsV6ThemesWidgetSettingsProductBannerApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: ProductBannerShopSettingsDto;
};
export type GetSettingsV6ThemesWidgetSettingsPromotionalBadgeApiResponse =
  /** status 200 Success */ WidgetSettingsDtoPromotionalBadgeShopSettingsDto;
export type GetSettingsV6ThemesWidgetSettingsPromotionalBadgeApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6ThemesWidgetSettingsPromotionalBadgeApiResponse =
  /** status 204 No Content */ void;
export type PutSettingsV6ThemesWidgetSettingsPromotionalBadgeApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: PromotionalBadgeShopSettingsDto;
};
export type GetSettingsV6ThemesWidgetSettingsNotificationApiResponse =
  /** status 200 Success */ WidgetSettingsDtoNotificationShopSettingsDto;
export type GetSettingsV6ThemesWidgetSettingsNotificationApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6ThemesWidgetSettingsNotificationApiResponse =
  /** status 204 No Content */ void;
export type PutSettingsV6ThemesWidgetSettingsNotificationApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: NotificationShopSettingsDto;
};
export type GetSettingsV6ThemesWidgetSettingsOfferRulePopupApiResponse =
  /** status 200 Success */ WidgetSettingsDtoOfferRulePopupShopSettingsDto;
export type GetSettingsV6ThemesWidgetSettingsOfferRulePopupApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6ThemesWidgetSettingsOfferRulePopupApiResponse =
  /** status 204 No Content */ void;
export type PutSettingsV6ThemesWidgetSettingsOfferRulePopupApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: OfferRulePopupShopSettingsDto;
};
export type GetSettingsV6ThemesWidgetSettingsPopupApiResponse =
  /** status 200 Success */ WidgetSettingsDtoOfferRulePopupShopSettingsDto;
export type GetSettingsV6ThemesWidgetSettingsPopupApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6ThemesWidgetSettingsPopupApiResponse =
  /** status 204 No Content */ void;
export type PutSettingsV6ThemesWidgetSettingsPopupApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: PopupShopSettingsDto;
};
export type GetSettingsV6ThemesWidgetSettingsPromotionCodeFieldApiResponse =
  /** status 200 Success */ WidgetSettingsDtoPromotionCodeFieldShopSettingsDto;
export type GetSettingsV6ThemesWidgetSettingsPromotionCodeFieldApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6ThemesWidgetSettingsPromotionCodeFieldApiResponse =
  /** status 204 No Content */ void;
export type PutSettingsV6ThemesWidgetSettingsPromotionCodeFieldApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: PromotionCodeFieldShopSettingsDto;
};
export type GetSettingsV6ThemesWidgetSettingsPromotionSummaryApiResponse =
  /** status 200 Success */ WidgetSettingsDtoPromotionSummaryShopSettingsDto;
export type GetSettingsV6ThemesWidgetSettingsPromotionSummaryApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6ThemesWidgetSettingsPromotionSummaryApiResponse =
  /** status 204 No Content */ void;
export type PutSettingsV6ThemesWidgetSettingsPromotionSummaryApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: PromotionSummaryShopSettingsDto;
};
export type GetSettingsV6ThemesWidgetSettingsCountdownClockApiResponse =
  /** status 200 Success */ WidgetSettingsDtoCountdownClockShopSettingsDto;
export type GetSettingsV6ThemesWidgetSettingsCountdownClockApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6ThemesWidgetSettingsCountdownClockApiResponse =
  /** status 204 No Content */ void;
export type PutSettingsV6ThemesWidgetSettingsCountdownClockApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: CountdownClockShopSettingsDto;
};
export type GetSettingsV6ThemesWidgetSettingsStrikethroughPricingApiResponse =
  /** status 200 Success */ StrikethroughPricingGeneralSettingsDto;
export type GetSettingsV6ThemesWidgetSettingsStrikethroughPricingApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6ThemesWidgetSettingsStrikethroughPricingApiResponse =
  /** status 204 No Content */ void;
export type PutSettingsV6ThemesWidgetSettingsStrikethroughPricingApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: StrikethroughPricingGeneralWidgetShopSettingsDto;
};
export type GetSettingsV6ThemesWidgetSettingsStrikethroughPricingProductApiResponse =
  /** status 200 Success */ WidgetSettingsDtoProductStrikethroughPricingShopSettingsDto;
export type GetSettingsV6ThemesWidgetSettingsStrikethroughPricingProductApiArg =
  {
    /** Name of the app to authenticate against */
    'X-LimoniApps-AppName': string;
    /** Secret of the app */
    'X-LimoniApps-AppSecret': string;
  };
export type PutSettingsV6ThemesWidgetSettingsStrikethroughPricingProductApiResponse =
  /** status 204 No Content */ void;
export type PutSettingsV6ThemesWidgetSettingsStrikethroughPricingProductApiArg =
  {
    /** Name of the app to authenticate against */
    'X-LimoniApps-AppName': string;
    /** Secret of the app */
    'X-LimoniApps-AppSecret': string;
    body: ProductStrikethroughPricingShopSettingsDto;
  };
export type GetSettingsV6ThemesWidgetSettingsCheckoutLoadingPopupApiResponse =
  /** status 200 Success */ WidgetSettingsDtoCheckoutLoadingPopupShopSettingsDto;
export type GetSettingsV6ThemesWidgetSettingsCheckoutLoadingPopupApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6ThemesWidgetSettingsCheckoutLoadingPopupApiResponse =
  /** status 204 No Content */ void;
export type PutSettingsV6ThemesWidgetSettingsCheckoutLoadingPopupApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: CheckoutLoadingPopupShopSettingsDto;
};
export type GetSettingsV6ThemesWidgetSettingsFrequentBoughtTogetherApiResponse =
  /** status 200 Success */ WidgetSettingsDtoFrequentBoughtTogetherShopSettingsDto;
export type GetSettingsV6ThemesWidgetSettingsFrequentBoughtTogetherApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutSettingsV6ThemesWidgetSettingsFrequentBoughtTogetherApiResponse =
  /** status 204 No Content */ void;
export type PutSettingsV6ThemesWidgetSettingsFrequentBoughtTogetherApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: FrequentBoughtTogetherShopSettingsDto;
};
export type AppVersionDto = {
  number?: string;
  name?: string;
  releaseNotesUrl?: string;
  imageUrl?: string;
};
export type AlertTypeDto =
  | (
      | 'SETTINGS_CHECKOUT_MODE'
      | 'SETTINGS_CONFIGURE_STRIKETHROUGH_PRICING'
      | 'SETTINGS_PLAN_UPGRADE'
      | 'SETTINGS_PLAN_CHECK_BILLING'
      | 'APP_RELOAD'
      | 'APP_UPGRADE'
    )
  | null;
export type AlertLevelDto = ('INFORMATION' | 'WARNING' | 'CRITICAL') | null;
export type AlertDto = {
  text?: string;
  actionType?: AlertTypeDto | null;
  level?: AlertLevelDto | null;
  url?: string | null;
  dismissKey?: string | null;
};
export type GetAlertsResponseDto = {
  appVersion?: AppVersionDto;
  alerts?: AlertDto[];
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type GetAlertsRequestDto = object;
export type PromotionEngineSetupStatusEntryDto = {
  enabled?: boolean;
  installationDeepLink?: string | null;
  imageUrl?: string;
};
export type SetupStatusEntryDto = {
  isCompleted?: boolean;
  imageUrl?: string;
};
export type AppEmbedSetupStatusEntryDto = {
  enabled?: boolean;
  installationDeepLink?: string | null;
  imageUrl?: string;
};
export type SubscriptionPlanSetupStatusEntryDto = {
  isCompleted?: boolean;
  imageUrl?: string;
  freeTrialDays?: number;
};
export type SetupStatusDto = {
  promotionEngine?: PromotionEngineSetupStatusEntryDto;
  strikethroughPricing?: SetupStatusEntryDto;
  ninjaCart?: AppEmbedSetupStatusEntryDto;
  subscriptionPlan?: SubscriptionPlanSetupStatusEntryDto;
};
export type SetupStatusDtoRead = {
  isCompleted?: boolean;
  promotionEngine?: PromotionEngineSetupStatusEntryDto;
  strikethroughPricing?: SetupStatusEntryDto;
  ninjaCart?: AppEmbedSetupStatusEntryDto;
  subscriptionPlan?: SubscriptionPlanSetupStatusEntryDto;
};
export type TestPromotionTypeDto =
  | ('FREE_GIFT' | 'FREE_SHIPPING' | 'EARLY_BIRD')
  | null;
export type TestPromotionEntryDto = {
  type?: TestPromotionTypeDto | null;
  imageUrl?: string;
};
export type TestPromotionStatusDto = {
  alreadyCreated?: boolean;
  types?: TestPromotionEntryDto[];
};
export type AlertsCountSummaryDto = {
  critical?: number;
  warning?: number;
};
export type SupportRequestStatusDto = {
  alreadySubmitted?: boolean;
  submissionContainsStrikethroughPricing?: boolean;
};
export type GetDashboardDataResponseDto = {
  setupStatus?: SetupStatusDto;
  testPromotionStatus?: TestPromotionStatusDto;
  alertStatus?: AlertsCountSummaryDto;
  tutorialVideoUrl?: string;
  appVersion?: AppVersionDto;
  arePromotionInsightsAvailable?: boolean;
  supportRequestStatus?: SupportRequestStatusDto;
};
export type GetDashboardDataResponseDtoRead = {
  setupStatus?: SetupStatusDtoRead;
  testPromotionStatus?: TestPromotionStatusDto;
  alertStatus?: AlertsCountSummaryDto;
  tutorialVideoUrl?: string;
  appVersion?: AppVersionDto;
  arePromotionInsightsAvailable?: boolean;
  supportRequestStatus?: SupportRequestStatusDto;
};
export type LimitationsOverrideShopifyDiscountCodesSettingsDto = {
  allowWithSelectedCountriesFilter?: boolean;
};
export type ShopifyDiscountCodesSettingsDto = {
  isEnabled?: boolean;
  limitationsOverrideSettings?: LimitationsOverrideShopifyDiscountCodesSettingsDto;
};
export type DiscountNinjaPromoCodesMultipleSupportTypeDto =
  | ('ALLOW_MULTIPLE' | 'DONT_ALLOW_MULTIPLE')
  | null;
export type DiscountNinjaPromoCodesRememberTypeDto =
  | ('ANY_TAB' | 'SPECIFIC_TAB')
  | null;
export type DiscountNinjaPromoCodesExpirationTypeDto =
  | ('DO_NOT_EXPIRE' | 'EXPIRE_AFTER_1_DAY' | 'EXPIRE_AFTER_7_DAYS')
  | null;
export type DiscountNinjaPromoCodesSettingsDto = {
  isEnabled?: boolean;
  multipleSupport?: DiscountNinjaPromoCodesMultipleSupportTypeDto | null;
  rememberType?: DiscountNinjaPromoCodesRememberTypeDto | null;
  expiration?: DiscountNinjaPromoCodesExpirationTypeDto | null;
};
export type TimezoneDto = {
  id?: string;
  name?: string;
};
export type ThemeDto = {
  id?: number;
  name?: string;
  author?: string;
  version?: string;
  isLiveTheme?: boolean;
  supportsOS2?: boolean;
  promotionEngineInstallationDeepLink?: string;
};
export type LocaleDto = {
  locale?: string;
  name?: string;
  isDefault?: boolean;
};
export type ReleaseVersionModeDto = ('STABLE' | 'LATEST' | 'REGULAR') | null;
export type ReleaseVersionDto = {
  id?: string;
  mode?: ReleaseVersionModeDto | null;
  version?: string;
};
export type ShopifyPageTemplateDto = {
  id?: string;
  name?: string;
  isEnabled?: boolean;
  installationDeepLink?: string;
};
export type ThemeTypeDto = ('CURRENT' | 'TEST') | null;
export type WidgetTypeDto =
  | (
      | 'ANNOUNCEMENT_BAR'
      | 'NOTIFICATIONS'
      | 'POP_UP'
      | 'OFFER_RULE_POPUP'
      | 'CHECKOUT_LOADING_POPUP'
      | 'COUNTDOWN_CLOCK'
      | 'DISCOUNT_LABEL'
      | 'FREQUENTLY_BOUGHT_TOGETHER'
      | 'PRODUCT_BANNER'
      | 'PROMOTION_CODE_FIELD'
      | 'PROMOTION_SUMMARY'
      | 'VOLUME_DISCOUNT'
      | 'PRODUCT_STRIKETHROUGH_PRICING'
      | 'CART_TEXT'
      | 'PROMOTIONAL_BADGE'
      | 'CUSTOM_STRIKETHROUGH_PRICING'
      | 'NINJA_CART'
      | 'ESSENTIALS'
    )
  | null;
export type OnboardingInitialDataResponseDto = {
  brandColors?: string[];
  isSupportRequestAlreadyCreated?: boolean;
};
export type SettingsValidationErrorCode =
  | (
      | 'COLOR_INVALID_HEX_FORMAT'
      | 'INVALID_TIMEZONE'
      | 'ORDER_TAGS_INVALID_TAG'
      | 'TAGS_METADATA_DISCOUNT_NINJA_ORIGINATED_TAG_MISSING'
      | 'TAGS_METADATA_PREFIX_OFFER_MISSING'
      | 'TAGS_METADATA_PREFIX_DISCOUNT_CODE_MISSING'
      | 'TAGS_METADATA_PREFIX_PROMOTION_MISSING'
      | 'TAGS_METADATA_PREFIX_PROMOTION_CODE_MISSING'
      | 'SHAREABLE_LINKS_SETTINGS_INVALID_LINK_EXPIRATION_DAYS'
      | 'CUSTOM_STYLING_INVALID_FONT_WEIGHT'
      | 'CUSTOM_STYLING_MISSING_LINK_STYLE_VALUE'
      | 'ACCOUNT_PRIMARY_USER_FIRST_NAME_INVALID'
      | 'ACCOUNT_PRIMARY_USER_LAST_NAME_INVALID'
      | 'ACCOUNT_RECIPIENTS_INVALID_EMAIL_FORMAT'
      | 'SHOPIFY_DISCOUNT_CODES_INCLUDE_PATTERN_MISSING'
      | 'SHOPIFY_DISCOUNT_CODES_EXCLUDE_PATTERN_MISSING'
      | 'CHECKOUT_DRAFT_ORDER_REMOVAL_OPTION_MISSING'
      | 'CHECKOUT_DISCOUNT_CODES_PREFIX_MISSING'
      | 'CHECKOUT_BUTTON_LOADING_EFFECT_TIMEOUT_MISSING'
      | 'CHECKOUT_BUTTON_LOADING_EFFECT_TIMEOUT_INVALID'
      | 'CHECKOUT_PRICES_LOADING_EFFECT_TIMEOUT_INVALID'
      | 'CHECKOUT_PRICES_LOADING_EFFECT_TIMEOUT_MISSING'
      | 'CHECKOUT_FALLBACK_MODE_MISSING'
      | 'TRANSLATIONS_INVALID_LOCALE'
      | 'TRANSLATIONS_INVALID_TEXT_LABELS'
      | 'TRANSLATIONS_INVALID_COUNTDOWN_CLOCK_LABELS'
      | 'TRANSLATIONS_INVALID_CHECKOUT_LOADING_MESSAGES'
      | 'TRANSLATIONS_INVALID_PAGE_REFRESH_MESSAGES'
      | 'TRANSLATIONS_INVALID_ACCESSIBILITY_LABELS'
      | 'INSTALLATION_SUPPORT_REQUEST_NOTE_CANNOT_BE_EMPTY'
      | 'INSTALLATION_SUPPORT_REQUEST_ALREADY_EXISTS'
      | 'INSTALLATION_SUPPORT_REQUEST_SELECTED_WIDGETS_ARE_REQUIRED'
      | 'SHAREABLE_LINKS_SETTINGS_MISSING_LINK_EXPIRATION_DAYS'
      | 'APP_SECRET_INVALID'
      | 'INSTALLATION_CODE_MARKUP_NOT_FOUND_IN_ASSET'
      | 'MISSING_CUSTOM_SPACING'
      | 'INVALID_CUSTOM_SPACING'
      | 'BREAKPOINT_CONTENT_WIDTH_CANNOT_BE_HIGHER_THAN_MAX_WIDTH'
      | 'BREAKPOINT_CONTENT_WIDTH_INVALID'
      | 'BREAKPOINT_MAX_WIDTH_INVALID'
      | 'OFFSET_INVALID_CUSTOM_VALUE'
      | 'OFFSET_TYPE_MISSING'
      | 'THEME_SLOTS_INVALID_THEME_TYPE'
      | 'INVALID_SIZE_FIXED_WIDTH'
      | 'INVALID_Z_INDEX_VALUE'
      | 'INVALID_ANIMATION'
      | 'INVALID_PAGE_TYPES'
      | 'PROMOTION_SUMMARY_SHIPPING_DISPLAY_TEXT_MISSING'
      | 'INVALID_COLOR'
      | 'LOCALIZATION_TRANSLATIONS_GIFT_PURCHASE_MESSAGES_MISSING'
      | 'LOCALIZATION_TRANSLATIONS_SUBSCRIPTION_PRODUCTS_MESSAGES_MISSING'
      | 'COLOR_INVALID_ALPHA'
      | 'TRANSLATIONS_INVALID_GIFT_PURCHASE_MESSAGES'
      | 'TRANSLATIONS_INVALID_SUBSCRIPTION_PRODUCTS_MESSAGES'
      | 'ACCOUNT_RECIPIENTS_MISSING_ALL_NOTIFICATION_TYPES'
      | 'INTEGRATIONS_SETTINGS_SUBSCRIPTION_PRODUCTS_CHECKOUT_MODE_MISSING'
      | 'CART_BEHAVIOR_REFRESH_BEHAVIOR_JAVASCRIPT_METHOD_MISSING'
      | 'GIFTS_FREE_GIFT_NOTIFICATION_LOCATION_MISSING'
      | 'GIFTS_GIFT_PRICE_HIDE_ON_PAGES_MISSING'
      | 'SETTINGS_FILE_TO_BE_IMPORTED_MISSING'
      | 'SETTINGS_FILE_INVALID_TYPE'
      | 'SETTINGS_FILE_BROKEN_CONTENT'
      | 'CART_BEHAVIOR_NOTIFICATION_POSITION_INVALID'
      | 'INVALID_PLAN_SELECTED'
      | 'NO_SUBSCRIPTION_TO_BE_CANCELED_OR_RESUMED'
      | 'CHECKOUT_NON_BASE_CURRENCY_CHECKOUT_MODE_INVALID'
      | 'CHECKOUT_INVALID_DYNAMIC_CHECKOUT_BUTTONS_OVERRIDDEN_PAGES'
      | 'SUBSCRIPTION_REPLACEMENT_BEHAVIOR_MISSING'
      | 'ONBOARDING_INVALID_EMAIL_FORMAT'
      | 'ONBOARDING_BRAND_COLORS_INVALID_AMOUNT'
      | 'ONBOARDING_INVALID_AMOUNT_OF_GOALS'
      | 'ONBOARDING_MISSING_OTHER_GOAL'
      | 'INSTALLATION_CHANGES_FORBIDDEN_FOR_WIDGET'
      | 'SHIPPING_RATES_INVALID_LABEL'
      | 'SHIPPING_RATES_LABEL_ALREADY_EXISTS'
    )
  | null;
export type ValidationFailedResponseDto = {
  errors?: SettingsValidationErrorCode[];
};
export type ValidationFailedResponseDtoRead = {
  errors?: SettingsValidationErrorCode[];
  relatedItems?: string[];
};
export type OnboardingAppGoalDto =
  | (
      | 'GROW_AVERAGE_ORDER_VALUE'
      | 'INCREASE_CONVERSION_RATE'
      | 'SPECIAL_OFFERS'
      | 'CROSS_SELL_UP_SELL'
      | 'SECRET_DEALS'
      | 'SMART_CART'
      | 'OTHER'
    )
  | null;
export type OnboardingAppGoalSetupDto = {
  goals?: OnboardingAppGoalDto[];
  otherGoal?: string | null;
};
export type CompleteOnboardingRequestDto = {
  goal?: OnboardingAppGoalSetupDto;
  email?: string;
  subscribeToNewsletter?: boolean;
  brandColors?: string[];
};
export type PlanLevelDto =
  | (
      | 'DEVELOPMENT'
      | 'STARTER_1'
      | 'STARTER_2'
      | 'STARTER_3'
      | 'STARTER_4'
      | 'PRO_1'
      | 'PRO_2'
      | 'PRO_3'
      | 'PRO_4'
      | 'PLUS_1'
      | 'PLUS_2'
      | 'PLUS_3'
      | 'PLUS_4'
      | 'ENTERPRISE'
    )
  | null;
export type CurrentPlanDiscountDto = {
  name?: string;
  discountPercentage?: number;
  discountedPrice?: number | null;
  isDiscountApplied?: boolean;
};
export type UserSubscriptionPlanPriceDetailsDto = {
  regularPrice?: number;
  discount?: CurrentPlanDiscountDto | null;
};
export type PlanIntervalDto = ('ANNUAL' | 'MONTHLY') | null;
export type PlanThresholdTypeDto = ('SESSION' | 'ORDER') | null;
export type PlanNameDto = {
  level?: PlanLevelDto | null;
  name?: string;
};
export type PlanUpgradeDetailsDto = {
  nextSameTierPlan?: PlanNameDto | null;
  nextHigherTierPlan?: PlanNameDto | null;
};
export type PlanForSelectionDto = {
  level?: PlanLevelDto | null;
  name?: string;
  interval?: PlanIntervalDto | null;
  thresholdType?: PlanThresholdTypeDto | null;
};
export type DowngradedPlanDto = {
  level?: PlanLevelDto | null;
  name?: string;
  publishedPromotionsLimit?: number;
  interval?: PlanIntervalDto | null;
};
export type UserSubscriptionPlanDto = {
  level?: PlanLevelDto | null;
  name?: string | null;
  price?: UserSubscriptionPlanPriceDetailsDto | null;
  interval?: PlanIntervalDto | null;
  thresholdType?: PlanThresholdTypeDto | null;
  trialEndDate?: number | null;
  cancellationDate?: number | null;
  upgradeDetails?: PlanUpgradeDetailsDto | null;
  previousPlan?: PlanForSelectionDto | null;
  downgradedPlan?: DowngradedPlanDto | null;
};
export type UserSubscriptionUsageDetailsDto = {
  threshold?: number;
  usage?: number;
};
export type UserSubscriptionUsageDto = {
  thresholdType?: PlanThresholdTypeDto | null;
  mainUsageDetails?: UserSubscriptionUsageDetailsDto | null;
  overageUsageDetails?: UserSubscriptionUsageDetailsDto | null;
  overageFeeUnitPrice?: number | null;
  counterResetDate?: number;
};
export type BillingSubscriptionEntryDto = {
  interval?: PlanIntervalDto | null;
  amount?: number;
};
export type BillingSummaryFeesDto = {
  subscription?: BillingSubscriptionEntryDto;
  overage?: BillingSubscriptionEntryDto;
};
export type BillingSummaryFeesDtoRead = {
  subscription?: BillingSubscriptionEntryDto;
  overage?: BillingSubscriptionEntryDto;
  total?: number;
};
export type UserBillingSummaryDto = {
  startDate?: number | null;
  endDate?: number | null;
  nextBillingDate?: number | null;
  fees?: BillingSummaryFeesDto;
  saveAnnuallyAmount?: number | null;
};
export type UserBillingSummaryDtoRead = {
  startDate?: number | null;
  endDate?: number | null;
  nextBillingDate?: number | null;
  fees?: BillingSummaryFeesDtoRead;
  saveAnnuallyAmount?: number | null;
};
export type GetCurrentPlanResponseDto = {
  plan?: UserSubscriptionPlanDto | null;
  usage?: UserSubscriptionUsageDto | null;
  billing?: UserBillingSummaryDto | null;
};
export type GetCurrentPlanResponseDtoRead = {
  plan?: UserSubscriptionPlanDto | null;
  usage?: UserSubscriptionUsageDto | null;
  billing?: UserBillingSummaryDtoRead | null;
};
export type BillingFeeTypeDto =
  | ('SUBSCRIPTION' | 'SUBSCRIPTION_OVERAGE' | 'FIXED')
  | null;
export type PaymentStatusDto = ('PENDING' | 'PAID') | null;
export type BillingEntryDto = {
  issuedAt?: number;
  number?: string;
  type?: BillingFeeTypeDto | null;
  status?: PaymentStatusDto | null;
  amount?: number;
};
export type PagedResponseDtoBillingEntryDto = {
  totalItems?: number;
  items?: BillingEntryDto[];
};
export type SortDirectionDto = ('ASC' | 'DESC') | null;
export type BillingsSortFieldDto = 'ISSUED_AT' | null;
export type GetBillingsRequestDto = {
  search?: string | null;
  page?: number;
  itemsPerPage?: number | null;
  sortDirection?: SortDirectionDto | null;
  sortBy?: BillingsSortFieldDto | null;
};
export type PlanDiscountDto = {
  name?: string;
  discountPercentage?: number;
};
export type PlanThresholdDetailsDto = {
  perYear?: number;
  perMonth?: number;
};
export type PlanPriceSetDto = {
  annualPlanPerYearPrice?: number;
  monthlyPlanPerMonthPrice?: number;
  annualOverageUnitPrice?: number;
  monthlyOverageUnitPrice?: number;
};
export type PlanPriceSetDtoRead = {
  annualPlanPerYearPrice?: number;
  annualPlanPerMonthPrice?: number;
  monthlyPlanPerYearPrice?: number;
  monthlyPlanPerMonthPrice?: number;
  annualOverageUnitPrice?: number;
  monthlyOverageUnitPrice?: number;
};
export type PlanIntervalPriceDto = {
  regular?: PlanPriceSetDto;
  discounted?: PlanPriceSetDto | null;
  saving?: number | null;
  savingPercentage?: number | null;
};
export type PlanIntervalPriceDtoRead = {
  regular?: PlanPriceSetDtoRead;
  discounted?: PlanPriceSetDtoRead | null;
  saving?: number | null;
  savingPercentage?: number | null;
};
export type PlanPricingDetailsDto = {
  intervalPrices?: PlanIntervalPriceDto;
};
export type PlanPricingDetailsDtoRead = {
  intervalPrices?: PlanIntervalPriceDtoRead;
};
export type PlanReportingComparisonDto = {
  kpis?: boolean;
  reportsPerPromotion?: boolean;
  orderTagging?: boolean;
};
export type PlanSpecialOffersComparisonDto = {
  percentageFixedAmountShipping?: boolean;
  gwpBogoVolumeDiscountTieredDiscounts?: boolean;
  standardScheduling?: boolean;
  advancedScheduling?: boolean;
};
export type PlanGiftComparisonDto = {
  automaticallyAddRemoveGifts?: boolean;
  detectOutOfStockGifts?: boolean;
  giftPicker?: boolean;
};
export type PlanNinjaCartComparisonDto = {
  smartDrawerCart?: boolean;
  singleGoalProgressBar?: boolean;
};
export type PlanNinjaCartProComparisonDto = {
  giftPicker?: boolean;
  multiGoalProgressBar?: boolean;
  frequentlyBoughtTogether?: boolean;
  subscriptionUpsell?: boolean;
};
export type PlanXUpsellComparisonDto = {
  frequentlyBoughtTogether?: boolean;
  orderBump?: boolean;
  crossSellUpsellPopup?: boolean;
};
export type PlanWidgetsComparisonDto = {
  strikethroughPricing?: boolean;
  announcementBar?: boolean;
  notifications?: boolean;
  countdownClock?: boolean;
  productBanner?: boolean;
  collectionBadge?: boolean;
  cartText?: boolean;
  promotionCodeField?: boolean;
  promotionSummary?: boolean;
  giftPicker?: boolean;
  checkoutUiExtensions?: boolean;
};
export type PlanPlatformComparisonDto = {
  customJs?: boolean;
  customCss?: boolean;
  multiLanguage?: boolean;
  apiAccess?: boolean;
};
export type PlanAccountComparisonDto = {
  expansionStoresSupported?: boolean;
  expansionStoresDiscount?: number;
};
export type PlanSupportDetailsDto = {
  prioritySupport?: boolean;
  roundTheClockSupport?: boolean;
  call?: boolean;
  chat?: boolean;
  email?: boolean;
};
export type PlanComparisonDetailsDto = {
  reporting?: PlanReportingComparisonDto;
  specialOffers?: PlanSpecialOffersComparisonDto;
  gift?: PlanGiftComparisonDto;
  ninjaCart?: PlanNinjaCartComparisonDto;
  ninjaCartPro?: PlanNinjaCartProComparisonDto;
  xUpsell?: PlanXUpsellComparisonDto;
  widgets?: PlanWidgetsComparisonDto;
  platform?: PlanPlatformComparisonDto;
  account?: PlanAccountComparisonDto;
  support?: PlanSupportDetailsDto;
};
export type PlanFeatureDto =
  | (
      | 'MULTI_LANGUAGE'
      | 'API_ACCESS'
      | 'ADVANCED_SCHEDULING'
      | 'ORDER_BUMP'
      | 'CROSS_SELL'
      | 'CUSTOM_JS_CSS'
      | 'NINJA_CART_PRO'
    )
  | null;
export type PlanQuoteDto = {
  contactEmail?: string;
  isAvailable?: boolean;
};
export type PlanDto = {
  level?: PlanLevelDto | null;
  thresholdType?: PlanThresholdTypeDto | null;
  name?: string;
  isCurrentMonthly?: boolean;
  isCurrentAnnually?: boolean;
  publishedPromotionsLimit?: number;
  threshold?: PlanThresholdDetailsDto;
  pricing?: PlanPricingDetailsDto;
  comparisonDetails?: PlanComparisonDetailsDto;
  features?: PlanFeatureDto[];
  quote?: PlanQuoteDto | null;
};
export type PlanDtoRead = {
  level?: PlanLevelDto | null;
  thresholdType?: PlanThresholdTypeDto | null;
  name?: string;
  isCurrentMonthly?: boolean;
  isCurrentAnnually?: boolean;
  publishedPromotionsLimit?: number;
  threshold?: PlanThresholdDetailsDto;
  pricing?: PlanPricingDetailsDtoRead;
  comparisonDetails?: PlanComparisonDetailsDto;
  features?: PlanFeatureDto[];
  quote?: PlanQuoteDto | null;
};
export type DevelopmentPlanDto = {
  level?: PlanLevelDto | null;
  thresholdType?: PlanThresholdTypeDto | null;
  threshold?: number;
  hasBeenRequested?: boolean;
};
export type GetPlansResponseDto = {
  discount?: PlanDiscountDto | null;
  plans?: PlanDto[];
  developmentPlan?: DevelopmentPlanDto;
  trialDays?: number;
  currentPlan?: PlanDto | null;
};
export type GetPlansResponseDtoRead = {
  discount?: PlanDiscountDto | null;
  plans?: PlanDtoRead[];
  developmentPlan?: DevelopmentPlanDto;
  trialDays?: number;
  currentPlan?: PlanDtoRead | null;
};
export type GetPlanSupportingFeatureRequestDto = {
  features?: PlanFeatureDto[];
};
export type SelectSubscriptionPlanResponseDto = {
  redirectUri?: string | null;
};
export type SelectSubscriptionPlanRequestDto = {
  level?: PlanLevelDto | null;
  interval?: PlanIntervalDto | null;
  thresholdType?: PlanThresholdTypeDto | null;
};
export type CancelSubscriptionPlanReasonDto =
  | (
      | 'CANNOT_FIND_PROPER_PLAN'
      | 'APP_NOT_USED_ENOUGH'
      | 'BETTER_ALTERNATIVE'
      | 'TECHNICAL_ISSUES'
      | 'OTHER'
    )
  | null;
export type CancelSubscriptionRequestDto = {
  reasons?: CancelSubscriptionPlanReasonDto[];
  otherReason?: string | null;
};
export type AccountSettingsPrimaryUserDto = {
  firstName?: string;
  lastName?: string;
};
export type AccountNotificationsRecipientDto = {
  emailAddress?: string;
  isEnabled?: boolean;
  statusUpdates?: boolean;
  system?: boolean;
  releaseNotes?: boolean;
};
export type AccountNotificationSettingsDto = {
  recipients?: AccountNotificationsRecipientDto[];
};
export type AccountSettingsDto = {
  primaryUser?: AccountSettingsPrimaryUserDto;
  notifications?: AccountNotificationSettingsDto;
  timezone?: string;
  shopAddress?: string | null;
};
export type CheckAppSecretRequestDto = {
  secret?: string;
};
export type AuditLogEntryPerformedByDto = {
  fullName?: string;
  email?: string;
  isSystem?: boolean;
};
export type AuditLogEntryDto = {
  id?: string;
  date?: string;
  performedBy?: string;
  performedByDetails?: AuditLogEntryPerformedByDto;
  action?: string;
};
export type PagedResponseDtoAuditLogEntryDto = {
  totalItems?: number;
  items?: AuditLogEntryDto[];
};
export type TranslationsMainSettingsDto = {
  planSupportsMultilanguage?: boolean;
  locales?: LocaleDto[];
};
export type TextLabelsTranslationsDto = {
  free?: string;
  here?: string;
  from?: string;
  soldOut?: string;
  asLowAs?: string;
};
export type CountdownClockLabelsTranslationsDto = {
  days?: string;
  hours?: string;
  minutes?: string;
  seconds?: string;
  and?: string;
};
export type CheckoutLoadingMessagesTranslationsDto = {
  initialMessage?: string;
  checkoutRedirectionMessage?: string;
  technicalFailureMessage?: string;
};
export type PageRefreshMessagesTranslationsDto = {
  updatingCart?: string;
};
export type AccessibilityLabelsTranslationsDto = {
  per?: string;
};
export type GiftPurchaseMessagesTranslationsDto = {
  freeGiftNotification?: string;
};
export type SubscriptionProductsMessagesTranslationsDto = {
  checkoutNotAllowed?: string;
  checkoutViaSubscriptionApp?: string;
};
export type TranslationsSettingsDto = {
  textLabels?: TextLabelsTranslationsDto;
  countdownClockLabels?: CountdownClockLabelsTranslationsDto;
  checkoutLoadingMessages?: CheckoutLoadingMessagesTranslationsDto;
  pageRefreshMessages?: PageRefreshMessagesTranslationsDto;
  accessibilityLabels?: AccessibilityLabelsTranslationsDto;
  giftPurchaseMessages?: GiftPurchaseMessagesTranslationsDto;
  subscriptionProductsMessages?: SubscriptionProductsMessagesTranslationsDto;
};
export type StyleSettingsDto = {
  brandColors?: string[];
};
export type PixelEventsIntegrationsSettingsDto = {
  trackCustomEventFacebookPixel?: boolean;
  trackCustomEventGoogleAnalyticsPixel?: boolean;
  trackPromoViewEventInGoogleAnalytics?: boolean;
};
export type SubscriptionProductsCheckoutModeDto =
  | ('ALLOW' | 'NOT_ALLOW')
  | null;
export type SubscriptionProductsCheckoutIntegrationsSettingsDto = {
  allowDiscountingSubscriptionProducts?: boolean;
  checkoutMode?: SubscriptionProductsCheckoutModeDto | null;
};
export type MicrodataIntegrationsSettingsDto = {
  allowMicrodataAttributesUpdate?: boolean;
};
export type IntegrationsSettingsDto = {
  pixelEventsSettings?: PixelEventsIntegrationsSettingsDto;
  subscriptionProductsCheckoutSettings?: SubscriptionProductsCheckoutIntegrationsSettingsDto;
  microdataSettings?: MicrodataIntegrationsSettingsDto;
};
export type IntegrationsSettingsDtoRead = {
  pixelEventsSettings?: PixelEventsIntegrationsSettingsDto;
  subscriptionProductsCheckoutSettings?: SubscriptionProductsCheckoutIntegrationsSettingsDto;
  microdataSettings?: MicrodataIntegrationsSettingsDto;
  trackingJavascript?: string;
};
export type OptionDtoString = {
  enabled?: boolean;
  value?: string | null;
};
export type TagsMetadataSettingsDto = {
  discountNinjaOriginatedTag?: OptionDtoString;
  prefixForEachAppliedPromotion?: OptionDtoString;
  prefixForEachAppliedOffer?: OptionDtoString;
  prefixForEachAppliedPromotionCode?: OptionDtoString;
  prefixAppliedDiscountCode?: OptionDtoString;
  addTagFlagCouponFraud?: boolean;
  storeDiscountAppDataInOrderMetafields?: boolean;
};
export type CartBehaviourRefreshBehaviourTypeDto =
  | ('RELOAD_PAGE' | 'REFRESH_JS')
  | null;
export type RefreshBehaviorCartBehaviorSettingsDto = {
  mode?: CartBehaviourRefreshBehaviourTypeDto | null;
  code?: string;
  ninjaCartEnabled?: boolean | null;
};
export type CartBehaviorSettingsDto = {
  cart?: RefreshBehaviorCartBehaviorSettingsDto;
  drawerCart?: RefreshBehaviorCartBehaviorSettingsDto;
  requiredCheckboxesCssSelector?: string;
};
export type TestingSettingsDto = {
  isEnabled?: boolean;
};
export type DiscountLinksBehaviorTypeDto =
  | ('ANY_TAB_UNTIL_LINK_EXPIRES' | 'TAB_WHERE_LINK_OPENED')
  | null;
export type DiscountLinksExpirationDurationDto =
  | ('NEVER' | 'DAY' | 'WEEK')
  | null;
export type DiscountLinksSettingsDto = {
  behavior?: DiscountLinksBehaviorTypeDto | null;
  expirationDuration?: DiscountLinksExpirationDurationDto | null;
};
export type AllowCustomersToRemoveGiftsTypeDto =
  | ('ALLOW' | 'NOT_ALLOWED')
  | null;
export type GiftPriceHideOnPagesDto =
  | ('CART_AND_DRAWER_CART_PAGES' | 'ALL_PAGES')
  | null;
export type GiftPriceGiftsSettingsDto = {
  hideGiftOriginalPrice?: boolean;
  hideOnPages?: GiftPriceHideOnPagesDto | null;
};
export type CheckoutModeDto =
  | ('SHOPIFY_FUNCTIONS' | 'DRAFT_ORDER' | 'DISCOUNT_CODE')
  | null;
export type GiftsSettingsDto = {
  allowCustomersToRemoveGifts?: AllowCustomersToRemoveGiftsTypeDto | null;
  automaticallyRemoveFreeGifts?: boolean | null;
  automaticallyRemoveExcessGifts?: boolean | null;
  giftPrice?: GiftPriceGiftsSettingsDto;
  checkoutMode?: CheckoutModeDto | null;
};
export type FallbackCheckoutModeDto =
  | ('ORDER_DISCOUNT_BY_SHOPIFY_FUNCTIONS' | 'DRAFT_ORDER')
  | null;
export type CheckoutDraftOrderRemovalOptionDto =
  | ('HALF_HOUR' | 'DAY' | 'THREE_DAYS' | 'WEEK')
  | null;
export type OptionDtoCheckoutDraftOrderRemovalOptionDto = {
  enabled?: boolean;
  value?: CheckoutDraftOrderRemovalOptionDto | null;
};
export type InternationalPricingCheckoutSettingsDto = {
  shopCurrency?: string;
  supportedCurrenciesAtCheckout?: string[];
  nonBaseCurrencyDiscountsCheckoutMode?: CheckoutModeDto | null;
};
export type CheckoutModeCheckoutSettingsDto = {
  mode?: CheckoutModeDto | null;
  fallbackMode?: FallbackCheckoutModeDto | null;
  draftOrderRemovalOption?: OptionDtoCheckoutDraftOrderRemovalOptionDto | null;
  discountCodesPrefix?: OptionDtoString | null;
  internationalPricing?: InternationalPricingCheckoutSettingsDto;
  isDiscountCombinationSupported?: boolean;
  shopifyFunctionsUpgradeAvailable?: boolean;
  shopifyFunctionsModeBlocked?: boolean;
};
export type PageTypeDto =
  | (
      | 'ALL'
      | 'HOME'
      | 'COLLECTION'
      | 'PRODUCT'
      | 'CART'
      | 'DRAWER_CART'
      | 'NINJA_CART'
      | 'CHECKOUT'
      | 'BLOG_POST'
      | 'SEARCH'
      | 'CUSTOM'
    )
  | null;
export type DynamicCheckoutButtonsCheckoutSettingsDto = {
  overrideOnPages?: PageTypeDto[];
};
export type OptionDtoInt32 = {
  enabled?: boolean;
  value?: number;
};
export type CheckoutSettingsDto = {
  checkoutModeSettings?: CheckoutModeCheckoutSettingsDto;
  dynamicCheckoutButtons?: DynamicCheckoutButtonsCheckoutSettingsDto;
  checkoutButtonLoadingEffectDuration?: OptionDtoInt32;
  isLegacyFlow?: boolean | null;
};
export type SystemCheckResponseDto = {
  isOnboardingRequired?: boolean;
  currencyCode?: string;
};
export type PromotionEngineInstallationDto = {
  enabled?: boolean;
  installationDeepLink?: string | null;
};
export type PromotionEngineAndPlanCheckResponseDto = {
  promotionEngine?: PromotionEngineInstallationDto;
  isActiveSubscriptionInPlace?: boolean;
};
export type ThemeType = ('CURRENT' | 'TEST') | null;
export type UninstallAppRequestDto = {
  reasons?: CancelSubscriptionPlanReasonDto[];
  otherReason?: string | null;
};
export type CustomShippingRateDto = {
  id?: string | null;
  label?: string;
};
export type CustomShippingRateSettingsDto = {
  customShippingRates?: CustomShippingRateDto[];
};
export type NotificationLocationSettingDto = ('TOP' | 'BOTTOM') | null;
export type NotificationStyleSettingDto = {
  backgroundColor?: string;
  textColor?: string;
};
export type NotificationsSettingsDto = {
  onGiftAdded?: boolean;
  onAppRefresh?: boolean;
  position?: NotificationLocationSettingDto | null;
  style?: NotificationStyleSettingDto | null;
};
export type ThemeSlotDto = {
  type?: ThemeTypeDto | null;
  themeId?: number;
  themeName?: string;
};
export type SaveThemeSlotsRequestDto = {
  slots?: ThemeSlotDto[];
};
export type ThemeStatusesDto = {
  themeId?: number;
  themeName?: string;
  type?: ThemeTypeDto | null;
  isDeleted?: boolean;
  isAppEngineEnabled?: boolean;
};
export type WidgetStatusDto =
  | (
      | 'NOT_READY'
      | 'LOCKED'
      | 'ENABLED'
      | 'DISABLED'
      | 'ENABLED_VIA_CODE'
      | 'ENABLED_VIA_CSS'
      | 'ENABLED_BUILT_IN'
    )
  | null;
export type WidgetInstallationModeDto =
  | ('APP_EMBED' | 'APP_BLOCKS' | 'CUSTOM')
  | null;
export type WidgetInstallationCustomWidgetCssSelectorDto =
  | ('BELOW_TITLE' | 'ABOVE_SUBTOTAL' | 'AUTOMATIC' | 'OTHER')
  | null;
export type WidgetInstallationCustomPropertiesDto = {
  cssSelector?: WidgetInstallationCustomWidgetCssSelectorDto | null;
  customCssSelector?: string | null;
  selectedAssetId?: string | null;
  pageType?: PageTypeDto | null;
};
export type WidgetInstallationDto = {
  status?: WidgetStatusDto | null;
  type?: WidgetTypeDto | null;
  installationMode?: WidgetInstallationModeDto | null;
  isSupportedByCurrentPlan?: boolean;
  planSupportingType?: PlanNameDto | null;
  pages?: PageTypeDto[] | null;
  isBuiltIn?: boolean;
  installationDeepLink?: string | null;
  customProperties?: WidgetInstallationCustomPropertiesDto | null;
  children?: WidgetInstallationDto[];
};
export type InstallationSupportRequestGeneralStatusDto =
  | ('SUBMITTED' | 'NOT_SUBMITTED')
  | null;
export type InstallationSupportRequestDemandDto =
  | ('REGULAR' | 'HIGH' | 'ULTRA_HIGH')
  | null;
export type InstallationSupportRequestSummaryDto = {
  id?: string | null;
  submissionDate?: number | null;
  status?: InstallationSupportRequestGeneralStatusDto | null;
  demand?: InstallationSupportRequestDemandDto | null;
};
export type WidgetsInstallationStatusSettingsDto = {
  promotionEngine?: PromotionEngineInstallationDto;
  widgets?: WidgetInstallationDto[];
  supportRequest?: InstallationSupportRequestSummaryDto;
  multipleVersionsSupport?: boolean | null;
  suggestedWidgets?: WidgetTypeDto[];
  criticalWidgets?: WidgetTypeDto[];
};
export type InstallWidgetRequestDto = {
  type?: WidgetTypeDto | null;
  status?: WidgetStatusDto | null;
  customProperties?: WidgetInstallationCustomPropertiesDto | null;
};
export type WidgetInstallationListDto = {
  status?: WidgetStatusDto | null;
  type?: WidgetTypeDto | null;
  installationMode?: WidgetInstallationModeDto | null;
  isSupportedByCurrentPlan?: boolean;
  planSupportingType?: PlanNameDto | null;
  pages?: PageTypeDto[] | null;
  isBuiltIn?: boolean;
};
export type InstallationSupportRequestStatusDto =
  | (
      | 'PROCESSING'
      | 'AWAITING_COLLABORATOR_ACCESS'
      | 'PLANNED'
      | 'STARTED'
      | 'COMPLETED'
    )
  | null;
export type InstallationSupportRequestPriorityDto = ('NORMAL' | 'HIGH') | null;
export type InstallationSupportRequestNoteDto = {
  message?: string;
  date?: number;
};
export type InstallationSupportRequestDto = {
  themeId?: number;
  themeName?: string | null;
  selectedWidgets?: WidgetTypeDto[];
  submissionDate?: number | null;
  expectedSupportDate?: number | null;
  status?: InstallationSupportRequestStatusDto | null;
  priority?: InstallationSupportRequestPriorityDto | null;
  notes?: InstallationSupportRequestNoteDto[] | null;
  note?: string | null;
  collaboratorAccessConsent?: boolean;
};
export type GetSupportRequestResponseDto = {
  widgets?: WidgetInstallationListDto[];
  demand?: InstallationSupportRequestDemandDto | null;
  supportRequest?: InstallationSupportRequestDto | null;
};
export type CreateSupportRequestResponseDto = {
  id?: string;
  paymentUrl?: string | null;
};
export type WidgetThemeStatusDto = {
  currentTheme?: WidgetStatusDto | null;
  testTheme?: WidgetStatusDto | null;
};
export type DeviceDisplayDto = ('ALL' | 'DESKTOP' | 'MOBILE') | null;
export type PageDisplayTypeDto = ('DEFAULT' | 'CUSTOM') | null;
export type ShopifyIdCollectionDto = {
  allItems?: boolean | null;
  selectedItems?: string[] | null;
};
export type PageDisplayComponentDto = {
  type?: PageDisplayTypeDto | null;
  pages?: PageTypeDto[] | null;
  collections?: ShopifyIdCollectionDto | null;
  products?: ShopifyIdCollectionDto | null;
  availablePages?: PageTypeDto[] | null;
};
export type AnnouncementBarPagePositionDto =
  | ('ABOVE_HEADER' | 'BELOW_HEADER' | 'BOTTOM_OF_PAGE')
  | null;
export type SizeTypeDto =
  | ('EXTRA_SMALL' | 'SMALL' | 'MEDIUM' | 'LARGE' | 'EXTRA_LARGE')
  | null;
export type SizeWidthDto = ('AUTO' | 'FIXED' | 'BREAKPOINTS') | null;
export type BreakpointDto = {
  maxWidth?: number;
  contentWidth?: number;
};
export type SizeComponentDto = {
  size?: SizeTypeDto | null;
  width?: SizeWidthDto | null;
  fixedWidth?: number | null;
  breakpoints?: BreakpointDto[] | null;
};
export type AnimationDto =
  | (
      | 'NONE'
      | 'SLIDE'
      | 'SLIDE_IN'
      | 'SLIDE_OUT'
      | 'SLIDE_OUT_TOP'
      | 'SLIDE_OUT_RIGHT'
      | 'SLIDE_OUT_LEFT'
      | 'SLIDE_OUT_BOTTOM'
      | 'FADE'
      | 'FADE_IN'
      | 'FADE_OUT'
      | 'FADE_OUT_TOP'
      | 'FADE_OUT_RIGHT'
      | 'FADE_OUT_LEFT'
      | 'FADE_OUT_BOTTOM'
      | 'INSTANT'
      | 'BOUNCE'
      | 'FLASH'
      | 'SHAKE'
      | 'MOVE_IN'
      | 'MOVE_OUT'
    )
  | null;
export type AnnouncementBarAnimationsDto = {
  onClose?: AnimationDto | null;
  cycling?: AnimationDto | null;
};
export type AnnouncementBarCyclingDurationTypeDto =
  | ('LONG' | 'NORMAL' | 'SHORT' | 'CUSTOM')
  | null;
export type AnnouncementBarCyclingDto = {
  duration?: AnnouncementBarCyclingDurationTypeDto | null;
  customDuration?: number | null;
};
export type AnnouncementBarIndicatorsStyleDto = ('DOTS' | 'BARS') | null;
export type OptionDtoNullableAnnouncementBarIndicatorsStyleDto = {
  enabled?: boolean;
  value?: AnnouncementBarIndicatorsStyleDto | null;
};
export type OptionDtoNullableGuid = {
  enabled?: boolean;
  value?: string | null;
};
export type AnnouncementBarShopSettingsDto = {
  deviceDisplay?: DeviceDisplayDto | null;
  pageDisplay?: PageDisplayComponentDto;
  pagePosition?: AnnouncementBarPagePositionDto | null;
  size?: SizeComponentDto;
  animations?: AnnouncementBarAnimationsDto;
  cycling?: AnnouncementBarCyclingDto;
  indicators?: OptionDtoNullableAnnouncementBarIndicatorsStyleDto;
  chevronsIcon?: OptionDtoNullableGuid;
  closeButtonIcon?: OptionDtoNullableGuid;
};
export type WidgetSettingsDtoAnnouncementBarShopSettingsDto = {
  status?: WidgetThemeStatusDto | null;
  settings?: AnnouncementBarShopSettingsDto | null;
  isShopLevelWidget?: boolean | null;
};
export type SpacingTypeDto =
  | ('EXTRA_SMALL' | 'SMALL' | 'MEDIUM' | 'LARGE' | 'EXTRA_LARGE' | 'CUSTOM')
  | null;
export type SpacingDto = {
  type?: SpacingTypeDto | null;
  customValue?: number | null;
};
export type OptionDtoSpacingDto = {
  enabled?: boolean;
  value?: SpacingDto | null;
};
export type ProductBannerShopSettingsDto = {
  deviceDisplay?: DeviceDisplayDto | null;
  stacking?: OptionDtoSpacingDto;
};
export type WidgetSettingsDtoProductBannerShopSettingsDto = {
  status?: WidgetThemeStatusDto | null;
  settings?: ProductBannerShopSettingsDto | null;
  isShopLevelWidget?: boolean | null;
};
export type PositionDto =
  | (
      | 'TOP'
      | 'TOP_LEFT'
      | 'TOP_RIGHT'
      | 'BOTTOM_LEFT'
      | 'BOTTOM_RIGHT'
      | 'BOTTOM'
      | 'CENTER'
      | 'MIDDLE'
      | 'LEFT'
      | 'RIGHT'
    )
  | null;
export type OffsetTypeDto =
  | ('EXTRA_SMALL' | 'SMALL' | 'MEDIUM' | 'LARGE' | 'EXTRA_LARGE' | 'CUSTOM')
  | null;
export type OffsetComponentDto = {
  type?: OffsetTypeDto | null;
  vertical?: number | null;
  horizontal?: number | null;
};
export type OptionDtoOffsetComponentDto = {
  enabled?: boolean;
  value?: OffsetComponentDto | null;
};
export type PromotionalBadgeShopSettingsDto = {
  position?: PositionDto | null;
  offset?: OptionDtoOffsetComponentDto;
  stacking?: OptionDtoSpacingDto;
};
export type WidgetSettingsDtoPromotionalBadgeShopSettingsDto = {
  status?: WidgetThemeStatusDto | null;
  settings?: PromotionalBadgeShopSettingsDto | null;
  isShopLevelWidget?: boolean | null;
};
export type NotificationMinimizingBehaviorDto = {
  minimizeAfterFirstView?: boolean;
  automicallyMinimize?: boolean;
};
export type OptionDtoNullableAnimationDto = {
  enabled?: boolean;
  value?: AnimationDto | null;
};
export type NotificationAnimationsDto = {
  firstDisplay?: OptionDtoNullableAnimationDto;
  minimizing?: OptionDtoNullableAnimationDto;
};
export type NotificationShopSettingsDto = {
  deviceDisplay?: DeviceDisplayDto | null;
  pageDisplay?: PageDisplayComponentDto;
  position?: PositionDto | null;
  offset?: OptionDtoOffsetComponentDto;
  minimizingBehavior?: NotificationMinimizingBehaviorDto;
  animations?: NotificationAnimationsDto;
  minimizingIcon?: OptionDtoNullableGuid;
  messageCountEnabled?: boolean;
};
export type WidgetSettingsDtoNotificationShopSettingsDto = {
  status?: WidgetThemeStatusDto | null;
  settings?: NotificationShopSettingsDto | null;
  isShopLevelWidget?: boolean | null;
};
export type OfferRulePopupOverlayDto = {
  openingAnimation?: AnimationDto | null;
  closingAnimation?: AnimationDto | null;
  background?: OptionDtoString;
  closeWhenClickingOutside?: boolean;
};
export type OfferRulePopupShopSettingsDto = {
  position?: PositionDto | null;
  offset?: OptionDtoOffsetComponentDto;
  overlay?: OfferRulePopupOverlayDto;
  closeButtonIcon?: OptionDtoNullableGuid;
};
export type WidgetSettingsDtoOfferRulePopupShopSettingsDto = {
  status?: WidgetThemeStatusDto | null;
  settings?: OfferRulePopupShopSettingsDto | null;
  isShopLevelWidget?: boolean | null;
};
export type PopupShopSettingsDto = {
  position?: PositionDto | null;
  offset?: OptionDtoOffsetComponentDto;
  overlay?: OfferRulePopupOverlayDto;
  closeButtonIcon?: OptionDtoNullableGuid;
};
export type PromotionCodeFieldDisplayModeDto =
  | ('COLLAPSED' | 'EXPANDED' | 'ALWAYS_EXPANDED')
  | null;
export type PromotionCodeFieldShopSettingsDisplayModeDto = {
  generalDisplayMode?: PromotionCodeFieldDisplayModeDto | null;
  cartDisplayMode?: PromotionCodeFieldDisplayModeDto | null;
  drawerCartDisplayMode?: PromotionCodeFieldDisplayModeDto | null;
};
export type PromotionCodeFieldCollapseExpandDto =
  | ('UNDERLINE' | 'CHEVRON')
  | null;
export type PromotionCodeFieldShopSettingsCollapseExpandDto = {
  generalCollapseExpand?: PromotionCodeFieldCollapseExpandDto | null;
  cartCollapseExpand?: PromotionCodeFieldCollapseExpandDto | null;
  drawerCartCollapseExpand?: PromotionCodeFieldCollapseExpandDto | null;
};
export type SpacingType =
  | ('EXTRA_SMALL' | 'SMALL' | 'MEDIUM' | 'LARGE' | 'EXTRA_LARGE' | 'CUSTOM')
  | null;
export type OptionDtoNullableSpacingType = {
  enabled?: boolean;
  value?: SpacingType | null;
};
export type PromotionCodeFieldShopSettingsDto = {
  displayMode?: PromotionCodeFieldShopSettingsDisplayModeDto;
  collapseExpand?: PromotionCodeFieldShopSettingsCollapseExpandDto;
  paddingSpace?: OptionDtoNullableSpacingType;
};
export type WidgetSettingsDtoPromotionCodeFieldShopSettingsDto = {
  status?: WidgetThemeStatusDto | null;
  settings?: PromotionCodeFieldShopSettingsDto | null;
  isShopLevelWidget?: boolean | null;
};
export type PromotionSummaryTotalDiscountCalculationModeDto =
  | ('SUM_OF_ALL' | 'ORDER_DISCOUNTS_ONLY')
  | null;
export type PromotionSummaryShippingAmountModeDto =
  | ('CALCULATED_AT_CHECKOUT' | 'CALCULATED_IN_CART')
  | null;
export type LocalizedStringDto = {
  text?: string;
  language?: string;
};
export type PromotionSummaryShippingAmountDto = {
  mode?: PromotionSummaryShippingAmountModeDto | null;
  displayText?: LocalizedStringDto[] | null;
};
export type PromotionSummaryShopSettingsDto = {
  defaultLanguage?: string | null;
  totalDiscountCalculationMode?: PromotionSummaryTotalDiscountCalculationModeDto | null;
  shippingAmount?: PromotionSummaryShippingAmountDto;
  showTotal?: boolean;
  showDiscountBreakdown?: boolean;
  considerFreeGiftADiscount?: boolean;
};
export type WidgetSettingsDtoPromotionSummaryShopSettingsDto = {
  status?: WidgetThemeStatusDto | null;
  settings?: PromotionSummaryShopSettingsDto | null;
  isShopLevelWidget?: boolean | null;
};
export type CountdownClockTimerUrgencyTypeDto = ('REAL' | 'FAKE') | null;
export type CountdownClockShopSettingsDto = {
  timerUrgencyType?: CountdownClockTimerUrgencyTypeDto | null;
};
export type WidgetSettingsDtoCountdownClockShopSettingsDto = {
  status?: WidgetThemeStatusDto | null;
  settings?: CountdownClockShopSettingsDto | null;
  isShopLevelWidget?: boolean | null;
};
export type StrikethroughPricingInstallmentsSettingsDto = {
  fourMonthInstallmentsCssSelector?: string;
  threeMonthInstallmentsCssSelector?: string;
};
export type StrikethroughPricingGeneralWidgetShopSettingsDto = {
  pageDisplay?: PageDisplayComponentDto;
  installments?: StrikethroughPricingInstallmentsSettingsDto;
};
export type StrikethroughPricingGeneralSettingsDto = {
  testUrl?: string;
  widgetSettings?: StrikethroughPricingGeneralWidgetShopSettingsDto;
  widgets?: WidgetInstallationListDto[];
};
export type ProductStrikethroughPricingShopSettingsDto = {
  useSoldOutLabel?: boolean;
  strikeThroughPriceLoadingAffect?: boolean;
  testUrl?: string;
  installments?: StrikethroughPricingInstallmentsSettingsDto;
};
export type WidgetSettingsDtoProductStrikethroughPricingShopSettingsDto = {
  status?: WidgetThemeStatusDto | null;
  settings?: ProductStrikethroughPricingShopSettingsDto | null;
  isShopLevelWidget?: boolean | null;
};
export type CheckoutLoadingPopupOverlayDto = {
  openingAnimation?: AnimationDto | null;
  closingAnimation?: AnimationDto | null;
  background?: OptionDtoString;
  closeWhenClickingOutside?: boolean;
};
export type CheckoutLoadingPopupShopSettingsDto = {
  overlay?: CheckoutLoadingPopupOverlayDto;
};
export type WidgetSettingsDtoCheckoutLoadingPopupShopSettingsDto = {
  status?: WidgetThemeStatusDto | null;
  settings?: CheckoutLoadingPopupShopSettingsDto | null;
  isShopLevelWidget?: boolean | null;
};
export type FrequentBoughtTogetherDisplayOrderDto =
  | ('DEFAULT' | 'PRICE' | 'INVENTORY')
  | null;
export type FrequentBoughtTogetherIndicatorsStyleDto = ('DOTS' | 'BARS') | null;
export type OptionDtoFrequentBoughtTogetherIndicatorsStyleDto = {
  enabled?: boolean;
  value?: FrequentBoughtTogetherIndicatorsStyleDto | null;
};
export type FrequentBoughtTogetherProductSettingsDto = {
  showProductVariantsCards?: boolean;
  dontSuggestProductsCart?: boolean;
};
export type FrequentBoughtTogetherShopSettingsDto = {
  displayOrder?: FrequentBoughtTogetherDisplayOrderDto | null;
  pageDisplay?: PageTypeDto[] | null;
  indicatorsStyle?: OptionDtoFrequentBoughtTogetherIndicatorsStyleDto;
  productSettings?: FrequentBoughtTogetherProductSettingsDto;
};
export type WidgetSettingsDtoFrequentBoughtTogetherShopSettingsDto = {
  status?: WidgetThemeStatusDto | null;
  settings?: FrequentBoughtTogetherShopSettingsDto | null;
  isShopLevelWidget?: boolean | null;
};
export const {
  usePostSettingsV6AlertsMutation,
  useGetSettingsV6DashboardQuery,
  useGetSettingsV6DiscountCodesShopifyDiscountCodesQuery,
  usePutSettingsV6DiscountCodesShopifyDiscountCodesMutation,
  useGetSettingsV6DiscountCodesDiscountNinjaPromoCodesQuery,
  usePutSettingsV6DiscountCodesDiscountNinjaPromoCodesMutation,
  useGetSettingsV6LookupTimezonesQuery,
  useGetSettingsV6LookupThemesQuery,
  useGetSettingsV6LookupLocalesQuery,
  useGetSettingsV6LookupReleaseVersionsQuery,
  useGetSettingsV6LookupPageTemplatesByThemeTypeAndWidgetTypeQuery,
  useGetSettingsV6OnboardingInitialDataQuery,
  usePostSettingsV6OnboardingCompleteMutation,
  useGetSettingsV6PlansCurrentQuery,
  usePostSettingsV6PlansBillsMutation,
  useGetSettingsV6PlansQuery,
  usePostSettingsV6PlansPlanSupportingFeatureMutation,
  usePostSettingsV6PlansSelectSubscriptionMutation,
  usePostSettingsV6PlansRequestDevelopmentPlanMutation,
  usePostSettingsV6PlansCancelSubscriptionMutation,
  usePostSettingsV6PlansResumeSubscriptionMutation,
  useGetSettingsV6AccountQuery,
  usePutSettingsV6AccountMutation,
  usePostSettingsV6GeneralAppSecretCheckMutation,
  useGetSettingsV6AccountAuditLogsQuery,
  useGetSettingsV6TranslationsMainQuery,
  useGetSettingsV6TranslationsByLocaleQuery,
  usePutSettingsV6TranslationsByLocaleMutation,
  useGetSettingsV6StyleQuery,
  usePutSettingsV6StyleMutation,
  useGetSettingsV6IntegrationsQuery,
  usePutSettingsV6IntegrationsMutation,
  useGetSettingsV6TagsMetadataQuery,
  usePutSettingsV6TagsMetadataMutation,
  useGetSettingsV6CartBehaviorQuery,
  usePutSettingsV6CartBehaviorMutation,
  useGetSettingsV6TestingQuery,
  usePutSettingsV6TestingMutation,
  useGetSettingsV6DiscountLinksQuery,
  usePutSettingsV6DiscountLinksMutation,
  useGetSettingsV6GiftsQuery,
  usePutSettingsV6GiftsMutation,
  usePostSettingsV6ImportMutation,
  useGetSettingsV6ExportQuery,
  useGetSettingsV6CheckoutQuery,
  usePutSettingsV6CheckoutMutation,
  useGetSettingsV6SystemCheckQuery,
  useGetSettingsV6EngineAndPlanCheckQuery,
  usePostSettingsV6UninstallAppMutation,
  useGetSettingsV6ShippingRatesQuery,
  usePostSettingsV6ShippingRatesMutation,
  usePutSettingsV6ShippingRatesByIdMutation,
  useDeleteSettingsV6ShippingRatesByIdMutation,
  useGetSettingsV6NotificationsQuery,
  usePutSettingsV6NotificationsMutation,
  useGetSettingsV6ThemesQuery,
  usePutSettingsV6ThemesMutation,
  useGetSettingsV6ThemesStatusQuery,
  useGetSettingsV6VersionQuery,
  useGetSettingsV6ThemesByThemeTypeWidgetInstallationQuery,
  usePutSettingsV6ThemesByThemeTypeWidgetInstallationInstallMutation,
  useGetSettingsV6WidgetInstallationSupportRequestQuery,
  usePostSettingsV6WidgetInstallationSupportRequestMutation,
  usePutSettingsV6WidgetInstallationSupportRequestMutation,
  useDeleteSettingsV6WidgetInstallationSupportRequestMutation,
  useGetSettingsV6ThemesWidgetSettingsAnnouncementBarQuery,
  usePutSettingsV6ThemesWidgetSettingsAnnouncementBarMutation,
  useGetSettingsV6ThemesWidgetSettingsProductBannerQuery,
  usePutSettingsV6ThemesWidgetSettingsProductBannerMutation,
  useGetSettingsV6ThemesWidgetSettingsPromotionalBadgeQuery,
  usePutSettingsV6ThemesWidgetSettingsPromotionalBadgeMutation,
  useGetSettingsV6ThemesWidgetSettingsNotificationQuery,
  usePutSettingsV6ThemesWidgetSettingsNotificationMutation,
  useGetSettingsV6ThemesWidgetSettingsOfferRulePopupQuery,
  usePutSettingsV6ThemesWidgetSettingsOfferRulePopupMutation,
  useGetSettingsV6ThemesWidgetSettingsPopupQuery,
  usePutSettingsV6ThemesWidgetSettingsPopupMutation,
  useGetSettingsV6ThemesWidgetSettingsPromotionCodeFieldQuery,
  usePutSettingsV6ThemesWidgetSettingsPromotionCodeFieldMutation,
  useGetSettingsV6ThemesWidgetSettingsPromotionSummaryQuery,
  usePutSettingsV6ThemesWidgetSettingsPromotionSummaryMutation,
  useGetSettingsV6ThemesWidgetSettingsCountdownClockQuery,
  usePutSettingsV6ThemesWidgetSettingsCountdownClockMutation,
  useGetSettingsV6ThemesWidgetSettingsStrikethroughPricingQuery,
  usePutSettingsV6ThemesWidgetSettingsStrikethroughPricingMutation,
  useGetSettingsV6ThemesWidgetSettingsStrikethroughPricingProductQuery,
  usePutSettingsV6ThemesWidgetSettingsStrikethroughPricingProductMutation,
  useGetSettingsV6ThemesWidgetSettingsCheckoutLoadingPopupQuery,
  usePutSettingsV6ThemesWidgetSettingsCheckoutLoadingPopupMutation,
  useGetSettingsV6ThemesWidgetSettingsFrequentBoughtTogetherQuery,
  usePutSettingsV6ThemesWidgetSettingsFrequentBoughtTogetherMutation,
} = injectedRtkApi;
