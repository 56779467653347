import { emptySplitAdminWidgetsApi as api } from './emptyAdminWidgetsApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    fonts: build.query<FontsApiResponse, FontsApiArg>({
      query: (queryArg) => ({
        url: `/widgets/v6/lookup/fonts`,
        headers: { 'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'] },
      }),
    }),
    presets: build.query<PresetsApiResponse, PresetsApiArg>({
      query: (queryArg) => ({
        url: `/widgets/v6/lookup/presets`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: {
          preselectedItemId: queryArg.preselectedItemId,
          boundaries: queryArg.boundaries,
          page: queryArg.page,
          itemsPerPage: queryArg.itemsPerPage,
          sortDirection: queryArg.sortDirection,
          search: queryArg.search,
        },
      }),
    }),
    icons: build.query<IconsApiResponse, IconsApiArg>({
      query: (queryArg) => ({
        url: `/widgets/v6/lookup/icons`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getWidgetsV6LookupIconsCustom: build.query<
      GetWidgetsV6LookupIconsCustomApiResponse,
      GetWidgetsV6LookupIconsCustomApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/lookup/icons/custom`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    images: build.query<ImagesApiResponse, ImagesApiArg>({
      query: (queryArg) => ({
        url: `/widgets/v6/lookup/images`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getWidgetsV6LookupImagesCustom: build.query<
      GetWidgetsV6LookupImagesCustomApiResponse,
      GetWidgetsV6LookupImagesCustomApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/lookup/images/custom`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    clockStyles: build.query<ClockStylesApiResponse, ClockStylesApiArg>({
      query: (queryArg) => ({
        url: `/widgets/v6/lookup/clockStyles`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    list: build.query<ListApiResponse, ListApiArg>({
      query: (queryArg) => ({
        url: `/widgets/v6/presets/list`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: {
          type: queryArg['type'],
          sortBy: queryArg.sortBy,
          boundaries: queryArg.boundaries,
          page: queryArg.page,
          itemsPerPage: queryArg.itemsPerPage,
          sortDirection: queryArg.sortDirection,
          search: queryArg.search,
        },
      }),
    }),
    create: build.mutation<CreateApiResponse, CreateApiArg>({
      query: (queryArg) => ({
        url: `/widgets/v6/presets`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    deleteWidgetsV6PresetsById: build.mutation<
      DeleteWidgetsV6PresetsByIdApiResponse,
      DeleteWidgetsV6PresetsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/presets/${queryArg.id}`,
        method: 'DELETE',
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getWidgetsV6PresetsById: build.query<
      GetWidgetsV6PresetsByIdApiResponse,
      GetWidgetsV6PresetsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/presets/${queryArg.id}`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: { widgets: queryArg.widgets },
      }),
    }),
    putWidgetsV6PresetsById: build.mutation<
      PutWidgetsV6PresetsByIdApiResponse,
      PutWidgetsV6PresetsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/presets/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postWidgetsV6PresetsByIdDuplicate: build.mutation<
      PostWidgetsV6PresetsByIdDuplicateApiResponse,
      PostWidgetsV6PresetsByIdDuplicateApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/presets/${queryArg.id}/duplicate`,
        method: 'POST',
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putWidgetsV6PresetsByIdRename: build.mutation<
      PutWidgetsV6PresetsByIdRenameApiResponse,
      PutWidgetsV6PresetsByIdRenameApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/presets/${queryArg.id}/rename`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getWidgetsV6PresetsShopDefaultShopLevel: build.query<
      GetWidgetsV6PresetsShopDefaultShopLevelApiResponse,
      GetWidgetsV6PresetsShopDefaultShopLevelApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/presets/shopDefault/shopLevel`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getWidgetsV6PresetsShopDefaultPromotionLevel: build.query<
      GetWidgetsV6PresetsShopDefaultPromotionLevelApiResponse,
      GetWidgetsV6PresetsShopDefaultPromotionLevelApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/presets/shopDefault/promotionLevel`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getWidgetsV6PresetsByIdAffectedPromotions: build.query<
      GetWidgetsV6PresetsByIdAffectedPromotionsApiResponse,
      GetWidgetsV6PresetsByIdAffectedPromotionsApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/presets/${queryArg.id}/affectedPromotions`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getWidgetsV6PresetsAffectedPromotionsShopLevel: build.query<
      GetWidgetsV6PresetsAffectedPromotionsShopLevelApiResponse,
      GetWidgetsV6PresetsAffectedPromotionsShopLevelApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/presets/affectedPromotions/shopLevel`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: { type: queryArg['type'] },
      }),
    }),
    getWidgetsV6PresetsByIdAffectedPromotionsOthers: build.query<
      GetWidgetsV6PresetsByIdAffectedPromotionsOthersApiResponse,
      GetWidgetsV6PresetsByIdAffectedPromotionsOthersApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/presets/${queryArg.id}/affectedPromotions/others`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postWidgetsV6PresetsByIdDefault: build.mutation<
      PostWidgetsV6PresetsByIdDefaultApiResponse,
      PostWidgetsV6PresetsByIdDefaultApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/presets/${queryArg.id}/default`,
        method: 'POST',
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postWidgetsV6PresetsByIdApplyToAllPromotions: build.mutation<
      PostWidgetsV6PresetsByIdApplyToAllPromotionsApiResponse,
      PostWidgetsV6PresetsByIdApplyToAllPromotionsApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/presets/${queryArg.id}/applyToAllPromotions`,
        method: 'POST',
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    systemDefaults: build.query<
      SystemDefaultsApiResponse,
      SystemDefaultsApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/presets/systemDefaults`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: { widgets: queryArg.widgets },
      }),
    }),
    widgetDefaults: build.query<
      WidgetDefaultsApiResponse,
      WidgetDefaultsApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/presets/widgetDefaults`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postWidgetsV6PromotionByIdTransactionClose: build.mutation<
      PostWidgetsV6PromotionByIdTransactionCloseApiResponse,
      PostWidgetsV6PromotionByIdTransactionCloseApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/promotion/${queryArg.id}/transaction/close`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
          'X-DiscountNinja-Transaction':
            queryArg['X-DiscountNinja-Transaction'],
        },
      }),
    }),
    getWidgetsV6PromotionById: build.query<
      GetWidgetsV6PromotionByIdApiResponse,
      GetWidgetsV6PromotionByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/promotion/${queryArg.id}`,
        headers: {
          'Accept-Language': queryArg['Accept-Language'],
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: {
          widgets: queryArg.widgets,
          transactionId: queryArg.transactionId,
        },
      }),
    }),
    putWidgetsV6PromotionById: build.mutation<
      PutWidgetsV6PromotionByIdApiResponse,
      PutWidgetsV6PromotionByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/promotion/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
          'X-DiscountNinja-Transaction':
            queryArg['X-DiscountNinja-Transaction'],
        },
      }),
    }),
    getWidgetsV6PromotionByIdOverview: build.query<
      GetWidgetsV6PromotionByIdOverviewApiResponse,
      GetWidgetsV6PromotionByIdOverviewApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/promotion/${queryArg.id}/overview`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putWidgetsV6PromotionByIdChangePreset: build.mutation<
      PutWidgetsV6PromotionByIdChangePresetApiResponse,
      PutWidgetsV6PromotionByIdChangePresetApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/promotion/${queryArg.id}/changePreset`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postWidgetsV6ResourcesUploadImage: build.mutation<
      PostWidgetsV6ResourcesUploadImageApiResponse,
      PostWidgetsV6ResourcesUploadImageApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/resources/upload/image`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postWidgetsV6ResourcesUploadIcon: build.mutation<
      PostWidgetsV6ResourcesUploadIconApiResponse,
      PostWidgetsV6ResourcesUploadIconApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/resources/upload/icon`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    deleteWidgetsV6ResourcesIconsAll: build.mutation<
      DeleteWidgetsV6ResourcesIconsAllApiResponse,
      DeleteWidgetsV6ResourcesIconsAllApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/resources/icons/all`,
        method: 'DELETE',
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    deleteWidgetsV6ResourcesImagesAll: build.mutation<
      DeleteWidgetsV6ResourcesImagesAllApiResponse,
      DeleteWidgetsV6ResourcesImagesAllApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/resources/images/all`,
        method: 'DELETE',
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    deleteWidgetsV6ResourcesIconById: build.mutation<
      DeleteWidgetsV6ResourcesIconByIdApiResponse,
      DeleteWidgetsV6ResourcesIconByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/resources/icon/${queryArg.id}`,
        method: 'DELETE',
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    deleteWidgetsV6ResourcesImagesById: build.mutation<
      DeleteWidgetsV6ResourcesImagesByIdApiResponse,
      DeleteWidgetsV6ResourcesImagesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/v6/resources/images/${queryArg.id}`,
        method: 'DELETE',
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as adminWidgetsApi };
export type FontsApiResponse = /** status 200 Success */ FontDto[];
export type FontsApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
};
export type PresetsApiResponse =
  /** status 200 Success */ LookupWithPreselectedItemPagedResponseDtoPresetLookupDto;
export type PresetsApiArg = {
  preselectedItemId?: string;
  boundaries?: PresetBoundariesTypeDto | null;
  page?: number;
  itemsPerPage?: number;
  sortDirection?: SortDirectionDto | null;
  search?: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type IconsApiResponse = /** status 200 Success */ IconResourceDto[];
export type IconsApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetWidgetsV6LookupIconsCustomApiResponse =
  /** status 200 Success */ IconResourceDto[];
export type GetWidgetsV6LookupIconsCustomApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type ImagesApiResponse = /** status 200 Success */ ImageResourceDto[];
export type ImagesApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetWidgetsV6LookupImagesCustomApiResponse =
  /** status 200 Success */ ImageResourceDto[];
export type GetWidgetsV6LookupImagesCustomApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type ClockStylesApiResponse = /** status 200 Success */ ClockStyleDto[];
export type ClockStylesApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type ListApiResponse =
  /** status 200 Success */ GetPresetListResponseDto;
export type ListApiArg = {
  type?: PresetTypeDto;
  sortBy?: PresetListSortFieldDto;
  boundaries?: PresetBoundariesTypeDto | null;
  page?: number;
  itemsPerPage?: number;
  sortDirection?: SortDirectionDto | null;
  search?: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type CreateApiResponse = /** status 200 Success */ EntityIdentifierDto;
export type CreateApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: CreatePresetRequestDto;
};
export type DeleteWidgetsV6PresetsByIdApiResponse =
  /** status 204 No Content */ void;
export type DeleteWidgetsV6PresetsByIdApiArg = {
  /** Identifier of preset */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetWidgetsV6PresetsByIdApiResponse =
  /** status 200 Success */ PresetDtoRead;
export type GetWidgetsV6PresetsByIdApiArg = {
  /** Identifier of preset */
  id: string;
  widgets?: WidgetTypeDto[];
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutWidgetsV6PresetsByIdApiResponse =
  /** status 204 No Content */ void;
export type PutWidgetsV6PresetsByIdApiArg = {
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: SavePresetRequestDto;
};
export type PostWidgetsV6PresetsByIdDuplicateApiResponse =
  /** status 201 Created */ PresetPreviewDto;
export type PostWidgetsV6PresetsByIdDuplicateApiArg = {
  /** Identifier of preset */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutWidgetsV6PresetsByIdRenameApiResponse =
  /** status 200 Success */ PresetPreviewDto;
export type PutWidgetsV6PresetsByIdRenameApiArg = {
  /** Identifier of preset */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: RenamePresetRequestDto;
};
export type GetWidgetsV6PresetsShopDefaultShopLevelApiResponse =
  /** status 200 Success */ PresetPreviewDto;
export type GetWidgetsV6PresetsShopDefaultShopLevelApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetWidgetsV6PresetsShopDefaultPromotionLevelApiResponse =
  /** status 200 Success */ PresetPreviewDto;
export type GetWidgetsV6PresetsShopDefaultPromotionLevelApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetWidgetsV6PresetsByIdAffectedPromotionsApiResponse =
  /** status 200 Success */ GetAffectedPromotionsByPresetResponseDto;
export type GetWidgetsV6PresetsByIdAffectedPromotionsApiArg = {
  /** Identifier of preset */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetWidgetsV6PresetsAffectedPromotionsShopLevelApiResponse =
  /** status 200 Success */ GetAffectedPromotionsByPresetResponseDto;
export type GetWidgetsV6PresetsAffectedPromotionsShopLevelApiArg = {
  /** Type of the widget */
  type?: WidgetTypeDto | null;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetWidgetsV6PresetsByIdAffectedPromotionsOthersApiResponse =
  /** status 200 Success */ GetAffectedPromotionsByPresetResponseDto;
export type GetWidgetsV6PresetsByIdAffectedPromotionsOthersApiArg = {
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostWidgetsV6PresetsByIdDefaultApiResponse =
  /** status 204 No Content */ void;
export type PostWidgetsV6PresetsByIdDefaultApiArg = {
  /** Identifier of preset */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostWidgetsV6PresetsByIdApplyToAllPromotionsApiResponse =
  /** status 204 No Content */ void;
export type PostWidgetsV6PresetsByIdApplyToAllPromotionsApiArg = {
  /** Identifier of preset */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type SystemDefaultsApiResponse =
  /** status 200 Success */ GetSystemDefaultsResponseDtoRead;
export type SystemDefaultsApiArg = {
  widgets?: WidgetTypeDto[];
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type WidgetDefaultsApiResponse =
  /** status 200 Success */ DefaultWidgetPresetsDto;
export type WidgetDefaultsApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostWidgetsV6PromotionByIdTransactionCloseApiResponse = unknown;
export type PostWidgetsV6PromotionByIdTransactionCloseApiArg = {
  /** Identifier of promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  /** Identifier of the transaction that performed operation is part of */
  'X-DiscountNinja-Transaction': string;
  body: CloseEditorTransactionRequestDto;
};
export type GetWidgetsV6PromotionByIdApiResponse =
  /** status 200 Success */ PromotionSetupDtoRead;
export type GetWidgetsV6PromotionByIdApiArg = {
  /** Identifier of promotion */
  id: string;
  widgets?: WidgetTypeDto[];
  transactionId?: string;
  /** Language in which translated data should be provided */
  'Accept-Language'?: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutWidgetsV6PromotionByIdApiResponse =
  /** status 204 No Content */ void;
export type PutWidgetsV6PromotionByIdApiArg = {
  /** Identifier of promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  /** Identifier of the transaction that performed operation is part of */
  'X-DiscountNinja-Transaction': string;
  body: PromotionSetupDto;
};
export type GetWidgetsV6PromotionByIdOverviewApiResponse =
  /** status 200 Success */ WidgetsPromotionOverviewDto;
export type GetWidgetsV6PromotionByIdOverviewApiArg = {
  /** Identifier of promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutWidgetsV6PromotionByIdChangePresetApiResponse =
  /** status 200 Success */ PresetPreviewDto;
export type PutWidgetsV6PromotionByIdChangePresetApiArg = {
  /** Identifier of promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: ChangePromotionPresetRequestDto;
};
export type PostWidgetsV6ResourcesUploadImageApiResponse =
  /** status 200 Success */ ResourcesUploadResponseDto;
export type PostWidgetsV6ResourcesUploadImageApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: {
    files?: Blob[];
  };
};
export type PostWidgetsV6ResourcesUploadIconApiResponse =
  /** status 200 Success */ ResourcesUploadResponseDto;
export type PostWidgetsV6ResourcesUploadIconApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: {
    files?: Blob[];
  };
};
export type DeleteWidgetsV6ResourcesIconsAllApiResponse = unknown;
export type DeleteWidgetsV6ResourcesIconsAllApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type DeleteWidgetsV6ResourcesImagesAllApiResponse = unknown;
export type DeleteWidgetsV6ResourcesImagesAllApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type DeleteWidgetsV6ResourcesIconByIdApiResponse = unknown;
export type DeleteWidgetsV6ResourcesIconByIdApiArg = {
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type DeleteWidgetsV6ResourcesImagesByIdApiResponse = unknown;
export type DeleteWidgetsV6ResourcesImagesByIdApiArg = {
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type FontDto = {
  id?: string;
  name?: string;
  url?: string;
  cssReference?: string;
};
export type PresetLookupDto = {
  id?: string;
  name?: string;
};
export type LookupWithPreselectedItemPagedResponseDtoPresetLookupDto = {
  totalItems?: number;
  items?: PresetLookupDto[];
  preselectedItem?: PresetLookupDto | null;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type PresetBoundariesTypeDto = ('PROMOTION_LEVEL' | 'SHOP_LEVEL') | null;
export type SortDirectionDto = ('ASC' | 'DESC') | null;
export type ResourceLibraryTypeDto = ('DEFAULT' | 'CUSTOM') | null;
export type ResourceGroupKeyDto =
  | ('OFFER' | 'CHEVRONS' | 'CLOSE_BUTTON')
  | null;
export type IconStyleGroupDto = ('THIN' | 'THICK' | 'SOLID') | null;
export type IconResourceDto = {
  id?: string;
  type?: ResourceLibraryTypeDto | null;
  name?: string | null;
  url?: string;
  groupKey?: ResourceGroupKeyDto | null;
  canBeDeleted?: boolean;
  styleGroup?: IconStyleGroupDto | null;
};
export type ImageResourceDto = {
  id?: string;
  type?: ResourceLibraryTypeDto | null;
  name?: string | null;
  url?: string;
  groupKey?: ResourceGroupKeyDto | null;
  canBeDeleted?: boolean;
};
export type ClockStyleDto = {
  id?: string;
  name?: string;
};
export type PresetTypeDto = ('SYSTEM' | 'CUSTOM') | null;
export type PresetPreviewDto = {
  id?: string;
  name?: string;
  colors?: string[];
  lastModifiedAt?: number;
  isDefault?: boolean;
  type?: PresetTypeDto | null;
  affectedPromotionsCount?: number;
};
export type GetPresetListResponseDto = {
  totalItems?: number;
  items?: PresetPreviewDto[];
  customPresetsLimitReached?: boolean;
};
export type PresetListSortFieldDto = 'NAME' | null;
export type EntityIdentifierDto = {
  id?: string;
};
export type WidgetsValidationErrorCode =
  | (
      | 'RESOURCE_CONTENT_IS_NULL_OR_EMPTY'
      | 'RESOURCE_IMAGE_EXCEEDS_MAX_FILE_SIZE'
      | 'RESOURCE_IMAGE_IS_NOT_AN_IMAGE_TYPE'
      | 'PRESET_RESTRICTED_TO_BE_DELETED'
      | 'PRESET_RESTRICTED_TO_BE_UPDATED'
      | 'PRESET_NAME_INVALID'
      | 'PRESET_WIDGET_TYPES_MISSING'
      | 'COLOR_INVALID_FORMAT'
      | 'TEXT_MESSAGE_FONT_SIZE_INVALID_VALUE'
      | 'TEXT_MESSAGE_FONT_WEIGHT_INVALID_VALUE'
      | 'TEXT_MESSAGE_LINE_HEIGHT_INVALID_VALUE'
      | 'GIVEN_FONT_MISSING'
      | 'TRANSACTION_IDENTIFIER_MISSING'
      | 'PRESET_CUSTOM_LIMIT_REACHED'
      | 'BREAKPOINT_CONTENT_WIDTH_INVALID'
      | 'BREAKPOINT_MAX_WIDTH_INVALID'
      | 'BREAKPOINT_CONTENT_WIDTH_CANNOT_BE_HIGHER_THAN_MAX_WIDTH'
      | 'PRESET_DOES_NOT_EXIST'
      | 'PROMOTION_SETUP_INVALID_STYLE_PRIORITY'
      | 'BEHAVIOR_INVALID_PAGE_TYPES'
      | 'RESOURCE_ICON_EXCEEDS_MAX_FILE_SIZE'
      | 'RESOURCE_ICON_IS_NOT_AN_ICON_TYPE'
      | 'STACKING_MISSING_CUSTOM_SPACING'
      | 'FONT_MISSING'
      | 'BACKGROUND_IMAGE_MISSING'
      | 'BACKGROUND_COLOR_MISSING'
      | 'IMAGE_NOT_FOUND'
      | 'ICON_NOT_FOUND'
      | 'PROMOTION_SETUP_AT_LEAST_ONE_SUPPORTED_WIDGET_REQUIRED'
      | 'OFFSET_TYPE_MISSING'
      | 'LINE_WEIGHT_INVALID_CUSTOM_VALUE'
      | 'LINE_WEIGHT_MISSING_CUSTOM_VALUE'
      | 'STITCH_MISSING_CUSTOM_DASH_SIZE'
      | 'STITCH_INVALID_CUSTOM_DASH_SIZE'
      | 'RESOURCE_CANNOT_BE_DELETED_ALREADY_IN_USE'
      | 'OFFSET_INVALID_CUSTOM_VALUE'
      | 'ANNOUNCEMENT_BAR_POSITION_ALREADY_TAKEN'
      | 'PRESET_WIDGET_TYPES_OUTSIDE_BOUNDARIES'
      | 'CORNER_RADIUS_INVALID_CUSTOM_VALUE'
      | 'PRESET_CANNOT_BE_SET_AS_PROMOTION_LEVEL'
      | 'PRESET_CANNOT_BE_SET_AS_SHOP_LEVEL'
      | 'PRESET_BOUNDARIES_MISSING'
      | 'PRESET_SET_AS_ACTIVE_FLAG_MISSING'
      | 'PRESET_CREATING_PRESET_ON_INVALID_BASE_PRESET_BOUNDARIES'
    )
  | null;
export type ValidationFailedResponseDto = {
  errors?: WidgetsValidationErrorCode[];
};
export type ValidationFailedResponseDtoRead = {
  errors?: WidgetsValidationErrorCode[];
  relatedItems?: string[];
};
export type CreatePresetRequestDto = {
  name?: string;
  basePresetId?: string | null;
  boundaries?: PresetBoundariesTypeDto | null;
};
export type ProductBannerIconPresetDto = {
  isHidden?: boolean | null;
  iconId?: string | null;
  color?: string | null;
};
export type ProductBannerIconPresetDtoRead = {
  isHidden?: boolean | null;
  iconId?: string | null;
  color?: string | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type ImageFitDto = ('COVER' | 'CONTAIN') | null;
export type ImageSizeDto = ('SMALL' | 'MEDIUM' | 'LARGE') | null;
export type ImageComponentDto = {
  imageId?: string | null;
  fit?: ImageFitDto | null;
  size?: ImageSizeDto | null;
};
export type CornerRadiusTypeDto = ('NONE' | 'CIRCLE' | 'CUSTOM') | null;
export type CornerRadiusSimpleTypeDto =
  | ('NONE' | 'SMALL' | 'MEDIUM' | 'LARGE')
  | null;
export type CornerRadiusComponentDto = {
  type?: CornerRadiusTypeDto | null;
  customValue?: number | null;
  simpleType?: CornerRadiusSimpleTypeDto | null;
};
export type BorderSideDto =
  | ('ALL' | 'TOP' | 'BOTTOM' | 'LEFT' | 'RIGHT' | 'MIDDLE')
  | null;
export type LineWeightTypeDto =
  | ('EXTRA_SMALL' | 'SMALL' | 'MEDIUM' | 'LARGE' | 'EXTRA_LARGE' | 'CUSTOM')
  | null;
export type LineWeightDto = {
  type?: LineWeightTypeDto | null;
  customValue?: number | null;
};
export type BorderStyleTypeDto = ('SOLID' | 'DASHED') | null;
export type BorderComponentDto = {
  color?: string | null;
  sides?: BorderSideDto[] | null;
  lineWeight?: LineWeightDto | null;
  type?: BorderStyleTypeDto | null;
};
export type OptionDtoBorderComponentDto = {
  enabled?: boolean;
  value?: BorderComponentDto | null;
};
export type ProductBannerImagePresetDto = {
  isHidden?: boolean | null;
  image?: ImageComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  border?: OptionDtoBorderComponentDto | null;
};
export type ProductBannerImagePresetDtoRead = {
  isHidden?: boolean | null;
  image?: ImageComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  border?: OptionDtoBorderComponentDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type DeviceSpecificSettingDtoNullableInt32 = {
  desktop?: number | null;
  mobile?: number | null;
};
export type DeviceSpecificSettingDtoString = {
  desktop?: string;
  mobile?: string | null;
};
export type TextStyleDto = {
  differentOnMobile?: boolean | null;
  fontSize?: DeviceSpecificSettingDtoNullableInt32 | null;
  lineHeight?: DeviceSpecificSettingDtoString | null;
  fontWeight?: DeviceSpecificSettingDtoNullableInt32 | null;
};
export type FontStyleTypeDto = ('INHERITED' | 'CUSTOM') | null;
export type FontStyleDto = {
  type?: FontStyleTypeDto | null;
  fontId?: string | null;
};
export type MessageStyleComponentDto = {
  style?: TextStyleDto | null;
  color?: string | null;
  font?: FontStyleDto | null;
};
export type ProductBannerMessagePresetDto = {
  isHidden?: boolean | null;
  style?: MessageStyleComponentDto | null;
};
export type ProductBannerMessagePresetDtoRead = {
  isHidden?: boolean | null;
  style?: MessageStyleComponentDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type ProductBannerPresetFirstLevelDto = {
  icon?: ProductBannerIconPresetDto | null;
  image?: ProductBannerImagePresetDto | null;
  message?: ProductBannerMessagePresetDto | null;
};
export type ProductBannerPresetFirstLevelDtoRead = {
  icon?: ProductBannerIconPresetDtoRead | null;
  image?: ProductBannerImagePresetDtoRead | null;
  message?: ProductBannerMessagePresetDtoRead | null;
};
export type ProductBannerFirstLevelEntryTypeDto =
  | ('ICON' | 'IMAGE' | 'MESSAGE')
  | null;
export type BackgroundColorFillTypeDto = ('SOLID' | 'IMAGE') | null;
export type IndicatorLineDto = {
  lineWeight?: LineWeightDto | null;
  color?: string | null;
};
export type OptionDtoIndicatorLineDto = {
  enabled?: boolean;
  value?: IndicatorLineDto | null;
};
export type BackgroundComponentDto = {
  fill?: BackgroundColorFillTypeDto | null;
  color?: string | null;
  imageId?: string | null;
  border?: OptionDtoBorderComponentDto | null;
  indicatorLine?: OptionDtoIndicatorLineDto | null;
};
export type StackingDirectionDto = ('VERTICAL' | 'HORIZONTAL') | null;
export type SpacingTypeDto =
  | ('EXTRA_SMALL' | 'SMALL' | 'MEDIUM' | 'LARGE' | 'EXTRA_LARGE' | 'CUSTOM')
  | null;
export type SpacingDto = {
  type?: SpacingTypeDto | null;
  customValue?: number | null;
};
export type StackingComponentDto = {
  direction?: StackingDirectionDto | null;
  spacing?: SpacingDto | null;
};
export type OptionDtoStackingComponentDto = {
  enabled?: boolean;
  value?: StackingComponentDto | null;
};
export type ProductBannerGeneralShopSettingsDto = {
  stacking?: OptionDtoStackingComponentDto | null;
};
export type ProductBannerPresetGeneralDto = {
  background?: BackgroundComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  shopSettings?: ProductBannerGeneralShopSettingsDto | null;
};
export type PageTypeDto =
  | (
      | 'ALL'
      | 'HOME'
      | 'COLLECTION'
      | 'PRODUCT'
      | 'CART'
      | 'DRAWER_CART'
      | 'NINJA_CART'
      | 'CHECKOUT'
      | 'BLOG_POST'
      | 'SEARCH'
      | 'CUSTOM'
    )
  | null;
export type ProductBannerPresetDto = {
  isHidden?: boolean | null;
  options?: ProductBannerPresetFirstLevelDto | null;
  currentEntries?: ProductBannerFirstLevelEntryTypeDto[] | null;
  availableEntries?: ProductBannerFirstLevelEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: ProductBannerPresetGeneralDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type ProductBannerPresetDtoRead = {
  isHidden?: boolean | null;
  options?: ProductBannerPresetFirstLevelDtoRead | null;
  currentEntries?: ProductBannerFirstLevelEntryTypeDto[] | null;
  availableEntries?: ProductBannerFirstLevelEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: ProductBannerPresetGeneralDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type PromotionalBadgeMessagePresetDto = {
  isHidden?: boolean | null;
  style?: MessageStyleComponentDto | null;
};
export type PromotionalBadgeMessagePresetDtoRead = {
  isHidden?: boolean | null;
  style?: MessageStyleComponentDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type PromotionalBadgeBadgeStyleSetKeyDto =
  | ('PRIMARY' | 'SECONDARY')
  | null;
export type BadgeStyleShapeDto =
  | ('RECTANGLE' | 'CIRCLE' | 'STAR' | 'RIBBON')
  | null;
export type BadgeStyleSizeDto = ('SMALL' | 'MEDIUM' | 'LARGE') | null;
export type BadgeStyleSectionDto = {
  shape?: BadgeStyleShapeDto | null;
  size?: BadgeStyleSizeDto | null;
};
export type StitchDashSizeTypeDto =
  | ('SMALL' | 'MEDIUM' | 'LARGE' | 'CUSTOM')
  | null;
export type StitchComponentDto = {
  dashSizeType?: StitchDashSizeTypeDto | null;
  customDashSize?: number | null;
  lineWeight?: LineWeightDto | null;
  color?: string | null;
};
export type OptionDtoStitchComponentDto = {
  enabled?: boolean;
  value?: StitchComponentDto | null;
};
export type PromotionalBadgeBadgeStyleSetDto = {
  style?: BadgeStyleSectionDto | null;
  background?: BackgroundComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  stitch?: OptionDtoStitchComponentDto | null;
};
export type PromotionalBadgeBadgePresetDto = {
  isHidden?: boolean | null;
  selectedStyleSet?: PromotionalBadgeBadgeStyleSetKeyDto | null;
  primaryStyle?: PromotionalBadgeBadgeStyleSetDto | null;
  secondaryStyle?: PromotionalBadgeBadgeStyleSetDto | null;
};
export type PromotionalBadgeBadgePresetDtoRead = {
  isHidden?: boolean | null;
  selectedStyleSet?: PromotionalBadgeBadgeStyleSetKeyDto | null;
  primaryStyle?: PromotionalBadgeBadgeStyleSetDto | null;
  secondaryStyle?: PromotionalBadgeBadgeStyleSetDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type PromotionalBadgePresetFirstLevelDto = {
  message?: PromotionalBadgeMessagePresetDto | null;
  badge?: PromotionalBadgeBadgePresetDto | null;
};
export type PromotionalBadgePresetFirstLevelDtoRead = {
  message?: PromotionalBadgeMessagePresetDtoRead | null;
  badge?: PromotionalBadgeBadgePresetDtoRead | null;
};
export type PromotionalBadgeFirstLevelEntryTypeDto =
  | ('MESSAGE' | 'BADGE')
  | null;
export type PositionDto =
  | (
      | 'TOP'
      | 'TOP_LEFT'
      | 'TOP_RIGHT'
      | 'BOTTOM_LEFT'
      | 'BOTTOM_RIGHT'
      | 'BOTTOM'
      | 'CENTER'
      | 'MIDDLE'
      | 'LEFT'
      | 'RIGHT'
    )
  | null;
export type PositionComponentDto = {
  position?: PositionDto | null;
};
export type OffsetTypeDto =
  | ('EXTRA_SMALL' | 'SMALL' | 'MEDIUM' | 'LARGE' | 'EXTRA_LARGE' | 'CUSTOM')
  | null;
export type OffsetComponentDto = {
  type?: OffsetTypeDto | null;
  vertical?: number | null;
  horizontal?: number | null;
};
export type OptionDtoOffsetComponentDto = {
  enabled?: boolean;
  value?: OffsetComponentDto | null;
};
export type PromotionalBadgeGeneralShopSettingsDto = {
  position?: PositionComponentDto | null;
  stacking?: OptionDtoStackingComponentDto | null;
  offset?: OptionDtoOffsetComponentDto | null;
};
export type PromotionalBadgePresetGeneralDto = {
  shopSettings?: PromotionalBadgeGeneralShopSettingsDto | null;
};
export type PromotionalBadgePresetDto = {
  isHidden?: boolean | null;
  options?: PromotionalBadgePresetFirstLevelDto | null;
  currentEntries?: PromotionalBadgeFirstLevelEntryTypeDto[] | null;
  availableEntries?: PromotionalBadgeFirstLevelEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: PromotionalBadgePresetGeneralDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type PromotionalBadgePresetDtoRead = {
  isHidden?: boolean | null;
  options?: PromotionalBadgePresetFirstLevelDtoRead | null;
  currentEntries?: PromotionalBadgeFirstLevelEntryTypeDto[] | null;
  availableEntries?: PromotionalBadgeFirstLevelEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: PromotionalBadgePresetGeneralDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type IconComponentDto = {
  iconId?: string | null;
  color?: string | null;
};
export type AnnouncementBarIconPresetDto = {
  isHidden?: boolean | null;
  icon?: IconComponentDto | null;
};
export type AnnouncementBarIconPresetDtoRead = {
  isHidden?: boolean | null;
  icon?: IconComponentDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type AnnouncementBarImagePresetDto = {
  isHidden?: boolean | null;
  image?: ImageComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  border?: OptionDtoBorderComponentDto | null;
};
export type AnnouncementBarImagePresetDtoRead = {
  isHidden?: boolean | null;
  image?: ImageComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  border?: OptionDtoBorderComponentDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type AnnouncementBarMessagePresetDto = {
  isHidden?: boolean | null;
  style?: MessageStyleComponentDto | null;
};
export type AnnouncementBarMessagePresetDtoRead = {
  isHidden?: boolean | null;
  style?: MessageStyleComponentDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type AnnouncementBarPrimaryBannerLevelPresetDto = {
  icon?: AnnouncementBarIconPresetDto | null;
  image?: AnnouncementBarImagePresetDto | null;
  message?: AnnouncementBarMessagePresetDto | null;
};
export type AnnouncementBarPrimaryBannerLevelPresetDtoRead = {
  icon?: AnnouncementBarIconPresetDtoRead | null;
  image?: AnnouncementBarImagePresetDtoRead | null;
  message?: AnnouncementBarMessagePresetDtoRead | null;
};
export type AnnouncementBarPrimaryBannerLevelPresetEntryTypeDto =
  | ('ICON' | 'IMAGE' | 'MESSAGE')
  | null;
export type AnnouncementBarCyclingDurationDto =
  | ('LONG' | 'NORMAL' | 'SHORT' | 'CUSTOM')
  | null;
export type AnnouncementBarShopSettingsCyclingDto = {
  duration?: AnnouncementBarCyclingDurationDto | null;
  customDuration?: number | null;
};
export type AnimationDto =
  | (
      | 'NONE'
      | 'SLIDE'
      | 'SLIDE_IN'
      | 'SLIDE_OUT'
      | 'SLIDE_OUT_TOP'
      | 'SLIDE_OUT_RIGHT'
      | 'SLIDE_OUT_LEFT'
      | 'SLIDE_OUT_BOTTOM'
      | 'FADE'
      | 'FADE_IN'
      | 'FADE_OUT'
      | 'FADE_OUT_TOP'
      | 'FADE_OUT_RIGHT'
      | 'FADE_OUT_LEFT'
      | 'FADE_OUT_BOTTOM'
      | 'INSTANT'
      | 'BOUNCE'
      | 'FLASH'
      | 'SHAKE'
      | 'MOVE_IN'
      | 'MOVE_OUT'
    )
  | null;
export type AnnouncementBarAnimationsShopSettingsDto = {
  onClose?: AnimationDto | null;
  whenCycling?: AnimationDto | null;
};
export type OptionDtoIconComponentDto = {
  enabled?: boolean;
  value?: IconComponentDto | null;
};
export type AnnouncementBarIndicatorsStyleDto = ('DOTS' | 'BARS') | null;
export type AnnouncementBarIndicatorsColorTypeDto = ('DARK' | 'LIGHT') | null;
export type AnnouncementBarIndicatorsDto = {
  style?: AnnouncementBarIndicatorsStyleDto | null;
  color?: AnnouncementBarIndicatorsColorTypeDto | null;
};
export type OptionDtoAnnouncementBarIndicatorsDto = {
  enabled?: boolean;
  value?: AnnouncementBarIndicatorsDto | null;
};
export type AnnouncementBarPrimaryBannerShopSettingsDto = {
  cycling?: AnnouncementBarShopSettingsCyclingDto | null;
  animations?: AnnouncementBarAnimationsShopSettingsDto | null;
  chevrons?: OptionDtoIconComponentDto | null;
  closeButton?: OptionDtoIconComponentDto | null;
  indicators?: OptionDtoAnnouncementBarIndicatorsDto | null;
};
export type AnnouncementBarPrimaryBannerGeneralPresetDto = {
  background?: BackgroundComponentDto | null;
  shopSettings?: AnnouncementBarPrimaryBannerShopSettingsDto | null;
};
export type AnnouncementBarPrimaryBannerPresetDto = {
  isHidden?: boolean | null;
  options?: AnnouncementBarPrimaryBannerLevelPresetDto | null;
  currentEntries?: AnnouncementBarPrimaryBannerLevelPresetEntryTypeDto[] | null;
  availableEntries?:
    | AnnouncementBarPrimaryBannerLevelPresetEntryTypeDto[]
    | null;
  supportsModules?: boolean | null;
  general?: AnnouncementBarPrimaryBannerGeneralPresetDto | null;
};
export type AnnouncementBarPrimaryBannerPresetDtoRead = {
  isHidden?: boolean | null;
  options?: AnnouncementBarPrimaryBannerLevelPresetDtoRead | null;
  currentEntries?: AnnouncementBarPrimaryBannerLevelPresetEntryTypeDto[] | null;
  availableEntries?:
    | AnnouncementBarPrimaryBannerLevelPresetEntryTypeDto[]
    | null;
  supportsModules?: boolean | null;
  general?: AnnouncementBarPrimaryBannerGeneralPresetDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type AnnouncementBarSecondaryBannerLevelPresetDto = {
  icon?: AnnouncementBarIconPresetDto | null;
  image?: AnnouncementBarImagePresetDto | null;
  message?: AnnouncementBarMessagePresetDto | null;
};
export type AnnouncementBarSecondaryBannerLevelPresetDtoRead = {
  icon?: AnnouncementBarIconPresetDtoRead | null;
  image?: AnnouncementBarImagePresetDtoRead | null;
  message?: AnnouncementBarMessagePresetDtoRead | null;
};
export type AnnouncementBarSecondaryBannerLevelPresetEntryTypeDto =
  | ('ICON' | 'IMAGE' | 'MESSAGE')
  | null;
export type AnnouncementBarSecondaryBannerGeneralPresetDto = {
  background?: BackgroundComponentDto | null;
};
export type AnnouncementBarSecondaryBannerPresetDto = {
  isHidden?: boolean | null;
  options?: AnnouncementBarSecondaryBannerLevelPresetDto | null;
  currentEntries?:
    | AnnouncementBarSecondaryBannerLevelPresetEntryTypeDto[]
    | null;
  availableEntries?:
    | AnnouncementBarSecondaryBannerLevelPresetEntryTypeDto[]
    | null;
  supportsModules?: boolean | null;
  general?: AnnouncementBarSecondaryBannerGeneralPresetDto | null;
};
export type AnnouncementBarSecondaryBannerPresetDtoRead = {
  isHidden?: boolean | null;
  options?: AnnouncementBarSecondaryBannerLevelPresetDtoRead | null;
  currentEntries?:
    | AnnouncementBarSecondaryBannerLevelPresetEntryTypeDto[]
    | null;
  availableEntries?:
    | AnnouncementBarSecondaryBannerLevelPresetEntryTypeDto[]
    | null;
  supportsModules?: boolean | null;
  general?: AnnouncementBarSecondaryBannerGeneralPresetDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type AnnouncementBarFirstLevelPresetDto = {
  primaryBanner?: AnnouncementBarPrimaryBannerPresetDto | null;
  secondaryBanner?: AnnouncementBarSecondaryBannerPresetDto | null;
};
export type AnnouncementBarFirstLevelPresetDtoRead = {
  primaryBanner?: AnnouncementBarPrimaryBannerPresetDtoRead | null;
  secondaryBanner?: AnnouncementBarSecondaryBannerPresetDtoRead | null;
};
export type AnnouncementBarFirstLevelPresetEntryTypeDto =
  | ('PRIMARY_BANNER' | 'SECONDARY_BANNER')
  | null;
export type AnnouncementBarPagePositionDto =
  | ('ABOVE_HEADER' | 'BELOW_HEADER' | 'BOTTOM_OF_PAGE')
  | null;
export type SizeTypeDto =
  | ('EXTRA_SMALL' | 'SMALL' | 'MEDIUM' | 'LARGE' | 'EXTRA_LARGE')
  | null;
export type SizeWidthDto = ('AUTO' | 'FIXED' | 'BREAKPOINTS') | null;
export type BreakpointDto = {
  maxWidth?: number;
  contentWidth?: number;
};
export type SizeComponentDto = {
  size?: SizeTypeDto | null;
  width?: SizeWidthDto | null;
  fixedWidth?: number | null;
  breakpoints?: BreakpointDto[] | null;
};
export type AnnouncementBarGeneralShopSettingsDto = {
  pagePosition?: AnnouncementBarPagePositionDto | null;
  size?: SizeComponentDto | null;
  isSticky?: boolean | null;
  zIndex?: number | null;
};
export type AnnouncementBarPresetGeneralDto = {
  shopSettings?: AnnouncementBarGeneralShopSettingsDto | null;
};
export type AnnouncementBarPresetDto = {
  isHidden?: boolean | null;
  options?: AnnouncementBarFirstLevelPresetDto | null;
  currentEntries?: AnnouncementBarFirstLevelPresetEntryTypeDto[] | null;
  availableEntries?: AnnouncementBarFirstLevelPresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: AnnouncementBarPresetGeneralDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type AnnouncementBarPresetDtoRead = {
  isHidden?: boolean | null;
  options?: AnnouncementBarFirstLevelPresetDtoRead | null;
  currentEntries?: AnnouncementBarFirstLevelPresetEntryTypeDto[] | null;
  availableEntries?: AnnouncementBarFirstLevelPresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: AnnouncementBarPresetGeneralDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type TextTypeDto = ('UNIFIED' | 'DIFFERENT') | null;
export type TextSettingsDto = {
  goalStates?: TextTypeDto | null;
  device?: TextTypeDto | null;
  collapsed?: TextTypeDto | null;
};
export type GoalStateDto = ('BEFORE' | 'DURING' | 'AFTER') | null;
export type DeviceTypeDto = ('DESKTOP' | 'MOBILE') | null;
export type CollapsedStateDto = ('COLLAPSED' | 'NOT_COLLAPSED') | null;
export type LocalizedStringDto = {
  text?: string;
  language?: string;
};
export type TextEntryDto = {
  goal?: GoalStateDto | null;
  device?: DeviceTypeDto | null;
  collapsed?: CollapsedStateDto | null;
  text?: LocalizedStringDto[];
};
export type MessageSetupDto = {
  settings?: TextSettingsDto | null;
  entries?: TextEntryDto[] | null;
};
export type MessageDto = {
  message?: MessageSetupDto | null;
  style?: MessageStyleComponentDto | null;
};
export type OptionDtoMessageDto = {
  enabled?: boolean;
  value?: MessageDto | null;
};
export type NotificationCollapsedStateHeaderPresetDto = {
  isHidden?: boolean | null;
  icon?: OptionDtoIconComponentDto | null;
  sameAsExpandedState?: boolean | null;
  headlineText?: OptionDtoMessageDto | null;
};
export type NotificationCollapsedStateHeaderPresetDtoRead = {
  isHidden?: boolean | null;
  icon?: OptionDtoIconComponentDto | null;
  sameAsExpandedState?: boolean | null;
  headlineText?: OptionDtoMessageDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type NotificationCollapsedStateLevelPresetDto = {
  header?: NotificationCollapsedStateHeaderPresetDto | null;
};
export type NotificationCollapsedStateLevelPresetDtoRead = {
  header?: NotificationCollapsedStateHeaderPresetDtoRead | null;
};
export type NotificationCollapsedStatePresetEntryTypeDto = 'HEADER' | null;
export type NotificationCollapsedStateGeneralPresetDto = {
  customCss?: string | null;
};
export type NotificationCollapsedStatePresetDto = {
  isHidden?: boolean | null;
  options?: NotificationCollapsedStateLevelPresetDto | null;
  currentEntries?: NotificationCollapsedStatePresetEntryTypeDto[] | null;
  availableEntries?: NotificationCollapsedStatePresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: NotificationCollapsedStateGeneralPresetDto | null;
};
export type NotificationCollapsedStatePresetDtoRead = {
  isHidden?: boolean | null;
  options?: NotificationCollapsedStateLevelPresetDtoRead | null;
  currentEntries?: NotificationCollapsedStatePresetEntryTypeDto[] | null;
  availableEntries?: NotificationCollapsedStatePresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: NotificationCollapsedStateGeneralPresetDto | null;
};
export type OptionDtoString = {
  enabled?: boolean;
  value?: string | null;
};
export type NotificationExpandedStateHeaderShopSettingsDto = {
  minimizingIcon?: OptionDtoIconComponentDto | null;
  messageCount?: OptionDtoString | null;
};
export type NotificationExpandedStateHeaderPresetDto = {
  isHidden?: boolean | null;
  headlineText?: OptionDtoMessageDto | null;
  shopSettings?: NotificationExpandedStateHeaderShopSettingsDto | null;
};
export type NotificationExpandedStateHeaderPresetDtoRead = {
  isHidden?: boolean | null;
  headlineText?: OptionDtoMessageDto | null;
  shopSettings?: NotificationExpandedStateHeaderShopSettingsDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type NotificationExpandedStateOfferPresetDto = {
  isHidden?: boolean | null;
  icon?: IconComponentDto | null;
  textStyle?: MessageStyleComponentDto | null;
};
export type NotificationExpandedStateOfferPresetDtoRead = {
  isHidden?: boolean | null;
  icon?: IconComponentDto | null;
  textStyle?: MessageStyleComponentDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type CountdownClockComponentDto = {
  text?: MessageSetupDto | null;
  textStyle?: MessageStyleComponentDto | null;
};
export type NotificationExpandedStateFooterPresetDto = {
  isHidden?: boolean | null;
  footerText?: OptionDtoMessageDto | null;
  countdownClock?: CountdownClockComponentDto | null;
};
export type NotificationExpandedStateFooterPresetDtoRead = {
  isHidden?: boolean | null;
  footerText?: OptionDtoMessageDto | null;
  countdownClock?: CountdownClockComponentDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type NotificationExpandedStateImagePresetDto = {
  isHidden?: boolean | null;
  image?: ImageComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  border?: OptionDtoBorderComponentDto | null;
};
export type NotificationExpandedStateImagePresetDtoRead = {
  isHidden?: boolean | null;
  image?: ImageComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  border?: OptionDtoBorderComponentDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type NotificationExpandedStateLevelPresetDto = {
  header?: NotificationExpandedStateHeaderPresetDto | null;
  offer?: NotificationExpandedStateOfferPresetDto | null;
  footer?: NotificationExpandedStateFooterPresetDto | null;
  image?: NotificationExpandedStateImagePresetDto | null;
};
export type NotificationExpandedStateLevelPresetDtoRead = {
  header?: NotificationExpandedStateHeaderPresetDtoRead | null;
  offer?: NotificationExpandedStateOfferPresetDtoRead | null;
  footer?: NotificationExpandedStateFooterPresetDtoRead | null;
  image?: NotificationExpandedStateImagePresetDtoRead | null;
};
export type NotificationExpandedStatePresetEntryTypeDto =
  | ('HEADER' | 'OFFER' | 'FOOTER' | 'IMAGE')
  | null;
export type StylePriorityComponentDto = {
  priority?: number | null;
};
export type ShadowSizeDto =
  | ('EXTRA_SMALL' | 'SMALL' | 'MEDIUM' | 'LARGE' | 'EXTRA_LARGE')
  | null;
export type ShadowComponentDto = {
  size?: ShadowSizeDto | null;
  color?: string | null;
};
export type OptionDtoShadowComponentDto = {
  enabled?: boolean;
  value?: ShadowComponentDto | null;
};
export type DeviceDisplayDto = ('ALL' | 'DESKTOP' | 'MOBILE') | null;
export type PageDisplayTypeDto = ('DEFAULT' | 'CUSTOM') | null;
export type ShopifyIdCollectionDto = {
  allItems?: boolean | null;
  selectedItems?: string[] | null;
};
export type PageDisplayComponentDto = {
  type?: PageDisplayTypeDto | null;
  pages?: PageTypeDto[] | null;
  collections?: ShopifyIdCollectionDto | null;
  products?: ShopifyIdCollectionDto | null;
  availablePages?: PageTypeDto[] | null;
};
export type OptionDtoAnimationDto = {
  enabled?: boolean;
  value?: AnimationDto | null;
};
export type NotificationAnimationsShopSettingsDto = {
  onFirstDisplay?: OptionDtoAnimationDto | null;
  whenMinimizing?: OptionDtoAnimationDto | null;
};
export type NotificationMinimizingBehaviorShopSettingsDto = {
  minimizeAfterFirstView?: boolean | null;
  minimizeAutomatically?: boolean | null;
};
export type MessageCountComponentDto = {
  color?: string | null;
};
export type OptionDtoMessageCountComponentDto = {
  enabled?: boolean;
  value?: MessageCountComponentDto | null;
};
export type NotificationGeneralShopSettingsDto = {
  deviceDisplay?: DeviceDisplayDto | null;
  pageDisplay?: PageDisplayComponentDto | null;
  pagePosition?: PositionComponentDto | null;
  offset?: OptionDtoOffsetComponentDto | null;
  animations?: NotificationAnimationsShopSettingsDto | null;
  minimizingBehavior?: NotificationMinimizingBehaviorShopSettingsDto | null;
  messageCount?: OptionDtoMessageCountComponentDto | null;
};
export type NotificationExpandedStateGeneralPresetDto = {
  stylePriority?: StylePriorityComponentDto | null;
  size?: SizeComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  background?: BackgroundComponentDto | null;
  shadow?: OptionDtoShadowComponentDto | null;
  shopSettings?: NotificationGeneralShopSettingsDto | null;
  customCss?: string | null;
};
export type NotificationExpandedStatePresetDto = {
  isHidden?: boolean | null;
  options?: NotificationExpandedStateLevelPresetDto | null;
  currentEntries?: NotificationExpandedStatePresetEntryTypeDto[] | null;
  availableEntries?: NotificationExpandedStatePresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: NotificationExpandedStateGeneralPresetDto | null;
};
export type NotificationExpandedStatePresetDtoRead = {
  isHidden?: boolean | null;
  options?: NotificationExpandedStateLevelPresetDtoRead | null;
  currentEntries?: NotificationExpandedStatePresetEntryTypeDto[] | null;
  availableEntries?: NotificationExpandedStatePresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: NotificationExpandedStateGeneralPresetDto | null;
};
export type NotificationStatesPresetDto = {
  collapsedState?: NotificationCollapsedStatePresetDto | null;
  expandedState?: NotificationExpandedStatePresetDto | null;
};
export type NotificationStatesPresetDtoRead = {
  collapsedState?: NotificationCollapsedStatePresetDtoRead | null;
  expandedState?: NotificationExpandedStatePresetDtoRead | null;
};
export type NotificationStatesPresetEntryTypeDto =
  | ('COLLAPSED_STATE' | 'EXPANDED_STATE')
  | null;
export type NotificationGeneralPresetDto = object;
export type NotificationPresetDto = {
  isHidden?: boolean | null;
  options?: NotificationStatesPresetDto | null;
  currentEntries?: NotificationStatesPresetEntryTypeDto[] | null;
  availableEntries?: NotificationStatesPresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: NotificationGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type NotificationPresetDtoRead = {
  isHidden?: boolean | null;
  options?: NotificationStatesPresetDtoRead | null;
  currentEntries?: NotificationStatesPresetEntryTypeDto[] | null;
  availableEntries?: NotificationStatesPresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: NotificationGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type PromotionCodeFieldCodeInputShopSettingsDto = {
  shopifyDiscountCodesEnabled?: boolean | null;
  discountNinjaPromotionCodesEnabled?: boolean | null;
};
export type PromotionCodeFieldCodeInputPresetDto = {
  isHidden?: boolean | null;
  placeholder?: MessageDto | null;
  shopSettings?: PromotionCodeFieldCodeInputShopSettingsDto | null;
};
export type PromotionCodeFieldCodeInputPresetDtoRead = {
  isHidden?: boolean | null;
  placeholder?: MessageDto | null;
  shopSettings?: PromotionCodeFieldCodeInputShopSettingsDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type PromotionCodeFieldCodeValidationStateDto =
  | ('INVALID' | 'NOT_APPLICABLE' | 'CODE_APPLIED')
  | null;
export type PromotionCodeFieldInvalidValidationStatePresetDto = {
  message?: MessageDto | null;
};
export type PromotionCodeFieldNotApplicableValidationStatePresetDto = {
  message?: MessageDto | null;
  deleteButton?: IconComponentDto | null;
};
export type PromotionCodeFieldAppliedValidationStatePresetDto = {
  message?: MessageDto | null;
  deleteButton?: IconComponentDto | null;
  confirmationIcon?: OptionDtoIconComponentDto | null;
};
export type PromotionCodeFieldCodeValidationPresetDto = {
  isHidden?: boolean | null;
  selectedState?: PromotionCodeFieldCodeValidationStateDto | null;
  invalidState?: PromotionCodeFieldInvalidValidationStatePresetDto | null;
  notApplicableState?: PromotionCodeFieldNotApplicableValidationStatePresetDto | null;
  codeAppliedState?: PromotionCodeFieldAppliedValidationStatePresetDto | null;
};
export type PromotionCodeFieldCodeValidationPresetDtoRead = {
  isHidden?: boolean | null;
  selectedState?: PromotionCodeFieldCodeValidationStateDto | null;
  invalidState?: PromotionCodeFieldInvalidValidationStatePresetDto | null;
  notApplicableState?: PromotionCodeFieldNotApplicableValidationStatePresetDto | null;
  codeAppliedState?: PromotionCodeFieldAppliedValidationStatePresetDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type ButtonCornersStyleDto =
  | (
      | 'SQUARE'
      | 'ROUND_EXTRA_SMALL'
      | 'ROUND_SMALL'
      | 'ROUND_MEDIUM'
      | 'ROUND_LARGE'
      | 'ROUND_EXTRA_LARGE'
      | 'CIRCLE'
    )
  | null;
export type HoveringStyleDto = 'DARKER' | null;
export type OptionDtoNullableHoveringStyleDto = {
  enabled?: boolean;
  value?: HoveringStyleDto | null;
};
export type CallToActionButtonStyleDto = {
  cornersStyle?: ButtonCornersStyleDto | null;
  backgroundColor?: string | null;
  borderColor?: string | null;
  textStyle?: MessageStyleComponentDto | null;
  hoveringEffect?: OptionDtoNullableHoveringStyleDto | null;
};
export type PromotionCodeFieldCallToActionButtonPresetDto = {
  size?: SizeComponentDto | null;
  position?: PositionComponentDto | null;
  label?: MessageDto | null;
  buttonStyle?: CallToActionButtonStyleDto | null;
};
export type PromotionCodeFieldFirstLevelPresetDto = {
  codeInput?: PromotionCodeFieldCodeInputPresetDto | null;
  codeValidation?: PromotionCodeFieldCodeValidationPresetDto | null;
  callToActionButton?: PromotionCodeFieldCallToActionButtonPresetDto | null;
};
export type PromotionCodeFieldFirstLevelPresetDtoRead = {
  codeInput?: PromotionCodeFieldCodeInputPresetDtoRead | null;
  codeValidation?: PromotionCodeFieldCodeValidationPresetDtoRead | null;
  callToActionButton?: PromotionCodeFieldCallToActionButtonPresetDto | null;
};
export type PromotionCodeFieldPresetEntryTypeDto =
  | ('CODE_INPUT' | 'CODE_VALIDATION' | 'CALL_TO_ACTION_BUTTON')
  | null;
export type PromotionCodeFieldHeaderStyleDto = ('STYLE_1' | 'STYLE_2') | null;
export type PromotionCodeFieldDisplayModeDto =
  | ('COLLAPSED' | 'EXPANDED')
  | null;
export type PromotionCodeFieldShopSettingsDisplayModeDto = {
  generalDisplayMode?: PromotionCodeFieldDisplayModeDto | null;
  cartDisplayMode?: PromotionCodeFieldDisplayModeDto | null;
  drawerCartDisplayMode?: PromotionCodeFieldDisplayModeDto | null;
};
export type CollapseExpandIconDto = ('UNDERLINE' | 'CHEVRON') | null;
export type PromotionCodeFieldShopSettingsCollapseExpandDto = {
  generalCollapseExpand?: CollapseExpandIconDto | null;
  cartCollapseExpand?: CollapseExpandIconDto | null;
  drawerCartCollapseExpand?: CollapseExpandIconDto | null;
};
export type OptionDtoNullableSpacingTypeDto = {
  enabled?: boolean;
  value?: SpacingTypeDto | null;
};
export type PromotionCodeFieldGeneralShopSettingsDto = {
  displayMode?: PromotionCodeFieldShopSettingsDisplayModeDto | null;
  collapseExpand?: PromotionCodeFieldShopSettingsCollapseExpandDto | null;
  paddingSpace?: OptionDtoNullableSpacingTypeDto;
};
export type PromotionCodeFieldGeneralPresetDto = {
  headerStyle?: PromotionCodeFieldHeaderStyleDto | null;
  headerText?: MessageDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  background?: BackgroundComponentDto | null;
  shopSettings?: PromotionCodeFieldGeneralShopSettingsDto | null;
};
export type PromotionCodeFieldPresetDto = {
  isHidden?: boolean | null;
  options?: PromotionCodeFieldFirstLevelPresetDto | null;
  currentEntries?: PromotionCodeFieldPresetEntryTypeDto[] | null;
  availableEntries?: PromotionCodeFieldPresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: PromotionCodeFieldGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type PromotionCodeFieldPresetDtoRead = {
  isHidden?: boolean | null;
  options?: PromotionCodeFieldFirstLevelPresetDtoRead | null;
  currentEntries?: PromotionCodeFieldPresetEntryTypeDto[] | null;
  availableEntries?: PromotionCodeFieldPresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: PromotionCodeFieldGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type SectionDividerComponentDto = {
  lineWeight?: LineWeightDto | null;
  color?: string | null;
};
export type OptionDtoSectionDividerComponentDto = {
  enabled?: boolean;
  value?: SectionDividerComponentDto | null;
};
export type PromotionSummaryTotalPresetDto = {
  isHidden?: boolean | null;
  label?: MessageDto | null;
  sectionDivider?: OptionDtoSectionDividerComponentDto | null;
};
export type PromotionSummaryTotalPresetDtoRead = {
  isHidden?: boolean | null;
  label?: MessageDto | null;
  sectionDivider?: OptionDtoSectionDividerComponentDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type PromotionSummarySubtotalShopSettingsDto = object;
export type PromotionSummarySubtotalPresetDto = {
  isHidden?: boolean | null;
  label?: MessageSetupDto | null;
  shopSettings?: PromotionSummarySubtotalShopSettingsDto | null;
};
export type PromotionSummarySubtotalPresetDtoRead = {
  isHidden?: boolean | null;
  label?: MessageSetupDto | null;
  shopSettings?: PromotionSummarySubtotalShopSettingsDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type PromotionSummaryShippingAmountCalculationBehaviorDto =
  | ('CALCULATED_AT_CHECKOUT' | 'CALCULATED_IN_CART')
  | null;
export type PromotionSummaryShippingAmountDto = {
  calculation?: PromotionSummaryShippingAmountCalculationBehaviorDto | null;
  displayText?: MessageSetupDto | null;
};
export type PromotionSummaryShippingShopSettingsDto = {
  amount?: PromotionSummaryShippingAmountDto | null;
};
export type PromotionSummaryShippingPresetDto = {
  isHidden?: boolean | null;
  label?: MessageSetupDto | null;
  shopSettings?: PromotionSummaryShippingShopSettingsDto | null;
};
export type PromotionSummaryShippingPresetDtoRead = {
  isHidden?: boolean | null;
  label?: MessageSetupDto | null;
  shopSettings?: PromotionSummaryShippingShopSettingsDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type PromotionSummaryTaxesAndDutiesPresetDto = {
  isHidden?: boolean | null;
  label?: MessageSetupDto | null;
  description?: MessageDto | null;
};
export type PromotionSummaryTaxesAndDutiesPresetDtoRead = {
  isHidden?: boolean | null;
  label?: MessageSetupDto | null;
  description?: MessageDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type PromotionSummaryCalculationsBehaviorDto =
  | ('SUM_OF_ALL' | 'ORDER_DISCOUNTS_ONLY')
  | null;
export type PromotionSummaryDiscountDisplaySettingsDto = {
  showTotal?: boolean | null;
  showDiscountBreakdown?: boolean | null;
};
export type PromotionSummaryDiscountShopSettingsDto = {
  calculations?: PromotionSummaryCalculationsBehaviorDto | null;
  displaySettings?: PromotionSummaryDiscountDisplaySettingsDto | null;
  considerFreeGiftAsDiscount?: boolean | null;
};
export type PromotionSummaryDiscountPresetDto = {
  isHidden?: boolean | null;
  label?: MessageSetupDto | null;
  shopSettings?: PromotionSummaryDiscountShopSettingsDto | null;
};
export type PromotionSummaryDiscountPresetDtoRead = {
  isHidden?: boolean | null;
  label?: MessageSetupDto | null;
  shopSettings?: PromotionSummaryDiscountShopSettingsDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type PromotionSummaryDisclaimerPresetDto = {
  isHidden?: boolean | null;
  text?: OptionDtoMessageDto | null;
};
export type PromotionSummaryDisclaimerPresetDtoRead = {
  isHidden?: boolean | null;
  text?: OptionDtoMessageDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type PromotionSummaryLevelPresetDto = {
  total?: PromotionSummaryTotalPresetDto | null;
  subtotal?: PromotionSummarySubtotalPresetDto | null;
  shipping?: PromotionSummaryShippingPresetDto | null;
  taxesAndDuties?: PromotionSummaryTaxesAndDutiesPresetDto | null;
  discount?: PromotionSummaryDiscountPresetDto | null;
  disclaimer?: PromotionSummaryDisclaimerPresetDto | null;
};
export type PromotionSummaryLevelPresetDtoRead = {
  total?: PromotionSummaryTotalPresetDtoRead | null;
  subtotal?: PromotionSummarySubtotalPresetDtoRead | null;
  shipping?: PromotionSummaryShippingPresetDtoRead | null;
  taxesAndDuties?: PromotionSummaryTaxesAndDutiesPresetDtoRead | null;
  discount?: PromotionSummaryDiscountPresetDtoRead | null;
  disclaimer?: PromotionSummaryDisclaimerPresetDtoRead | null;
};
export type PromotionSummaryLevelPresetEntryTypeDto =
  | (
      | 'TOTAL'
      | 'SUBTOTAL'
      | 'SHIPPING'
      | 'TAXES_AND_DUTIES'
      | 'DISCOUNT'
      | 'DISCLAIMER'
    )
  | null;
export type PromotionSummaryPriceBreakdownBehaviorDto =
  | ('ALLOW_COLLAPSING' | 'ALWAYS_OPEN')
  | null;
export type PromotionSummaryGeneralPresetDto = {
  size?: SizeComponentDto | null;
  priceBreakdown?: PromotionSummaryPriceBreakdownBehaviorDto | null;
  expandButton?: IconComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  background?: BackgroundComponentDto | null;
  textStyle?: MessageStyleComponentDto | null;
};
export type PromotionSummaryPresetDto = {
  isHidden?: boolean | null;
  options?: PromotionSummaryLevelPresetDto | null;
  currentEntries?: PromotionSummaryLevelPresetEntryTypeDto[] | null;
  availableEntries?: PromotionSummaryLevelPresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: PromotionSummaryGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type PromotionSummaryPresetDtoRead = {
  isHidden?: boolean | null;
  options?: PromotionSummaryLevelPresetDtoRead | null;
  currentEntries?: PromotionSummaryLevelPresetEntryTypeDto[] | null;
  availableEntries?: PromotionSummaryLevelPresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: PromotionSummaryGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type OfferRulePopupOfferRulePresetDto = {
  isHidden?: boolean | null;
  headlineText?: MessageStyleComponentDto | null;
  ruleText?: MessageStyleComponentDto | null;
};
export type OfferRulePopupOfferRulePresetDtoRead = {
  isHidden?: boolean | null;
  headlineText?: MessageStyleComponentDto | null;
  ruleText?: MessageStyleComponentDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type OfferRulePopupCallToActionButtonPresetDto = {
  size?: SizeComponentDto | null;
  position?: PositionComponentDto | null;
  label?: MessageDto | null;
  buttonStyle?: CallToActionButtonStyleDto | null;
};
export type OfferRulePopupLevelPresetDto = {
  offerRule?: OfferRulePopupOfferRulePresetDto | null;
  callToActionButton?: OfferRulePopupCallToActionButtonPresetDto | null;
};
export type OfferRulePopupLevelPresetDtoRead = {
  offerRule?: OfferRulePopupOfferRulePresetDtoRead | null;
  callToActionButton?: OfferRulePopupCallToActionButtonPresetDto | null;
};
export type OfferRulePopupLevelPresetEntryTypeDto =
  | ('OFFER_RULE' | 'CALL_TO_ACTION_BUTTON')
  | null;
export type OptionDtoBackgroundComponentDto = {
  enabled?: boolean;
  value?: BackgroundComponentDto | null;
};
export type OverlayComponentDto = {
  opening?: AnimationDto | null;
  closing?: AnimationDto | null;
  background?: OptionDtoBackgroundComponentDto | null;
  closeOnOutsideClick?: boolean | null;
};
export type OfferRulePopupGeneralShopSettingsDto = {
  position?: PositionComponentDto | null;
  offset?: OptionDtoOffsetComponentDto | null;
  overlay?: OverlayComponentDto | null;
  closeButton?: IconComponentDto | null;
};
export type OfferRulePopupGeneralPresetDto = {
  background?: BackgroundComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  shadow?: OptionDtoShadowComponentDto | null;
  customCss?: string | null;
  shopSettings?: OfferRulePopupGeneralShopSettingsDto | null;
};
export type OfferRulePopupPresetDto = {
  isHidden?: boolean | null;
  options?: OfferRulePopupLevelPresetDto | null;
  currentEntries?: OfferRulePopupLevelPresetEntryTypeDto[] | null;
  availableEntries?: OfferRulePopupLevelPresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: OfferRulePopupGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type OfferRulePopupPresetDtoRead = {
  isHidden?: boolean | null;
  options?: OfferRulePopupLevelPresetDtoRead | null;
  currentEntries?: OfferRulePopupLevelPresetEntryTypeDto[] | null;
  availableEntries?: OfferRulePopupLevelPresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: OfferRulePopupGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type HeaderTextPricingModuleLevelDto = object;
export type HeaderTextPricingModuleEntryTypeDto = string | null;
export type HeaderTextPricingModuleGeneralDto = {
  text?: MessageDto | null;
};
export type HeaderTextPricingModuleDto = {
  isHidden?: boolean | null;
  options?: HeaderTextPricingModuleLevelDto | null;
  currentEntries?: HeaderTextPricingModuleEntryTypeDto[] | null;
  availableEntries?: HeaderTextPricingModuleEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: HeaderTextPricingModuleGeneralDto | null;
};
export type HeaderTextPricingModuleDtoRead = {
  isHidden?: boolean | null;
  options?: HeaderTextPricingModuleLevelDto | null;
  currentEntries?: HeaderTextPricingModuleEntryTypeDto[] | null;
  availableEntries?: HeaderTextPricingModuleEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeDragged?: boolean;
  general?: HeaderTextPricingModuleGeneralDto | null;
  canBeRemoved?: boolean;
};
export type PricePricingBlockDto = {
  isHidden?: boolean | null;
  text?: MessageDto | null;
};
export type PricePricingBlockDtoRead = {
  isHidden?: boolean | null;
  text?: MessageDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type BadgePricingBlockDto = {
  isHidden?: boolean | null;
  text?: MessageDto | null;
  size?: SizeComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  background?: BackgroundComponentDto | null;
};
export type BadgePricingBlockDtoRead = {
  isHidden?: boolean | null;
  text?: MessageDto | null;
  size?: SizeComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  background?: BackgroundComponentDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type BodyRowModuleLevelDto = {
  price?: PricePricingBlockDto | null;
  badge?: BadgePricingBlockDto | null;
};
export type BodyRowModuleLevelDtoRead = {
  price?: PricePricingBlockDtoRead | null;
  badge?: BadgePricingBlockDtoRead | null;
};
export type BodyRowPricingModuleEntryTypeDto = ('PRICE' | 'BADGE') | null;
export type BodyRowPricingModuleGeneralDto = object;
export type BodyRowPricingModuleDto = {
  isHidden?: boolean | null;
  options?: BodyRowModuleLevelDto | null;
  currentEntries?: BodyRowPricingModuleEntryTypeDto[] | null;
  availableEntries?: BodyRowPricingModuleEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: BodyRowPricingModuleGeneralDto | null;
};
export type BodyRowPricingModuleDtoRead = {
  isHidden?: boolean | null;
  options?: BodyRowModuleLevelDtoRead | null;
  currentEntries?: BodyRowPricingModuleEntryTypeDto[] | null;
  availableEntries?: BodyRowPricingModuleEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeDragged?: boolean;
  general?: BodyRowPricingModuleGeneralDto | null;
  canBeRemoved?: boolean;
};
export type FooterTextPricingModuleLevelDto = object;
export type FooterTextPricingModuleEntryTypeDto = string | null;
export type FooterTextPricingModuleGeneralDto = {
  text?: MessageDto | null;
};
export type FooterTextPricingModuleDto = {
  isHidden?: boolean | null;
  options?: FooterTextPricingModuleLevelDto | null;
  currentEntries?: FooterTextPricingModuleEntryTypeDto[] | null;
  availableEntries?: FooterTextPricingModuleEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: FooterTextPricingModuleGeneralDto | null;
};
export type FooterTextPricingModuleDtoRead = {
  isHidden?: boolean | null;
  options?: FooterTextPricingModuleLevelDto | null;
  currentEntries?: FooterTextPricingModuleEntryTypeDto[] | null;
  availableEntries?: FooterTextPricingModuleEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeDragged?: boolean;
  general?: FooterTextPricingModuleGeneralDto | null;
  canBeRemoved?: boolean;
};
export type PriceStateLevelDto = {
  headerText?: HeaderTextPricingModuleDto | null;
  bodyRow1?: BodyRowPricingModuleDto | null;
  bodyRow2?: BodyRowPricingModuleDto | null;
  bodyRow3?: BodyRowPricingModuleDto | null;
  footerText?: FooterTextPricingModuleDto | null;
};
export type PriceStateLevelDtoRead = {
  headerText?: HeaderTextPricingModuleDtoRead | null;
  bodyRow1?: BodyRowPricingModuleDtoRead | null;
  bodyRow2?: BodyRowPricingModuleDtoRead | null;
  bodyRow3?: BodyRowPricingModuleDtoRead | null;
  footerText?: FooterTextPricingModuleDtoRead | null;
};
export type PriceStateLevelEntryTypeDto =
  | ('HEADER_TEXT' | 'BODY_ROW_1' | 'BODY_ROW_2' | 'BODY_ROW_3' | 'FOOTER_TEXT')
  | null;
export type DirectionalSpacingComponentDto = {
  horizontal?: SpacingTypeDto | null;
  vertical?: SpacingTypeDto | null;
};
export type PriceShopSettingsDto = {
  useSoldOutLabel?: boolean | null;
  useFromLabel?: boolean | null;
};
export type PriceStateGeneralDto = {
  spacing?: DirectionalSpacingComponentDto | null;
  customCss?: string | null;
  shopSettings?: PriceShopSettingsDto | null;
};
export type PriceStateDto = {
  isHidden?: boolean | null;
  options?: PriceStateLevelDto | null;
  currentEntries?: PriceStateLevelEntryTypeDto[] | null;
  availableEntries?: PriceStateLevelEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  key?: string | null;
  general?: PriceStateGeneralDto | null;
};
export type PriceStateDtoRead = {
  isHidden?: boolean | null;
  options?: PriceStateLevelDtoRead | null;
  currentEntries?: PriceStateLevelEntryTypeDto[] | null;
  availableEntries?: PriceStateLevelEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  key?: string | null;
  general?: PriceStateGeneralDto | null;
};
export type PricingStatesDto = {
  discountedPrice?: PriceStateDto | null;
  notDiscountedPrice?: PriceStateDto | null;
};
export type PricingStatesDtoRead = {
  discountedPrice?: PriceStateDtoRead | null;
  notDiscountedPrice?: PriceStateDtoRead | null;
};
export type PriceStatesEntryTypeDto =
  | ('DISCOUNTED_PRICE' | 'NOT_DISCOUNTED_PRICE')
  | null;
export type PricingGeneralPresetDto = object;
export type ProductPagePricePresetDto = {
  isHidden?: boolean | null;
  options?: PricingStatesDto | null;
  currentEntries?: PriceStatesEntryTypeDto[] | null;
  availableEntries?: PriceStatesEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: PricingGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type ProductPagePricePresetDtoRead = {
  isHidden?: boolean | null;
  options?: PricingStatesDtoRead | null;
  currentEntries?: PriceStatesEntryTypeDto[] | null;
  availableEntries?: PriceStatesEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: PricingGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type CollectionPagePricePresetDto = {
  isHidden?: boolean | null;
  options?: PricingStatesDto | null;
  currentEntries?: PriceStatesEntryTypeDto[] | null;
  availableEntries?: PriceStatesEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: PricingGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type CollectionPagePricePresetDtoRead = {
  isHidden?: boolean | null;
  options?: PricingStatesDtoRead | null;
  currentEntries?: PriceStatesEntryTypeDto[] | null;
  availableEntries?: PriceStatesEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: PricingGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type DrawerCartTotalPricePresetDto = {
  isHidden?: boolean | null;
  options?: PricingStatesDto | null;
  currentEntries?: PriceStatesEntryTypeDto[] | null;
  availableEntries?: PriceStatesEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: PricingGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type DrawerCartTotalPricePresetDtoRead = {
  isHidden?: boolean | null;
  options?: PricingStatesDtoRead | null;
  currentEntries?: PriceStatesEntryTypeDto[] | null;
  availableEntries?: PriceStatesEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: PricingGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type DrawerCartLinePricePresetDto = {
  isHidden?: boolean | null;
  options?: PricingStatesDto | null;
  currentEntries?: PriceStatesEntryTypeDto[] | null;
  availableEntries?: PriceStatesEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: PricingGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type DrawerCartLinePricePresetDtoRead = {
  isHidden?: boolean | null;
  options?: PricingStatesDtoRead | null;
  currentEntries?: PriceStatesEntryTypeDto[] | null;
  availableEntries?: PriceStatesEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: PricingGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type DrawerCartProductPricePresetDto = {
  isHidden?: boolean | null;
  options?: PricingStatesDto | null;
  currentEntries?: PriceStatesEntryTypeDto[] | null;
  availableEntries?: PriceStatesEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: PricingGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type DrawerCartProductPricePresetDtoRead = {
  isHidden?: boolean | null;
  options?: PricingStatesDtoRead | null;
  currentEntries?: PriceStatesEntryTypeDto[] | null;
  availableEntries?: PriceStatesEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: PricingGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type DrawerCartBreakdownPricePresetDto = {
  isHidden?: boolean | null;
  options?: PricingStatesDto | null;
  currentEntries?: PriceStatesEntryTypeDto[] | null;
  availableEntries?: PriceStatesEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: PricingGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type DrawerCartBreakdownPricePresetDtoRead = {
  isHidden?: boolean | null;
  options?: PricingStatesDtoRead | null;
  currentEntries?: PriceStatesEntryTypeDto[] | null;
  availableEntries?: PriceStatesEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: PricingGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type CartTotalPricePresetDto = {
  isHidden?: boolean | null;
  options?: PricingStatesDto | null;
  currentEntries?: PriceStatesEntryTypeDto[] | null;
  availableEntries?: PriceStatesEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: PricingGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type CartTotalPricePresetDtoRead = {
  isHidden?: boolean | null;
  options?: PricingStatesDtoRead | null;
  currentEntries?: PriceStatesEntryTypeDto[] | null;
  availableEntries?: PriceStatesEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: PricingGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type CartLinePricePresetDto = {
  isHidden?: boolean | null;
  options?: PricingStatesDto | null;
  currentEntries?: PriceStatesEntryTypeDto[] | null;
  availableEntries?: PriceStatesEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: PricingGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type CartLinePricePresetDtoRead = {
  isHidden?: boolean | null;
  options?: PricingStatesDtoRead | null;
  currentEntries?: PriceStatesEntryTypeDto[] | null;
  availableEntries?: PriceStatesEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: PricingGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type CartProductPricePresetDto = {
  isHidden?: boolean | null;
  options?: PricingStatesDto | null;
  currentEntries?: PriceStatesEntryTypeDto[] | null;
  availableEntries?: PriceStatesEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: PricingGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type CartProductPricePresetDtoRead = {
  isHidden?: boolean | null;
  options?: PricingStatesDtoRead | null;
  currentEntries?: PriceStatesEntryTypeDto[] | null;
  availableEntries?: PriceStatesEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: PricingGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type CartBreakdownPricePresetDto = {
  isHidden?: boolean | null;
  options?: PricingStatesDto | null;
  currentEntries?: PriceStatesEntryTypeDto[] | null;
  availableEntries?: PriceStatesEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: PricingGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type CartBreakdownPricePresetDtoRead = {
  isHidden?: boolean | null;
  options?: PricingStatesDtoRead | null;
  currentEntries?: PriceStatesEntryTypeDto[] | null;
  availableEntries?: PriceStatesEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: PricingGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type CartTextLevelPresetDto = object;
export type CartTextPresetEntryTypeDto = string | null;
export type CartTextGeneralPresetDto = {
  textStyle?: MessageStyleComponentDto | null;
  size?: SizeComponentDto | null;
  background?: BackgroundComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
};
export type CartTextPresetDto = {
  isHidden?: boolean | null;
  options?: CartTextLevelPresetDto | null;
  currentEntries?: CartTextPresetEntryTypeDto[] | null;
  availableEntries?: CartTextPresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: CartTextGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type CartTextPresetDtoRead = {
  isHidden?: boolean | null;
  options?: CartTextLevelPresetDto | null;
  currentEntries?: CartTextPresetEntryTypeDto[] | null;
  availableEntries?: CartTextPresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: CartTextGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type NinjaCartEmptyStateHeaderPresetDto = object;
export type NinjaCartEmptyStateImagePresetDto = {
  image?: ImageComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
};
export type NinjaCartEmptyStateTextPresetDto = {
  headline?: MessageDto | null;
  paragraph?: MessageDto | null;
};
export type NinjaCartButtonStyleSetKeyDto = ('PRIMARY' | 'SECONDARY') | null;
export type NinjaCartCallToActionButtonActionTypeDto =
  | ('CLOSE' | 'OPEN_PAGE' | 'GO_TO_CHECKOUT' | 'GO_TO_CART' | 'EVALUATE_JS')
  | null;
export type LinkComponentDto = {
  link?: string | null;
  openInNewTab?: boolean | null;
};
export type NinjaCartCallToActionButtonActionDto = {
  type?: NinjaCartCallToActionButtonActionTypeDto | null;
  selectedPage?: LinkComponentDto | null;
  disableBeforeTermsAndConditionsChecked?: boolean | null;
  evaluateJs?: string | null;
};
export type NinjaCartEmptyStateCallToActionButtonPresetDto = {
  buttonSize?: SizeTypeDto | null;
  buttonStyleSet?: NinjaCartButtonStyleSetKeyDto | null;
  action?: NinjaCartCallToActionButtonActionDto | null;
  label?: MessageSetupDto | null;
};
export type NinjaCartEmptyStateLevelPresetDto = {
  header?: NinjaCartEmptyStateHeaderPresetDto | null;
  image?: NinjaCartEmptyStateImagePresetDto | null;
  text?: NinjaCartEmptyStateTextPresetDto | null;
  callToActionButton?: NinjaCartEmptyStateCallToActionButtonPresetDto | null;
};
export type NinjaCartEmptyStatePresetEntryTypeDto =
  | ('HEADER' | 'IMAGE' | 'TEXT' | 'CALL_TO_ACTION_BUTTON')
  | null;
export type NinjaCartEmptyStateGeneralPresetDto = {
  background?: BackgroundComponentDto | null;
  customCss?: string | null;
};
export type NinjaCartEmptyStatePresetDto = {
  isHidden?: boolean | null;
  options?: NinjaCartEmptyStateLevelPresetDto | null;
  currentEntries?: NinjaCartEmptyStatePresetEntryTypeDto[] | null;
  availableEntries?: NinjaCartEmptyStatePresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: NinjaCartEmptyStateGeneralPresetDto | null;
};
export type NinjaCartEmptyStatePresetDtoRead = {
  isHidden?: boolean | null;
  options?: NinjaCartEmptyStateLevelPresetDto | null;
  currentEntries?: NinjaCartEmptyStatePresetEntryTypeDto[] | null;
  availableEntries?: NinjaCartEmptyStatePresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: NinjaCartEmptyStateGeneralPresetDto | null;
};
export type NinjaCartDefaultStateHeaderHeadingPresetDto = {
  text?: MessageDto | null;
};
export type NinjaCartHeaderItemCountStyleDto = ('STYLE_1' | 'STYLE_2') | null;
export type NinjaCartDefaultStateHeaderItemCountPresetDto = {
  style?: NinjaCartHeaderItemCountStyleDto | null;
  textStyle?: MessageStyleComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  background?: BackgroundComponentDto | null;
};
export type NinjaCartDefaultStateHeaderLevelPresetDto = {
  heading?: NinjaCartDefaultStateHeaderHeadingPresetDto | null;
  itemCount?: NinjaCartDefaultStateHeaderItemCountPresetDto | null;
};
export type NinjaCartDefaultStateHeaderPresetEntryTypeDto =
  | ('HEADING' | 'ITEM_COUNT')
  | null;
export type NinjaCartHeaderLayoutDto = ('LAYOUT_1' | 'LAYOUT_2') | null;
export type NinjaCartDefaultStateHeaderGeneralPresetDto = {
  layout?: NinjaCartHeaderLayoutDto | null;
  cartIcon?: OptionDtoIconComponentDto | null;
  closeButtonIcon?: IconComponentDto | null;
  background?: BackgroundComponentDto | null;
};
export type NinjaCartDefaultStateHeaderPresetDto = {
  isHidden?: boolean | null;
  options?: NinjaCartDefaultStateHeaderLevelPresetDto | null;
  currentEntries?: NinjaCartDefaultStateHeaderPresetEntryTypeDto[] | null;
  availableEntries?: NinjaCartDefaultStateHeaderPresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: NinjaCartDefaultStateHeaderGeneralPresetDto | null;
};
export type NinjaCartDefaultStateHeaderPresetDtoRead = {
  isHidden?: boolean | null;
  options?: NinjaCartDefaultStateHeaderLevelPresetDto | null;
  currentEntries?: NinjaCartDefaultStateHeaderPresetEntryTypeDto[] | null;
  availableEntries?: NinjaCartDefaultStateHeaderPresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: NinjaCartDefaultStateHeaderGeneralPresetDto | null;
};
export type NinjaCartCartItemCartTextPresetDto = {
  textStyle?: MessageStyleComponentDto | null;
  size?: SizeComponentDto | null;
  background?: BackgroundComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
};
export type NinjaCartCartItemProductBadgeShopSettingsDto = {
  position?: PositionDto | null;
  stacking?: StackingComponentDto;
  offset?: OffsetComponentDto;
};
export type NinjaCartCartItemProductBadgePresetDto = {
  textStyle?: MessageStyleComponentDto | null;
  style?: SizeComponentDto | null;
  background?: BackgroundComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  stitch?: OptionDtoStitchComponentDto | null;
  shopSettings?: NinjaCartCartItemProductBadgeShopSettingsDto | null;
};
export type NinjaCartCartItemLevelPresetDto = {
  cartText?: NinjaCartCartItemCartTextPresetDto | null;
  productBadge?: NinjaCartCartItemProductBadgePresetDto | null;
};
export type NinjaCartCartItemPresetEntryTypeDto =
  | ('PRODUCT_BADGE' | 'CART_TEXT')
  | null;
export type NinjaCartCartItemQuantitySelectorTypeDto =
  | ('TYPE_1' | 'TYPE_2' | 'TYPE_3')
  | null;
export type NinjaCartCartItemGeneralPresetDto = {
  imageSize?: ImageSizeDto | null;
  deleteButtonIcon?: OptionDtoIconComponentDto | null;
  quantitySelectorType?: NinjaCartCartItemQuantitySelectorTypeDto | null;
};
export type NinjaCartCartItemPresetDto = {
  isHidden?: boolean | null;
  options?: NinjaCartCartItemLevelPresetDto | null;
  currentEntries?: NinjaCartCartItemPresetEntryTypeDto[] | null;
  availableEntries?: NinjaCartCartItemPresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: NinjaCartCartItemGeneralPresetDto | null;
};
export type NinjaCartCartItemPresetDtoRead = {
  isHidden?: boolean | null;
  options?: NinjaCartCartItemLevelPresetDto | null;
  currentEntries?: NinjaCartCartItemPresetEntryTypeDto[] | null;
  availableEntries?: NinjaCartCartItemPresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: NinjaCartCartItemGeneralPresetDto | null;
};
export type NinjaCartDefaultStateContentLevelPresetDto = {
  cartItem?: NinjaCartCartItemPresetDto | null;
};
export type NinjaCartDefaultStateContentLevelPresetDtoRead = {
  cartItem?: NinjaCartCartItemPresetDtoRead | null;
};
export type NinjaCartDefaultStateContentPresetEntryTypeDto = 'CART_ITEM' | null;
export type NinjaCartDefaultStateContentGeneralPresetDto = {
  background?: BackgroundComponentDto | null;
};
export type NinjaCartDefaultStateContentPresetDto = {
  isHidden?: boolean | null;
  options?: NinjaCartDefaultStateContentLevelPresetDto | null;
  currentEntries?: NinjaCartDefaultStateContentPresetEntryTypeDto[] | null;
  availableEntries?: NinjaCartDefaultStateContentPresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: NinjaCartDefaultStateContentGeneralPresetDto | null;
};
export type NinjaCartDefaultStateContentPresetDtoRead = {
  isHidden?: boolean | null;
  options?: NinjaCartDefaultStateContentLevelPresetDtoRead | null;
  currentEntries?: NinjaCartDefaultStateContentPresetEntryTypeDto[] | null;
  availableEntries?: NinjaCartDefaultStateContentPresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: NinjaCartDefaultStateContentGeneralPresetDto | null;
};
export type NinjaCartDefaultStateFooterCtaButton1PresetDto = {
  action?: NinjaCartCallToActionButtonActionDto | null;
  label?: MessageSetupDto | null;
  buttonStyleSet?: NinjaCartButtonStyleSetKeyDto | null;
  prefix?: OptionDtoIconComponentDto | null;
  suffixEnabled?: boolean | null;
};
export type NinjaCartDefaultStateFooterCtaButton2PresetDto = {
  action?: NinjaCartCallToActionButtonActionDto | null;
  label?: MessageSetupDto | null;
  buttonStyleSet?: NinjaCartButtonStyleSetKeyDto | null;
};
export type NinjaCartDefaultStateFooterCtaButtonsLevelPresetDto = {
  ctaButton1?: NinjaCartDefaultStateFooterCtaButton1PresetDto | null;
  ctaButton2?: NinjaCartDefaultStateFooterCtaButton2PresetDto | null;
};
export type NinjaCartDefaultStateFooterCtaButtonsPresetEntryTypeDto =
  | ('CTA_BUTTON_1' | 'CTA_BUTTON_2')
  | null;
export type NinjaCartDefaultStateFooterCtaButtonsGeneralPresetDto = {
  buttonSize?: SizeComponentDto | null;
  stacking?: StackingComponentDto | null;
};
export type NinjaCartDefaultStateFooterCtaButtonsPresetDto = {
  isHidden?: boolean | null;
  options?: NinjaCartDefaultStateFooterCtaButtonsLevelPresetDto | null;
  currentEntries?:
    | NinjaCartDefaultStateFooterCtaButtonsPresetEntryTypeDto[]
    | null;
  availableEntries?:
    | NinjaCartDefaultStateFooterCtaButtonsPresetEntryTypeDto[]
    | null;
  supportsModules?: boolean | null;
  general?: NinjaCartDefaultStateFooterCtaButtonsGeneralPresetDto | null;
};
export type NinjaCartDefaultStateFooterCtaButtonsPresetDtoRead = {
  isHidden?: boolean | null;
  options?: NinjaCartDefaultStateFooterCtaButtonsLevelPresetDto | null;
  currentEntries?:
    | NinjaCartDefaultStateFooterCtaButtonsPresetEntryTypeDto[]
    | null;
  availableEntries?:
    | NinjaCartDefaultStateFooterCtaButtonsPresetEntryTypeDto[]
    | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: NinjaCartDefaultStateFooterCtaButtonsGeneralPresetDto | null;
};
export type PaymentOptionsType =
  | ('VISA' | 'MASTERCARD' | 'AMEX' | 'PAYPAL')
  | null;
export type NinjaCartDefaultStateFooterPaymentOptionsPresetDto = {
  isHidden?: boolean | null;
  paymentOptionsTypes?: PaymentOptionsType[];
  size?: SizeTypeDto | null;
  cornerRadius?: CornerRadiusComponentDto;
  background?: BackgroundComponentDto;
};
export type NinjaCartDefaultStateFooterPaymentOptionsPresetDtoRead = {
  isHidden?: boolean | null;
  paymentOptionsTypes?: PaymentOptionsType[];
  size?: SizeTypeDto | null;
  cornerRadius?: CornerRadiusComponentDto;
  background?: BackgroundComponentDto;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type NinjaCartDefaultStateFooterTermsAndConditionsPresetDto = {
  isHidden?: boolean | null;
  text?: MessageDto;
  icon?: IconComponentDto;
};
export type NinjaCartDefaultStateFooterTermsAndConditionsPresetDtoRead = {
  isHidden?: boolean | null;
  text?: MessageDto;
  icon?: IconComponentDto;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type NinjaCartContinueShoppingActionButtonType =
  | ('CLOSE_NINJA_CART' | 'OPEN_SELECTED_PAGE')
  | null;
export type NinjaCartDefaultStateFooterContinueShoppingActionDto = {
  actionType?: NinjaCartContinueShoppingActionButtonType | null;
  pageLink?: string | null;
  disablePageBeforeTermsAndConditions?: boolean | null;
};
export type NinjaCartDefaultStateFooterContinueShoppingButtonPresetDto = {
  isHidden?: boolean | null;
  action?: NinjaCartDefaultStateFooterContinueShoppingActionDto;
  label?: MessageDto;
};
export type NinjaCartDefaultStateFooterContinueShoppingButtonPresetDtoRead = {
  isHidden?: boolean | null;
  action?: NinjaCartDefaultStateFooterContinueShoppingActionDto;
  label?: MessageDto;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type NinjaCartDefaultStateFooterLevelPresetDto = {
  promotionSummary?: PromotionSummaryPresetDto | null;
  ctaButtons?: NinjaCartDefaultStateFooterCtaButtonsPresetDto | null;
  paymentOptions?: NinjaCartDefaultStateFooterPaymentOptionsPresetDto | null;
  termsAndConditions?: NinjaCartDefaultStateFooterTermsAndConditionsPresetDto | null;
  continueShoppingButton?: NinjaCartDefaultStateFooterContinueShoppingButtonPresetDto | null;
};
export type NinjaCartDefaultStateFooterLevelPresetDtoRead = {
  promotionSummary?: PromotionSummaryPresetDtoRead | null;
  ctaButtons?: NinjaCartDefaultStateFooterCtaButtonsPresetDtoRead | null;
  paymentOptions?: NinjaCartDefaultStateFooterPaymentOptionsPresetDtoRead | null;
  termsAndConditions?: NinjaCartDefaultStateFooterTermsAndConditionsPresetDtoRead | null;
  continueShoppingButton?: NinjaCartDefaultStateFooterContinueShoppingButtonPresetDtoRead | null;
};
export type NinjaCartDefaultStateFooterPresetEntryTypeDto =
  | (
      | 'PROMOTION_SUMMARY'
      | 'CTA_BUTTONS'
      | 'PAYMENT_OPTIONS'
      | 'TERMS_AND_CONDITIONS'
      | 'CONTINUE_SHOPPING_BUTTON'
    )
  | null;
export type NinjaCartDefaultStateFooterGeneralPresetDto = {
  isSticky?: boolean | null;
  background?: BackgroundComponentDto | null;
};
export type NinjaCartDefaultStateFooterPresetDto = {
  isHidden?: boolean | null;
  options?: NinjaCartDefaultStateFooterLevelPresetDto | null;
  currentEntries?: NinjaCartDefaultStateFooterPresetEntryTypeDto[] | null;
  availableEntries?: NinjaCartDefaultStateFooterPresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: NinjaCartDefaultStateFooterGeneralPresetDto | null;
};
export type NinjaCartDefaultStateFooterPresetDtoRead = {
  isHidden?: boolean | null;
  options?: NinjaCartDefaultStateFooterLevelPresetDtoRead | null;
  currentEntries?: NinjaCartDefaultStateFooterPresetEntryTypeDto[] | null;
  availableEntries?: NinjaCartDefaultStateFooterPresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: NinjaCartDefaultStateFooterGeneralPresetDto | null;
};
export type NinjaCartDefaultStateLevelPresetDto = {
  header?: NinjaCartDefaultStateHeaderPresetDto | null;
  content?: NinjaCartDefaultStateContentPresetDto | null;
  footer?: NinjaCartDefaultStateFooterPresetDto | null;
};
export type NinjaCartDefaultStateLevelPresetDtoRead = {
  header?: NinjaCartDefaultStateHeaderPresetDtoRead | null;
  content?: NinjaCartDefaultStateContentPresetDtoRead | null;
  footer?: NinjaCartDefaultStateFooterPresetDtoRead | null;
};
export type NinjaCartDefaultStatePresetEntryTypeDto =
  | ('HEADER' | 'CONTENT' | 'FOOTER')
  | null;
export type PaddingsDto =
  | ('EXTRA_SMALL' | 'SMALL' | 'MEDIUM' | 'LARGE' | 'EXTRA_LARGE')
  | null;
export type CardSizeComponentDto = {
  width?: SizeTypeDto | null;
  spacing?: SpacingTypeDto | null;
  paddings?: PaddingsDto | null;
};
export type NinjaCartButtonsStyleDto = {
  selectedStyleSet?: NinjaCartButtonStyleSetKeyDto | null;
  primaryStyle?: CallToActionButtonStyleDto | null;
  secondaryStyle?: CallToActionButtonStyleDto | null;
};
export type InputCornersStyleDto =
  | (
      | 'SQUARE'
      | 'ROUND_EXTRA_SMALL'
      | 'ROUND_SMALL'
      | 'ROUND_MEDIUM'
      | 'ROUND_LARGE'
      | 'ROUND_EXTRA_LARGE'
      | 'CIRCLE'
    )
  | null;
export type InputControlsComponentDto = {
  cornersStyle?: InputCornersStyleDto | null;
  borderColor?: string | null;
  textColor?: string | null;
  placeholderColor?: string | null;
};
export type NinjaCartDefaultStateGeneralPresetDto = {
  size?: CardSizeComponentDto | null;
  overlay?: OverlayComponentDto | null;
  offset?: OptionDtoOffsetComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  border?: OptionDtoBorderComponentDto | null;
  buttonsStyle?: NinjaCartButtonsStyleDto | null;
  inputControls?: InputControlsComponentDto | null;
  customCss?: string | null;
};
export type NinjaCartDefaultStatePresetDto = {
  isHidden?: boolean | null;
  options?: NinjaCartDefaultStateLevelPresetDto | null;
  currentEntries?: NinjaCartDefaultStatePresetEntryTypeDto[] | null;
  availableEntries?: NinjaCartDefaultStatePresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: NinjaCartDefaultStateGeneralPresetDto | null;
};
export type NinjaCartDefaultStatePresetDtoRead = {
  isHidden?: boolean | null;
  options?: NinjaCartDefaultStateLevelPresetDtoRead | null;
  currentEntries?: NinjaCartDefaultStatePresetEntryTypeDto[] | null;
  availableEntries?: NinjaCartDefaultStatePresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: NinjaCartDefaultStateGeneralPresetDto | null;
};
export type NinjaCartStatesPresetDto = {
  emptyState?: NinjaCartEmptyStatePresetDto | null;
  defaultState?: NinjaCartDefaultStatePresetDto | null;
};
export type NinjaCartStatesPresetDtoRead = {
  emptyState?: NinjaCartEmptyStatePresetDtoRead | null;
  defaultState?: NinjaCartDefaultStatePresetDtoRead | null;
};
export type NinjaCartStatesPresetEntryTypeDto =
  | ('EMPTY_STATE' | 'DEFAULT_STATE')
  | null;
export type NinjaCartGeneralPresetDto = object;
export type NinjaCartPresetDto = {
  isHidden?: boolean | null;
  options?: NinjaCartStatesPresetDto | null;
  currentEntries?: NinjaCartStatesPresetEntryTypeDto[] | null;
  availableEntries?: NinjaCartStatesPresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: NinjaCartGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type NinjaCartPresetDtoRead = {
  isHidden?: boolean | null;
  options?: NinjaCartStatesPresetDtoRead | null;
  currentEntries?: NinjaCartStatesPresetEntryTypeDto[] | null;
  availableEntries?: NinjaCartStatesPresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: NinjaCartGeneralPresetDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type WidgetTypeDto =
  | (
      | 'ANNOUNCEMENT_BAR'
      | 'NOTIFICATION'
      | 'PROMOTIONAL_BADGE'
      | 'POP_UP'
      | 'PRODUCT_BANNER'
      | 'DRAWER_CART_PROMOTION_SUMMARY'
      | 'CART_PROMOTION_SUMMARY'
      | 'DRAWER_CART_PROMOTION_CODE_FIELD'
      | 'CART_PROMOTION_CODE_FIELD'
      | 'CHECKOUT_MESSAGE'
      | 'VOLUME_DISCOUNT'
      | 'OFFER_RULE_POPUP'
      | 'PRODUCT_PAGE_PRICE'
      | 'COLLECTION_PAGE_PRICE'
      | 'DRAWER_CART_PRODUCT_PRICE'
      | 'DRAWER_CART_LINE_PRICE'
      | 'DRAWER_CART_TOTAL_PRICE'
      | 'DRAWER_CART_BREAKDOWN_PRICE'
      | 'CART_PRODUCT_PRICE'
      | 'CART_LINE_PRICE'
      | 'CART_TOTAL_PRICE'
      | 'CART_BREAKDOWN_PRICE'
      | 'CART_PAGE_CART_TEXT'
      | 'DRAWER_CART_PAGE_CART_TEXT'
      | 'DISCOUNT_LABEL'
      | 'NINJA_CART'
      | 'NINJA_CART_PROMOTION_SUMMARY'
      | 'NINJA_CART_CART_TEXT'
      | 'NINJA_CART_PRODUCT_BADGE'
    )
  | null;
export type PageSetupEntryDto = {
  page?: PageTypeDto | null;
  currentEntries?: WidgetTypeDto[];
  availableEntries?: WidgetTypeDto[] | null;
};
export type PresetDto = {
  id?: string;
  isShopLevel?: boolean;
  name?: string;
  productBanner?: ProductBannerPresetDto | null;
  promotionalBadge?: PromotionalBadgePresetDto | null;
  announcementBar?: AnnouncementBarPresetDto | null;
  notification?: NotificationPresetDto | null;
  drawerCartPromotionCodeField?: PromotionCodeFieldPresetDto | null;
  cartPromotionCodeField?: PromotionCodeFieldPresetDto | null;
  drawerCartPromotionSummary?: PromotionSummaryPresetDto | null;
  cartPromotionSummary?: PromotionSummaryPresetDto | null;
  offerRulePopup?: OfferRulePopupPresetDto | null;
  productPagePrice?: ProductPagePricePresetDto | null;
  collectionPagePrice?: CollectionPagePricePresetDto | null;
  drawerCartTotalPrice?: DrawerCartTotalPricePresetDto | null;
  drawerCartLinePrice?: DrawerCartLinePricePresetDto | null;
  drawerCartProductPrice?: DrawerCartProductPricePresetDto | null;
  drawerCartBreakdownPrice?: DrawerCartBreakdownPricePresetDto | null;
  cartTotalPrice?: CartTotalPricePresetDto | null;
  cartLinePrice?: CartLinePricePresetDto | null;
  cartProductPrice?: CartProductPricePresetDto | null;
  cartBreakdownPrice?: CartBreakdownPricePresetDto | null;
  cartPageCartText?: CartTextPresetDto | null;
  drawerCartPageCartText?: CartTextPresetDto | null;
  ninjaCart?: NinjaCartPresetDto | null;
  defaultLanguage?: string | null;
  pages?: PageSetupEntryDto[] | null;
};
export type PresetDtoRead = {
  id?: string;
  isShopLevel?: boolean;
  name?: string;
  productBanner?: ProductBannerPresetDtoRead | null;
  promotionalBadge?: PromotionalBadgePresetDtoRead | null;
  announcementBar?: AnnouncementBarPresetDtoRead | null;
  notification?: NotificationPresetDtoRead | null;
  drawerCartPromotionCodeField?: PromotionCodeFieldPresetDtoRead | null;
  cartPromotionCodeField?: PromotionCodeFieldPresetDtoRead | null;
  drawerCartPromotionSummary?: PromotionSummaryPresetDtoRead | null;
  cartPromotionSummary?: PromotionSummaryPresetDtoRead | null;
  offerRulePopup?: OfferRulePopupPresetDtoRead | null;
  productPagePrice?: ProductPagePricePresetDtoRead | null;
  collectionPagePrice?: CollectionPagePricePresetDtoRead | null;
  drawerCartTotalPrice?: DrawerCartTotalPricePresetDtoRead | null;
  drawerCartLinePrice?: DrawerCartLinePricePresetDtoRead | null;
  drawerCartProductPrice?: DrawerCartProductPricePresetDtoRead | null;
  drawerCartBreakdownPrice?: DrawerCartBreakdownPricePresetDtoRead | null;
  cartTotalPrice?: CartTotalPricePresetDtoRead | null;
  cartLinePrice?: CartLinePricePresetDtoRead | null;
  cartProductPrice?: CartProductPricePresetDtoRead | null;
  cartBreakdownPrice?: CartBreakdownPricePresetDtoRead | null;
  cartPageCartText?: CartTextPresetDtoRead | null;
  drawerCartPageCartText?: CartTextPresetDtoRead | null;
  ninjaCart?: NinjaCartPresetDtoRead | null;
  defaultLanguage?: string | null;
  pages?: PageSetupEntryDto[] | null;
};
export type SavePresetRequestDto = {
  productBanner?: ProductBannerPresetDto | null;
  promotionalBadge?: PromotionalBadgePresetDto | null;
  announcementBar?: AnnouncementBarPresetDto | null;
  notification?: NotificationPresetDto | null;
  drawerCartPromotionCodeField?: PromotionCodeFieldPresetDto | null;
  cartPromotionCodeField?: PromotionCodeFieldPresetDto | null;
  drawerCartPromotionSummary?: PromotionSummaryPresetDto | null;
  cartPromotionSummary?: PromotionSummaryPresetDto | null;
  offerRulePopup?: OfferRulePopupPresetDto | null;
  productPagePrice?: ProductPagePricePresetDto | null;
  collectionPagePrice?: CollectionPagePricePresetDto | null;
  drawerCartProductPrice?: DrawerCartProductPricePresetDto | null;
  drawerCartTotalPrice?: DrawerCartTotalPricePresetDto | null;
  drawerCartLinePrice?: DrawerCartLinePricePresetDto | null;
  drawerCartBreakdownPrice?: DrawerCartBreakdownPricePresetDto | null;
  cartProductPrice?: CartProductPricePresetDto | null;
  cartTotalPrice?: CartTotalPricePresetDto | null;
  cartLinePrice?: CartLinePricePresetDto | null;
  cartBreakdownPrice?: CartBreakdownPricePresetDto | null;
  cartPageCartText?: CartTextPresetDto | null;
  drawerCartPageCartText?: CartTextPresetDto | null;
  ninjaCart?: NinjaCartPresetDto | null;
};
export type SavePresetRequestDtoRead = {
  productBanner?: ProductBannerPresetDtoRead | null;
  promotionalBadge?: PromotionalBadgePresetDtoRead | null;
  announcementBar?: AnnouncementBarPresetDtoRead | null;
  notification?: NotificationPresetDtoRead | null;
  drawerCartPromotionCodeField?: PromotionCodeFieldPresetDtoRead | null;
  cartPromotionCodeField?: PromotionCodeFieldPresetDtoRead | null;
  drawerCartPromotionSummary?: PromotionSummaryPresetDtoRead | null;
  cartPromotionSummary?: PromotionSummaryPresetDtoRead | null;
  offerRulePopup?: OfferRulePopupPresetDtoRead | null;
  productPagePrice?: ProductPagePricePresetDtoRead | null;
  collectionPagePrice?: CollectionPagePricePresetDtoRead | null;
  drawerCartProductPrice?: DrawerCartProductPricePresetDtoRead | null;
  drawerCartTotalPrice?: DrawerCartTotalPricePresetDtoRead | null;
  drawerCartLinePrice?: DrawerCartLinePricePresetDtoRead | null;
  drawerCartBreakdownPrice?: DrawerCartBreakdownPricePresetDtoRead | null;
  cartProductPrice?: CartProductPricePresetDtoRead | null;
  cartTotalPrice?: CartTotalPricePresetDtoRead | null;
  cartLinePrice?: CartLinePricePresetDtoRead | null;
  cartBreakdownPrice?: CartBreakdownPricePresetDtoRead | null;
  cartPageCartText?: CartTextPresetDtoRead | null;
  drawerCartPageCartText?: CartTextPresetDtoRead | null;
  ninjaCart?: NinjaCartPresetDtoRead | null;
};
export type RenamePresetRequestDto = {
  name?: string;
};
export type PromotionStatusDto =
  | (
      | 'PUBLISHED'
      | 'SCHEDULED'
      | 'EXPIRED'
      | 'DRAFT'
      | 'IN_PROGRESS_PUBLISHING'
      | 'IN_PROGRESS_UNPUBLISHING'
      | 'IN_PROGRESS_ROLLING_BACK'
      | 'USED'
      | 'ARCHIVED'
      | 'PUBLISHED_EDITED'
    )
  | null;
export type AffectedPromotionByPresetItemDto = {
  name?: string;
  status?: PromotionStatusDto | null;
};
export type AffectedPromotionByPresetDto = {
  totalCount?: number;
  topItems?: AffectedPromotionByPresetItemDto[];
};
export type GetAffectedPromotionsByPresetResponseDto = {
  active?: AffectedPromotionByPresetDto;
  inactive?: AffectedPromotionByPresetDto;
};
export type OrderComponentDto = {
  priority?: number | null;
};
export type ProductBannerImageSystemDefaultsDto = {
  image?: ImageComponentDto;
  cornerRadius?: CornerRadiusComponentDto;
  border?: OptionDtoBorderComponentDto;
};
export type ProductBannerSystemDefaultsDto = {
  stacking?: StackingComponentDto;
  order?: OrderComponentDto;
  size?: SizeComponentDto;
  link?: LinkComponentDto;
  background?: BackgroundComponentDto;
  cornerRadius?: CornerRadiusComponentDto;
  deviceDisplay?: DeviceDisplayDto | null;
  icon?: IconComponentDto;
  image?: ProductBannerImageSystemDefaultsDto;
  textStyle?: MessageStyleComponentDto;
};
export type PromotionalBadgeBadgeSystemDefaultsDto = {
  primaryStyle?: PromotionalBadgeBadgeStyleSetDto;
  secondaryStyle?: PromotionalBadgeBadgeStyleSetDto;
};
export type PromotionalBadgeSystemDefaultsDto = {
  stacking?: StackingComponentDto;
  offset?: OptionDtoOffsetComponentDto;
  order?: OrderComponentDto;
  position?: PositionComponentDto;
  deviceDisplay?: DeviceDisplayDto | null;
  textStyle?: MessageStyleComponentDto;
  badge?: PromotionalBadgeBadgeSystemDefaultsDto;
};
export type AnnouncementBarImageSystemDefaultsDto = {
  image?: ImageComponentDto;
  cornerRadius?: CornerRadiusComponentDto;
  border?: OptionDtoBorderComponentDto;
};
export type AnnouncementBarPrimaryBannerSystemDefaultsDto = {
  background?: BackgroundComponentDto;
  indicatorsColor?: AnnouncementBarIndicatorsColorTypeDto | null;
  chevronsColor?: string | null;
  closeButtonColor?: string | null;
  icon?: IconComponentDto;
  image?: AnnouncementBarImageSystemDefaultsDto;
  textStyle?: MessageStyleComponentDto;
};
export type AnnouncementBarSecondaryBannerSystemDefaultsDto = {
  background?: BackgroundComponentDto;
  icon?: IconComponentDto;
  image?: AnnouncementBarImageSystemDefaultsDto;
  textStyle?: MessageStyleComponentDto;
};
export type AnnouncementBarSystemDefaultsDto = {
  stylePriority?: StylePriorityComponentDto;
  primaryBanner?: AnnouncementBarPrimaryBannerSystemDefaultsDto;
  secondaryBanner?: AnnouncementBarSecondaryBannerSystemDefaultsDto;
  link?: LinkComponentDto;
  deviceDisplay?: DeviceDisplayDto | null;
  pageDisplay?: PageDisplayComponentDto;
};
export type NotificationSystemDefaultsDto = {
  expandedState?: NotificationExpandedStatePresetDto;
  collapsedState?: NotificationCollapsedStatePresetDto;
};
export type NotificationSystemDefaultsDtoRead = {
  expandedState?: NotificationExpandedStatePresetDtoRead;
  collapsedState?: NotificationCollapsedStatePresetDtoRead;
};
export type PromotionCodeFieldSystemDefaultsDto = {
  general?: PromotionCodeFieldGeneralPresetDto;
  codeInput?: PromotionCodeFieldCodeInputPresetDto;
  codeValidation?: PromotionCodeFieldCodeValidationPresetDto;
  callToActionButton?: PromotionCodeFieldCallToActionButtonPresetDto;
};
export type PromotionCodeFieldSystemDefaultsDtoRead = {
  general?: PromotionCodeFieldGeneralPresetDto;
  codeInput?: PromotionCodeFieldCodeInputPresetDtoRead;
  codeValidation?: PromotionCodeFieldCodeValidationPresetDtoRead;
  callToActionButton?: PromotionCodeFieldCallToActionButtonPresetDto;
};
export type PromotionSummarySystemDefaultsDto = {
  general?: PromotionSummaryGeneralPresetDto;
  total?: PromotionSummaryTotalPresetDto;
  subtotal?: PromotionSummarySubtotalPresetDto;
  shipping?: PromotionSummaryShippingPresetDto;
  taxesAndDuties?: PromotionSummaryTaxesAndDutiesPresetDto;
  discount?: PromotionSummaryDiscountPresetDto;
  disclaimer?: PromotionSummaryDisclaimerPresetDto;
};
export type PromotionSummarySystemDefaultsDtoRead = {
  general?: PromotionSummaryGeneralPresetDto;
  total?: PromotionSummaryTotalPresetDtoRead;
  subtotal?: PromotionSummarySubtotalPresetDtoRead;
  shipping?: PromotionSummaryShippingPresetDtoRead;
  taxesAndDuties?: PromotionSummaryTaxesAndDutiesPresetDtoRead;
  discount?: PromotionSummaryDiscountPresetDtoRead;
  disclaimer?: PromotionSummaryDisclaimerPresetDtoRead;
};
export type OfferRulePopupSystemDefaultsDto = {
  general?: OfferRulePopupGeneralPresetDto;
  offerRule?: OfferRulePopupOfferRulePresetDto;
  callToActionButton?: OfferRulePopupCallToActionButtonPresetDto;
};
export type OfferRulePopupSystemDefaultsDtoRead = {
  general?: OfferRulePopupGeneralPresetDto;
  offerRule?: OfferRulePopupOfferRulePresetDtoRead;
  callToActionButton?: OfferRulePopupCallToActionButtonPresetDto;
};
export type PricingStateSystemDefaultsDto = {
  spacing?: DirectionalSpacingComponentDto;
  customCss?: string;
  headerText?: MessageDto;
  footerText?: MessageDto;
  price?: PricePricingBlockDto;
  badge?: BadgePricingBlockDto;
};
export type PricingStateSystemDefaultsDtoRead = {
  spacing?: DirectionalSpacingComponentDto;
  customCss?: string;
  headerText?: MessageDto;
  footerText?: MessageDto;
  price?: PricePricingBlockDtoRead;
  badge?: BadgePricingBlockDtoRead;
};
export type PricingSystemDefaultsDto = {
  discounted?: PricingStateSystemDefaultsDto;
  notDiscounted?: PricingStateSystemDefaultsDto;
};
export type PricingSystemDefaultsDtoRead = {
  discounted?: PricingStateSystemDefaultsDtoRead;
  notDiscounted?: PricingStateSystemDefaultsDtoRead;
};
export type CartTextSystemDefaultsDto = {
  textStyle?: MessageStyleComponentDto | null;
  size?: SizeComponentDto | null;
  background?: BackgroundComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
};
export type DiscountLabelTypeDto =
  | (
      | 'EMPTY'
      | 'DISCOUNT_CODE'
      | 'PERCENTAGE'
      | 'YOU_SAVE_PERCENTAGE'
      | 'YOU_SAVE_AMOUNT_ON_EACH'
      | 'FREE_GIFT'
      | 'FREE_GIFT_ENHANCED'
      | 'BLACK_FRIDAY'
      | 'CYBER_MONDAY_SALE'
      | 'CHRISTMAS'
      | 'CUSTOM'
    )
  | null;
export type DiscountLabelSystemDefaultsDto = {
  labelType?: DiscountLabelTypeDto | null;
};
export type NinjaCartSystemDefaultsDto = {
  general?: NinjaCartGeneralPresetDto;
  emptyState?: NinjaCartEmptyStatePresetDto;
  defaultState?: NinjaCartDefaultStatePresetDto;
};
export type NinjaCartSystemDefaultsDtoRead = {
  general?: NinjaCartGeneralPresetDto;
  emptyState?: NinjaCartEmptyStatePresetDtoRead;
  defaultState?: NinjaCartDefaultStatePresetDtoRead;
};
export type GetSystemDefaultsResponseDto = {
  productBanner?: ProductBannerSystemDefaultsDto | null;
  promotionalBadge?: PromotionalBadgeSystemDefaultsDto | null;
  announcementBar?: AnnouncementBarSystemDefaultsDto | null;
  notification?: NotificationSystemDefaultsDto | null;
  drawerCartPromotionCodeField?: PromotionCodeFieldSystemDefaultsDto | null;
  cartPromotionCodeField?: PromotionCodeFieldSystemDefaultsDto | null;
  drawerCartPromotionSummary?: PromotionSummarySystemDefaultsDto | null;
  cartPromotionSummary?: PromotionSummarySystemDefaultsDto | null;
  offerRulePopup?: OfferRulePopupSystemDefaultsDto | null;
  productPagePrice?: PricingSystemDefaultsDto | null;
  collectionPagePrice?: PricingSystemDefaultsDto | null;
  drawerCartTotalPrice?: PricingSystemDefaultsDto | null;
  drawerCartProductPrice?: PricingSystemDefaultsDto | null;
  drawerCartLinePrice?: PricingSystemDefaultsDto | null;
  drawerCartBreakdownPrice?: PricingSystemDefaultsDto | null;
  cartTotalPrice?: PricingSystemDefaultsDto | null;
  cartProductPrice?: PricingSystemDefaultsDto | null;
  cartLinePrice?: PricingSystemDefaultsDto | null;
  cartBreakdownPrice?: PricingSystemDefaultsDto | null;
  cartPageCartText?: CartTextSystemDefaultsDto | null;
  drawerCartPageCartText?: CartTextSystemDefaultsDto | null;
  discountLabel?: DiscountLabelSystemDefaultsDto | null;
  ninjaCart?: NinjaCartSystemDefaultsDto | null;
};
export type GetSystemDefaultsResponseDtoRead = {
  productBanner?: ProductBannerSystemDefaultsDto | null;
  promotionalBadge?: PromotionalBadgeSystemDefaultsDto | null;
  announcementBar?: AnnouncementBarSystemDefaultsDto | null;
  notification?: NotificationSystemDefaultsDtoRead | null;
  drawerCartPromotionCodeField?: PromotionCodeFieldSystemDefaultsDtoRead | null;
  cartPromotionCodeField?: PromotionCodeFieldSystemDefaultsDtoRead | null;
  drawerCartPromotionSummary?: PromotionSummarySystemDefaultsDtoRead | null;
  cartPromotionSummary?: PromotionSummarySystemDefaultsDtoRead | null;
  offerRulePopup?: OfferRulePopupSystemDefaultsDtoRead | null;
  productPagePrice?: PricingSystemDefaultsDtoRead | null;
  collectionPagePrice?: PricingSystemDefaultsDtoRead | null;
  drawerCartTotalPrice?: PricingSystemDefaultsDtoRead | null;
  drawerCartProductPrice?: PricingSystemDefaultsDtoRead | null;
  drawerCartLinePrice?: PricingSystemDefaultsDtoRead | null;
  drawerCartBreakdownPrice?: PricingSystemDefaultsDtoRead | null;
  cartTotalPrice?: PricingSystemDefaultsDtoRead | null;
  cartProductPrice?: PricingSystemDefaultsDtoRead | null;
  cartLinePrice?: PricingSystemDefaultsDtoRead | null;
  cartBreakdownPrice?: PricingSystemDefaultsDtoRead | null;
  cartPageCartText?: CartTextSystemDefaultsDto | null;
  drawerCartPageCartText?: CartTextSystemDefaultsDto | null;
  discountLabel?: DiscountLabelSystemDefaultsDto | null;
  ninjaCart?: NinjaCartSystemDefaultsDtoRead | null;
};
export type DefaultWidgetPresetsDto = {
  shopLevel?: PresetPreviewDto;
  promotionLevel?: PresetPreviewDto;
};
export type TransactionSaveModeDto =
  | ('SAVE' | 'SAVE_AS_NEW_PRESET' | 'DISCARD')
  | null;
export type CloseTransactionPresetSaveModeDto = {
  saveMode?: TransactionSaveModeDto | null;
  newPresetName?: string | null;
  setNewPresetAsActive?: boolean | null;
};
export type CloseEditorTransactionRequestDto = {
  promotionLevel?: CloseTransactionPresetSaveModeDto | null;
  shopLevel?: CloseTransactionPresetSaveModeDto | null;
  isDiscarding?: boolean | null;
};
export type ProductBannerPromotionDto = {
  shopSettings?: ProductBannerGeneralShopSettingsDto | null;
};
export type PromotionWidgetSetupDtoProductBannerPromotionDto = {
  isHidden?: boolean | null;
  general?: ProductBannerPromotionDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type PromotionWidgetSetupDtoProductBannerPromotionDtoRead = {
  isHidden?: boolean | null;
  general?: ProductBannerPromotionDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type PromotionalBadgePromotionDto = {
  shopSettings?: PromotionalBadgeGeneralShopSettingsDto | null;
};
export type PromotionWidgetSetupDtoPromotionalBadgePromotionDto = {
  isHidden?: boolean | null;
  general?: PromotionalBadgePromotionDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type PromotionWidgetSetupDtoPromotionalBadgePromotionDtoRead = {
  isHidden?: boolean | null;
  general?: PromotionalBadgePromotionDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type AnnouncementBarPrimaryBannerGeneralPromotionDto = {
  background?: BackgroundComponentDto | null;
  shopSettings?: AnnouncementBarPrimaryBannerShopSettingsDto | null;
};
export type AnnouncementBarPrimaryBannerPromotionDto = {
  isHidden?: boolean | null;
  general?: AnnouncementBarPrimaryBannerGeneralPromotionDto | null;
};
export type AnnouncementBarPrimaryBannerPromotionDtoRead = {
  isHidden?: boolean | null;
  key?: string;
  general?: AnnouncementBarPrimaryBannerGeneralPromotionDto | null;
  canBeDragged?: boolean;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
};
export type AnnouncementBarSecondaryBannerSlotPromotionDto = {
  isHidden?: boolean | null;
  key?: string;
};
export type AnnouncementBarSecondaryBannerSlotPromotionDtoRead = {
  isHidden?: boolean | null;
  key?: string;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type AnnouncementBarSecondaryBannerLevelPromotionDto = {
  slot1?: AnnouncementBarSecondaryBannerSlotPromotionDto;
  slot2?: AnnouncementBarSecondaryBannerSlotPromotionDto;
  slot3?: AnnouncementBarSecondaryBannerSlotPromotionDto;
};
export type AnnouncementBarSecondaryBannerLevelPromotionDtoRead = {
  slot1?: AnnouncementBarSecondaryBannerSlotPromotionDtoRead;
  slot2?: AnnouncementBarSecondaryBannerSlotPromotionDtoRead;
  slot3?: AnnouncementBarSecondaryBannerSlotPromotionDtoRead;
};
export type AnnouncementBarSecondaryBannerLevelEntryTypePromotionDto =
  | ('SLOT_1' | 'SLOT_2' | 'SLOT_3')
  | null;
export type AnnouncementBarSecondaryBannerGeneralPromotionDto = {
  background?: BackgroundComponentDto | null;
};
export type AnnouncementBarSecondaryBannerPromotionDto = {
  isHidden?: boolean | null;
  options?: AnnouncementBarSecondaryBannerLevelPromotionDto | null;
  currentEntries?:
    | AnnouncementBarSecondaryBannerLevelEntryTypePromotionDto[]
    | null;
  availableEntries?:
    | AnnouncementBarSecondaryBannerLevelEntryTypePromotionDto[]
    | null;
  supportsModules?: boolean | null;
  general?: AnnouncementBarSecondaryBannerGeneralPromotionDto | null;
};
export type AnnouncementBarSecondaryBannerPromotionDtoRead = {
  isHidden?: boolean | null;
  options?: AnnouncementBarSecondaryBannerLevelPromotionDtoRead | null;
  currentEntries?:
    | AnnouncementBarSecondaryBannerLevelEntryTypePromotionDto[]
    | null;
  availableEntries?:
    | AnnouncementBarSecondaryBannerLevelEntryTypePromotionDto[]
    | null;
  supportsModules?: boolean | null;
  general?: AnnouncementBarSecondaryBannerGeneralPromotionDto | null;
  canBeDragged?: boolean;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
};
export type AnnouncementBarFirstLevelPromotionDto = {
  primaryBanner?: AnnouncementBarPrimaryBannerPromotionDto | null;
  secondaryBanner?: AnnouncementBarSecondaryBannerPromotionDto | null;
};
export type AnnouncementBarFirstLevelPromotionDtoRead = {
  primaryBanner?: AnnouncementBarPrimaryBannerPromotionDtoRead | null;
  secondaryBanner?: AnnouncementBarSecondaryBannerPromotionDtoRead | null;
};
export type AnnouncementBarFirstLevelPromotionEntryTypeDto =
  | ('PRIMARY_BANNER' | 'SECONDARY_BANNER')
  | null;
export type AnnouncementBarPromotionDto = {
  stylePriority?: StylePriorityComponentDto | null;
  shopSettings?: AnnouncementBarGeneralShopSettingsDto | null;
};
export type PromotionWidgetSetupMultiLevelDtoAnnouncementBarPromotionDtoAnnouncementBarFirstLevelPromotionDtoAnnouncementBarFirstLevelPromotionEntryTypeDto =
  {
    isHidden?: boolean | null;
    options?: AnnouncementBarFirstLevelPromotionDto | null;
    currentEntries?: AnnouncementBarFirstLevelPromotionEntryTypeDto[] | null;
    availableEntries?: AnnouncementBarFirstLevelPromotionEntryTypeDto[] | null;
    supportsModules?: boolean | null;
    general?: AnnouncementBarPromotionDto | null;
    isShopLevelWidget?: boolean | null;
    page?: PageTypeDto | null;
    isAvailable?: boolean;
    isDefault?: boolean;
  };
export type PromotionWidgetSetupMultiLevelDtoAnnouncementBarPromotionDtoAnnouncementBarFirstLevelPromotionDtoAnnouncementBarFirstLevelPromotionEntryTypeDtoRead =
  {
    isHidden?: boolean | null;
    options?: AnnouncementBarFirstLevelPromotionDtoRead | null;
    currentEntries?: AnnouncementBarFirstLevelPromotionEntryTypeDto[] | null;
    availableEntries?: AnnouncementBarFirstLevelPromotionEntryTypeDto[] | null;
    supportsModules?: boolean | null;
    canBeHidden?: boolean;
    canBeRemoved?: boolean;
    canBeDragged?: boolean;
    general?: AnnouncementBarPromotionDto | null;
    isShopLevelWidget?: boolean | null;
    page?: PageTypeDto | null;
    isAvailable?: boolean;
    isDefault?: boolean;
  };
export type NotificationCollapsedStatePromotionDto = {
  isHidden?: boolean | null;
  options?: NotificationCollapsedStateLevelPresetDto | null;
  currentEntries?: NotificationCollapsedStatePresetEntryTypeDto[] | null;
  availableEntries?: NotificationCollapsedStatePresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: NotificationCollapsedStateGeneralPresetDto | null;
};
export type NotificationCollapsedStatePromotionDtoRead = {
  isHidden?: boolean | null;
  options?: NotificationCollapsedStateLevelPresetDtoRead | null;
  currentEntries?: NotificationCollapsedStatePresetEntryTypeDto[] | null;
  availableEntries?: NotificationCollapsedStatePresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: NotificationCollapsedStateGeneralPresetDto | null;
};
export type NotificationExpandedStatePromotionDto = {
  isHidden?: boolean | null;
  options?: NotificationExpandedStateLevelPresetDto | null;
  currentEntries?: NotificationExpandedStatePresetEntryTypeDto[] | null;
  availableEntries?: NotificationExpandedStatePresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: NotificationExpandedStateGeneralPresetDto | null;
  key?: string | null;
};
export type NotificationExpandedStatePromotionDtoRead = {
  isHidden?: boolean | null;
  options?: NotificationExpandedStateLevelPresetDtoRead | null;
  currentEntries?: NotificationExpandedStatePresetEntryTypeDto[] | null;
  availableEntries?: NotificationExpandedStatePresetEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  general?: NotificationExpandedStateGeneralPresetDto | null;
  key?: string | null;
};
export type NotificationStatesPromotionDto = {
  collapsedState?: NotificationCollapsedStatePromotionDto | null;
  expandedState?: NotificationExpandedStatePromotionDto | null;
};
export type NotificationStatesPromotionDtoRead = {
  collapsedState?: NotificationCollapsedStatePromotionDtoRead | null;
  expandedState?: NotificationExpandedStatePromotionDtoRead | null;
};
export type NotificationStatesPromotionEntryTypeDto =
  | ('COLLAPSED_STATE' | 'EXPANDED_STATE')
  | null;
export type NotificationPromotionDto = object;
export type PromotionWidgetSetupMultiLevelDtoNotificationPromotionDtoNotificationStatesPromotionDtoNotificationStatesPromotionEntryTypeDto =
  {
    isHidden?: boolean | null;
    options?: NotificationStatesPromotionDto | null;
    currentEntries?: NotificationStatesPromotionEntryTypeDto[] | null;
    availableEntries?: NotificationStatesPromotionEntryTypeDto[] | null;
    supportsModules?: boolean | null;
    general?: NotificationPromotionDto | null;
    isShopLevelWidget?: boolean | null;
    page?: PageTypeDto | null;
    isAvailable?: boolean;
    isDefault?: boolean;
  };
export type PromotionWidgetSetupMultiLevelDtoNotificationPromotionDtoNotificationStatesPromotionDtoNotificationStatesPromotionEntryTypeDtoRead =
  {
    isHidden?: boolean | null;
    options?: NotificationStatesPromotionDtoRead | null;
    currentEntries?: NotificationStatesPromotionEntryTypeDto[] | null;
    availableEntries?: NotificationStatesPromotionEntryTypeDto[] | null;
    supportsModules?: boolean | null;
    canBeHidden?: boolean;
    canBeRemoved?: boolean;
    canBeDragged?: boolean;
    general?: NotificationPromotionDto | null;
    isShopLevelWidget?: boolean | null;
    page?: PageTypeDto | null;
    isAvailable?: boolean;
    isDefault?: boolean;
  };
export type PromotionCodeFieldFirstLevelPromotionDto = {
  codeInput?: PromotionCodeFieldCodeInputPresetDto | null;
  codeValidation?: PromotionCodeFieldCodeValidationPresetDto | null;
  callToActionButton?: PromotionCodeFieldCallToActionButtonPresetDto | null;
};
export type PromotionCodeFieldFirstLevelPromotionDtoRead = {
  codeInput?: PromotionCodeFieldCodeInputPresetDtoRead | null;
  codeValidation?: PromotionCodeFieldCodeValidationPresetDtoRead | null;
  callToActionButton?: PromotionCodeFieldCallToActionButtonPresetDto | null;
};
export type PromotionCodeFieldPromotionEntryTypeDto =
  | ('CODE_INPUT' | 'CODE_VALIDATION' | 'CALL_TO_ACTION_BUTTON')
  | null;
export type PromotionCodeFieldPromotionDto = {
  headerStyle?: PromotionCodeFieldHeaderStyleDto | null;
  headerText?: MessageDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  background?: BackgroundComponentDto | null;
  shopSettings?: PromotionCodeFieldGeneralShopSettingsDto | null;
};
export type PromotionWidgetSetupMultiLevelDtoPromotionCodeFieldPromotionDtoPromotionCodeFieldFirstLevelPromotionDtoPromotionCodeFieldPromotionEntryTypeDto =
  {
    isHidden?: boolean | null;
    options?: PromotionCodeFieldFirstLevelPromotionDto | null;
    currentEntries?: PromotionCodeFieldPromotionEntryTypeDto[] | null;
    availableEntries?: PromotionCodeFieldPromotionEntryTypeDto[] | null;
    supportsModules?: boolean | null;
    general?: PromotionCodeFieldPromotionDto | null;
    isShopLevelWidget?: boolean | null;
    page?: PageTypeDto | null;
    isAvailable?: boolean;
    isDefault?: boolean;
  };
export type PromotionWidgetSetupMultiLevelDtoPromotionCodeFieldPromotionDtoPromotionCodeFieldFirstLevelPromotionDtoPromotionCodeFieldPromotionEntryTypeDtoRead =
  {
    isHidden?: boolean | null;
    options?: PromotionCodeFieldFirstLevelPromotionDtoRead | null;
    currentEntries?: PromotionCodeFieldPromotionEntryTypeDto[] | null;
    availableEntries?: PromotionCodeFieldPromotionEntryTypeDto[] | null;
    supportsModules?: boolean | null;
    canBeHidden?: boolean;
    canBeRemoved?: boolean;
    canBeDragged?: boolean;
    general?: PromotionCodeFieldPromotionDto | null;
    isShopLevelWidget?: boolean | null;
    page?: PageTypeDto | null;
    isAvailable?: boolean;
    isDefault?: boolean;
  };
export type PromotionSummaryTotalPromotionDto = {
  isHidden?: boolean | null;
  label?: MessageDto | null;
  sectionDivider?: OptionDtoSectionDividerComponentDto | null;
};
export type PromotionSummaryTotalPromotionDtoRead = {
  isHidden?: boolean | null;
  label?: MessageDto | null;
  sectionDivider?: OptionDtoSectionDividerComponentDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type PromotionSummarySubtotalPromotionDto = {
  isHidden?: boolean | null;
  label?: MessageSetupDto | null;
  shopSettings?: PromotionSummarySubtotalShopSettingsDto | null;
};
export type PromotionSummarySubtotalPromotionDtoRead = {
  isHidden?: boolean | null;
  label?: MessageSetupDto | null;
  shopSettings?: PromotionSummarySubtotalShopSettingsDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type PromotionSummaryShippingPromotionDto = {
  isHidden?: boolean | null;
  label?: MessageSetupDto | null;
  shopSettings?: PromotionSummaryShippingShopSettingsDto | null;
};
export type PromotionSummaryShippingPromotionDtoRead = {
  isHidden?: boolean | null;
  label?: MessageSetupDto | null;
  shopSettings?: PromotionSummaryShippingShopSettingsDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type PromotionSummaryTaxesAndDutiesPromotionDto = {
  isHidden?: boolean | null;
  label?: MessageSetupDto | null;
  description?: MessageDto | null;
};
export type PromotionSummaryTaxesAndDutiesPromotionDtoRead = {
  isHidden?: boolean | null;
  label?: MessageSetupDto | null;
  description?: MessageDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type PromotionSummaryDiscountPromotionDto = {
  isHidden?: boolean | null;
  label?: MessageSetupDto | null;
  shopSettings?: PromotionSummaryDiscountShopSettingsDto | null;
  key?: string;
};
export type PromotionSummaryDiscountPromotionDtoRead = {
  isHidden?: boolean | null;
  label?: MessageSetupDto | null;
  shopSettings?: PromotionSummaryDiscountShopSettingsDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  key?: string;
};
export type PromotionSummaryDisclaimerPromotionDto = {
  isHidden?: boolean | null;
  text?: OptionDtoMessageDto | null;
};
export type PromotionSummaryDisclaimerPromotionDtoRead = {
  isHidden?: boolean | null;
  text?: OptionDtoMessageDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type PromotionSummaryLevelPromotionDto = {
  total?: PromotionSummaryTotalPromotionDto | null;
  subtotal?: PromotionSummarySubtotalPromotionDto | null;
  shipping?: PromotionSummaryShippingPromotionDto | null;
  taxesAndDuties?: PromotionSummaryTaxesAndDutiesPromotionDto | null;
  discount?: PromotionSummaryDiscountPromotionDto | null;
  disclaimer?: PromotionSummaryDisclaimerPromotionDto | null;
};
export type PromotionSummaryLevelPromotionDtoRead = {
  total?: PromotionSummaryTotalPromotionDtoRead | null;
  subtotal?: PromotionSummarySubtotalPromotionDtoRead | null;
  shipping?: PromotionSummaryShippingPromotionDtoRead | null;
  taxesAndDuties?: PromotionSummaryTaxesAndDutiesPromotionDtoRead | null;
  discount?: PromotionSummaryDiscountPromotionDtoRead | null;
  disclaimer?: PromotionSummaryDisclaimerPromotionDtoRead | null;
};
export type PromotionSummaryLevelPromotionEntryTypeDto =
  | (
      | 'TOTAL'
      | 'SUBTOTAL'
      | 'SHIPPING'
      | 'TAXES_AND_DUTIES'
      | 'DISCOUNT'
      | 'DISCLAIMER'
    )
  | null;
export type PromotionSummaryPromotionDto = {
  size?: SizeComponentDto | null;
  priceBreakdown?: PromotionSummaryPriceBreakdownBehaviorDto | null;
  expandButton?: IconComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  background?: BackgroundComponentDto | null;
  textStyle?: MessageStyleComponentDto | null;
};
export type PromotionWidgetSetupMultiLevelDtoPromotionSummaryPromotionDtoPromotionSummaryLevelPromotionDtoPromotionSummaryLevelPromotionEntryTypeDto =
  {
    isHidden?: boolean | null;
    options?: PromotionSummaryLevelPromotionDto | null;
    currentEntries?: PromotionSummaryLevelPromotionEntryTypeDto[] | null;
    availableEntries?: PromotionSummaryLevelPromotionEntryTypeDto[] | null;
    supportsModules?: boolean | null;
    general?: PromotionSummaryPromotionDto | null;
    isShopLevelWidget?: boolean | null;
    page?: PageTypeDto | null;
    isAvailable?: boolean;
    isDefault?: boolean;
  };
export type PromotionWidgetSetupMultiLevelDtoPromotionSummaryPromotionDtoPromotionSummaryLevelPromotionDtoPromotionSummaryLevelPromotionEntryTypeDtoRead =
  {
    isHidden?: boolean | null;
    options?: PromotionSummaryLevelPromotionDtoRead | null;
    currentEntries?: PromotionSummaryLevelPromotionEntryTypeDto[] | null;
    availableEntries?: PromotionSummaryLevelPromotionEntryTypeDto[] | null;
    supportsModules?: boolean | null;
    canBeHidden?: boolean;
    canBeRemoved?: boolean;
    canBeDragged?: boolean;
    general?: PromotionSummaryPromotionDto | null;
    isShopLevelWidget?: boolean | null;
    page?: PageTypeDto | null;
    isAvailable?: boolean;
    isDefault?: boolean;
  };
export type OfferRulePopupFirstLevelPromotionDto = object;
export type OfferRulePopupPromotionEntryTypeDto = string | null;
export type OfferRulePopupPromotionShopSettingsDto = {
  position?: PositionComponentDto | null;
  offset?: OptionDtoOffsetComponentDto | null;
  overlay?: OverlayComponentDto | null;
};
export type OfferRulePopupPromotionDto = {
  customCss?: string | null;
  shopSettings?: OfferRulePopupPromotionShopSettingsDto | null;
};
export type PromotionWidgetSetupMultiLevelDtoOfferRulePopupPromotionDtoOfferRulePopupFirstLevelPromotionDtoOfferRulePopupPromotionEntryTypeDto =
  {
    isHidden?: boolean | null;
    options?: OfferRulePopupFirstLevelPromotionDto | null;
    currentEntries?: OfferRulePopupPromotionEntryTypeDto[] | null;
    availableEntries?: OfferRulePopupPromotionEntryTypeDto[] | null;
    supportsModules?: boolean | null;
    general?: OfferRulePopupPromotionDto | null;
    isShopLevelWidget?: boolean | null;
    page?: PageTypeDto | null;
    isAvailable?: boolean;
    isDefault?: boolean;
  };
export type PromotionWidgetSetupMultiLevelDtoOfferRulePopupPromotionDtoOfferRulePopupFirstLevelPromotionDtoOfferRulePopupPromotionEntryTypeDtoRead =
  {
    isHidden?: boolean | null;
    options?: OfferRulePopupFirstLevelPromotionDto | null;
    currentEntries?: OfferRulePopupPromotionEntryTypeDto[] | null;
    availableEntries?: OfferRulePopupPromotionEntryTypeDto[] | null;
    supportsModules?: boolean | null;
    canBeHidden?: boolean;
    canBeRemoved?: boolean;
    canBeDragged?: boolean;
    general?: OfferRulePopupPromotionDto | null;
    isShopLevelWidget?: boolean | null;
    page?: PageTypeDto | null;
    isAvailable?: boolean;
    isDefault?: boolean;
  };
export type PricingLevelPromotionDto = {
  discountedPrice?: PriceStateDto | null;
  notDiscountedPrice?: PriceStateDto | null;
};
export type PricingLevelPromotionDtoRead = {
  discountedPrice?: PriceStateDtoRead | null;
  notDiscountedPrice?: PriceStateDtoRead | null;
};
export type PricingLevelPromotionEntryTypeDto =
  | ('DISCOUNTED_PRICE' | 'NOT_DISCOUNTED_PRICE')
  | null;
export type PricingPromotionDto = object;
export type PromotionWidgetSetupMultiLevelDtoPricingPromotionDtoPricingLevelPromotionDtoPricingLevelPromotionEntryTypeDto =
  {
    isHidden?: boolean | null;
    options?: PricingLevelPromotionDto | null;
    currentEntries?: PricingLevelPromotionEntryTypeDto[] | null;
    availableEntries?: PricingLevelPromotionEntryTypeDto[] | null;
    supportsModules?: boolean | null;
    general?: PricingPromotionDto | null;
    isShopLevelWidget?: boolean | null;
    page?: PageTypeDto | null;
    isAvailable?: boolean;
    isDefault?: boolean;
  };
export type PromotionWidgetSetupMultiLevelDtoPricingPromotionDtoPricingLevelPromotionDtoPricingLevelPromotionEntryTypeDtoRead =
  {
    isHidden?: boolean | null;
    options?: PricingLevelPromotionDtoRead | null;
    currentEntries?: PricingLevelPromotionEntryTypeDto[] | null;
    availableEntries?: PricingLevelPromotionEntryTypeDto[] | null;
    supportsModules?: boolean | null;
    canBeHidden?: boolean;
    canBeRemoved?: boolean;
    canBeDragged?: boolean;
    general?: PricingPromotionDto | null;
    isShopLevelWidget?: boolean | null;
    page?: PageTypeDto | null;
    isAvailable?: boolean;
    isDefault?: boolean;
  };
export type CartTextPromotionDto = object;
export type PromotionWidgetSetupDtoCartTextPromotionDto = {
  isHidden?: boolean | null;
  general?: CartTextPromotionDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type PromotionWidgetSetupDtoCartTextPromotionDtoRead = {
  isHidden?: boolean | null;
  general?: CartTextPromotionDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type DiscountLabelPromotionDto = object;
export type PromotionWidgetSetupDtoDiscountLabelPromotionDto = {
  isHidden?: boolean | null;
  general?: DiscountLabelPromotionDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type PromotionWidgetSetupDtoDiscountLabelPromotionDtoRead = {
  isHidden?: boolean | null;
  general?: DiscountLabelPromotionDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type NinjaCartCartTextPromotionDto = {
  textStyle?: MessageStyleComponentDto | null;
  size?: SizeComponentDto | null;
  background?: BackgroundComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
};
export type PromotionWidgetSetupDtoNinjaCartCartTextPromotionDto = {
  isHidden?: boolean | null;
  general?: NinjaCartCartTextPromotionDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type PromotionWidgetSetupDtoNinjaCartCartTextPromotionDtoRead = {
  isHidden?: boolean | null;
  general?: NinjaCartCartTextPromotionDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type NinjaCartPromotionSummaryLevelPromotionDto = {
  total?: PromotionSummaryTotalPromotionDto | null;
  subtotal?: PromotionSummarySubtotalPromotionDto | null;
  shipping?: PromotionSummaryShippingPromotionDto | null;
  taxesAndDuties?: PromotionSummaryTaxesAndDutiesPromotionDto | null;
  discount?: PromotionSummaryDiscountPromotionDto | null;
  disclaimer?: PromotionSummaryDisclaimerPromotionDto | null;
};
export type NinjaCartPromotionSummaryLevelPromotionDtoRead = {
  total?: PromotionSummaryTotalPromotionDtoRead | null;
  subtotal?: PromotionSummarySubtotalPromotionDtoRead | null;
  shipping?: PromotionSummaryShippingPromotionDtoRead | null;
  taxesAndDuties?: PromotionSummaryTaxesAndDutiesPromotionDtoRead | null;
  discount?: PromotionSummaryDiscountPromotionDtoRead | null;
  disclaimer?: PromotionSummaryDisclaimerPromotionDtoRead | null;
};
export type NinjaCartPromotionSummaryLevelPromotionEntryTypeDto =
  | (
      | 'TOTAL'
      | 'SUBTOTAL'
      | 'SHIPPING'
      | 'TAXES_AND_DUTIES'
      | 'DISCOUNT'
      | 'DISCLAIMER'
    )
  | null;
export type NinjaCartPromotionSummaryPromotionDto = {
  size?: SizeComponentDto | null;
  priceBreakdown?: PromotionSummaryPriceBreakdownBehaviorDto | null;
  expandButton?: IconComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  background?: BackgroundComponentDto | null;
  textStyle?: MessageStyleComponentDto | null;
};
export type PromotionWidgetSetupMultiLevelDtoNinjaCartPromotionSummaryPromotionDtoNinjaCartPromotionSummaryLevelPromotionDtoNinjaCartPromotionSummaryLevelPromotionEntryTypeDto =
  {
    isHidden?: boolean | null;
    options?: NinjaCartPromotionSummaryLevelPromotionDto | null;
    currentEntries?:
      | NinjaCartPromotionSummaryLevelPromotionEntryTypeDto[]
      | null;
    availableEntries?:
      | NinjaCartPromotionSummaryLevelPromotionEntryTypeDto[]
      | null;
    supportsModules?: boolean | null;
    general?: NinjaCartPromotionSummaryPromotionDto | null;
    isShopLevelWidget?: boolean | null;
    page?: PageTypeDto | null;
    isAvailable?: boolean;
    isDefault?: boolean;
  };
export type PromotionWidgetSetupMultiLevelDtoNinjaCartPromotionSummaryPromotionDtoNinjaCartPromotionSummaryLevelPromotionDtoNinjaCartPromotionSummaryLevelPromotionEntryTypeDtoRead =
  {
    isHidden?: boolean | null;
    options?: NinjaCartPromotionSummaryLevelPromotionDtoRead | null;
    currentEntries?:
      | NinjaCartPromotionSummaryLevelPromotionEntryTypeDto[]
      | null;
    availableEntries?:
      | NinjaCartPromotionSummaryLevelPromotionEntryTypeDto[]
      | null;
    supportsModules?: boolean | null;
    canBeHidden?: boolean;
    canBeRemoved?: boolean;
    canBeDragged?: boolean;
    general?: NinjaCartPromotionSummaryPromotionDto | null;
    isShopLevelWidget?: boolean | null;
    page?: PageTypeDto | null;
    isAvailable?: boolean;
    isDefault?: boolean;
  };
export type NinjaCartProductBadgePromotionDto = {
  textStyle?: MessageStyleComponentDto | null;
  style?: SizeComponentDto | null;
  background?: BackgroundComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  stitch?: OptionDtoStitchComponentDto | null;
  shopSettings?: NinjaCartCartItemProductBadgeShopSettingsDto | null;
};
export type PromotionWidgetSetupDtoNinjaCartProductBadgePromotionDto = {
  isHidden?: boolean | null;
  general?: NinjaCartProductBadgePromotionDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
};
export type PromotionWidgetSetupDtoNinjaCartProductBadgePromotionDtoRead = {
  isHidden?: boolean | null;
  general?: NinjaCartProductBadgePromotionDto | null;
  isShopLevelWidget?: boolean | null;
  page?: PageTypeDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type ProductBannerIconOfferDto = {
  color?: string | null;
  iconId?: string | null;
  isHidden?: boolean | null;
};
export type ProductBannerIconOfferDtoRead = {
  color?: string | null;
  iconId?: string | null;
  isHidden?: boolean | null;
  canBeDragged?: boolean;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
};
export type ProductBannerImageOfferDto = {
  isHidden?: boolean | null;
  image?: ImageComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  border?: OptionDtoBorderComponentDto | null;
};
export type ProductBannerImageOfferDtoRead = {
  isHidden?: boolean | null;
  image?: ImageComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  border?: OptionDtoBorderComponentDto | null;
  canBeDragged?: boolean;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
};
export type ProductBannerMessageOfferDto = {
  isHidden?: boolean | null;
  style?: MessageStyleComponentDto | null;
  message?: MessageSetupDto | null;
};
export type ProductBannerMessageOfferDtoRead = {
  isHidden?: boolean | null;
  style?: MessageStyleComponentDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  message?: MessageSetupDto | null;
};
export type ProductBannerOfferLevelDto = {
  icon?: ProductBannerIconOfferDto | null;
  image?: ProductBannerImageOfferDto | null;
  message?: ProductBannerMessageOfferDto | null;
};
export type ProductBannerOfferLevelDtoRead = {
  icon?: ProductBannerIconOfferDtoRead | null;
  image?: ProductBannerImageOfferDtoRead | null;
  message?: ProductBannerMessageOfferDtoRead | null;
};
export type ProductBannerOfferShopSettingsDto = {
  deviceDisplay?: DeviceDisplayDto | null;
};
export type ProductBannerGeneralOfferDto = {
  size?: SizeComponentDto | null;
  order?: OrderComponentDto | null;
  link?: LinkComponentDto | null;
  background?: BackgroundComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  shopSettings?: ProductBannerOfferShopSettingsDto | null;
};
export type ProductBannerOfferPrerequisiteEntitledDto = {
  options?: ProductBannerOfferLevelDto | null;
  currentEntries?: ProductBannerFirstLevelEntryTypeDto[] | null;
  availableEntries?: ProductBannerFirstLevelEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: ProductBannerGeneralOfferDto | null;
};
export type ProductBannerOfferPrerequisiteEntitledDtoRead = {
  options?: ProductBannerOfferLevelDtoRead | null;
  currentEntries?: ProductBannerFirstLevelEntryTypeDto[] | null;
  availableEntries?: ProductBannerFirstLevelEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: ProductBannerGeneralOfferDto | null;
};
export type ProductBannerOfferFirstLevelDto = {
  icon?: ProductBannerIconOfferDto | null;
  image?: ProductBannerImageOfferDto | null;
  message?: ProductBannerMessageOfferDto | null;
  entitled?: ProductBannerOfferPrerequisiteEntitledDto | null;
  prerequisite?: ProductBannerOfferPrerequisiteEntitledDto | null;
};
export type ProductBannerOfferFirstLevelDtoRead = {
  icon?: ProductBannerIconOfferDtoRead | null;
  image?: ProductBannerImageOfferDtoRead | null;
  message?: ProductBannerMessageOfferDtoRead | null;
  entitled?: ProductBannerOfferPrerequisiteEntitledDtoRead | null;
  prerequisite?: ProductBannerOfferPrerequisiteEntitledDtoRead | null;
};
export type ProductBannerOfferLevelEntryTypeDto =
  | ('ICON' | 'IMAGE' | 'MESSAGE' | 'ENTITLED' | 'PREREQUISITE')
  | null;
export type ProductBannerGeneralMainOfferDto = {
  size?: SizeComponentDto | null;
  order?: OrderComponentDto | null;
  link?: LinkComponentDto | null;
  background?: BackgroundComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  shopSettings?: ProductBannerOfferShopSettingsDto | null;
  supportEntitledAndPrerequisiteProducts?: boolean | null;
  isEntitledAndPrerequisiteProductsSupported?: boolean | null;
};
export type ProductBannerOfferDto = {
  options?: ProductBannerOfferFirstLevelDto | null;
  currentEntries?: ProductBannerOfferLevelEntryTypeDto[] | null;
  availableEntries?: ProductBannerOfferLevelEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: ProductBannerGeneralMainOfferDto | null;
};
export type ProductBannerOfferDtoRead = {
  options?: ProductBannerOfferFirstLevelDtoRead | null;
  currentEntries?: ProductBannerOfferLevelEntryTypeDto[] | null;
  availableEntries?: ProductBannerOfferLevelEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: ProductBannerGeneralMainOfferDto | null;
};
export type OfferWidgetSetupDtoProductBannerOfferDto = {
  isHidden?: boolean | null;
  parentKey?: string | null;
  offerId?: string;
  setup?: ProductBannerOfferDto | null;
  title?: string | null;
  isDefault?: boolean;
  isAvailable?: boolean;
};
export type OfferWidgetSetupDtoProductBannerOfferDtoRead = {
  isHidden?: boolean | null;
  parentKey?: string | null;
  offerId?: string;
  setup?: ProductBannerOfferDtoRead | null;
  title?: string | null;
  isDefault?: boolean;
  isAvailable?: boolean;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type PromotionalBadgeMessageOfferDto = {
  isHidden?: boolean | null;
  style?: MessageStyleComponentDto | null;
  message?: MessageSetupDto | null;
};
export type PromotionalBadgeMessageOfferDtoRead = {
  isHidden?: boolean | null;
  style?: MessageStyleComponentDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
  message?: MessageSetupDto | null;
};
export type PromotionalBadgeBadgeOfferDto = {
  isHidden?: boolean | null;
  selectedStyleSet?: PromotionalBadgeBadgeStyleSetKeyDto | null;
  primaryStyle?: PromotionalBadgeBadgeStyleSetDto | null;
  secondaryStyle?: PromotionalBadgeBadgeStyleSetDto | null;
};
export type PromotionalBadgeBadgeOfferDtoRead = {
  isHidden?: boolean | null;
  selectedStyleSet?: PromotionalBadgeBadgeStyleSetKeyDto | null;
  primaryStyle?: PromotionalBadgeBadgeStyleSetDto | null;
  secondaryStyle?: PromotionalBadgeBadgeStyleSetDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type PromotionalBadgeOfferFirstLevelDto = {
  message?: PromotionalBadgeMessageOfferDto | null;
  badge?: PromotionalBadgeBadgeOfferDto | null;
};
export type PromotionalBadgeOfferFirstLevelDtoRead = {
  message?: PromotionalBadgeMessageOfferDtoRead | null;
  badge?: PromotionalBadgeBadgeOfferDtoRead | null;
};
export type OffsetSetupDto = {
  type?: OffsetTypeDto | null;
  vertical?: number | null;
  horizontal?: number | null;
};
export type OptionOffsetSetupDto = {
  enabled?: boolean;
  value?: OffsetSetupDto;
};
export type SpacingSetupDto = {
  type?: SpacingTypeDto | null;
  customSpacing?: number | null;
};
export type OptionSpacingSetupDto = {
  enabled?: boolean;
  value?: SpacingSetupDto;
};
export type PromotionalBadgeOfferShopSettingsDto = {
  position?: PositionDto | null;
  offset?: OptionOffsetSetupDto;
  stacking?: OptionSpacingSetupDto;
};
export type PromotionalBadgeGeneralOfferDto = {
  order?: OrderComponentDto | null;
  shopSettings?: PromotionalBadgeOfferShopSettingsDto | null;
};
export type PromotionalBadgeOfferDto = {
  options?: PromotionalBadgeOfferFirstLevelDto | null;
  currentEntries?: PromotionalBadgeFirstLevelEntryTypeDto[] | null;
  availableEntries?: PromotionalBadgeFirstLevelEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: PromotionalBadgeGeneralOfferDto | null;
};
export type PromotionalBadgeOfferDtoRead = {
  options?: PromotionalBadgeOfferFirstLevelDtoRead | null;
  currentEntries?: PromotionalBadgeFirstLevelEntryTypeDto[] | null;
  availableEntries?: PromotionalBadgeFirstLevelEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: PromotionalBadgeGeneralOfferDto | null;
};
export type OfferWidgetSetupDtoPromotionalBadgeOfferDto = {
  isHidden?: boolean | null;
  parentKey?: string | null;
  offerId?: string;
  setup?: PromotionalBadgeOfferDto | null;
  title?: string | null;
  isDefault?: boolean;
  isAvailable?: boolean;
};
export type OfferWidgetSetupDtoPromotionalBadgeOfferDtoRead = {
  isHidden?: boolean | null;
  parentKey?: string | null;
  offerId?: string;
  setup?: PromotionalBadgeOfferDtoRead | null;
  title?: string | null;
  isDefault?: boolean;
  isAvailable?: boolean;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type AnnouncementBarIconOfferDto = {
  isHidden?: boolean | null;
  icon?: IconComponentDto | null;
};
export type AnnouncementBarIconOfferDtoRead = {
  isHidden?: boolean | null;
  icon?: IconComponentDto | null;
  canBeRemoved?: boolean;
  canBeHidden?: boolean;
  canBeDragged?: boolean;
};
export type AnnouncementBarImageOfferDto = {
  isHidden?: boolean | null;
  image?: ImageComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  border?: OptionDtoBorderComponentDto | null;
};
export type AnnouncementBarImageOfferDtoRead = {
  isHidden?: boolean | null;
  image?: ImageComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  border?: OptionDtoBorderComponentDto | null;
  canBeRemoved?: boolean;
  canBeHidden?: boolean;
  canBeDragged?: boolean;
};
export type OptionDtoMessageSetupDto = {
  enabled?: boolean;
  value?: MessageSetupDto | null;
};
export type AnnouncementBarMessageOfferDto = {
  isHidden?: boolean | null;
  style?: MessageStyleComponentDto | null;
  preLaunchText?: OptionDtoMessageSetupDto | null;
  text?: MessageSetupDto | null;
};
export type AnnouncementBarMessageOfferDtoRead = {
  isHidden?: boolean | null;
  style?: MessageStyleComponentDto | null;
  preLaunchText?: OptionDtoMessageSetupDto | null;
  text?: MessageSetupDto | null;
  canBeRemoved?: boolean;
  canBeHidden?: boolean;
  canBeDragged?: boolean;
};
export type AnnouncementBarOfferFirstLevelDto = {
  icon?: AnnouncementBarIconOfferDto | null;
  image?: AnnouncementBarImageOfferDto | null;
  message?: AnnouncementBarMessageOfferDto | null;
};
export type AnnouncementBarOfferFirstLevelDtoRead = {
  icon?: AnnouncementBarIconOfferDtoRead | null;
  image?: AnnouncementBarImageOfferDtoRead | null;
  message?: AnnouncementBarMessageOfferDtoRead | null;
};
export type AnnouncementBarOfferLevelEntryTypeDto =
  | ('ICON' | 'IMAGE' | 'MESSAGE')
  | null;
export type AnnouncementBarOfferPositionDto =
  | (
      | 'ANNOUNCEMENT_BAR_PRIMARY_BANNER'
      | 'ANNOUNCEMENT_BAR_SECONDARY_BANNER_SLOT_1'
      | 'ANNOUNCEMENT_BAR_SECONDARY_BANNER_SLOT_2'
      | 'ANNOUNCEMENT_BAR_SECONDARY_BANNER_SLOT_3'
    )
  | null;
export type AnnouncementBarOfferShopSettingsDto = {
  deviceDisplay?: DeviceDisplayDto | null;
  pageDisplay?: PageDisplayComponentDto | null;
};
export type AnnouncementBarGeneralOfferDto = {
  isHidden?: boolean | null;
  position?: AnnouncementBarOfferPositionDto | null;
  link?: LinkComponentDto | null;
  shopSettings?: AnnouncementBarOfferShopSettingsDto | null;
};
export type AnnouncementBarGeneralOfferDtoRead = {
  isHidden?: boolean | null;
  position?: AnnouncementBarOfferPositionDto | null;
  link?: LinkComponentDto | null;
  shopSettings?: AnnouncementBarOfferShopSettingsDto | null;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type AnnouncementBarOfferDto = {
  options?: AnnouncementBarOfferFirstLevelDto | null;
  currentEntries?: AnnouncementBarOfferLevelEntryTypeDto[] | null;
  availableEntries?: AnnouncementBarOfferLevelEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: AnnouncementBarGeneralOfferDto | null;
};
export type AnnouncementBarOfferDtoRead = {
  options?: AnnouncementBarOfferFirstLevelDtoRead | null;
  currentEntries?: AnnouncementBarOfferLevelEntryTypeDto[] | null;
  availableEntries?: AnnouncementBarOfferLevelEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: AnnouncementBarGeneralOfferDtoRead | null;
};
export type OfferWidgetSetupDtoAnnouncementBarOfferDto = {
  isHidden?: boolean | null;
  parentKey?: string | null;
  offerId?: string;
  setup?: AnnouncementBarOfferDto | null;
  title?: string | null;
  isDefault?: boolean;
  isAvailable?: boolean;
};
export type OfferWidgetSetupDtoAnnouncementBarOfferDtoRead = {
  isHidden?: boolean | null;
  parentKey?: string | null;
  offerId?: string;
  setup?: AnnouncementBarOfferDtoRead | null;
  title?: string | null;
  isDefault?: boolean;
  isAvailable?: boolean;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type NotificationOfferFirstLevelDto = object;
export type NotificationOfferLevelEntryTypeDto = string | null;
export type NotificationGeneralOfferDto = {
  text?: MessageDto | null;
  icon?: OptionDtoIconComponentDto | null;
};
export type NotificationOfferDto = {
  options?: NotificationOfferFirstLevelDto | null;
  currentEntries?: NotificationOfferLevelEntryTypeDto[] | null;
  availableEntries?: NotificationOfferLevelEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: NotificationGeneralOfferDto | null;
};
export type OfferWidgetSetupDtoNotificationOfferDto = {
  isHidden?: boolean | null;
  parentKey?: string | null;
  offerId?: string;
  setup?: NotificationOfferDto | null;
  title?: string | null;
  isDefault?: boolean;
  isAvailable?: boolean;
};
export type OfferWidgetSetupDtoNotificationOfferDtoRead = {
  isHidden?: boolean | null;
  parentKey?: string | null;
  offerId?: string;
  setup?: NotificationOfferDto | null;
  title?: string | null;
  isDefault?: boolean;
  isAvailable?: boolean;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type PromotionCodeFieldOfferDto = object;
export type OfferWidgetSetupDtoPromotionCodeFieldOfferDto = {
  isHidden?: boolean | null;
  parentKey?: string | null;
  offerId?: string;
  setup?: PromotionCodeFieldOfferDto | null;
  title?: string | null;
  isDefault?: boolean;
  isAvailable?: boolean;
};
export type OfferWidgetSetupDtoPromotionCodeFieldOfferDtoRead = {
  isHidden?: boolean | null;
  parentKey?: string | null;
  offerId?: string;
  setup?: PromotionCodeFieldOfferDto | null;
  title?: string | null;
  isDefault?: boolean;
  isAvailable?: boolean;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type PromotionSummaryLevelOfferDto = object;
export type PromotionSummaryLevelOfferEntryTypeDto = string | null;
export type PromotionSummaryGeneralOfferDto = {
  label?: MessageDto | null;
};
export type PromotionSummaryOfferDto = {
  options?: PromotionSummaryLevelOfferDto | null;
  currentEntries?: PromotionSummaryLevelOfferEntryTypeDto[] | null;
  availableEntries?: PromotionSummaryLevelOfferEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: PromotionSummaryGeneralOfferDto | null;
};
export type OfferWidgetSetupDtoPromotionSummaryOfferDto = {
  isHidden?: boolean | null;
  parentKey?: string | null;
  offerId?: string;
  setup?: PromotionSummaryOfferDto | null;
  title?: string | null;
  isDefault?: boolean;
  isAvailable?: boolean;
};
export type OfferWidgetSetupDtoPromotionSummaryOfferDtoRead = {
  isHidden?: boolean | null;
  parentKey?: string | null;
  offerId?: string;
  setup?: PromotionSummaryOfferDto | null;
  title?: string | null;
  isDefault?: boolean;
  isAvailable?: boolean;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type OfferRulePopupHeadlineTextOfferDto = {
  headlineText?: MessageDto | null;
};
export type OfferRulePopupRuleTextOfferDto = {
  ruleText?: MessageDto | null;
};
export type OfferRulePopupCallToActionButtonOfferDto = {
  size?: SizeComponentDto | null;
  position?: PositionComponentDto | null;
  label?: MessageDto | null;
  buttonStyle?: CallToActionButtonStyleDto | null;
  link?: LinkComponentDto | null;
};
export type OfferRulePopupOfferLevelDto = {
  headlineText?: OfferRulePopupHeadlineTextOfferDto | null;
  ruleText?: OfferRulePopupRuleTextOfferDto | null;
  callToActionButton?: OfferRulePopupCallToActionButtonOfferDto | null;
};
export type OfferRulePopupOfferLevelEntryTypeDto =
  | ('HEADLINE_TEXT' | 'RULE_TEXT' | 'CALL_TO_ACTION_BUTTON')
  | null;
export type OfferRulePopupOfferShopSettingsDto = {
  closeButton?: IconComponentDto | null;
};
export type OfferRulePopupGeneralOfferDto = {
  size?: SizeComponentDto | null;
  background?: BackgroundComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
  shadow?: OptionDtoShadowComponentDto | null;
  shopSettings?: OfferRulePopupOfferShopSettingsDto | null;
};
export type OfferRulePopupOfferDto = {
  options?: OfferRulePopupOfferLevelDto | null;
  currentEntries?: OfferRulePopupOfferLevelEntryTypeDto[] | null;
  availableEntries?: OfferRulePopupOfferLevelEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: OfferRulePopupGeneralOfferDto | null;
};
export type OfferWidgetSetupDtoOfferRulePopupOfferDto = {
  isHidden?: boolean | null;
  parentKey?: string | null;
  offerId?: string;
  setup?: OfferRulePopupOfferDto | null;
  title?: string | null;
  isDefault?: boolean;
  isAvailable?: boolean;
};
export type OfferWidgetSetupDtoOfferRulePopupOfferDtoRead = {
  isHidden?: boolean | null;
  parentKey?: string | null;
  offerId?: string;
  setup?: OfferRulePopupOfferDto | null;
  title?: string | null;
  isDefault?: boolean;
  isAvailable?: boolean;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type PricingLevelOfferDto = {
  headerText?: HeaderTextPricingModuleGeneralDto | null;
  footerText?: FooterTextPricingModuleGeneralDto | null;
};
export type PricingOfferEntryTypeDto = ('HEADER_TEXT' | 'FOOTER_TEXT') | null;
export type PricingBaseGeneralOfferDto = {
  customCss?: string | null;
};
export type PricingOfferDto = {
  options?: PricingLevelOfferDto | null;
  currentEntries?: PricingOfferEntryTypeDto[] | null;
  availableEntries?: PricingOfferEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: PricingBaseGeneralOfferDto | null;
};
export type OfferWidgetSetupDtoPricingOfferDto = {
  isHidden?: boolean | null;
  parentKey?: string | null;
  offerId?: string;
  setup?: PricingOfferDto | null;
  title?: string | null;
  isDefault?: boolean;
  isAvailable?: boolean;
};
export type OfferWidgetSetupDtoPricingOfferDtoRead = {
  isHidden?: boolean | null;
  parentKey?: string | null;
  offerId?: string;
  setup?: PricingOfferDto | null;
  title?: string | null;
  isDefault?: boolean;
  isAvailable?: boolean;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type CartTextOfferLevelDto = object;
export type CartTextOfferLevelEntryTypeDto = string | null;
export type CartTextGeneralOfferDto = {
  text?: MessageDto | null;
  size?: SizeComponentDto | null;
  background?: BackgroundComponentDto | null;
  cornerRadius?: CornerRadiusComponentDto | null;
};
export type CartTextOfferDto = {
  options?: CartTextOfferLevelDto | null;
  currentEntries?: CartTextOfferLevelEntryTypeDto[] | null;
  availableEntries?: CartTextOfferLevelEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: CartTextGeneralOfferDto | null;
};
export type OfferWidgetSetupDtoCartTextOfferDto = {
  isHidden?: boolean | null;
  parentKey?: string | null;
  offerId?: string;
  setup?: CartTextOfferDto | null;
  title?: string | null;
  isDefault?: boolean;
  isAvailable?: boolean;
};
export type OfferWidgetSetupDtoCartTextOfferDtoRead = {
  isHidden?: boolean | null;
  parentKey?: string | null;
  offerId?: string;
  setup?: CartTextOfferDto | null;
  title?: string | null;
  isDefault?: boolean;
  isAvailable?: boolean;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type DiscountLabelOfferLevelDto = object;
export type DiscountLabelOfferLevelEntryTypeDto = string | null;
export type DiscountLabelOfferValueDto = {
  type?: DiscountLabelTypeDto | null;
  value?: string;
};
export type DiscountLabelGeneralOfferDto = {
  labelType?: DiscountLabelTypeDto | null;
  customLabel?: MessageSetupDto | null;
  values?: DiscountLabelOfferValueDto[] | null;
};
export type DiscountLabelOfferDto = {
  options?: DiscountLabelOfferLevelDto | null;
  currentEntries?: DiscountLabelOfferLevelEntryTypeDto[] | null;
  availableEntries?: DiscountLabelOfferLevelEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: DiscountLabelGeneralOfferDto | null;
};
export type OfferWidgetSetupDtoDiscountLabelOfferDto = {
  isHidden?: boolean | null;
  parentKey?: string | null;
  offerId?: string;
  setup?: DiscountLabelOfferDto | null;
  title?: string | null;
  isDefault?: boolean;
  isAvailable?: boolean;
};
export type OfferWidgetSetupDtoDiscountLabelOfferDtoRead = {
  isHidden?: boolean | null;
  parentKey?: string | null;
  offerId?: string;
  setup?: DiscountLabelOfferDto | null;
  title?: string | null;
  isDefault?: boolean;
  isAvailable?: boolean;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type NinjaCartProductBadgeOfferLevelDto = object;
export type NinjaCartProductBadgeLevelOfferEntryTypeDto = string | null;
export type NinjaCartProductBadgeGeneralOfferDto = {
  text?: MessageDto | null;
};
export type NinjaCartProductBadgeOfferDto = {
  options?: NinjaCartProductBadgeOfferLevelDto | null;
  currentEntries?: NinjaCartProductBadgeLevelOfferEntryTypeDto[] | null;
  availableEntries?: NinjaCartProductBadgeLevelOfferEntryTypeDto[] | null;
  supportsModules?: boolean | null;
  general?: NinjaCartProductBadgeGeneralOfferDto | null;
};
export type OfferWidgetSetupDtoNinjaCartProductBadgeOfferDto = {
  isHidden?: boolean | null;
  parentKey?: string | null;
  offerId?: string;
  setup?: NinjaCartProductBadgeOfferDto | null;
  title?: string | null;
  isDefault?: boolean;
  isAvailable?: boolean;
};
export type OfferWidgetSetupDtoNinjaCartProductBadgeOfferDtoRead = {
  isHidden?: boolean | null;
  parentKey?: string | null;
  offerId?: string;
  setup?: NinjaCartProductBadgeOfferDto | null;
  title?: string | null;
  isDefault?: boolean;
  isAvailable?: boolean;
  canBeHidden?: boolean;
  canBeRemoved?: boolean;
  canBeDragged?: boolean;
};
export type OffersSetupDto = {
  defaultLanguage?: string | null;
  productBanner?: OfferWidgetSetupDtoProductBannerOfferDto[] | null;
  promotionalBadge?: OfferWidgetSetupDtoPromotionalBadgeOfferDto[] | null;
  announcementBar?: OfferWidgetSetupDtoAnnouncementBarOfferDto[] | null;
  notification?: OfferWidgetSetupDtoNotificationOfferDto[] | null;
  drawerCartPromotionCodeField?:
    | OfferWidgetSetupDtoPromotionCodeFieldOfferDto[]
    | null;
  cartPromotionCodeField?:
    | OfferWidgetSetupDtoPromotionCodeFieldOfferDto[]
    | null;
  drawerCartPromotionSummary?:
    | OfferWidgetSetupDtoPromotionSummaryOfferDto[]
    | null;
  cartPromotionSummary?: OfferWidgetSetupDtoPromotionSummaryOfferDto[] | null;
  offerRulePopup?: OfferWidgetSetupDtoOfferRulePopupOfferDto[] | null;
  productPagePrice?: OfferWidgetSetupDtoPricingOfferDto[] | null;
  collectionPagePrice?: OfferWidgetSetupDtoPricingOfferDto[] | null;
  cartPageCartText?: OfferWidgetSetupDtoCartTextOfferDto[] | null;
  drawerCartPageCartText?: OfferWidgetSetupDtoCartTextOfferDto[] | null;
  discountLabel?: OfferWidgetSetupDtoDiscountLabelOfferDto[] | null;
  ninjaCartCartText?: OfferWidgetSetupDtoCartTextOfferDto[] | null;
  ninjaCartProductBadge?:
    | OfferWidgetSetupDtoNinjaCartProductBadgeOfferDto[]
    | null;
  ninjaCartPromotionSummary?:
    | OfferWidgetSetupDtoPromotionSummaryOfferDto[]
    | null;
};
export type OffersSetupDtoRead = {
  defaultLanguage?: string | null;
  productBanner?: OfferWidgetSetupDtoProductBannerOfferDtoRead[] | null;
  promotionalBadge?: OfferWidgetSetupDtoPromotionalBadgeOfferDtoRead[] | null;
  announcementBar?: OfferWidgetSetupDtoAnnouncementBarOfferDtoRead[] | null;
  notification?: OfferWidgetSetupDtoNotificationOfferDtoRead[] | null;
  drawerCartPromotionCodeField?:
    | OfferWidgetSetupDtoPromotionCodeFieldOfferDtoRead[]
    | null;
  cartPromotionCodeField?:
    | OfferWidgetSetupDtoPromotionCodeFieldOfferDtoRead[]
    | null;
  drawerCartPromotionSummary?:
    | OfferWidgetSetupDtoPromotionSummaryOfferDtoRead[]
    | null;
  cartPromotionSummary?:
    | OfferWidgetSetupDtoPromotionSummaryOfferDtoRead[]
    | null;
  offerRulePopup?: OfferWidgetSetupDtoOfferRulePopupOfferDtoRead[] | null;
  productPagePrice?: OfferWidgetSetupDtoPricingOfferDtoRead[] | null;
  collectionPagePrice?: OfferWidgetSetupDtoPricingOfferDtoRead[] | null;
  cartPageCartText?: OfferWidgetSetupDtoCartTextOfferDtoRead[] | null;
  drawerCartPageCartText?: OfferWidgetSetupDtoCartTextOfferDtoRead[] | null;
  discountLabel?: OfferWidgetSetupDtoDiscountLabelOfferDtoRead[] | null;
  ninjaCartCartText?: OfferWidgetSetupDtoCartTextOfferDtoRead[] | null;
  ninjaCartProductBadge?:
    | OfferWidgetSetupDtoNinjaCartProductBadgeOfferDtoRead[]
    | null;
  ninjaCartPromotionSummary?:
    | OfferWidgetSetupDtoPromotionSummaryOfferDtoRead[]
    | null;
};
export type PresetNameDto = {
  id?: string | null;
  name?: string | null;
  affectedPromotionsCount?: number | null;
};
export type PromotionPresetsDto = {
  promotionLevel?: PresetNameDto | null;
  shopLevel?: PresetNameDto | null;
};
export type PresetsSetupDto = {
  productBanner?: ProductBannerPresetDto | null;
  promotionalBadge?: PromotionalBadgePresetDto | null;
  announcementBar?: AnnouncementBarPresetDto | null;
  notification?: NotificationPresetDto | null;
  drawerCartPromotionCodeField?: PromotionCodeFieldPresetDto | null;
  cartPromotionCodeField?: PromotionCodeFieldPresetDto | null;
  drawerCartPromotionSummary?: PromotionSummaryPresetDto | null;
  cartPromotionSummary?: PromotionSummaryPresetDto | null;
  offerRulePopup?: OfferRulePopupPresetDto | null;
  productPagePrice?: ProductPagePricePresetDto | null;
  collectionPagePrice?: CollectionPagePricePresetDto | null;
  cartPageCartText?: CartTextPresetDto | null;
  drawerCartPageCartText?: CartTextPresetDto | null;
  ninjaCart?: NinjaCartPresetDto | null;
};
export type PresetsSetupDtoRead = {
  productBanner?: ProductBannerPresetDtoRead | null;
  promotionalBadge?: PromotionalBadgePresetDtoRead | null;
  announcementBar?: AnnouncementBarPresetDtoRead | null;
  notification?: NotificationPresetDtoRead | null;
  drawerCartPromotionCodeField?: PromotionCodeFieldPresetDtoRead | null;
  cartPromotionCodeField?: PromotionCodeFieldPresetDtoRead | null;
  drawerCartPromotionSummary?: PromotionSummaryPresetDtoRead | null;
  cartPromotionSummary?: PromotionSummaryPresetDtoRead | null;
  offerRulePopup?: OfferRulePopupPresetDtoRead | null;
  productPagePrice?: ProductPagePricePresetDtoRead | null;
  collectionPagePrice?: CollectionPagePricePresetDtoRead | null;
  cartPageCartText?: CartTextPresetDtoRead | null;
  drawerCartPageCartText?: CartTextPresetDtoRead | null;
  ninjaCart?: NinjaCartPresetDtoRead | null;
};
export type PromotionSetupDto = {
  id?: string;
  title?: string | null;
  productBanner?: PromotionWidgetSetupDtoProductBannerPromotionDto | null;
  promotionalBadge?: PromotionWidgetSetupDtoPromotionalBadgePromotionDto | null;
  announcementBar?: PromotionWidgetSetupMultiLevelDtoAnnouncementBarPromotionDtoAnnouncementBarFirstLevelPromotionDtoAnnouncementBarFirstLevelPromotionEntryTypeDto | null;
  notification?: PromotionWidgetSetupMultiLevelDtoNotificationPromotionDtoNotificationStatesPromotionDtoNotificationStatesPromotionEntryTypeDto | null;
  drawerCartPromotionCodeField?: PromotionWidgetSetupMultiLevelDtoPromotionCodeFieldPromotionDtoPromotionCodeFieldFirstLevelPromotionDtoPromotionCodeFieldPromotionEntryTypeDto | null;
  cartPromotionCodeField?: PromotionWidgetSetupMultiLevelDtoPromotionCodeFieldPromotionDtoPromotionCodeFieldFirstLevelPromotionDtoPromotionCodeFieldPromotionEntryTypeDto | null;
  drawerCartPromotionSummary?: PromotionWidgetSetupMultiLevelDtoPromotionSummaryPromotionDtoPromotionSummaryLevelPromotionDtoPromotionSummaryLevelPromotionEntryTypeDto | null;
  cartPromotionSummary?: PromotionWidgetSetupMultiLevelDtoPromotionSummaryPromotionDtoPromotionSummaryLevelPromotionDtoPromotionSummaryLevelPromotionEntryTypeDto | null;
  offerRulePopup?: PromotionWidgetSetupMultiLevelDtoOfferRulePopupPromotionDtoOfferRulePopupFirstLevelPromotionDtoOfferRulePopupPromotionEntryTypeDto | null;
  productPagePrice?: PromotionWidgetSetupMultiLevelDtoPricingPromotionDtoPricingLevelPromotionDtoPricingLevelPromotionEntryTypeDto | null;
  collectionPagePrice?: PromotionWidgetSetupMultiLevelDtoPricingPromotionDtoPricingLevelPromotionDtoPricingLevelPromotionEntryTypeDto | null;
  cartPageCartText?: PromotionWidgetSetupDtoCartTextPromotionDto | null;
  drawerCartPageCartText?: PromotionWidgetSetupDtoCartTextPromotionDto | null;
  discountLabel?: PromotionWidgetSetupDtoDiscountLabelPromotionDto | null;
  ninjaCartCartText?: PromotionWidgetSetupDtoNinjaCartCartTextPromotionDto | null;
  ninjaCartPromotionSummary?: PromotionWidgetSetupMultiLevelDtoNinjaCartPromotionSummaryPromotionDtoNinjaCartPromotionSummaryLevelPromotionDtoNinjaCartPromotionSummaryLevelPromotionEntryTypeDto | null;
  ninjaCartProductBadge?: PromotionWidgetSetupDtoNinjaCartProductBadgePromotionDto | null;
  offers?: OffersSetupDto | null;
  presets?: PromotionPresetsDto | null;
  presetSetup?: PresetsSetupDto | null;
  pages?: PageSetupEntryDto[] | null;
};
export type PromotionSetupDtoRead = {
  id?: string;
  title?: string | null;
  productBanner?: PromotionWidgetSetupDtoProductBannerPromotionDtoRead | null;
  promotionalBadge?: PromotionWidgetSetupDtoPromotionalBadgePromotionDtoRead | null;
  announcementBar?: PromotionWidgetSetupMultiLevelDtoAnnouncementBarPromotionDtoAnnouncementBarFirstLevelPromotionDtoAnnouncementBarFirstLevelPromotionEntryTypeDtoRead | null;
  notification?: PromotionWidgetSetupMultiLevelDtoNotificationPromotionDtoNotificationStatesPromotionDtoNotificationStatesPromotionEntryTypeDtoRead | null;
  drawerCartPromotionCodeField?: PromotionWidgetSetupMultiLevelDtoPromotionCodeFieldPromotionDtoPromotionCodeFieldFirstLevelPromotionDtoPromotionCodeFieldPromotionEntryTypeDtoRead | null;
  cartPromotionCodeField?: PromotionWidgetSetupMultiLevelDtoPromotionCodeFieldPromotionDtoPromotionCodeFieldFirstLevelPromotionDtoPromotionCodeFieldPromotionEntryTypeDtoRead | null;
  drawerCartPromotionSummary?: PromotionWidgetSetupMultiLevelDtoPromotionSummaryPromotionDtoPromotionSummaryLevelPromotionDtoPromotionSummaryLevelPromotionEntryTypeDtoRead | null;
  cartPromotionSummary?: PromotionWidgetSetupMultiLevelDtoPromotionSummaryPromotionDtoPromotionSummaryLevelPromotionDtoPromotionSummaryLevelPromotionEntryTypeDtoRead | null;
  offerRulePopup?: PromotionWidgetSetupMultiLevelDtoOfferRulePopupPromotionDtoOfferRulePopupFirstLevelPromotionDtoOfferRulePopupPromotionEntryTypeDtoRead | null;
  productPagePrice?: PromotionWidgetSetupMultiLevelDtoPricingPromotionDtoPricingLevelPromotionDtoPricingLevelPromotionEntryTypeDtoRead | null;
  collectionPagePrice?: PromotionWidgetSetupMultiLevelDtoPricingPromotionDtoPricingLevelPromotionDtoPricingLevelPromotionEntryTypeDtoRead | null;
  cartPageCartText?: PromotionWidgetSetupDtoCartTextPromotionDtoRead | null;
  drawerCartPageCartText?: PromotionWidgetSetupDtoCartTextPromotionDtoRead | null;
  discountLabel?: PromotionWidgetSetupDtoDiscountLabelPromotionDtoRead | null;
  ninjaCartCartText?: PromotionWidgetSetupDtoNinjaCartCartTextPromotionDtoRead | null;
  ninjaCartPromotionSummary?: PromotionWidgetSetupMultiLevelDtoNinjaCartPromotionSummaryPromotionDtoNinjaCartPromotionSummaryLevelPromotionDtoNinjaCartPromotionSummaryLevelPromotionEntryTypeDtoRead | null;
  ninjaCartProductBadge?: PromotionWidgetSetupDtoNinjaCartProductBadgePromotionDtoRead | null;
  offers?: OffersSetupDtoRead | null;
  presets?: PromotionPresetsDto | null;
  presetSetup?: PresetsSetupDtoRead | null;
  pages?: PageSetupEntryDto[] | null;
};
export type WidgetUsageOverviewDto = {
  widget?: WidgetTypeDto | null;
  offersCount?: number;
};
export type WidgetPageOverviewDto = {
  page?: PageTypeDto | null;
  usedWidgets?: WidgetUsageOverviewDto[];
  availableWidgets?: WidgetTypeDto[];
};
export type WidgetsPromotionOverviewDto = {
  pages?: WidgetPageOverviewDto[];
  preset?: PresetPreviewDto;
};
export type ChangePromotionPresetRequestDto = {
  presetId?: string;
};
export type ResourceDto = {
  id?: string;
  fileName?: string;
  url?: string;
};
export type ResourcesUploadResponseDto = {
  resources?: ResourceDto[];
};
export const {
  useFontsQuery,
  usePresetsQuery,
  useIconsQuery,
  useGetWidgetsV6LookupIconsCustomQuery,
  useImagesQuery,
  useGetWidgetsV6LookupImagesCustomQuery,
  useClockStylesQuery,
  useListQuery,
  useCreateMutation,
  useDeleteWidgetsV6PresetsByIdMutation,
  useGetWidgetsV6PresetsByIdQuery,
  usePutWidgetsV6PresetsByIdMutation,
  usePostWidgetsV6PresetsByIdDuplicateMutation,
  usePutWidgetsV6PresetsByIdRenameMutation,
  useGetWidgetsV6PresetsShopDefaultShopLevelQuery,
  useGetWidgetsV6PresetsShopDefaultPromotionLevelQuery,
  useGetWidgetsV6PresetsByIdAffectedPromotionsQuery,
  useGetWidgetsV6PresetsAffectedPromotionsShopLevelQuery,
  useGetWidgetsV6PresetsByIdAffectedPromotionsOthersQuery,
  usePostWidgetsV6PresetsByIdDefaultMutation,
  usePostWidgetsV6PresetsByIdApplyToAllPromotionsMutation,
  useSystemDefaultsQuery,
  useWidgetDefaultsQuery,
  usePostWidgetsV6PromotionByIdTransactionCloseMutation,
  useGetWidgetsV6PromotionByIdQuery,
  usePutWidgetsV6PromotionByIdMutation,
  useGetWidgetsV6PromotionByIdOverviewQuery,
  usePutWidgetsV6PromotionByIdChangePresetMutation,
  usePostWidgetsV6ResourcesUploadImageMutation,
  usePostWidgetsV6ResourcesUploadIconMutation,
  useDeleteWidgetsV6ResourcesIconsAllMutation,
  useDeleteWidgetsV6ResourcesImagesAllMutation,
  useDeleteWidgetsV6ResourcesIconByIdMutation,
  useDeleteWidgetsV6ResourcesImagesByIdMutation,
} = injectedRtkApi;
