import React from 'react';
type CheckCircleProps = {
  checked: boolean;
};
export const CheckCircle: React.FC<CheckCircleProps> = ({ checked }) => {
  return checked ? (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <circle
        cx='10'
        cy='10'
        r='7'
        fill='#1A1A1A'
        stroke='#1A1A1A'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M13.5059 8.64256C13.8314 8.31712 13.8314 7.78949 13.5059 7.46405C13.1805 7.13861 12.6528 7.13861 12.3274 7.46405L9.02778 10.7637L7.67259 9.40849C7.34715 9.08306 6.81951 9.08306 6.49408 9.40849C6.16864 9.73393 6.16864 10.2616 6.49408 10.587L8.43852 12.5314C8.76396 12.8569 9.2916 12.8569 9.61703 12.5314L13.5059 8.64256Z'
        fill='white'
      />
    </svg>
  ) : (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <circle
        cx='10'
        cy='10'
        r='7'
        stroke='#8A8A8A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeDasharray='2 3'
      />
    </svg>
  );
};
